import React, { useEffect } from "react";
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { CurrentTableBateryMobile } from "./CurrentTableBateryMobile";
import { CurrentTableBateryDesktop } from "./CurrentTableBateryDesktop";

export const CurrentTableBatery = (props) => {
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, { label: "Fecha", key: "fecha" }, { label: "Intensidad (A)", key: "i" }, { label: "Potencia (kW)", key: "p" }, { label: "Voltaje (V)", key: "v" }, { label: "Carga (%)", key: "soc" }]
    const headTable = ["Nombre", "Fecha", "Intensidad(A)", "Potencia (kW)", "Voltaje (V)", "Carga"]

    useEffect(() => {
        props?.setHeadersCsv(variableTableSelfConsumption)
    }, [])

    return (
        <>
            {props?.isMobile === true ?
                <CurrentTableBateryMobile selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    isMobile={props?.isMobile}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} />
                :
                <CurrentTableBateryDesktop headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} loading={props?.loading} />
            }
        </>
    )
}

