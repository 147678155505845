import React, { useState, useEffect, useContext } from "react"
import { GlobalContext } from 'context/GlobalContext'
import { Menu, MenuItem, Button } from '@mui/material'
import { SwitchStyled } from "components/Switch/SwitchStyled"
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { FilterIconScada } from "utils/icon-centrocontrol"
import { result } from "utils/functionsforGraphs"

export const Filter = (props) => {
    const [noRepeatData, setNoRepeatData] = useState([])
    const { updateDevice } = useContext(GlobalContext)

    useEffect(() => {
        setNoRepeatData([])
        if (props?.data1) {
            const setObj = new Set();
            const uniqs = props?.data1?.map(device => result(props?.data1, device?.numserie))
            const noRepeat = uniqs?.reduce((acc, device) => {
                if (!setObj.has(device?.numserie)) {
                    setObj.add(device?.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setNoRepeatData(noRepeat)
        }
    }, [props?.data1, props?.axis, updateDevice, props?.deviceType])

    const [initDevice, setInitDevice] = useState(noRepeatData.map(device => device?.numserie))

    useEffect(() => {
        setInitDevice(noRepeatData.map(device => device?.numserie))
    }, [props?.finalData, noRepeatData, props?.axis])

    let selec = document.getElementsByName('dv');
    let updatedList = []
    for (let i = 0; i < selec.length; i++) {
        if (selec[i].checked === true) {
            updatedList.push(parseInt(selec[i].value))
        }
    }

    const selectCurrentDevice = (e) => {
        props?.setActivo(false)
        updatedList = deSelects()
        updatedList.push(parseInt(e.target.parentElement.id))
        let selec = document.getElementsByName('dv')
        for (let i = 0; i < selec.length; i++) {
            if (selec[i].value === e.target.parentElement.id)
                selec[i].checked = true
        }
        return updatedList
    }

    const handleCheck = (e) => {
        props?.setLoading(false)
        if (e.target.checked) {
            updatedList = [...props?.checked, parseInt(e.target.value)]
        } else {
            updatedList.splice(updatedList.indexOf(parseInt(e.target.value)), 1)
        }
        props?.setChecked(updatedList)
        if ((props?.data1.length !== updatedList.length + 1) && (updatedList.length !== 0)) {
            props?.setActivo(true)
        } else {
            props?.setActivo(false)
        }
        return updatedList
    }

    let isChecked = (item) => props?.checked.includes(item) ? 'checked-item' : 'not-checked-item'

    const pulsarBoton = () => {
        props?.setActivo(current => !current)
        if (props?.activo) {
            deSelects()
        } else {
            selects()
        }
    }

    const selects = () => {
        let selec = document.getElementsByName('dv')
        updatedList = [];
        for (let i = 0; i < selec.length; i++) {
            if (selec[i].type === 'checkbox')
                selec[i].checked = true;
        }
        for (const devices of props?.data1) {
            updatedList.push(devices.numserie)
        }
        props?.setChecked(updatedList)
        return updatedList
    }

    const deSelects = () => {
        let selec = document.getElementsByName('dv')
        for (let i = 0; i < selec.length; i++) {
            if (selec[i].type === 'checkbox')
                selec[i].checked = false
        }
        updatedList = []
        props?.setChecked([])
        return updatedList
    }

    useEffect(() => {
        if (updatedList?.length > 0) {
            props?.setUpdatedList(updatedList)
        } else if (props?.activo === true && props?.isMobile === true) {
            props?.setUpdatedList(initDevice)
        } else {
            props?.setUpdatedList(props?.checked)
        }
    }, [props?.device, props.checked, props?.daily, props?.start, props?.end, props?.activo, props?.loading, props?.data1, initDevice])

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [deleteRefresh, setDeleteRefresh] = useState(false)

    useEffect(() => {
        if (deleteRefresh !== false) {
            setDeleteRefresh(false)
        }
    })

    useEffect(() => {
        noRepeatData?.sort((a, b) => {
            const nickA = a?.nick?.toLowerCase()
            const nickB = b?.nick?.toLowerCase()

            if (nickA < nickB) {
                return -1
            }
            if (nickA > nickB) {
                return 1
            }
            return 0
        })
    }, [noRepeatData])

    return (
        <span className="listFilter">
            <div className="checkAll" >
                <input type="button" onClick={pulsarBoton} value='Todos' className={props?.activo ? 'button-filter-active pd-filter' : 'button-filter pd-filter'} id="filterButtonAll" />
            </div>
            <div className="checkList" >
                {props?.isMobile === true ?
                    <>
                        <Button id="fade-button" onClick={handleClick}>
                            <FilterIconScada width="30" height="30" color="#8c8c8c" className="icon-filter-mobile-menu" />
                        </Button>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className="pr-2">
                            {noRepeatData?.map((devices) => {
                                return (
                                    <MenuItem >
                                        <SwitchStyled value={devices.numserie} name='dv' defaultChecked={true} onChange={handleCheck} className="checkboxFilter switch-small" />
                                        <div
                                            id={devices.numserie}
                                            onClick={selectCurrentDevice}
                                            className=" mobile-filter-menu"
                                        >
                                            <p className="filter-name">{devices.nick}</p>
                                        </div>
                                        <span className={isChecked(devices)} id="button-menu-device" />
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    </>
                :
                    <>
                        {noRepeatData?.map((devices) => {
                            let device = props?.devices?.childrenDevices?.find(ele => Number(ele?.numserie) === Number(devices?.numserie))
                            return (
                                <button className="button-filter" onClick={() => props?.setSelectedDevice(devices)}>
                                    <SwitchStyled value={devices?.numserie} name='dv' defaultChecked={true} onChange={handleCheck} className="checkboxFilter switch-small" />
                                    <div id={devices?.numserie} onClick={selectCurrentDevice} >
                                        <p className="filter-name">{devices?.nick}</p>
                                    </div>
                                    <span className={isChecked(devices)} id="button-menu-device"  >
                                        <DropDownDevices
                                            selectedDevice={devices}
                                            end={props?.end}
                                            start={props?.start}
                                            icon="text"
                                            device={device ? device : devices}
                                            setRefresh={props?.setRefresh}
                                            instalacion={props?.masterPermission?.[props?.devices?.master_serial ? props?.devices?.master_serial : props?.devices?.mabt_serial ? props?.devices?.mabt_serial : props?.devices?.id_padre ? props?.devices?.id_padre : props?.devices?.numserie]}
                                            masterPermission={props?.masterPermission}
                                        />
                                    </span>
                                </button>
                            )
                        })}
                    </>
                }
            </div>
        </span>
    )
}
