import React, { useEffect } from "react"
import useGoogleMap from "./useGoogleMap"

const Map = ({ center, zoom, children, events, defaultOptions, mapTypeId }) => {
  const { maps, map, mapRef, loading } = useGoogleMap({ zoom, center, events, defaultOptions, mapTypeId })

  useEffect(() => {
    map && map.panTo(center)
  }, [center.lat, center.lng,])

  return (
    <div className="map-container" style={{ height: '75vh' }}>
      <div ref={mapRef} className="map-ref" map={map} />
      {!loading &&
        React.Children.map(children, child => {
          return React.cloneElement(child, { map, maps })
        })}
    </div>
  )
}
export default Map
