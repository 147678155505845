import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, TextField, Card } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { ButtonLogin } from 'components/Buttons/ButtonLogin'
import { iniciarSesion } from 'views/User/Functions/Sesion'
import { headersUsersLogin } from 'services/headers'
import { validatorEmail, validatorPass } from 'utils/ValidatorForm'
import { urls } from 'utils/constant'
import { RememberPassword } from '../RememberPassword/RememberPassword'
import '../styles.scss'

export const LoginForm = (props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [mensajeError, setMensajeError] = useState("")
    const [visible, setVisible] = useState(false)
    const [visibleMessage, setVisibleMessage] = useState('')
    const [validMail, setValidMail] = useState(true)
    const [passwordShow, setPasswordShow] = useState(false)
    const [validPass, setValidPass] = useState(false)
    const validateForm = () => email.length > 0 && validMail && password.length > 0 && !validPass

    const handleReCaptchaVerify = () => {
        asynLogin()
    }

    /**
     * switch para poder añadir más opciones de respuesta en un futuro.
     * @param {*} obj objeto con status y mensaje recibido durante error en la llamada
     * @returns mensaje correspondiente según error de login
     */
    const errorMessage = (obj) => {
        let message = obj.message
        if (obj?.status === 432 || obj?.status === 433) {
            message = 'Correo o contraseña incorrecta'
        }
        return message
    }

    /// New asynLogin
    const asynLogin = () => {
        setLoading(true)
        fetch(urls.users + 'auth/login', {
            method: 'POST', redirect: 'follow', headers: headersUsersLogin,
            body: JSON.stringify({
                'email': email,
                'pass': password
            })
        })
        .then(res => {
            if ([200, 400, 432, 433, 434, 215].indexOf(res.status) > -1) {
                return res.json()
            } else {
                throw res.json()
            }
        })
        .then(data => {
            setLoading(false)
            if (data.status === 434) {
                setVisible(true)
                setVisibleMessage(errorMessage({ message: 'Error al ingresar las credenciales' }))
            } else if ([400, 401, 432, 433].indexOf(data.status) > -1) {
                setVisible(true)
                setMensajeError(false)
                setVisibleMessage(errorMessage(data))
            } else if ([215].indexOf(data.status) > -1) {
                document.authentication_formAcceso.email.value = email
                document.authentication_formAcceso.pass.value = password
                document.authentication_formAcceso.submit()
            }
            else {
                if (data?.services?.includes(4)) {
                    document.authentication_formAcceso_integracion.token_minerva.value = data?.token
                    document.authentication_formAcceso_integracion.submit()
                } else {
                    iniciarSesion(email, data?.token, data)
                    sessionStorage.setItem('name', data?.name)
                    localStorage.setItem('lastLogin', data?.latestLogin)
                    if (data.token) {
                        sessionStorage.setItem('token', data?.token)
                        sessionStorage.setItem('rol', data?.rol)
                        sessionStorage.setItem('email', email)
                        navigate(`/projects/inicio`)
                    }
                }
            }
        }).catch(err => {
            console.error("Error parsing error response:", err?.message)
        }).finally(() => {
            setVisible(false)
            setLoading(false)
        })
    }

    const handleAsynLogin = (e) => {
        e.preventDefault()
        handleReCaptchaVerify()
    }

    const validateEmail = () => {
        if (email.length > 0) {
            setValidMail(validatorEmail(email))
        }
    }

    const handleBlurMail = () => {
        validateEmail()
    }

    const validatePass = (e) => {
        setPassword(e)
        setValidPass(validatorPass(e))
    }

    return (
        <>
            <form users={props?.users} style={{ width: "100%" }} autoSave="off" onSubmit={handleAsynLogin}>
                <Grid item xs={12} sm={12} lg={12} xl={12} >
                    <span className="input-login">
                        <Grid item xs={12} sm={12} lg={6} xl={6} className="input-login-text">
                            <TextField
                                required
                                placeholder='Email*'
                                type="email"
                                value={email}
                                name="email"
                                className="textfied-login"
                                onChange={(e) => setEmail(e.target.value)}
                                error={!validMail}
                                helperText={!validMail ? 'Email inválido' : ''}
                                onBlur={handleBlurMail}
                                inputProps={{ maxLength: 50 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6} xl={6} className="input-login-text mb-2">
                            <TextField
                                required placeholder="Contraseña"
                                type={passwordShow ? "text" : "password"}
                                autoComplete="off"
                                className="textfied-login"
                                value={password}
                                error={validPass}
                                helperText={validPass ? 'Caracteres no permitidos' : ''}
                                name="password"
                                inputProps={{
                                    maxLength: 65
                                }}
                                InputProps={{
                                    endAdornment:
                                        passwordShow ?
                                            <HtmlTooltip title={'Ocultar contraseña'}>
                                                <FontAwesomeIcon
                                                    name='eye'
                                                    onClick={() => setPasswordShow(false)}
                                                    icon={faEyeSlash}
                                                    className="font-size-lg pointer"
                                                />
                                            </HtmlTooltip>
                                        :
                                            <HtmlTooltip title={'Mostrar contraseña'}>
                                                <FontAwesomeIcon
                                                    name='eye'
                                                    onClick={() => setPasswordShow(true)}
                                                    icon={faEye}
                                                    className="font-size-lg pointer"
                                                />
                                            </HtmlTooltip>
                                }}
                                onChange={(e) => validatePass(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={10} sm={10} lg={6} xl={6} className="input-login-text">
                            <small className="hoverAction mt-1 mb-1">
                                <RememberPassword email={email} />
                            </small>
                        </Grid>
                    </span>
                    {mensajeError &&
                        <Grid
                            item
                            xs={12} sm={12} lg={12} xl={12}
                            className='d-flex justify-content-center'
                        >
                            <Card
                                color='danger'
                                style={{
                                    padding: '5px',
                                    textAlign: 'center',
                                    backgroundColor: '#f75676',
                                    color: '#fff',
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    width: '50%'
                                }}
                            >
                                {mensajeError}
                            </Card>
                        </Grid>
                    }
                    <Grid
                        item xs={12}
                        sm={12} lg={12}
                        xl={12}
                        className='d-flex justify-content-center'
                    >
                        {visible &&
                            <Card
                                color='danger'
                                style={{
                                    padding: '5px',
                                    textAlign: 'center',
                                    backgroundColor: '#f75676',
                                    color: '#fff', marginTop: '15px',
                                    marginBottom: '15px',
                                    width: '50%'
                                }}
                            >
                                {visibleMessage}
                            </Card>
                        }
                    </Grid>
                    <div className="text-center mt-4 mb-4 d-flex justify-content-center">
                        <Grid item xs={4} sm={4} lg={3} xl={3}>
                            <ButtonLogin
                                disabled={!validateForm()}
                                loading={loading}
                            >
                                Entrar
                            </ButtonLogin>
                        </Grid>
                    </div>
                    <span>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="counter-separator">
                            <span className="line-separator-login"></span>
                            <span className="rounded-separator-login"></span>
                            <span className="line-separator-login"></span>
                        </Grid>
                    </span>
                </Grid>
            </form >
            {/* 
                <Modal open={resendMail} setOpen={reToggle} title={`Confirmación usuario`} functSave={buttonResendMail} loading={loading} buttonAction="Reenviar Email">
                    <Grid container className="text-center mt-2 pr-2 pl-2">
                        <h3> Te hemos enviado un email de confirmación</h3>
                        <p>
                            Se ha enviado un email con sus credenciales. Tiene 48 horas para iniciar sesión por primera vez y finalizar su proceso de registro.
                            Por favor, si no ve el email en su bandeja de entrada, revise su bandeja de entrada de spam.
                        </p>
                    </Grid>
                    <hr className='m-0' />
                </Modal> 
            */}
        </>
    )
}