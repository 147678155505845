import React, { useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import "../styleEms.scss"
import { headersScada } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { urls } from 'utils/constant'
import { useDispatch } from 'react-redux'
import { deviceName } from 'utils/Utils'
import { SelectStyled } from 'components/Select/SelectStyled'
import { modeEMS } from 'utils/constant'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { ModalActionEMS } from './ModalActionEMS'
import { IconSave } from 'utils/icon-centrocontrol'
import { formatDate } from 'utils/Utils'
import { dispositivos } from 'utils/constant'
import { ButtonModal } from 'components/Buttons/ButtonModal'

export const ModalEditMaxPower = ({ device, open, setOpen, setKeySwitch, dataDevices }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const [maxPower, setMaxPower] = useState(device?.potmax?.max_power)
    const [body, setBody] = useState([])
    const [enable, setEnable] = useState(device?.potmax?.enable)
    const [manual, setManual] = useState(device?.potmax?.manual_conn)
    const [order, setOrder] = useState()
    const [config, setConfig] = useState()
    const [openModal, setOpenModal] = useState(false)
    const [change, setChange] = useState([])
    const data = dataDevices && dataDevices?.filter(dev => dev?.id_tipo_dispositivo === (dispositivos.enchufe && dispositivos.ccmaster && dispositivos.inversor))

    const dispatch = useDispatch()

    const relay = (rele) => {
        switch (rele) {
            case 1:
                return "Relay 1";
            case 2:
                return "Relay 2";
            default:
                return "Relay 1"
        }
    }

    const fetchEditMaxPower = (numserie) => {
        setLoading(true)
        let status
        fetch(urls.scada + `emsConfig/${numserie}?idCcMaster=${numserie}`, {
            method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body)
        }).then(res => {
            if (res.status === 200) {
                status = res.status
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
            } else {
                status = res.status
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
        }).catch((error) => {
            console.error("Ha habido un erroe en API Scada", error)
        }).finally(() => {
            setLoading(false)
            setKeySwitch(Date.now())
            setOpen(false)
        })
    }

    const handleEdit = () => { fetchEditMaxPower(device.master?.numserie) }

    const arrayOrder = dataDevices.map((_, index) => ({ value: index + 1 }));

    const handleOrderChange = (dev, modeValue) => {
        setOrder(prevState => ({
            ...prevState,
            [`${dev.numserie}-${dev.id_entry}`]: modeValue
        }));
        let find = change.filter(ele => ele.numserie === dev.numserie && ele.id === dev.id_entry)
        if (find.length === 0) {
            setChange(prev => [...prev, { numserie: dev.numserie, id: dev.id_entry, control_order: modeValue }])
        } else if (modeValue === dev?.control_order) {
            let modeDefault = change.filter(ele => ele.numserie !== dev.numserie || ele.id !== dev.id_entry)
            setChange(modeDefault)
        } else {
            let aux = [...change]
            aux.forEach(ele => {
                if (ele.numserie === dev.numserie && ele.id === dev.id_entry) {
                    ele.control_order = modeValue
                }
            })
            setChange(aux)
        };
    }

    const disableButton = (dev) => {
        let find = change.filter(ele => ele.numserie === dev.numserie && ele.id === dev.id_entry)
        const dis = find.length > 0 ? false : true
        return dis
    }

    useEffect(() => {
        setBody({
            potmax: {
                "enable": enable,
                "manual_conn": manual,
                "max_power": Number(maxPower),
            }
        }
        )
    }, [device, enable, manual, maxPower])

    const disabledModal = () => device.potmax.enable === enable && device.potmax.manual_conn === manual && device.potmax.max_power === Number(maxPower)

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} open={open} setOpen={setOpen} disabled={loading || disabledModal()} title={`Editar máxima potencia de ${device?.master?.nick}`} loading={loading} footer=" " maxWidth="xl">
                <Grid item xs={12} className="container-modal-max-power">
                    <span className="section-modal-edit-mp">
                        <span>
                            <h4>Activado/desactivado</h4>
                            <SwitchStyled id="habilitado" className="switch-small" checked={enable} onClick={() => setEnable(!enable)} />
                        </span>
                        <span>
                            <h4>Reconexión Manual</h4>
                            <SwitchStyled id="habilitado" className="switch-small" checked={manual} onClick={() => setManual(!manual)} />
                        </span>
                        <span className="section-modal-edit-mp input-mp-modal">
                            <h4>Máxima potencia en la instalación (W)</h4>
                            <InputStyled
                                defaultValue={device?.potmax?.max_power}
                                value={maxPower}
                                onChange={(e) => setMaxPower(e)}
                            >
                            </InputStyled>
                            <ButtonModal
                                fullWidth={true}
                                disabled={loading || disabledModal()}
                                loading={loading}
                                className="button-ok-modal"
                                onClick={() => handleEdit()}
                            >
                                Guardar
                            </ButtonModal>
                        </span>
                    </span>
                    <span className="table-modal-edit-mp">
                        <TableContainer>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                                    <TableRow>
                                        <StyledTableCellHead align="center">Tipo de dispositivo</StyledTableCellHead>
                                        <StyledTableCellHead align="center">Dispositivo</StyledTableCellHead>
                                        <StyledTableCellHead align="center">Fecha última actualización</StyledTableCellHead>
                                        <StyledTableCellHead align="center">Relay</StyledTableCellHead>
                                        <StyledTableCellHead align="center">Modo</StyledTableCellHead>
                                        <StyledTableCellHead align="center">Orden</StyledTableCellHead>
                                        <StyledTableCellHead align="center"></StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.map((dev, i) => {
                                        const nameMode = modeEMS.find(mode => mode.value === dev.mode)
                                        return (
                                            <StyledTableRow key={i}>
                                                {dev.id_tipo_dispositivo !== null && ///////////QUITAR
                                                    <><StyledTableCell align="center">{deviceName(dev?.id_tipo_dispositivo)}</StyledTableCell>
                                                        <StyledTableCell align="center">{dev?.nick}</StyledTableCell>
                                                        <StyledTableCell align="center">{formatDate(dev.datetime)}</StyledTableCell>
                                                        <StyledTableCell>{relay(dev.id_entry)}</StyledTableCell>
                                                        <StyledTableCell align="center">{nameMode && nameMode.mode} </StyledTableCell><StyledTableCell align="center">
                                                            {dev.mode === 6 ?
                                                                <FormControl className='width-100' size="small">
                                                                    <SelectStyled
                                                                        fullWidth
                                                                        size="small"
                                                                        value={order} defaultValue={dev.control_order} onChange={(e) => handleOrderChange(dev, e.target.value)}>
                                                                        {arrayOrder.map((prm, i) => {
                                                                            return (
                                                                                <MenuItem value={prm.value} key={i}>
                                                                                    {prm.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })}
                                                                    </SelectStyled>
                                                                </FormControl> : <p> Mode: Normal </p>}
                                                        </StyledTableCell><StyledTableCell>
                                                            <ButtonStyledIcon className='boton-icon mr-1' title={'Guardar'} onClick={() => { setOpenModal(!openModal); setConfig(dev) }} disabled={disableButton(dev)}>
                                                                <IconSave className='color-icon' />
                                                            </ButtonStyledIcon></StyledTableCell></>}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </span>
                </Grid>
            </Modal>
            {openModal && <ModalActionEMS device={config} open={openModal} setOpen={setOpenModal} order={order} master={device.master} setKeySwitch={setKeySwitch} />}
        </>)
}
