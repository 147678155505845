import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Loader } from 'components/Loader/Loader'
import { devicesAllowedTablePlant, urls } from 'utils/constant'
import { IconOk } from 'utils/icon-centrocontrol'
import { headersScada } from 'services/headers'
import { ModalConfigGlobalEms } from './Modal/ModalConfigGlobalEms'
import { ModalConfirmEms } from './Modal/ModalConfirmEms'
import { TableConfigPlant } from './TableConfigPlant'
import { InyectionEms } from './InyectionEms'
import { SurplusEms } from './SurplusEms'
import { BateryEms } from './BateryEms'
import { PotMaxEms } from './PotMaxEms'
import "./styleEms.scss"

export const EmsScada = ({ devices, autoconsumo, action }) => {
    const [actionTab, setActionTab] = useState(true)
    const [activeTabDevice, setActiveTabDevice] = useState(-1)
    const [emsEnable, setEmsEnable] = useState(false)
    const [configOrder, setConfigOrder] = useState(false)
    const [configOrderInitial, setConfigOrderInitial] = useState(false)
    const [disabled, setDisabled] = useState(true)
    const [loading, setLoading] = useState(false)
    const [loadingEms, setLoadingEms] = useState(false)
    const [configEms, setConfigEms] = useState({})
    const [openEmsEnable, setOpenEmsEnable] = useState(false)
    const [open, setOpen] = useState(false)
    const [meter, setMeter] = useState(false)
    const token = sessionStorage?.token

    const handleTab = (tab) => {
        setActionTab(!actionTab)
        setActiveTabDevice(tab)
    }

    const compareOptions = () => {
        let change = true
        configOrder?.forEach((ele, index) => {
            if (ele?.ID_plant !== configOrderInitial?.[index]?.ID_plant) {
                change = false
            }
        })
        setDisabled(change)
    }

    const fetchConfig = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `getConfigPlantInstallation/${devices?.[0]?.numserie}?idCCMaster=${devices?.[0]?.numserie}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                let data = json.filter(ele => devicesAllowedTablePlant?.includes(ele?.id_tipo_dispositivo))
                setConfigOrder(JSON.parse(JSON.stringify(data)))
                setConfigOrderInitial(JSON.parse(JSON.stringify(data)))
            }
        } catch (err) {
            console.error("No se ha podido traer datos de la API", err)
        } finally {
            setLoading(false)
        }
    }

    const fetchEms = async () => {
        setLoadingEms(true)
        try {
            const res = await fetch(urls.scada + `emsConfigScada/${devices?.[0]?.numserie}?idCcMaster=${devices?.[0]?.numserie}`, { method: 'GET', headers: headersScada(token) })
            let json = await res.json()
            if (res.status === 200) {
                setEmsEnable(json?.config?.enable)
                setConfigEms({ ...json })
            } else {
                setConfigEms({ ...json })
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        } finally {
            setLoadingEms(false)
        }
    }

    useEffect(() => {
        if (configOrder?.length > 0) {
            compareOptions()
        } 
    }, [emsEnable, configOrder, configOrderInitial])

    useEffect(() => {
        if (configOrderInitial?.length > 0) {
            let anyMeter = false
            configOrderInitial?.forEach(ele => {
                if (ele?.ID_plant === 'CONS_0001') {
                    anyMeter = true
                }
            })
            setMeter(anyMeter)
        }
    }, [configOrderInitial, emsEnable])

    useEffect(() => {
        fetchEms()
        fetchConfig()
    }, [action])

    return (
        <Grid container className="container-tab-ems-scada">
            <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                {(loading || loadingEms) && <Loader />}
            </Grid>
            {!loading && !loadingEms && configEms?.message !== undefined && 
                <Grid items xs={12} md={12} lg={12} xl={12} className="d-flex justify-content-center align-items-center flex-column p-4">
                    <h2>El máster no está conectado</h2>
                    <ButtonStyled onClick={() => fetchEms()}>Intentar Conectar</ButtonStyled>
                </Grid>
            }
            {!loading && !loadingEms && configEms?.message === undefined &&
                <>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <h3>Configuración de los dispositivos de la planta</h3>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <TableConfigPlant autoconsumo={autoconsumo} configOrder={configOrder} setConfigOrder={setConfigOrder} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className="button-apply-table d-flex justify-content-flex-end mt-3" >
                        <ButtonStyled size="small" color="#fff" background="#FF8A1F" disabled={disabled} onClick={() => setOpen(true)}>Aplicar</ButtonStyled>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <SwitchStyled id="emsEnable" className="switch-small" checked={emsEnable} onClick={() => setOpenEmsEnable(true)} />
                        <label className="ml-2 mb-0" htmlFor='emsEnable'> EMS </label>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} className='mt-3'>
                        <span className='listItem-componente'>
                            <ul className="nav nav-tabs mt-3" >
                                <li className={`flex-center margin-left-1-px mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${!emsEnable ? 'li-disabled' : ''} ${activeTabDevice === 0 && emsEnable ? "active-button" : null} `} onClick={() => emsEnable && handleTab(0)}>
                                    {configEms?.potmax?.enable && emsEnable && <IconOk />} Pot. Max
                                </li>
                                {autoconsumo &&
                                    <>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${!emsEnable ? 'li-disabled' : ''} ${activeTabDevice === 1 && emsEnable ? "active-button" : null} `} onClick={() => emsEnable && handleTab(1)}>
                                            {configEms?.self_consumption?.enable && emsEnable && <IconOk />} Inyección 0
                                        </li>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${!emsEnable ? 'li-disabled' : ''} ${activeTabDevice === 2 && emsEnable ? "active-button" : null} `} onClick={() => emsEnable && handleTab(2)}>
                                            {configEms?.battery?.enable && emsEnable && <IconOk />} Baterías
                                        </li>
                                        <li className={`flex-center ml-0 mr-2 list-item-icon  active-button-list-device pointer-button tab-style-mabt ${!emsEnable ? 'li-disabled' : ''} ${activeTabDevice === 3 && emsEnable ? "active-button" : null} `} onClick={() => emsEnable && handleTab(3)}>
                                            {configEms?.surplus?.enable && emsEnable && <IconOk />} Surplus
                                        </li>
                                    </>
                                }
                            </ul>
                        </span>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        {activeTabDevice === 0 && emsEnable &&
                            <PotMaxEms configEms={configEms} master={devices?.[0]} meter={meter} setConfigEms={setConfigEms} action={actionTab} />
                        }
                        {activeTabDevice === 1 && emsEnable &&
                            <InyectionEms configEms={configEms} master={devices?.[0]} setConfigEms={setConfigEms} />
                        }
                        {activeTabDevice === 2 && emsEnable &&
                            <BateryEms configEms={configEms} master={devices?.[0]} setConfigEms={setConfigEms} />
                        }
                        {activeTabDevice === 3 && emsEnable &&
                            <SurplusEms configEms={configEms} master={devices?.[0]} meter={meter} setConfigEms={setConfigEms} action={actionTab} />
                        }
                    </Grid>
                </>
            }
            {open &&
                <ModalConfigGlobalEms
                    master={devices?.[0]} open={open} setOpen={setOpen} configEms={configEms}
                    setConfigOrderInitial={setConfigOrderInitial}
                    configOrder={configOrder} configOrderInitial={configOrderInitial}
                />
            }
            {openEmsEnable && 
                <ModalConfirmEms
                    master={devices?.[0]} open={openEmsEnable}
                    setOpen={setOpenEmsEnable} configEms={configEms}
                    setConfigEms={setConfigEms} emsEnable={emsEnable} 
                    setEmsEnable={setEmsEnable}
                />
            }
        </Grid>
    )
}