import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Provider } from 'react-redux'
import { ScadaScreen } from 'views/Scada/General/ScadaScreen'
import { NotFound } from 'views/PageError/NotFound'
import Projects from "routes/layouts/Projects"
import AuthLayout from "routes/layouts/Auth"
import { Admin } from 'routes/layouts/Admin'
import { RecaptchaPublic } from 'services/headers'
import store from 'reduxSlice/store'
import { GlobalProvider } from 'context/GlobalContext'
import { ScadaEnerSim } from 'views/Scada/Enersim/ScadaEnerSim'
import { ToastResponse } from 'components/Toast/ToastResponse'
import { Lighting } from 'views/Scada/Lighting/Lighting'
import { ChangeLog } from 'views/ChangeLog/ChangeLog'
import { Consumption } from 'views/Scada/Consumption/Consumption'
import { MabtController } from 'views/Scada/MABT/MabController'
import { Photovoltaic } from 'views/Scada/Photovoltaic/Photovoltaic'

export const App = () => {

    if (sessionStorage?.tokenImpersonate) {
        sessionStorage.setItem('token', sessionStorage?.tokenImpersonate)
        sessionStorage.setItem('impersonate', true)
        sessionStorage.setItem('email', sessionStorage?.emailImpersonate)
        sessionStorage.removeItem('tokenImpersonate')
        sessionStorage.removeItem('emailImpersonate')
    }

    // Capture errors that ErrorBoundary class does not catch
    let div = document.getElementById('webpack-dev-server-client-overlay')
    let div2 = document.getElementById('webpack-dev-server-client-overlay-div')
    if (div) {
        div.parentNode.replaceChild(div)
    }
    if (div2) {
        div2.parentNode.replaceChild(div2)
    }

    return (
        <GoogleReCaptchaProvider reCaptchaKey={RecaptchaPublic} >
            <Provider store={store}>
                <GlobalProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/admin/*" element={<Admin />} />
                            <Route path="/projects/*" element={<Projects />} />
                            <Route path="/view/scada/:id/:numserieMaster" element={<ScadaScreen />} />
                            <Route path="/view/scada/:id" element={<ScadaEnerSim />} />
                            <Route path="/scada/lighting/:id/:numserieMaster" element={<Lighting />} />
                            <Route path="/scada/consumption/:id/:numserieMaster" element={<Consumption />} />
                            <Route path="/scada/distribution/:id/:numserieMaster" element={<MabtController />} />
                            <Route path="/scada/photovoltaic/:id/:numserieMaster" element={<Photovoltaic />} />
                            <Route path="/auth/*" element={<AuthLayout />} />
                            <Route path="/changelog" element={<ChangeLog />} />
                            <Route path="*" element={<Navigate to="/auth/login" replace />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </BrowserRouter>
                    <ToastResponse />
                </GlobalProvider>
            </Provider>
        </GoogleReCaptchaProvider>
    )
}