import React, { useEffect } from 'react'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { CurrentTableRedMobile } from './CurrentTableRedMobile'
import { CurrentTableRedDesktop } from './CurrentTableRedDesktop'

export const CurrentTableRed = (props) => {
    const variableTableSelfConsumption = [
        { label: "Nombre", key: "nick" },
        { label: "Fecha", key: "datetime" },
        { label: "Energía Vertida (kWh)", key: "eagendia" },
        { label: "Energía Consumida (kWh)", key: "eaconsdia" },
        { label: "Potencia Activa (kW)", key: "pact" },
    ]

    useEffect(() => {
        props?.setHeadersCsv(variableTableSelfConsumption)
    }, [])

    const headTable = ["Nombre", "Fecha", "Energía Vertida (kWh)", "Energía Consumida (kWh)", "Potencia Activa (kW)"]

    return (
        <>
            {props?.isMobile === true ?
                <CurrentTableRedMobile selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    isMobile={props?.isMobile}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} />
                :
                <CurrentTableRedDesktop selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    isMobile={props?.isMobile}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} />
            }
        </>
    )
}
