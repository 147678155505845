import React, { useEffect, useState } from "react"
import { DragDropContext } from "@hello-pangea/dnd"
import { Grid } from "@mui/material"
import { devices, getUniqueID, getIconComponent, deviceStatus, switchConfig } from "utils/Utils"
import { IconNewObject, IconSave, IconDeleteAlarm } from "utils/icon-centrocontrol"
import { areArraysEqual } from "utils/Utils"
import { urls } from "utils/constant"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { Loader } from "components/Loader/Loader"
import DropContainer from "./DropContainer"
import { ModalDeleteLayout } from "views/Modals/ModalLayout/ModalDeleteLayout"
import { ModalSaveLayout } from "views/Modals/ModalLayout/ModalSaveLayout"
import { ModalAddRelay } from "views/Modals/ModalLayout/ModalAddRelay"
import { headersScada } from "services/headers"
import "./LayOut.scss"

function LayOutBoard({ dispositivo }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openAddRelayModal, setOpenAddRelayModal] = useState(false)
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [columns, setColumns] = useState(deviceStatus)
  const [layoutData, setLayoutData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [isSavedDisabled, setIsSaveDisabled] = useState(false)
  const [relayCount, setRelayCount] = useState(1)
  const [moves, setMoves] = useState({})
  const [configuration, setConfiguration] = useState([])
  const isDeleteDisabled = !layoutData || (layoutData && layoutData?.length === 0)
  const relayIds = Object?.keys(columns)?.filter(key => key?.startsWith('Relay'))
  const token = sessionStorage?.token

  const onDragEnd = (result, columns, setColumns, moves, setMoves) => {
    if (!result?.destination) return
    const { source, destination } = result
    const updatedMoves = { ...moves }
    if (source?.droppableId === "Devices" && destination?.droppableId !== "Devices") {
      const sourceColumn = columns?.[source?.droppableId]
      const destColumn = columns?.[destination?.droppableId]
      const sourceItems = [...sourceColumn?.items]
      const destItems = [...destColumn?.items]
      const itemToClone = sourceItems?.[source?.index]
      const clonedItem = { ...itemToClone, id: getUniqueID() }
      const itemType = itemToClone?.device_type
      destItems?.splice(destination?.index, 0, clonedItem)
      setColumns({
        ...columns,
        [destination?.droppableId]: {
          ...destColumn,
          items: destItems
        }
      })
      // Decrease the move count for the device type
      if (itemType !== 25 && itemType !== 26) {
        updatedMoves[itemType] = updatedMoves[itemType] ? updatedMoves[itemType] - 1 : 0
      }
    } else if (source?.droppableId !== destination?.droppableId && destination?.droppableId === "Devices") {
      const sourceColumn = columns[source?.droppableId]
      const destColumn = columns[destination?.droppableId]
      const sourceItems = [...sourceColumn?.items]
      const destItems = [...destColumn?.items]
      const [removed] = sourceItems?.splice(source.index, 1)
      const itemType = removed?.device_type
      // Remove any existing item with the same name from Devices
      const filteredDestItems = destItems?.filter(item => item?.name !== removed?.name)
      filteredDestItems?.splice(destination?.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: filteredDestItems
        }
      })
      // Increase the move count for the device type
      if (itemType !== 25 && itemType !== 26) {
        updatedMoves[itemType] = updatedMoves?.[itemType] ? updatedMoves[itemType] + 1 : 1
      }
    } else if (source?.droppableId !== destination?.droppableId) {
      const sourceColumn = columns?.[source?.droppableId]
      const destColumn = columns?.[destination?.droppableId]
      const sourceItems = [...sourceColumn?.items]
      const destItems = [...destColumn?.items]
      const [removed] = sourceItems?.splice(source?.index, 1)
      destItems?.splice(destination?.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      })
    } else {
      const column = columns[source?.droppableId]
      const copiedItems = [...column?.items]
      const [removed] = copiedItems?.splice(source?.index, 1)
      copiedItems?.splice(destination?.index, 0, removed)
      setColumns({
        ...columns,
        [source?.droppableId]: {
          ...column,
          items: copiedItems
        }
      })
    }
    setMoves(updatedMoves)
  }

  const handleRemoveRelay = () => {
    if (relayCount > 1) {
      const updatedMoves = { ...moves }
      const lastRelayId = `Relay${relayCount}`
      setColumns((prevColumns) => {
        const newColumns = { ...prevColumns }
        delete newColumns[lastRelayId]
        return newColumns
      })
      setRelayCount(relayCount - 1)
      const devicesToRemove = columns?.[lastRelayId]?.items?.map((item) => item?.device_type) || []
      devicesToRemove.forEach((deviceType) => {
        updatedMoves[deviceType] = (updatedMoves?.[deviceType] || 0) + 1
      })
      setMoves(updatedMoves)
    }
  }

  const updateMovesCount = (data) => {
    if (!dispositivo || !dispositivo?.[0]) {
      return {}
    }

    const movesCount = {}

    if (dispositivo?.[0]?.childrenDevices && dispositivo?.[0]?.childrenDevices?.length > 0) {
      const devices = dispositivo?.[0]?.childrenDevices

      devices.forEach((child) => {
        const typeId = child?.id_tipo_dispositivo
        movesCount[typeId] = (movesCount?.[typeId] || 0) + 1
      })

      devices.forEach(device => {
        if(device.childrenDevices) {
          device.childrenDevices.forEach(child => {
            const typeId = child?.id_tipo_dispositivo
            movesCount[typeId] = (movesCount?.[typeId] || 0) + 1
          })
        }
      })
    }

    if (dispositivo?.[0]?.id_tipo_dispositivo !== null && dispositivo?.[0]?.id_tipo_dispositivo !== undefined) {
      movesCount[dispositivo?.[0]?.id_tipo_dispositivo] = (movesCount?.[dispositivo?.[0]?.id_tipo_dispositivo] || 0) + 1
    }

    if (data && data?.length > 0) {
      data?.forEach((type) => {
        if (movesCount[type] !== undefined) {
          movesCount[type] -= 1
        }
      })
    }
    return movesCount
  }

  const isActive = (type, columnId) => {
      const hasMovesLeft = moves?.[type] && moves[type] > 0
      let isDeviceActive = false

      const checkDeviceTypeInChildren = (devices, type) => {
          if (!devices) {
              return false
          }
          return devices.some(device => {
              if (Number(device?.id_tipo_dispositivo) === Number(type)) {
                  return true
              }
              if (device?.childrenDevices) {
                  return checkDeviceTypeInChildren(device.childrenDevices, type);
              }
              return false
          })
      }

      if (dispositivo && dispositivo?.[0]?.childrenDevices) {
        isDeviceActive = checkDeviceTypeInChildren(dispositivo?.[0]?.childrenDevices, type)
      }

      if (dispositivo && Number(dispositivo?.[0]?.id_tipo_dispositivo) === Number(type)) {
        isDeviceActive = true
      }

      if (type === 25 || type === 26) {
          return true
      }

      if (columnId === "Devices") {
          return isDeviceActive && hasMovesLeft
      } else {
          return isDeviceActive
      }
  }

  const getLayout = async ({ numserie }) => {
    let url = `distributionPanel/${numserie}`
    try {
      const res = await fetch(urls.scada + url, {
        method: "GET",
        headers: headersScada(token)
      })
      if (res.ok) {
        const data = await res.json()
        let configuration
        if(data?.length === 0 ) {
          configuration = []
          setLayoutData(null)
        } else {
          configuration = JSON.parse(data?.confPanel)
          setLayoutData(configuration)
        }
        let transformedConfig = []
        configuration?.forEach(array => {
          array?.forEach(item => {
             transformedConfig?.push(switchConfig(item))
            }
          )
        })
        const newMoves = updateMovesCount(transformedConfig)
        setMoves(newMoves)
        const initialColumns = {
          Devices: {
            name: "Devices",
            items: devices
          },
          Relay1: {
            name: "Relay1",
            items: []
          }
        }
        configuration?.forEach((relay, index) => {
          initialColumns[`Relay${index + 1}`] = {
            name: `Relay${index + 1}`,
            items: relay.map((iconType) => {
              const { device_type, icon_type, name, content } = getIconComponent(iconType)
              return {
                id: getUniqueID(),
                device_type,
                icon_type,
                name,
                content
              }
            })
          }
        })
        const count = configuration.length === 0 ? 1 : configuration.length 
        setColumns(initialColumns)
        setRelayCount(count)
      } else {
        console.error("Error en la solicitud")
      }
    } catch (error) {
      console.error("Error en la solicitud", error)
    }
  }  

  useEffect(() => {
    if (dispositivo) {
      getLayout({ numserie: dispositivo[0]?.numserie })
    }
  }, [])

  useEffect(() => {
    let configurationPanel = []
    Object.keys(columns)?.forEach((key) => {
      const relay = columns[key]
      if (key !== "Devices") {
        const config = relay?.items?.map(item => item?.icon_type)
        if (config) {
           configurationPanel?.push(config)
        } 
      }
    })
    setConfiguration(configurationPanel)
  }, [columns])

  useEffect(() => {
    const isConfigurationEmpty = configuration?.length === 1 && Array.isArray(configuration?.[0]) && configuration?.[0]?.length === 0
    if(!layoutData && isConfigurationEmpty) {
      setIsSaveDisabled(true)
    } else {
      const areConfigurationsEqual = areArraysEqual(layoutData, configuration)
      setIsSaveDisabled(areConfigurationsEqual)
    }
  }, [layoutData, configuration])

  return (
    <>
      {isLoading ? 
        <Loader />
      :
        <div className="pt-4">
          <Grid container>
            <Grid item xs={3} md={3} lg={3} xl={2}></Grid>
            <Grid item xs={9} md={9} lg={9} xl={10}>
              <div className="flex space-between px-3">
                <div className="flex gap-4">
                  <ButtonStyled className="button-with-text mb-3 gap-1" onClick={()=> setOpenAddRelayModal(!openAddRelayModal)} disabled={relayCount >= 4}>
                    <span className="px-1">Añadir relay</span>
                    <IconNewObject className='icon-hover-with-text' />
                  </ButtonStyled>
                  <ButtonStyled
                    className="boton-delete-alarm mb-3 gap-1"
                    onClick={handleRemoveRelay}
                    disabled={relayCount <= 1}
                  >
                    <span className="px-1">Borrar relay</span>
                    <IconDeleteAlarm className="boton-delete-hijo" />
                  </ButtonStyled>
                </div>
                <div className="flex gap-4">
                  <ButtonStyled className="boton-delete-alarm mb-3 gap-1" onClick={() => setOpenSaveModal(!openSaveModal)} disabled={isSavedDisabled}>
                    <span className="px-1">Guardar cuadro</span>
                    <IconSave className="boton-delete-hijo" />
                  </ButtonStyled>
                  <ButtonStyled className="boton-delete-alarm mb-3 gap-1" onClick={()=>setOpenDeleteModal(!openDeleteModal)} disabled={isDeleteDisabled}>
                    <span className="px-1">Borrar cuadro</span>
                    <IconDeleteAlarm className="boton-delete-hijo" />
                  </ButtonStyled>
                </div>
              </div>
            </Grid>
          </Grid>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns, moves, setMoves)}>
            <Grid container>
              <Grid item xs={3} md={3} lg={3} xl={2}>
                <DropContainer columnId="Devices" column={columns.Devices} isActive={type => isActive(type, "Devices")} />
              </Grid>
              <Grid item xs={9} md={9} lg={9} xl={10} className="pl-3 w-full">
                <div className="flex flex-col relay-main-container">
                  <div className="relay-div">
                    {relayIds.map((relayId) => {
                      const column = columns[relayId]
                      return (
                          <DropContainer  key={relayId} isActive={type => isActive(type, relayId)} columnId={relayId} column={column} direction="horizontal" />
                      )
                    })}
                  </div>
                </div>
              </Grid>
            </Grid>
          </DragDropContext>
          {openSaveModal && 
            <ModalSaveLayout
              open={openSaveModal}
              setOpen={setOpenSaveModal}
              layoutData={layoutData}
              configuration={configuration}
              setLayoutData={setLayoutData}
              numserie={dispositivo[0]?.numserie}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          }
          {openDeleteModal &&
            <ModalDeleteLayout      
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}  
                setIsLoading={setIsLoading}
                setColumns={setColumns}
                setRelayCount={setRelayCount}
                setLayoutData={setLayoutData}
                setConfiguration={setConfiguration}
                numserie={dispositivo[0]?.numserie}
                isLoading={isLoading}
                configuration={configuration}
                updateMovesCount={updateMovesCount}
                setMoves={setMoves}
            />
          }
            {openAddRelayModal && 
              <ModalAddRelay
                open={openAddRelayModal}
                setOpen={setOpenAddRelayModal}
                relayCount={relayCount}
                setRelayCount={setRelayCount}
                setColumns={setColumns}
              />
          }
        </div>
      }
    </>
  )
}
export default LayOutBoard