import React, { useState, useEffect } from 'react'
import { Table } from '@mui/material'
import { toKiloW, toKiloWFecha } from 'utils/functionsforGraphs'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableCellHead } from "components/Table/TableStyle";
import { StyledTableRow } from "components/Table/TableStyle";
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const HistoricalTableConsumptionDesktop = (props) => {

    const [data1, setData1] = useState()

    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    useEffect(() => {
        if (props?.data) {
            let set = new Set(props?.data?.map(JSON.stringify))
            let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
            setData1(arrSinDuplicaciones)
        }
    }, [props.data])



    return (
        <>

            <div className="boder-table">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead style={{ borderRadius: '50px' }}>
                            <TableRow>
                                <StyledTableCellHead align="center" >Nombre</StyledTableCellHead>
                                {props?.daily === "hours" ?
                                    <StyledTableCellHead align="center" >
                                        <div>Hora</div>
                                    </StyledTableCellHead> :
                                    props?.daily === "days" ?
                                        <StyledTableCellHead align="center" >
                                            <div>Fecha</div>
                                        </StyledTableCellHead>
                                        : props?.daily === "months" ?
                                            <StyledTableCellHead align="center" >
                                                <div>Mes</div>
                                            </StyledTableCellHead>
                                            : props?.daily === "years" ?
                                                <StyledTableCellHead align="center">
                                                    <div>Año</div>
                                                </StyledTableCellHead>
                                                : null
                                }
                                <StyledTableCellHead align="center" >
                                    <div>Energía Consumida (kWh)</div>
                                </StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data1 && data1?.map((device, i) => {
                                return (
                                    <>
                                        {props?.checked.indexOf(device?.data?.numserie) >= 0 || props?.activo === true ?
                                            <>
                                                <>
                                                    <StyledTableRow>
                                                        <StyledTableCell >{device?.data?.nick}</StyledTableCell>
                                                        <StyledTableCell align="center" >{toKiloWFecha(device?.data?.datetime, props?.daily)}</StyledTableCell>
                                                        <StyledTableCell >{toKiloW(device?.data?.eaconsdia)}</StyledTableCell>
                                                    </StyledTableRow>
                                                </>
                                            </>
                                            :
                                            null
                                        }
                                    </>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </>
    )
}