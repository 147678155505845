import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { dispositivos } from "utils/constant"
import Map from "components/Mapa/Map"
import InputStyled from "components/Input/InputStyled"
import { validatorNumber } from "utils/ValidatorForm"
import { validatorInput } from "utils/ValidatorForm"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { ConfiguracionDispositivo } from "./ConfiguracionDispositivo"
import { ConfiguracionMabtResumen } from "./ConfiguracionMabtResumen"
import "./ConfiguracionPlanta.scss"

export const ConfiguracionPlantaMabt = ({ dispositivo, modal }) => {
  const token = sessionStorage?.token
  const [modalConfigurar, setModalConfigurar] = useState(false)
  const [instalacion, setInstalacion] = useState(dispositivo[0].nick)
  const [potTrans, setPotTrans] = useState(dispositivo[0].pap_trafo)
  const [errorMessage, setErrorMessage] = useState()
  const [errorMessage2, setErrorMessage2] = useState()
  const [validPotTrans, setValidPoTrans] = useState(false)
  const [transCorr, setTransCorr] = useState(dispositivo[0].max_iac)
  const [validTransCorr, setValidTransCorr] = useState(false)
  const dispositivosInstalacion = dispositivo[0].childrenDevices
  const vtn = dispositivo?.[0]?.childrenDevices?.find(ele => ele.id_tipo_dispositivo === dispositivos.ccmabtv)
  const impar = vtn ? (dispositivo[0]?.childrenDevices?.length - 1) % 2 : dispositivo[0]?.childrenDevices?.length % 2
  const [address, setAddress] = useState(dispositivo[0].address)
  const [lat, setLat] = useState(dispositivo[0].latitud)
  const [lng, setLng] = useState(dispositivo[0].longitud)
  const [cambio, setCambio] = useState(false)
  const [isValidNick, setIsValidNick] = useState(false)
  const [medidores, setMedidores] = useState([])
  dispositivosInstalacion?.sort((a, b) => {
    const aOutput = a['mabt_output']
    const bOutput = b['mabt_output']
    if (a?.id_tipo_dispositivo === dispositivos.ccmabtv || b?.id_tipo_dispositivo === dispositivos.ccmabtv) {
      if (a?.id_tipo_dispositivo === dispositivos.ccmabtv) {
        return -1
      }
      if (b?.id_tipo_dispositivo === dispositivos.ccmabtv) {
        return 1
      }
    } else {
      if (!aOutput) {
        return 1
      }
      if (!bOutput) {
        return -1
      }
    }
    if (aOutput > bOutput) {
      return 1
    }
    if (aOutput < bOutput) {
      return -1
    }
    return 0
  })

  const handleOnChangeInstalacion = (valor) => {
    setInstalacion(valor)
    setIsValidNick(validatorInput(valor))
  }

  const handleCancelarInstalacion = () => {
    setInstalacion(dispositivo[0].nick)
    setIsValidNick(false)
  }

  const handleOnChangePotTrans = (valor) => {
    setPotTrans(valor)
    if (Number(valor) >= 100000) {
      setErrorMessage(true)
    } else {
      setErrorMessage(false)
    }
    setValidPoTrans(validatorNumber(valor))
  }

  const handleCancelarPotTrans = () => {
    setPotTrans(dispositivo[0].pap_trafo)
    setValidPoTrans(false)
    setErrorMessage(false)
  }

  const handleOnChangeTransCorr = (valor) => {
    setTransCorr(valor)
    if (Number(valor) >= 100000) {
      setErrorMessage2(true)
    } else {
      setErrorMessage2(false)
    }
    setValidTransCorr(validatorNumber(valor))
  }

  const handleCancelarTransCorr = () => {
    setTransCorr(dispositivo[0].max_iac)
    setErrorMessage2(false)
    setValidTransCorr(false)
  }

  const onSelectMarker = (lat, lng, adres) => {
    setLat(lat)
    setLng(lng)
    setAddress(adres)
  }

  useEffect(() => {

    let aux = medidores?.filter(med => med.isDisabled || med.nick === "")
    let cambios = aux.length === 0

    if (medidores.length === 0) {
      cambios = instalacion !== dispositivo[0].nick ||
        (potTrans !== null &&
          (parseFloat(potTrans) !== null && parseFloat(potTrans) !== dispositivo[0].pap_trafo)) ||
        (transCorr !== null && (parseFloat(transCorr) !== dispositivo[0].max_iac)) ||
        (address !== dispositivo[0].address) ||
        (lat && Number(lat) !== Number(dispositivo[0].latitud)) ||
        (lng && Number(lng) !== Number(dispositivo[0].longitud))
    }

    if (medidores && medidores?.length >= 0 && aux?.length === 0 && instalacion && !isValidNick && !validPotTrans && !validTransCorr && !errorMessage && !errorMessage2) {
      setCambio(cambios)
    } else {
      setCambio(false)
    }
  }, [medidores, instalacion, isValidNick, potTrans, validPotTrans, transCorr, validTransCorr, address, lat, lng, errorMessage, errorMessage2])

  return (
    <Grid container style={{ position: 'relative' }}>
      <Grid item xs={12} md={12} lg={6} xl={6} className={`${modal !== true ? "configuracion-box" : "configuracion-box-mabt"}`}>
        {modal !== true && <h1 className="titulo">Configuración Instalación</h1>}
        <Grid container className="campo-container">
          <Grid item xs={12} md={12} lg={12} xl={12}>
            <h2 className="mb-4">Instalación Media Tensión</h2>
          </Grid>
          <Grid item xs={window.innerWidth > 750 ? 6 : 12} md={6} lg={6} xl={6}>
            <h4 style={{ fontWeight: 500 }}>Nombre:</h4>
            <InputStyled
              classname='inputTextoConfiguracionMabt'
              value={instalacion}
              error={isValidNick}
              onChange={handleOnChangeInstalacion}
              maxLength={32}
              enableRefresh={dispositivo[0].nick !== instalacion}
              onCancelar={handleCancelarInstalacion}
            />
          </Grid>
          <Grid item xs={window.innerWidth > 750 ? 6 : 12} md={6} lg={6} xl={6} className={`${window.innerWidth < 750 && 'mt-3'}`}>
            <h4 style={{ fontWeight: 500 }}>Potencia Transformador kVA:</h4>
            <InputStyled
              classname='inputTextoConfiguracionMabt'
              error={validPotTrans}
              value={potTrans !== null ? potTrans : ''}
              onChange={handleOnChangePotTrans}
              enableRefresh={(dispositivo[0].pap_trafo !== null && parseFloat(dispositivo[0].pap_trafo) !== parseFloat(potTrans)) || (dispositivo[0].pap_trafo === null && potTrans !== '' && potTrans !== null)}
              onCancelar={handleCancelarPotTrans}
            />
            {errorMessage && <div className='pb-1'>
              <small className='pb-1 pl-1 color-red-error'>
                El valor no puede ser mayor de 100000
              </small>
            </div>}
          </Grid>
          <Grid item xs={12} md={12} lg={12} xl={12} className="mt-4">
            <Map center={{ lat: lat ? Number(lat) : lat, lng: lng ? Number(lng) : lng }} onSelectMarker={onSelectMarker} zoom={5} address={address} utmShow={true} classNameInput={'inputTextoConfiguracionMabt'} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={6} xl={6} className={`${modal !== true ? "configuracion-box" : "configuracion-box-mabt"}`}>
        <div className={`${(window.innerWidth > 1185 && modal !== true) ? 'mt-6 pl-3 mb-3 mapa' : modal === true ? null : 'mt-3'}`}>
          <Grid container>
            <Grid item xs={window.innerWidth > 750 ? 6 : 12} md={6} lg={12} xl={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <h2 className="mt-4 mb-4">Medidores MABT</h2>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className="mb-3">
                  <h4 style={{ fontWeight: 500 }}> Transformadores Corriente:</h4>
                  <InputStyled
                    classname='inputTextoConfiguracionMabt'
                    value={transCorr !== null ? transCorr : ''}
                    error={validTransCorr}
                    onChange={handleOnChangeTransCorr}
                    enableRefresh={(dispositivo[0].max_iac !== null && parseFloat(dispositivo[0].max_iac) !== parseFloat(transCorr)) || (dispositivo[0].max_iac === null && transCorr !== '' && transCorr !== null)}
                    onCancelar={handleCancelarTransCorr}
                    pie={<h2 style={{ fontWeight: 500 }}>/ 1</h2>}
                  />
                  {errorMessage2 && <div className='pb-1'>
                    <small className='pb-1 pl-1 color-red-error'>
                      El valor no puede ser mayor de 100000
                    </small>
                  </div>}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={window.innerWidth > 750 ? 6 : 12} md={6} lg={12} xl={6}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                  <h2 className="mt-4 mb-4">VTN</h2>
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={12} className="mb-3">
                  <h4 style={{ fontWeight: 500 }}>Nombre:</h4>
                  {vtn &&
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <ConfiguracionDispositivo dispositivo={vtn} instalacion={dispositivo[0]} medidores={medidores} setMedidores={setMedidores} vtn={true} />
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <h2 className="mt-4 mb-3">Salidas</h2>
          <Grid container>
            {dispositivosInstalacion && dispositivosInstalacion?.length > 0 &&
              dispositivosInstalacion.map((dis) => {
                return (
                  <>
                    {dis.id_tipo_dispositivo !== dispositivos.ccmabtv &&
                      <Grid item xs={window.innerWidth > 750 ? 6 : 12} md={6} lg={window.innerWidth > 1430 ? 6 : 12} xl={6}>
                        <ConfiguracionDispositivo index={dis.mabt_output} dispositivo={dis} instalacion={dispositivo[0]} medidores={medidores} setMedidores={setMedidores} />
                      </Grid>
                    }
                  </>
                )
              })
            }
            {((window.innerWidth > 750 && window.innerWidth < 1200) || (window.innerWidth > 1430)) && <Grid item xs={6} md={6} lg={6} xl={6} className="mt-4" />}
            {impar === 1 && <Grid item xs={6} md={6} lg={6} xl={6} className="mt-4" />}
            <Grid item xs={6} md={6} lg={6} xl={6} className="mt-4">
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '21em' }}>
                <ButtonStyled
                  type="submit"
                  color="#FF8A1F"
                  style={{ border: "1px solid #FF8A1F" }}
                  onClick={() => setModalConfigurar(true)}
                  disabled={!cambio}
                >
                  Aplicar
                </ButtonStyled>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {modalConfigurar && <ConfiguracionMabtResumen token={token} cambio={cambio} dispositivosInstalacion={dispositivosInstalacion} modalConfigurar={modalConfigurar} setModalConfigurar={setModalConfigurar} medidores={medidores} setMedidores={setMedidores} dispositivo={dispositivo} instalacion={instalacion} potTrans={potTrans} transCorr={transCorr} address={address} lat={lat} lng={lng} setCambio={setCambio} />}
    </Grid>
  )
}