// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-style {
  display: flex;
  justify-content: center;
  top: 0px;
  z-index: 50;
  height: 5rem;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(233 238 240/var(--tw-bg-opacity));
  padding: 0.5rem 0.75rem;
  position: sticky;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.logo-container {
  height: 100%;
  flex: 1 1;
  overflow: hidden;
}

.logos-container {
  display: flex;
  height: 100%;
  flex: 1 1;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.items-container {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Navbar/navbar.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,uDAAA;EACA,uBAAA;EACA,gBAAA;EACA,2EAAA;AACJ;;AAEA;EACI,YAAA;EACA,SAAA;EACA,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,YAAA;EACA,SAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AACJ","sourcesContent":[".navbar-style {\n    display: flex;\n    justify-content: center;\n    top: 0px;\n    z-index: 50;\n    height: 5rem;\n    width: 100%;\n    --tw-bg-opacity: 1;\n    background-color: rgb(233 238 240 / var(--tw-bg-opacity));\n    padding: 0.5rem 0.75rem;\n    position: sticky;\n    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 4px 8px 0 rgba(0, 0, 0, 0.1);\n}\n\n.logo-container {\n    height: 100%;\n    flex: 1 1 0%;\n    overflow: hidden;\n}\n\n.logos-container {\n    display: flex;\n    height: 100%;\n    flex: 1 1 0%;\n    align-items: center;\n    justify-content: center;\n    gap: 0.75rem;\n}\n\n.items-container {\n    display: flex;\n    flex: 1 1 0%;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
