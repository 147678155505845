import React, { useEffect, useState } from 'react'
import './ScadaGeneral.scss'
import { GraphsGeneral } from './GraphsGeneral/GraphsGeneral'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { ResumeGeneral } from './ResumeGeneral'
import { DateGeneral } from './DateGeneral'
import { moreOneDay } from 'utils/Utils'

export const ScadaGeneral = (props) => {
    let now = new Date()
    let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let ends = moment(startt).add(1, "days").subtract(1, "seconds")
    const [start, setStart] = useState(startt)
    const [end, setEnd] = useState(ends)
    const [titleButton, setTitleButton] = useState('Hoy')
    const [daily, setDaily] = useState("days")
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split("/")[4]
    const [dataResume, setDataResume] = useState()
    const [loading, setLoading] = useState(true)
    const [loadingCurves, setLoadingCurves] = useState()
    const [graphDataProduction, setGraphDataProduction] = useState()
    const [historicalData, setHistoricalData] = useState()
    const [noData, setNoData] = useState(true)

    const fetchData = () => {
        fetch(urls.scada + `getCurrentData/${props?.numserieSelfConsumption?.[0]?.numserie}/?idCCMaster=${props?.numserieSelfConsumption?.[0]?.master_serial}`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then(data => {
                setDataResume(data)
                setLoading(false)
            })
            .catch(err => {
                console.error("ERROR. API Scada", err)
                setLoading(true)
            })
    }

    const fetchHistoricalData = () => {
        fetch(urls.scada + `getHistoricalDataScada/${props?.numserieSelfConsumption?.[0]?.numserie}?idCCMaster=${props?.numserieSelfConsumption?.[0]?.master_serial}&iniDate=${start.format("YYYY-MM-DD")}&endDate=${end.format("YYYY-MM-DD")}&groupDate=hours`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then(data => {
                setHistoricalData(data)
                setLoading(false)
            }
            )
            .catch(err => {
                console.error("ERROR. API Scada", err)
                setLoading(true)
            })
    }

    const fetchDataProduction = (body) => {
        if (titleButton !== "Mes Actual"
            && titleButton !== "Rango"
            && titleButton !== "Año") {
            fetch(urls.scada + `chart/getDataChartsCurves/${props.numserieSelfConsumption?.[0]?.numserie}?idCCMaster=${idMaster}`, {
                method: 'POST',
                redirect: 'follow',
                headers: headersScada(token),
                body: JSON.stringify(body),
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        setLoadingCurves(false)
                        return []
                    }
                })
                .then(data => {
                    setGraphDataProduction(data)
                    setLoading(false)
                })
                .catch(err => console.error('ERROR. API Scada', err))

        };
    };

    const consultor = (body) => {
        if (props.numserieSelfConsumption[0].numserie !== undefined &&
            props.numserieSelfConsumption[0].numserie !== null && moreOneDay(start, end)) {
            if (daily === "days" || daily === "hours") {
                fetchDataProduction(body)
            }
        }
        props?.master?.numserie &&
            fetchData()
        fetchHistoricalData()
    }

    const bodySeter = (time) => {
        let body = null
        if (props?.bateria21?.length !== 0) {
            body = {
                "iniDate": start.format("YYYY-MM-DD"),
                "endDate": end.format("YYYY-MM-DD"),
                "iniTime": `00:00`,
                "endTime": time,
                "interval": "5",
                "axis": [{
                    "idDeviceType": 7,
                    "fields": ["pact"],
                    "numseries": [`${props?.numserieSelfConsumption?.[0]?.numserie}`],
                    "plantType": "produccion",
                    "unit": "kWh"
                },
                {
                    "idDeviceType": 7,
                    "fields": ["pact"],
                    "numseries": [`${props?.numserieSelfConsumption?.[0]?.numserie}`],
                    "plantType": "consumo",
                    "unit": "kWh"
                },
                {
                    "idDeviceType": 9,
                    "fields": ["soc", "p"],
                    "numseries": props?.bateria21.map(dv => dv.numserie.toString()),
                    "plantType": "bateria",
                    "unit": "kWh"
                }]
            }
        } else {
            body = {
                "iniDate": start.format("YYYY-MM-DD"),
                "endDate": end.format("YYYY-MM-DD"),
                "iniTime": `00:00`,
                "endTime": time,
                "interval": "5",
                "axis": [{
                    "idDeviceType": 7,
                    "fields": ["pact"],
                    "numseries": [`${props?.numserieSelfConsumption?.[0]?.numserie}`],
                    "plantType": "produccion",
                    "unit": "kWh"
                },
                {
                    "idDeviceType": 7,
                    "fields": ["pact"],
                    "numseries": [`${props?.numserieSelfConsumption?.[0]?.numserie}`],
                    "plantType": "consumo",
                    "unit": "kWh"
                }]
            }
        }
        consultor(body)
    }

    const initial = () => {
        let endTime = null
        if (moment().format('YYYY-MM-DD') !== start.format("YYYY-MM-DD")) {
            endTime = `23:59`
        } else {
            endTime = moment().format("HH:mm")
        }
        bodySeter(endTime)
    }

    useEffect(() => {
        initial()
    }, [start])

    let deviceType = 7

    return (
        <div className="general-scada">
            <DateGeneral loading={props?.loading} master={props?.master} data={dataResume} />
            <span className="weather-general-scada-SelfConsumption" style={{ width: '70%' }}>
            </span>
            <span className='resume-general'>
                <ResumeGeneral
                    idCcMaster={props?.numserieSelfConsumption?.[0]?.master_serial}
                    data={dataResume}
                    loading={loading}
                    setImportSelf={props?.setImportSelf}
                    setExportSelf={props?.setExportSelf}
                    ImportSelf={props?.importSelf}
                    master={props?.device}
                />
            </span>
            <span className='graphs-general'>
                <GraphsGeneral
                    data={props?.data}
                    isMobile={props?.isMobile}
                    device={props?.device}
                    start={start} end={end}
                    setStart={setStart}
                    now={now}
                    setEnd={setEnd}
                    titleButton={titleButton}
                    setTitleButton={setTitleButton}
                    daily={daily} setDaily={setDaily}
                    graphDataProduction={graphDataProduction}
                    setHistoricalData={setHistoricalData}
                    historicalData={historicalData}
                    loadingCurves={loadingCurves}
                    loading={loading}
                    noData={noData}
                    setNoData={setNoData}
                    childrenDevices={props.childrenDevices}
                    setRefresh={props?.setRefresh}
                    importSelf={props?.importSelf}
                    refresh={props?.refresh}
                    deviceType={deviceType}
                />
            </span>
        </div>
    )
}