import React from 'react'
import { Bar } from 'react-chartjs-2'

export const GraphsBar = ({ eacons, eagen, labels, options, data, label, dataSet }) => {

    const LineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: "linear",
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: "nearest",
            intersect: false,
            axis: "x"
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: true,
                    drawTicks: false
                }
            },
            y: {
                grid: {
                    drawOnChartArea: true,
                    drawTicks: false
                },
                drawBorder: true,
                suggestedMax: 1,
                ticks: {
                    callback: function (value) {
                        return value + " kWh"
                    }
                }
            },
            y2: {
                position: "right",
                grid: {
                    drawOnChartArea: false,
                    drawTicks: false
                },
                ticks: {
                    callback: function () {
                        return ""
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    padding: 10,
                    usePointStyle: true,
                    pointStyle: "circle"
                },
                display: true,
                position: "bottom",
                align: "center"
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || ""
                        if (context.parsed.y !== null) {
                            label += " : "
                            return (label += new Intl.NumberFormat().format(context.parsed.y) + " kWh")
                        }
                    }
                }
            }
        },
        zoom: {
            pan: {
                enabled: true,
                mode: "x",
                speed: 0.5
            },
            zoom: {
                wheel: {
                    enabled: true,
                    speed: 0.1,
                },
                mode: "x"
            }
        }
    }

    const dataFilter = {
        labels,
        datasets: dataSet ? dataSet : eagen && eacons ? [
            {
                Type: "bar",
                label: label ? label : "Importada",
                data: eacons,
                backgroundColor: ["#3B9FD1"],
                borderColor: "#3B9FD1",
                pointBackgroundColor: "#3B9FD1",
                borderWidth: 0,
                fill: true,
                id: "1",
                numserie: "",
                maintainAspectRatio: "true"
            },
            {
                Type: "bar",
                label: label ? label : "Exportada",
                data: eagen,
                backgroundColor: "#B4DA9D",
                borderColor: "#B4DA9D",
                pointBackgroundColor: "#B4DA9D",
                borderWidth: 0,
                fill: true,
                id: "1",
                numserie: "",
                maintainAspectRatio: "true"
            }
        ] : eagen ? [{
            Type: "bar",
            label: label ? label : "Exportada",
            data: eagen,
            backgroundColor: "#B4DA9D",
            borderColor: "#B4DA9D",
            pointBackgroundColor: "#B4DA9D",
            borderWidth: 0,
            fill: true,
            id: "1",
            numserie: "",
            maintainAspectRatio: "true"
        }] :
            [{
                Type: "bar",
                label: label ? label : "Importada",
                data: eacons,
                backgroundColor: ["#3B9FD1"],
                borderColor: ["#3B9FD1"],
                pointBackgroundColor: ["#3B9FD1"],
                borderWidth: 0,
                fill: true,
                id: "1",
                numserie: "",
                maintainAspectRatio: "true"
            }],
    }

    return (
        <div>

            <Bar
                style={{ width: '100%', height: '20em' }}
                data={data ? data : dataFilter}
                options={options ? options : LineOptions}
            />
        </div>
    )
}

