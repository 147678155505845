import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { Devices } from 'views/Scada/Cards/Devices'

export const ScadaconsumptionResume = (props) => {

    useEffect(() => {
        props?.setDeviceSelected()
    }, [])

    return (
        <Grid container xs={12} md={12} lg={12} xl={12}>
            <Grid item xs={12} md={12} lg={12} xl={12}>
                <Devices
                    confirmSelfConsumption={props?.confirmSelfConsumption}
                    devices={props?.devices}
                    device={props?.device}
                    setDevices={props?.setDevices}
                    setDevice={props?.setDevice}
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    refresh={props?.refresh}
                    setRefresh={props?.setRefresh}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    seeWeb={props?.seeWeb}
                    selfConsumption={props?.selfConsumption}
                    visible={props?.visible}
                    setVisible={props?.setVisible}
                    importSelf={props?.importSelf}
                    exportSelf={props?.exportSelf}
                    resumeView={props?.resumeView}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}

                />
            </Grid>
        </Grid>
    )
}