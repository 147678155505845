import React, { useEffect } from 'react'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { HistoricalTableProductionMobile } from './HistoricalTableProductionMobile'
import { HistoricalTableProductionDesktop } from './HistoricalTableProductionDesktop'
import { useDebounce } from 'use-debounce'
import { dispositivos } from 'utils/constant'

export const HistoricalTableProduction = (props) => {
    const [debouncedData] = useDebounce(props?.historicalData, 200)
    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    const headTable = ["Nombre", "Fecha", "Energía Generada (kWh)"]
    const devices = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4]

    return (
        <>
            {props?.isMobile === true ?
                <HistoricalTableProductionMobile selectedDevice={props?.selectedDevice}
                    arrayDevices={devices}
                    inverter={props?.inverter}
                    setSelectedDevice={props?.setSelectedDevice}
                    daily={props?.daily}
                    end={props?.end}
                    isMobile={props?.isMobile}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    setHeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    data={debouncedData}
                    icon="text"
                    deviceType={props?.deviceType}
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable}
                    checked={props?.checked} active={props?.active} />
                :
                <HistoricalTableProductionDesktop
                    arrayDevices={devices}
                    deviceType={props?.deviceType}
                    inverter={props?.inverter}
                    checked={props?.checked} setHeadersCsv={props?.setHeadersCsv} daily={props?.daily} headTable={headTable} data={debouncedData} active={props?.active} loading={props?.loading} noData={props?.noData} />
            }
        </>
    )
}
