import { CardsinglePhase } from 'views/Scada/Consumption/CardsConsumption/CardsinglePhase'
import { CardTriphasic } from 'views/Scada/Consumption/CardsConsumption/CardTriphasic'
import { dispositivos } from 'utils/constant'

export const childrenMap = (data, props, dispoType) => {
    let res = null


    switch (dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo) {
        case dispositivos.ccm1:
            res =
                <CardsinglePhase
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    devices={data}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    setDevices={props?.setDevices}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    resumeView={props?.resumeView}
                    id_tipo_dispositivo={dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo}
                    device={props?.device}
                    setDevice={props?.setDevice}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}
                />
            break
        case dispositivos.ccm2:
            res =
                <CardsinglePhase
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    devices={data}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    setDevices={props?.setDevices}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    resumeView={props?.resumeView}
                    id_tipo_dispositivo={dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo}
                    device={props?.device}
                    setDevice={props?.setDevice}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}
                />
            break
        case dispositivos.ccm4:
            res =
                <CardTriphasic
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    devices={data}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    setDevices={props?.setDevices}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    resumeView={props?.resumeView}
                    id_tipo_dispositivo={dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo}
                    device={props?.device}
                    setDevice={props?.setDevice}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}
                />
            break
        case dispositivos.ccm3:
            res =
                <CardTriphasic
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    devices={data}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    setDevices={props?.setDevices}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    resumeView={props?.resumeView}
                    id_tipo_dispositivo={dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo}
                    device={props?.device}
                    setDevice={props?.setDevice}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}
                />
            break
        case dispositivos.analizador:
            res =
                <CardTriphasic
                    loose={props?.loose}
                    setLoose={props?.setLoose}
                    devices={data}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    setDevices={props?.setDevices}
                    setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                    resumeView={props?.resumeView}
                    id_tipo_dispositivo={dispoType !== undefined ? dispoType : data?.id_tipo_dispositivo}
                    device={props?.device}
                    setDevice={props?.setDevice}
                    setResumeView={props?.setResumeView}
                    toggleDevice={props?.toggleDevice}
                />
            break
        default:
            break
    };
    return res;
};

