import React, { useEffect, useState } from 'react'
import { SolarGraphs } from './GraphsGeneral/RadialBarGraphs/SolarGraphs'
import { ConsumptionGraphs } from './GraphsGeneral/RadialBarGraphs/ConsumptionGraphs'
import { RedGraphs } from './GraphsGeneral/RadialBarGraphs/RedGraphs'
import { BatteryGraphs } from './GraphsGeneral/RadialBarGraphs/BatteryGraphs'
import { getPercent } from 'utils/functionsforGraphs'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { PanelGraphs } from './GraphsGeneral/RadialBarGraphs/PanelGraphs'
import { toKilowDate, toKilowPercent } from 'utils/functionsforGraphs'
import { IconArrowSmallRight, IconArrowSmallLeft, IconArrowChargeBlue, IconArrowDisableLeft, IconArrowChargeGreen, IconArrowShortChargeGreen, IconArrowDisableRight, IconArrowShortChargeBlue } from 'utils/icon-centrocontrol'

export const ResumeGeneral = ({ data, setImportSelf, setExportSelf, loading, ImportSelf }) => {
    let totalEnergy = data?.produccion?.pact + data?.red?.pact
    let percentConsumption = getPercent(data?.produccion?.pact, totalEnergy)
    let percentRed = getPercent(data?.red?.pact, totalEnergy)
    let percentRedExported = getPercent(Math.abs(data?.red?.pact), data?.produccion?.pact)
    const [leftArrow, setLeftArrow] = useState(<IconArrowChargeGreen />)
    const [rightArrow, setRightArrow] = useState(<IconArrowChargeBlue />)

    useEffect(() => {
        setImportSelf(percentConsumption)
        setExportSelf(percentRed)
    }, [data])

    useEffect(() => {
        if (window.screen.width <= 500) {
            setLeftArrow(<IconArrowShortChargeGreen />)
            setRightArrow(<IconArrowShortChargeBlue />)
        }
    }, [window.screen.width])

    return (
        <div className="resume">
            <div className='son'>
                <div className="resume-left">
                    <div className="flex-center justify-center">
                        <PanelGraphs data={data} loading={loading}/>
                        <div style={{width:'21px', height:'21px'}}>
                            <IconArrowSmallRight fill={data?.produccion?.pact > 0 ? "#28A745" : "#808080"} />
                        </div>
                    </div>
                    <div className="flex-center justify-center">
                        <BatteryGraphs data={data} loading={loading} />                            
                        <div style={{width:'21px', height:'21px'}}>
                            {data?.bateria?.p >= 0 && <IconArrowSmallLeft fill={data?.bateria?.p > 0 ? "#f99c40" : "#808080"} />}
                            {(data?.bateria?.p < 0 || !data?.bateria) && <IconArrowSmallRight fill={data?.bateria?.p < 0 ? "#f99c40" : "#808080"} />}
                        </div>
                    </div>
                </div>
                <div className="card-Resume">
                    <SolarGraphs className='graph' data={data} loading={loading} />
                    <p className='style-text-self-consumption' >Solar</p>
                    <p className='kw style-value-self-consumption'>{toKilowDate(data?.produccion?.datetime, data?.produccion?.pact)} kW</p>
                </div>
                <div className="card-Resume-arrow">
                    <p className='queryText'>{percentConsumption} %</p>
                    {data?.produccion?.pact === 0 ? <IconArrowDisableRight /> : leftArrow}
                    <p className='card-Resume-arrow-text'>Autoconsumo:</p>
                    <p className='card-Resume-arrow-value'>{toKilowPercent(ImportSelf)} %</p>
                </div>
                <div className="card-Resume">
                    <ConsumptionGraphs data={data} loading={loading} ImportSelf={ImportSelf} />
                    <p className='style-text-self-consumption'>Consumo </p>
                    <p className='kw style-value-self-consumption'>{toKilowDate(data?.consumo?.datetime, data?.consumo?.pact)} kW</p>
                </div>
                <div className="card-Resume-arrow">
                    {data?.red?.pact === 0 || data?.red?.pact === null ? <IconArrowDisableLeft /> : rightArrow}
                    <p id={data?.red?.pact >= 0 || data?.red?.pact === null ? 'azul' : 'green'} className='card-Resume-arrow-text'>{data?.red?.pact >= 0 || data?.red?.pact === null ? 'Importando' : 'Exportando'}</p>
                    <p id={data?.red?.pact >= 0 || data?.red?.pact === null ? 'azul' : 'green'} className='card-Resume-arrow-value'>{toKilowPercent(data?.red?.pact >= 0 || data?.red?.pact === null ? percentRed : percentRedExported)} %</p>
                </div>
                <div className="card-Resume">
                    <RedGraphs data={data} loading={loading} />
                    <p className='style-text-self-consumption'>Red </p>
                    <p className='kw style-value-self-consumption'>{toKilowDate(data?.red?.datetime, data?.red?.pact)}  kW</p>
                </div>
            </div>
        </div>
    )
}