import React, { useState, useEffect, useContext } from 'react'
import { urls } from 'utils/constant'
import { Loader } from 'components/Loader/Loader.jsx'
import { headersScada } from 'services/headers'
import { useLocation } from 'react-router-dom'
import { result } from 'utils/functionsforGraphs'
import { Grid } from "@mui/material"
import moment from 'moment'
import { ScadaDeviceHeader } from 'views/Scada/SelfConsumption/ScadaDeviceHeader'
import bateria from 'assets/img/icons/consumption/batery.png'
import { Filter } from 'views/Scada/FilterScada/FilterScada'
import { GlobalContext } from 'context/GlobalContext'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { HistoricalTableBatery } from './Tables/HistoricalTableBatery'
import { CurrentTableBatery } from './Tables/CurrentTableBatery'
import { GraphsBattery } from '../Batery/GraphsBattery/GraphsBattery'
import { useDebounce } from 'use-debounce'
import { convertDatetime } from 'utils/Utils'
import { isToday } from 'utils/Utils'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import { CsvDownloader } from '../CsvDownloader'

export const ScadaBatery = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split('/')[4]
    const [deviceType, setDeviceType] = useState(props?.data?.[0]?.deviceType ? props?.data?.[0]?.deviceType : props?.data?.[0]?.id_tipo_dispositivo)
    const [historicalData, setHistoricalData] = useState()
    const [selectedDevice, setSelectedDevice] = useState()
    const [batery, setBatery] = useState([])
    const toggleDevice = tab => setActiveTabDevice(tab)
    const [finalData, setFinalData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [headersCsv, setHeadersCsv] = useState();
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, (props?.daily === "hours" && isToday(props?.start)) ? { label: "Fecha", key: "fecha" } : { label: "Fecha", key: "datetime" }, { label: "Carga Máxima (%)", key: "maxsoc" }, { label: "Carga Mínima (%)", key: "minsoc" }]
    const [activeTabDevice, setActiveTabDevice] = useState('0')
    let diff = props?.end.diff(props?.start, "days")
    const fechaNow = moment(props?.now).format("DD-MM-YYYY")
    const [checked, setChecked] = useState([]);
    const [activo, setActivo] = useState(true)
    const [updatedList, setUpdatedList] = useState([])
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectDaily, setSelectDaily] = useState("day")


    let historicalCSV = historicalData?.map(device => device.data)
    let FinalDataCSV = finalData?.map(device => device?.data)
    let finalDataCSV2 = FinalDataCSV?.map(device => {
        let obj = Object.assign({}, device?.currentData)
        if (isToday(props?.start)) {
            obj.fecha = convertDatetime(obj?.datetime)
        }
        return obj
    })
    const { updateDevice } = useContext(GlobalContext)

    const [debouncedBatery] = useDebounce(batery, 200);


    const fetchData = async (numserie, start, end, daily) => {
        let url
        if (isToday(props?.start) && props?.daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idMaster}`
        }
        else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${daily}`
        }

        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                setLoading(false)
                const json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }


    const arrayData = () => {
        setHistoricalData([])
        setBatery([])
        let filterDeviceByType = props?.data?.filter(ele => ele.deviceType === deviceType)
        if (filterDeviceByType?.length > 0) {
            setFinalData([])
            if (isToday(props?.start) && props?.daily === "days") {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(device => {
                        fetchData(device).then(item => {
                            setFinalData(prev => [...prev, { nick: item?.currentData?.nick, id_tipo_dispositivo: item?.currentData?.id_tipo_dispositivo, data: item, numserie: item?.currentData?.numserie, datetime: item?.currentData?.datetime }])
                        })
                    })
                })
            } else {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(device => {
                        fetchData(device, props?.start, props?.end, props?.daily).then(item => {
                            item?.forEach(item2 => {
                                setHistoricalData(prev => [...prev, { nick: item2?.nick, data: item2, numserie: item2?.numserie }])
                            })
                        })
                    })
                })
            }
        }
    }

    const finalCSV = finalDataCSV2?.filter(dev => updatedList?.includes(dev.numserie))
    const historCSV = historicalCSV?.filter(dev => updatedList?.includes(dev.numserie))


    useEffect(() => {
        arrayData()
    }, [activeTabDevice, props?.end, props?.start, keySwitch])

    useEffect(() => {
        setFinalData([])
    }, [updateDevice])

    useEffect(() => {
        if (isToday(props?.start) && props?.daily === "days") {
            const setObj = new Set();
            const uniqs = finalData?.map(device => result(finalData, device.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device.numserie)) {
                    setObj.add(device.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setBatery(noRepeat)
        } else {
            setBatery(historicalData)
        }
    }, [finalData, historicalData])




    return (
        <Grid className="d-flex flex-column scada-component mt-3" item sm={12} lg={12} xl={12}>
            <ScadaDeviceHeader props={props} icon={bateria} setDeviceType={setDeviceType} data={batery} type="Batería" ></ScadaDeviceHeader>
            <span className="filter-component-calendar">
                <div>
                    <CalendarComponent
                        maxDate={props?.end}
                        setStart={props?.setStart}
                        setEnd={props?.setEnd}
                        setDaily={props?.setDaily}
                        setSelectDaily={setSelectDaily}
                    />
                </div>
                <div className="ml-3 d-flex align-items-center">
                    <SelectorFecha
                        daily={props?.daily}
                        setDaily={props?.setDaily}
                        tipo={props?.daily}
                        setKeySwitch={setKeySwitch}
                        selectDaily={selectDaily}
                    />
                    {batery && batery?.length > 0 &&
                        <CsvDownloader
                            className="csvDonwloader"
                            today={isToday(props?.start)}
                            daily={props?.daily}
                            props={props}
                            historicalData={historCSV !== undefined && historicalData !== undefined ? historCSV : historicalData}
                            finalData={finalCSV !== undefined && finalData !== undefined ? finalCSV : finalData}
                            start={props?.start}
                            end={props?.end}
                            setHeadersCsv={setHeadersCsv}
                            headersCsv={headersCsv}
                        />}
                </div>
            </span>
            <span className='listItem-componente'>
                <ul className="nav nav-tabs list-general" >
                    <li className="nav-item" button selected={activeTabDevice === '0'} onClick={() => { toggleDevice('0'); }}>
                        <span className="nav-link active list-p">Batería</span>
                    </li>
                </ul>
            </span>
            <span className="filter-scada-component">
                <Filter selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={setHeadersCsv}
                    headersCsv={headersCsv}
                    variableTableSelfConsumption={variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={loading}
                    setLoading={setLoading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device}
                    setRefresh={props?.setRefresh}
                    activo={activo}
                    setActivo={setActivo}
                    data1={debouncedBatery}
                    checked={checked}
                    setChecked={setChecked}
                    updatedList={updatedList}
                    setUpdatedList={setUpdatedList}
                    props={props}
                    daily={props?.daily}
                    isMobile={props?.isMobile}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            </span>
            <div className="table-card " >
                {(batery?.length === 0) ?
                    <Loader />
                    :
                    <>
                        {moment(props?.startt?.format('YYYY-MM-DD')).isAfter(props?.start?.format('YYYY-MM-DD')) || props?.daily !== "days" ?
                            <HistoricalTableBatery inverter={props?.inverter} isMobile={props?.isMobile} activo={activo} checked={updatedList} variableTableSelfConsumption={variableTableSelfConsumption}
                                setHeadersCsv={setHeadersCsv} headersCsv={headersCsv} historicalData={historicalData} loading={loading} start={props?.start} daily={props?.daily} setDaily={props?.setDaily}
                                data={props?.data} device={props.device} end={props?.end} childrenDevices={props.childrenDevices} deviceType={deviceType}
                                setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd}
                                setActivo={setActivo} data1={batery} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props}
                            />
                            :
                            <CurrentTableBatery inverter={props?.inverter} isMobile={props?.isMobile} activo={activo} setHeadersCsv={setHeadersCsv} checked={updatedList} headersCsv={headersCsv} daily={props?.daily} setFinalData={setFinalData} finalData={finalData} deviceType={deviceType} data={props?.data} loading={loading} device={props.device} start={props?.start} end={props?.end} setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} variableTableSelfConsumption={variableTableSelfConsumption} historicalData={true} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd}
                                setActivo={setActivo} data1={debouncedBatery} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props} setBatery={setBatery} />
                        }
                    </>
                }
            </div>
            <div className="graph-card "  >
                <GraphsBattery updatedList={updatedList} data={props.data} deviceType={deviceType} device={props?.devices} start={props?.start} end={props?.end} groupData={props.groupData} setStart={props.setStart} now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props?.titleButton} setTitleButton={props?.setTitleButton} daily={props?.daily} setDaily={props?.setDaily} historicalData={historicalData} diff={diff} setRefresh={props?.setRefresh} refresh={props?.refresh}
                    bateria21={props?.bateria21} loading={loading} />
            </div>
        </Grid>
    )
}