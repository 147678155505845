import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { ModalSwitchOffSim } from "views/Modals/ModalSim/ModalSwitchOffSim"
import { ModalCedeSim } from "views/Modals/ModalSim/ModalCedeSim"
import { AuthFooter } from "views/Footer/AuthFooter"
import { NavBar } from "views/NavBar/NavBar"
import { ButtonStyled } from "components/Buttons/ButtonStyled"
import { Loader } from 'components/Loader/Loader'
import { EnerSim } from "utils/icon-centrocontrol"
import { urls } from "utils/constant"
import { headersCentroControl } from "services/headers"
import { ScadaEnerSimGraphs } from "./ScadaEnerSImGraphs"
import { ScadaEnerSimForm } from "./ScadaEnerSimForm"
import "./scadaEnerSim.scss"

export const ScadaEnerSim = () => {
    const { id } = useParams()   
    const token = sessionStorage?.token
    const [openCedeModal, setOpenCedeModal] = useState(false)
    const [openSwitchOffModal, setOpenSwitchOffModal] = useState(false)
    const [enerSimData, setEnerSimData] = useState([])
    const [loading, setLoading] = useState(true)
    const [measuringData, setMeasuringData] = useState(null);

    const fetchEnerSim = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + 'siminfo', {
                method: 'POST',
                headers: headersCentroControl("bearer" + token), 
                body: JSON.stringify({"numserie_sim": id, "getAll": false}) 
            })

            if (res.ok) {
                const enerSimData = await res.json()
                if (enerSimData) {
                    setEnerSimData({...enerSimData})
                }
            } 
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        } finally {
            setLoading(false)
        }
    }

    const fetchEnerSimData = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + 'measuring', {
                method: 'POST',
                headers: headersCentroControl("bearer" + token), 
                body: JSON.stringify({"numserie_sim": id,
                "start_date": "2024-01-18T11:01:16.737Z",
                "end_date": "2024-01-18T11:01:16.737Z"}) 
            })

            if (res.ok) {
                const measuringData = await res.json()
                if (measuringData) {
                    setMeasuringData({...measuringData})
                }
            } 
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
        fetchEnerSim()
        fetchEnerSimData()
    }, [])

    useEffect(() => {
        document.title = `Scada - EnerSim - ${id}`
      }, [])

    return (
        <>
          <div className="enersim-container">
            <NavBar selfConsumption="general" />
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="header-projectList mx-5 mt-4">
                  <div className="header-container">
                    <EnerSim />
                    <div className="titles-container">
                      <span className="header-title">
                        {enerSimData.installationName || "enerSim"}
                      </span>
                      <span className="header-subtitle">Información general</span>
                    </div>
                  </div>
                  <div className="header-button">
                    <ButtonStyled className='button-with-text ml-2' onClick={() => setOpenCedeModal(!openCedeModal)}>
                        <p>Portabilidad</p>
                    </ButtonStyled>
                    <ButtonStyled className='button-with-text ml-2' onClick={() => setOpenSwitchOffModal(!openSwitchOffModal)}>
                        <p>Dar de baja</p>
                    </ButtonStyled>
                  </div>
                </div>
                <div className="content-container px-5 py-2">
                  <div className="enersim-form">
                    <ScadaEnerSimForm
                      enerSimData={enerSimData}
                      measuringData={measuringData}
                      openSwitchOffModal={openSwitchOffModal}
                      setopenSwitchOffModal={setOpenSwitchOffModal}
                    />
                  </div>
                  <div className="scada-graphs">
                    <ScadaEnerSimGraphs measuringData={measuringData} />
                  </div>
                </div>
              </>
            )}
          </div>
          <ModalCedeSim 
            open={openCedeModal} 
            setOpen={setOpenCedeModal} 
            enerSimData={enerSimData} 
          />
          <ModalSwitchOffSim 
            open={openSwitchOffModal} 
            setOpen={setOpenSwitchOffModal}
            enerSimData={enerSimData}
          />
          <AuthFooter />
        </>
      )
  }
