import React from 'react'
import { toKiloW, toKiloWFecha } from 'utils/functionsforGraphs'
import { Table } from "@mui/material"
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from "components/Table/TableStyle"
import { toKilowPercent } from 'utils/functionsforGraphs'

export const TableGeneral = (props) => {

    return (
        <div className='boder-table'>
            <TableContainer component={Paper} >
                <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                    <TableHead className="header-table">
                        <TableRow>
                            {props?.headTable?.map((headers, i) => {
                                return (
                                    <StyledTableCellHead key={i} align="center" width="50px ">
                                        {headers}
                                    </StyledTableCellHead>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.general?.map((data, i) => {
                            return (
                                <StyledTableRow key={i} className="data-row-mobile">
                                    {props?.inverter === true ?
                                        <><StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloWFecha(data?.datetime, props?.daily)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloW(data?.eagendia)}</StyledTableCell></>
                                        :
                                        <><StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloWFecha(data?.datetime, props?.daily)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloW(data?.eaconsdia)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloW(data?.consumida)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloW(data?.eagendia)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKiloW(data?.vertida)}</StyledTableCell>
                                            <StyledTableCell align="center" className="data-table-mobile" style={{ verticalAlign: "middle" }}>{toKilowPercent(data?.eco)} %</StyledTableCell></>
                                    }</StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}