import React, { useState, useEffect } from 'react'
import { conversorFecha } from 'utils/functionsforGraphs'
import { PhaseGraphsRed } from './PhaseGrapshRed'
import { dispositivos } from "utils/constant.jsx"
import { useLocation } from 'react-router-dom'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import moment from 'moment'
import { moreOneDay } from 'utils/Utils'
import { GraphsLine } from 'components/Graph/GraphsLine'
import { formatLabel } from 'views/Scada/MABT/utils'
import { formatDateNoSecond } from 'utils/Utils'
import { vertidos } from 'utils/constant'
import { consumidos } from 'utils/constant'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { Loader } from 'semantic-ui-react'

export const GraphsRed = (props) => {
    const location = useLocation()
    const idMaster = location.pathname.split("/")[4]
    const token = sessionStorage?.token
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [loading, setLoading] = useState(true)
    const [dataGraphs, setDataGrahps] = useState([])
    const [dataSets, setDataSets] = useState([])
    const [labelEnergy, setLabelEnergy] = useState([])
    const [dataSetsEnergy, setDataSetsEnergy] = useState([])


    const fetchData = async (numserie) => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `chart/getDataChartsCurves/${numserie}?idCCMaster=${idMaster}`, {
                method: 'POST', redirect: 'follow', headers: headersScada(token),
                body: JSON.stringify(
                    {
                        "iniDate": props?.start?.format("YYYY-MM-DD"),
                        "endDate": props?.end?.format("YYYY-MM-DD"),
                        "iniTime": `00:00`,
                        "endTime": moment().format("YYYY-MM-DD") === props?.end?.format("YYYY-MM-DD") ? moment().format("HH:mm") : `23:59`,
                        "interval": "5",
                        "axis": [{
                            "idDeviceType": `${props?.deviceType}`,
                            "fields": [`${(props.deviceType === dispositivos.ccm2 || props?.deviceType === dispositivos.analizador || props?.deviceType === dispositivos.ccm1) ? 'pact1' : 'pact'}`],
                            "numseries": [`${numserie}`],
                            "unit": "kWh"
                        }]
                    }
                )
            })
            const json = await res?.json()
            if (res?.ok) {
                setLoading(false)
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        } finally {
            setLoading(false)
        }
    }

    const arrayGraphsRed = async () => {
        setLoading(true)
        setDataGrahps([])
        let result = await Promise.all(props?.childrenDevices?.map(device => fetchData(device.numserie)))
        setDataGrahps(result)
        setLoading(false)
    }

    useEffect(() => {
        if (props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango") {
            setActiveTabDevice(1)
        }
    }, [props?.titleButton])

    const colorPower = ['rgb(20, 20, 255)', 'rgb(50, 20, 255)', 'rgb(60, 60, 255)', 'rgb(60, 80, 255)', 'rgb(60, 100, 255)'];

    const labels = dataGraphs && dataGraphs?.[0]?.categories

    const dataGraphsPower = () => {
        setDataSets([])
        dataGraphs && dataGraphs?.forEach(item => {
            item?.data?.forEach((datos, i) => {
                if (props?.updatedList?.includes(Number(datos.numserie))) {
                    setDataSets(prev => [...prev, {
                        label: datos.nick,
                        data: datos.consumida,
                        borderColor: colorPower[i],
                        pointBackgroundColor: colorPower[i],
                        fill: false,
                        id: i + 5,
                        pointRadius: 0,
                        tension: 0.1,
                        numserie: `${datos.numserie}`
                    }])
                }
            })
        })
    }



    const EnergyRed = () => {
        setDataSetsEnergy([])
        props?.devices && props?.devices?.forEach((dev, i) => {
            if (props?.updatedList.includes(Number(dev.numserie))) {
                if (activeTabDevice === 1) {
                    setDataSetsEnergy(prev => [...prev, {
                        Type: "bar",
                        label: dev.nick ? dev.nick : "Importada",
                        data: props?.red && props?.red?.filter(disp => disp.numserie === dev.numserie).map(device => device?.data?.currentData ? device?.data?.currentData.eagendia : device.data.eagendia),
                        backgroundColor: vertidos[i],
                        borderColor: vertidos[i],
                        pointBackgroundColor: vertidos[i],
                        borderWidth: 0,
                        fill: true,
                        id: "1",
                        numserie: "",
                        maintainAspectRatio: "true"
                    }])
                } else {
                    setDataSetsEnergy(prev => [...prev, {
                        Type: "bar",
                        label: dev.nick ? dev.nick : "Importada",
                        data: props?.red && props?.red?.filter(disp => disp.numserie === dev.numserie).map(device => device?.data?.currentData ? device?.data?.currentData.eaconsdia : device.data.eaconsdia),
                        backgroundColor: consumidos[i],
                        borderColor: consumidos[i],
                        pointBackgroundColor: consumidos[i],
                        borderWidth: 0,
                        fill: true,
                        id: "1",
                        numserie: "",
                        maintainAspectRatio: "true"
                    }])
                }
            }
        })
    }


    useEffect(() => {
        setLabelEnergy([])
        setLabelEnergy((prev) => {
            const updatedLabelEnergy = [...prev];
            props?.red?.forEach((dev) => {
                if (!updatedLabelEnergy.includes(dev.data.currentData ? formatDateNoSecond(dev.data.currentData.datetime) : dev.data.datetime)) {
                    updatedLabelEnergy.push(dev.data.currentData ? formatDateNoSecond(dev.data.currentData.datetime) : dev.data.datetime);
                }
            });

            return updatedLabelEnergy;
        });
    }, [props.red]); //

    useEffect(() => {

        if (!moreOneDay(props?.start, props?.end)) {
            setActiveTabDevice(1)
        }
    }, [props?.start, props?.end, props?.daily])


    useEffect(() => {
        if (moreOneDay(props?.start, props?.end)) {
            arrayGraphsRed()
        }
    }, [props?.start, props?.end])

    useEffect(() => {
        EnergyRed()
    }, [props?.red, activeTabDevice, props?.updatedList])


    useEffect(() => {
        dataGraphsPower()
    }, [dataGraphs, props?.updatedList])

    return (
        <>
            <div className="selected-graphs">
                <button className={activeTabDevice === 0 ? "button-general-active" : "button-general"} disabled={!moreOneDay(props?.start, props?.end)} selected={activeTabDevice === 0} onClick={() => setActiveTabDevice(0)}>
                    Potencia (kW)
                </button>
                {(props?.deviceType === dispositivos.ccm4 || props?.deviceType === dispositivos.analizador) &&
                    <button className={activeTabDevice === 3 ? "button-general-active" : "button-general"} disabled={!moreOneDay(props?.start, props?.end)} selected={activeTabDevice === 3} onClick={() => setActiveTabDevice(3)}>
                        Potencia fases (kW)
                    </button>
                }
                <button className={activeTabDevice === 1 ? "button-general-active" : "button-general"} selected={activeTabDevice === 1} onClick={() => setActiveTabDevice(1)}>
                    Energía vertida (kWh)
                </button>
                <button className={activeTabDevice === 2 ? "button-general-active" : "button-general"} selected={activeTabDevice === 2} onClick={() => setActiveTabDevice(2)}>
                    Energía consumida (kWh)
                </button>
            </div>
            <div className='date-graphs-scada'>
                <p>
                    {conversorFecha(props.start, props.end, props?.titleButton)}
                </p>
            </div>
            {!loading && props?.diff === 0 &&
                <>
                    <div className={`tab-item-wrapper ${activeTabDevice === 0 ? 'active' : ''}`} index={0}>
                        {activeTabDevice === 0 &&
                            <div className="my-2">
                                <GraphsLine data={dataSets} labels={formatLabel(labels)} />
                            </div>
                        }
                    </div>
                    {(props?.deviceType === dispositivos.ccm4 || props?.deviceType === dispositivos.analizador) &&
                        activeTabDevice === 3 &&
                        <div className={`tab-item-wrapper ${activeTabDevice === 3 ? 'active' : ''}`} index={3}>
                            <div className="my-2">
                                <PhaseGraphsRed updatedList={props?.updatedList} data={props.data} deviceType={props.deviceType} device={props?.device} start={props.start} end={props.end} groupData={props.groupData}
                                    loading={props.loading} setStart={props.setStart} now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                                    handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} activeTabDevice={3} historicalData={props.historicalData} />
                            </div>
                        </div>
                    }
                </>
            }
            <div>

                {(activeTabDevice === 1 || activeTabDevice === 2) &&
                    <div className="my-2">
                        {props?.loading === true ? <Loader /> :
                            <GraphsBar dataSet={dataSetsEnergy} labels={labelEnergy} />
                        }
                    </div>
                }
            </div>
        </>
    )
}
