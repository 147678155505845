import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { GraphsGeneral } from 'views/Scada/SelfConsumption/General/GraphsGeneral/GraphsGeneral'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const ScadaGeneralInverter = (props) => {
    const token = sessionStorage?.token
    const [data, setData] = useState()
    const [loading, setLoading] = useState()
    const [loadingCurves, setLoadingCurves] = useState()
    const [graphDataProduction, setGraphDataProduction] = useState()
    const [historicalData, setHistoricalData] = useState()
    const [noData, setNoData] = useState(true)
    let now = new Date()
    let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
    let ends = moment(startt).add(1, "days").subtract(1, "seconds")
    const [start, setStart] = useState(startt)
    const [end, setEnd] = useState(ends)
    const [titleButton, setTitleButton] = useState('Hoy')
    const [daily, setDaily] = useState("days")
    const [endTime, setEndTime] = useState(`${moment().format("HH:mm")}`)

    useEffect(() => {
        if (moment(startt?.format('YYYY-MM-DD')).isAfter(start?.format('YYYY-MM-DD'))) {
            setEndTime('23:59')
        } else {
            setEndTime(`${moment().format("HH:mm")}`)
        }
    }, [end?.format('YYYY-MM-DD'), start?.format('YYYY-MM-DD')])

    const fetchData = () => {
        setLoading(true)
        fetch(urls.scada + `getCurrentData/${props?.numserieSelfConsumption?.[0]?.numserie}?idCCMaster=${props?.numserieSelfConsumption?.[0]?.master_serial}`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then(data => {
                setLoading(false)
                setData(data)
            })
            .catch(err => {
                console.error("ERROR. API Scada", err)
                setLoading(true)
            })
    }

    const fetchHistoricalData = () => {
        fetch(urls.scada + `getHistoricalDataScada/${props?.numserieSelfConsumption?.[0]?.numserie}?idCCMaster=${props?.numserieSelfConsumption?.[0]?.master_serial}&iniDate=${start.format("YYYY-MM-DD")}&endDate=${end.format("YYYY-MM-DD")}&groupDate=hours`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    return res.json()
                } else {
                    return []
                }
            })
            .then(data => {
                setHistoricalData(data)
            }
            )
            .catch(err => {
                console.error("ERROR. API Scada", err)
                setLoading(true)
            })
    }

    const fetchDataProduction = () => {
        if (titleButton !== "Mes Actual"
            && titleButton !== "Rango"
            && titleButton !== "Año") {
            fetch(urls.scada + `chart/getDataChartsCurves/${props?.numserieSelfConsumption?.[0]?.numserie}?idCCMaster=${props?.numserieSelfConsumption?.[0]?.master_serial}`, {
                method: 'POST', redirect: 'follow', headers: headersScada(token),
                body: JSON.stringify(
                    {
                        "iniDate": `${start.format("YYYY-MM-DD")}`,
                        "endDate": `${end.format("YYYY-MM-DD")}`,
                        "iniTime": `00:00`,
                        "endTime": `${endTime}`,
                        "interval": "5",
                        "axis": [{
                            "idDeviceType": 5,
                            "fields": ["pact"],
                            "numseries": [`${props.numserieSelfConsumption?.[0]?.numserie}`],
                            "plantType": "produccion",
                            "unit": "kWh"
                        },
                        ]
                    }
                )
            })
                .then(res => {
                    if (res.status === 200) {
                        setLoadingCurves(false)
                        return res.json()
                    } else {
                        setLoadingCurves(false)
                        return []
                    }
                })
                .then(data => {
                    // setLoadingCurves(false)
                    setGraphDataProduction(data)
                })
                .catch(err => console.error('ERROR. API Scada', err))
        }
    }

    useEffect(() => {
        if (props?.master?.numserie) {
            fetchHistoricalData()
            fetchData()
        }
    }, [props?.master?.numserie, start, end])

    useEffect(() => {
        if (props?.master?.numserie) {
            fetchData()
        }
    }, [props?.master?.numserie, start, end, props?.refresh])

    useEffect(() => {
        if (props.numserieSelfConsumption?.[0]?.numserie && daily === "days") {
            fetchDataProduction()
        }
        if (props.numserieSelfConsumption?.[0]?.numserie && daily === "hours") {

            fetchDataProduction()
        }
    }, [start, end, props.numserieSelfConsumption?.[0]?.numserie, endTime, props?.refresh])

    const inverter = true

    return (
        <div className="general-scada general-inverter">
            <span className='graphs-general'>
                <GraphsGeneral
                    isMobile={props?.isMobile}
                    data={data} device={props?.device} start={start} end={end}
                    setStart={setStart} now={now} setEnd={setEnd} titleButton={titleButton}
                    setTitleButton={setTitleButton} daily={daily} setDaily={setDaily}
                    graphDataProduction={graphDataProduction} historicalData={historicalData}
                    loadingCurves={loadingCurves} loading={loading} inverter={inverter}
                    setLoading={setLoading} noData={noData} setNoData={setNoData}
                    childrenDevices={props.childrenDevices} setRefresh={props?.setRefresh}
                    refresh={props?.refresh}
                />
            </span>
        </div>
    )
}