import React from 'react'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableCell } from "components/Table/TableStyle";
import { StyledTableRow } from "components/Table/TableStyle";
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { toKiloW } from 'utils/functionsforGraphs';
import { convertDatetime } from 'utils/Utils';

export const CurrentTableBateryMobile = (props) => {

    return (
        <div >
            {props?.data && props?.data?.map((device, i) => {
                return (
                    <>
                        {(props?.checked?.indexOf(device?.data?.numserie) || props?.activo === true) &&
                            <TableContainer className="boder-table">
                                <Table aria-label="customized table">
                                    <TableHead >
                                        <TableRow className="title-mobile-table">
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <> <StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[0]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{device?.data?.currentData?.nick}</StyledTableCell>
                                        </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[1]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile">{convertDatetime(device?.data?.currentData?.datetime)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[2]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile data">{toKiloW(device?.data?.currentData?.p)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[3]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile">{toKiloW(device?.data?.currentData?.v)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[4]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile">{toKiloW(device?.data?.currentData?.soc) + "%"}</StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </>
                )
            })}
        </div>
    )
}