import React, { useEffect } from 'react'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { HistoricalTableConsumptionMobile } from './HistoricalTableConsumptionMobile'
import { HistoricalTableConsumptionDesktop } from './HistoricalTableConsumptionDesktop'
import { useDebounce } from 'use-debounce'

export const HistoricalTableConsumption = (props) => {
    const [debouncedData] = useDebounce(props?.historicalData, 200)

    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    const headTable = ["Nombre", "Fecha", "Energía Generada (kWh)"]

    return (
        <>
            {props?.isMobile === true ?
                <HistoricalTableConsumptionMobile selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    end={props?.end}
                    daily={props?.daily}
                    isMobile={props?.isMobile}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    setHeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    data={debouncedData}
                    icon="text"
                    open={props?.open}
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable}
                    checked={props?.checked} active={props?.active}
                    deviceType={props?.deviceType}
                />
                :
                <HistoricalTableConsumptionDesktop checked={props?.checked} setHeadersCsv={props?.setHeadersCsv} daily={props?.daily} headTable={headTable} data={debouncedData} active={props?.active} loading={props?.loading} noData={props?.noData} finalData={props?.finalData} axis={props?.axis} dataPack={props?.dataPack} />
            }
        </>
    )
}
