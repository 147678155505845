import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { CSVLink } from 'react-csv'
import { Button } from '@mui/material'
import { Loader } from 'components/Loader/Loader'
import { GraficaMabt } from './GraficaMabt'
import { TablaMabt } from './TablaMabt'
import { MenuMabt } from './MenuMabt'
import { MenuDescripcionMabt } from './MenuDescripcionMabt'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha.jsx'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import CalendarComponent from 'components/Calendar/CalendarComponent'

export const InformeMabt = ({ activeTabDeviceMenuGrafica, seleccionarTabMenuGrafica, activeTabDeviceGrafica, seleccionarTabGrafica, now, start, setStart, end, setEnd, visible, datosTabla, datosGrafica, loadingGrafica, tipoEscala, mobile, errorGrafica, selectorFecha, groupDate, daily, setDaily, loadingTabla, tipoGrafica, master }) => {
    const [selectDaily, setSelectDaily] = useState("day")


    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 mb-4'>
                <MenuMabt activeTab={activeTabDeviceMenuGrafica} seleccionarTab={seleccionarTabMenuGrafica} />
            </Grid>
            <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 mb-5 pr-3'>
                <Grid container>
                    <Grid item xs={mobile ? 12 : 8} md={window.innerWidth < 945 ? 12 : 8} lg={8} xl={8}>
                        <MenuDescripcionMabt medidores={activeTabDeviceMenuGrafica} activeTab={activeTabDeviceGrafica} seleccionarTab={seleccionarTabGrafica} start={start} grafica={true} visible={visible} />
                    </Grid>
                    <Grid item xs={mobile ? 12 : 12} md={window.innerWidth < 1000 ? 12 : 4} lg={4} xl={4} className={`${window.innerWidth < 1000 && 'mt-2'}`}>
                        <div className='flex-end'>
                            <div className="mr-2">
                                <CalendarComponent setSelectDaily={setSelectDaily}
                                    maxDate={end} setDaily={setDaily} start={start} end={end} setStart={setStart} setEnd={setEnd} />
                            </div>
                            <div className="mr-2">
                                <SelectorFecha selectDaily={selectDaily} daily={daily} setDaily={setDaily} tipo={daily} />
                            </div>
                            <Button className="pt-1 pl-0" disabled={!datosTabla}>
                                <CSVLink data={datosTabla ? datosTabla : []} disabled={!datosTabla} separator={";"} style={{ color: 'white' }} filename={`${master.nick}.csv`}>
                                    <HtmlTooltip title={<span>Descargar datos</span>}>
                                        <IconDownloadFile fill={(datosTabla && datosTabla.length > 0) && '#FF8A1F'} />
                                    </HtmlTooltip>
                                </CSVLink>
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {!loadingGrafica && !loadingTabla ?
                <>
                    {datosGrafica && datosTabla &&
                        <Grid container columns={28}>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                            <Grid item xs={26} md={26} lg={26} xl={26} className='mb-8'>
                                <GraficaMabt datosGrafica={datosGrafica} escalaTipo={tipoEscala} tipoGrafica={tipoGrafica} activeTabDeviceMenuGrafica={activeTabDeviceMenuGrafica} />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                            <Grid item xs={26} md={26} lg={26} xl={26} className='mb-4'>
                                <TablaMabt datosTabla={datosTabla} />
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} xl={1} />
                        </Grid>
                    }
                    {errorGrafica &&
                        <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                            <h3 className='texto-error-carga'>
                                Error al cargar los datos, contacte con el soporte técnico.
                            </h3>
                        </Grid>
                    }
                </>
                :
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                    <div className="justify-content-center">
                        <Loader />
                    </div>
                </Grid>
            }
        </>
    )
}