import React from 'react'
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material'
import { datoNull } from './utils'


export const StyledTableCellHead = ({ style, children, className, scope }) => {
    return (
        <TableCell
            align="center" 
            style={style}
            className={className} 
            scope={scope}
            sx={{ 
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                color: "#FF8A1F",
                borderBottom: "4px solid #575757 ",
                padding: "0px 10px",
                align: "center"
            }}
        >
            {children}
        </TableCell>
    )
}

const StyledTableCell = ({ style, children, className, scope }) => {
    return (
        <TableCell
            align="center" 
            style={style}
            className={className} 
            scope={scope}
            sx={{ 
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "17px",
                color: "#575757",
                padding: "0px 10px",
                align: "center"
            }}
        >
            {children}
        </TableCell>
    )
}

const StyledTableRow = ({ children, className }) => {
    return (
        <TableRow className={className + ' table-row'}>
            {children}
        </TableRow>
    )
}


const StyledTableCellMiniHead = ({ children, className }) => {
    return (
        <TableCell
            className={className}
            sx={{ 
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "13px",
                lineHeight: "16px",
                color: "#262626",
                borderBottom: "1px solid #fff",
                boxShadow: "none",
                align: "center"
            }}
        >
            {children}
        </TableCell>
    )
}

const StyledTableCellMini = ({ children, className }) => {
    return (
        <TableCell
            className={className}
            sx={{ 
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                borderBottom: "none !important",
                color: "#575757",
                align: "center"
            }}
        >
            {children}
        </TableCell>
    )
}

export const TablaPotencia = ({ importada, exportada, potenciaActiva, devices }) => {
    const variables = ['R', 'S', 'T', 'Total']

    return (
        <Grid container className={`${window.innerWidth >= 1380 && 'position-relative tabla-potencia-posicion'}`}>
            <Grid item xl={12} className='tabla-max-width pl-4 pr-4'>
                <div className='header-tabla-potencia'>
                    <p className='text-start header-tabla-potencia-texto m-0'>
                        NOMINAL: {datoNull(devices?.pap_trafo)} kVA
                    </p>
                    <p className='text-start header-tabla-potencia-texto m-0'>
                        USO: {potenciaActiva && devices?.pap_trafo ? datoNull(potenciaActiva > devices?.pap_trafo ? 100 : ((potenciaActiva * 100) / devices?.pap_trafo)) : '-'} %
                    </p>
                </div>
                <TableContainer component={Paper} className='tabla-potencia'>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCellHead align="center">
                                </StyledTableCellHead>
                                <StyledTableCellHead align="center">
                                    <div className="d-flex justify-content-around title-head-tabla-potencia">
                                        Exportada (kW)
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                            Activa
                                        </StyledTableCellMiniHead>
                                        <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                            Reactiva
                                        </StyledTableCellMiniHead>
                                    </div>
                                </StyledTableCellHead>
                                <StyledTableCellHead align="center">
                                    <div className="d-flex justify-content-around title-head-tabla-potencia">
                                        Importada (kW)
                                    </div>
                                    <div className="d-flex justify-content-around">
                                        <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                            Activa
                                        </StyledTableCellMiniHead>
                                        <StyledTableCellMiniHead align="center" style={{ border: "none !important" }}>
                                            Reactiva
                                        </StyledTableCellMiniHead>
                                    </div>
                                </StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {variables.map((fila, i) => {
                                let datoFila
                                if (i === 0) {
                                    datoFila = [exportada?.pac1, exportada?.pre1, importada?.pac1, importada?.pre1]
                                } else if (i === 1) {
                                    datoFila = [exportada?.pac2, exportada?.pre2, importada?.pac2, importada?.pre2]
                                } else if (i === 2) {
                                    datoFila = [exportada?.pac3, exportada?.pre3, importada?.pac3, importada?.pre3]
                                } else {
                                    datoFila = [exportada?.totalpac, exportada?.totalpre, importada?.totalpac, importada?.totalpre]
                                }
                                return (
                                    <StyledTableRow className={i === variables.length - 1 && "last-row"} key={i}>
                                        <StyledTableCell className="cell-left-power-mabt" align="center" >
                                            {fila}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div className="d-flex justify-content-around">
                                                <StyledTableCellMini className={`${datoFila[0] !== null && datoFila[0] === 0 ? 'number-cero' : ''} ${datoFila[0] !== null && datoFila[0] > 0 ? 'number-positivo' : ''} ${datoFila[0] !== null && datoFila[0] < 0 ? 'numero-negativo' : ''}`}>{datoNull(datoFila[0])}</StyledTableCellMini>
                                                <StyledTableCellMini className={`${datoFila[1] !== null && datoFila[1] === 0 ? 'number-cero' : ''} ${datoFila[1] !== null && datoFila[1] > 0 ? 'number-positivo' : ''} ${datoFila[1] !== null && datoFila[1] < 0 ? 'numero-negativo' : ''}`}>{datoNull(datoFila[1])}</StyledTableCellMini>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div className="d-flex justify-content-around">
                                                <StyledTableCellMini className={`${datoFila[2] !== null && datoFila[2] === 0 ? 'number-cero' : ''} ${datoFila[2] !== null && datoFila[2] > 0 ? 'number-positivo' : ''} ${datoFila[2] !== null && datoFila[2] < 0 ? 'numero-negativo' : ''}`}>{datoNull(datoFila[2])}</StyledTableCellMini>
                                                <StyledTableCellMini className={`${datoFila[3] !== null && datoFila[3] === 0 ? 'number-cero' : ''} ${datoFila[3] !== null && datoFila[3] > 0 ? 'number-positivo' : ''} ${datoFila[3] !== null && datoFila[3] < 0 ? 'numero-negativo' : ''}`} >{datoNull(datoFila[3])}</StyledTableCellMini>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer >
            </Grid>
        </Grid >
    )
}