import React, { useState, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { GlobalContext } from "context/GlobalContext"
import { Loader } from 'components/Loader/Loader'
import { headersCentroControl } from 'services/headers'
import { IconMaintenance } from 'utils/icon-centrocontrol'
import { urls } from 'utils/constant'
import { FiltroConfiguracionInstalaciones } from './FilterInstall/FiltroConfiguracionInstalaciones'
import { TableConfigInstallation } from "./TableInstall/TableConfigInstallation"
import { MaintenanceModal } from "./ModalMaintance/MaintenanceModal"
import 'views/Alarms/styleAlarm.scss'

/**
 * @param {{ instalacioneSeleccionadas: string[] | number[], dispositivos: {}[], activeTab: number, InstallationConfig, scadaSelf: boolean }} 
 * @param instalacioneSeleccionadas Instalaciones seleccionadas en componenten @Principal
 * @param dispositivos Array de los dispositivos que tienen las instalaciones
 * @param activeTab número que indica si este componente está seleccionado en
 * el componenten @ConfiguracionList
 * @param scadaSelf Te dice si estamos en selfConsumption o no
 * @returns Devuelve la vista de la configuracion de la instalación
 */
export const InstallationConfig = ({ activeTab, instalacioneSeleccionadas, dispositivos, scadaSelf }) => {
    const { actualProject } = useContext(GlobalContext)
    const token = sessionStorage?.token
    const [open, setOpen] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectedMasters, setSelectedMasters] = useState([])
    const [refresh, setRefresh] = useState(true)
    const [filteredMasters, setFilteredMasters] = useState([])
    const [loading, setLoading] = useState(false)
    const [dataInstall, setDataInstall] = useState([])
    const [filterMaster, setFilterMaster] = useState()
    const [data, setData] = useState([])
    const infoInstal = useSelector((state) => state.infoInstal)

    /**
     * @description estado que se declara aquí para que esté accesible para la tabla y mantenimiento, 
     * pero se setea en @TableConfigInstallation .
     */
    const userPermiss = () => {
        if (selectedMasters.length > 0) {
            let res = true
            let aux
            aux = selectedMasters.filter(device => device?.permissions !== undefined && device?.permissions?.permissions !== undefined && device?.permissions?.permissions !== 'user').map((device) => { return device })
            if (aux[0] !== undefined) {
                res = false
            }
            return res
        }
    }

    /**
    * @description recorre los dispositivos seleccionados, les aplica @devicePermission ,
    * y los que pasan el filtro se introducen en @filteredMasters .
    */
    useEffect(() => {
        setData([])
        setFilteredMasters(dispositivos)
    }, [dispositivos, refresh])

    /**
     * @description recoge las instalaciones seleccionadas que llegan y 
     * filtra los masters, antes de pasarlas a la tabla o a mantenimiento.
     */
    useEffect(() => {
        let aux = []
        instalacioneSeleccionadas.forEach((selected, key) => {
            filteredMasters.forEach(
                (device) => {
                    device.numserie === Number(selected)
                        && (device.id_tipo_dispositivo === 0 || device.id_tipo_dispositivo === 20)
                        && aux.push(device)
                }
            )
        })
        setSelectedMasters(aux)
    }, [instalacioneSeleccionadas, filteredMasters])

    const fetchDataFirmware = async (device, error) => {
        try {
            if (device.id_tipo_dispositivo === 0 || device.id_tipo_dispositivo === 20) {
                const res = await fetch(urls.ccontrol + `getFirmware/${device.numserie}`, { method: "GET", headers: headersCentroControl(token) })
                if (res.status === 200) {
                    let json = await res.json()
                    if (error) {
                        json.permissions = device.permissions
                        json.error = true
                        json.id_tipo_dispositivo = device.id_tipo_dispositivo
                    } else {
                        json.permissions = device.permissions
                        json.id_tipo_dispositivo = device.id_tipo_dispositivo
                    }
                    return json
                } else {
                    return []
                }
            }
        } catch (err) {
            console.error("Error Api Centro Control", err)
        }
    }


    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const devicesCorrect = dispositivos.filter(device => infoInstal[device?.numserie]?.error === 0)
            const devicesIncorrect = dispositivos.filter(device => infoInstal[device?.numserie]?.error === 1 || infoInstal[device?.numserie]?.error === 2)
            let errors = await Promise.all(devicesIncorrect?.map(device => fetchDataFirmware(device, true)))
            errors = errors.filter(dato => { return dato !== undefined })
            let correct = await Promise.all(devicesCorrect?.map(device => fetchDataFirmware(device, false)))
            correct = correct.filter(dato => { return dato !== undefined })
            errors = errors.map(error => {
                const correspondingDevice = dispositivos.find(device => device.numserie === Number(error.numserie))
                if (correspondingDevice) {
                    return { ...error, id_tipo_dispositivo: correspondingDevice.id_tipo_dispositivo }
                }
                return error
            })
            setData(errors.concat(correct))
            setDataInstall(errors.concat(correct))
            setLoading(false)
        }
        fetchData()
    }, [dispositivos, keySwitch])

    return (
        <div className="mt-2">
            {activeTab === 3 &&
                <div className="table-responsive-md">
                    {!scadaSelf &&
                        <Grid className='mt-1 mb-1' container spacing={2} >
                            <Grid item className="boton-create-alarm"
                                xs={12} sm={12} md={12} lg={12} xl={12}
                                style={{ display: 'flex', justifyContent: "space-between" }}>
                                <FiltroConfiguracionInstalaciones dataInstall={dataInstall} setFilterMaster={setFilterMaster} />
                                {actualProject?.category !== "distribution" &&
                                    <div className='header-title'>
                                        <ButtonStyled className='button-with-text' onClick={() => setOpen(!open)} disabled={userPermiss() || dispositivos[0]?.id_tipo_dispositivo === 20}>
                                            <IconMaintenance className='icon-hover-with-text' />
                                            <p className='ml-2'>Mantenimiento</p>
                                        </ButtonStyled>
                                    </div>
                                }
                                {open &&
                                    <MaintenanceModal
                                        setKeySwitch={setKeySwitch}
                                        open={open}
                                        setOpen={setOpen}
                                        dispositivos={selectedMasters}
                                        token={token}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                        data={data}
                                    />
                                }
                            </Grid>
                        </Grid>
                    }
                    {!loading && dataInstall ?
                        <TableConfigInstallation
                            modalEditar={modalEditar}
                            setModalEditar={setModalEditar}
                            scadaSelf={scadaSelf}
                            dataInstall={dataInstall}
                            filterMaster={filterMaster}
                            token={token}
                            keySwitch={keySwitch}
                            instalacioneSeleccionadas={instalacioneSeleccionadas}
                            dispositivos={selectedMasters}
                            data={data}
                            setData={setData}
                            dispositivo={dispositivos}
                            setKeySwitch={setKeySwitch}
                        />
                        :
                        <div className="justify-content-center">
                            <Loader />
                        </div>
                    }
                </div>
            }
        </div >
    )
}