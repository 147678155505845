import React from 'react'
import { Draggable } from '@hello-pangea/dnd'
import { getClassName } from 'utils/Utils'
import "./LayOut.scss"

const DraggableItem = ({ item, index, columnId, isActive, disabled }) => {
  const isDisabled = disabled ? true : !isActive(item.device_type) && columnId === "Devices"
  const isDevices = columnId === "Devices"
  const itemClassName = getClassName(item?.name)

  return (
    <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDisabled}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided?.draggableProps}
          {...provided?.dragHandleProps}
          className={`${isDevices ? "device-draggable" : "relay-draggable"} ${!isDevices ? itemClassName : ""}`}
        >
          {React.cloneElement(item?.content, {
            fill: columnId === "Devices" && disabled ? undefined : columnId === "Devices" && !isActive(item?.device_type) ? "#C6C6C6" : undefined
          })}
        </div>
      )}
    </Draggable>
  )
}

export default DraggableItem

