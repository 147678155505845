import React, { useEffect } from "react"

import { Grid, Card } from "@mui/material";

import Colors from "./Colors";

const Bulb = (props) => {

    //Detectar en que dispositivo estamos


    useEffect(() => {
        window.addEventListener('resize', window.innerWidth);
        return () => {
            window.removeEventListener('resize', window.innerWidth);
        }
    }, []);

    return (
        <>
            <div className="mt-3">
                <Card className="p-4">
                    <Grid>
                        <h1>{props.devices.nick}</h1>
                        <Grid sm={12}>
                            <Colors device={props.devices}></Colors>

                        </Grid>
                    </Grid>
                </Card>
            </div>
        </>
    )

};

export default Bulb;
