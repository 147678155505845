import React, { useEffect, useState } from 'react'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import { Loader } from "components/Loader/Loader"
import { dispositivos } from 'utils/constant'

export const PowerLineGraph = (props) => {
    const [graph, setGraph] = useState(<Chart />)
    const dataPack = { labels: [], datasets: [], };
    const colors = ["#078307", "#800080", "#ffa500", "#a52a2a"];
    const names = props?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtm ? ['R', 'S', 'T'] : props?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtv && props?.hoy ? ['VTN'] : props?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtv && !props?.hoy ? ['VTN_max', 'VTN_min'] : ["Potencia Total", "Potencia Activa - L1", "Potencia Activa - L2", "Potencia Activa - L3"]
    const LineOptions =
    {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        size: 9,
                    },
                    callback: function (value, index, ticks) {
                        return value.toFixed(2) + ' kW'
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
                display: true,
                position: 'bottom',
                align: 'center'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label
                        return label + ' : ' + new Intl.NumberFormat().format(context.parsed.y) + ' kW'
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    speed: 0.5
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    mode: 'x'
                }
            }
        }
    }

    useEffect(() => {
        if (props?.graphData?.categories) {
            dataPack.labels = []
            let aux1 = props?.graphData?.categories
            let aux2 = dataPack.labels
            aux1.forEach((element) => {
                let aux3 = element.slice(11)
                aux2.push(aux3)
            })
        }
    }, [props])

    useEffect(() => {
        if (props?.graphData?.data) {
            if (props?.graphData?.data?.[0]?.data) {
                dataPack.datasets = [];
                let aux2 = props?.graphData.data;
                let aux3 = dataPack.datasets;
                aux2.forEach((element, index) => {
                    aux3.push(
                        {
                            Type: 'line',
                            data: element.consumida ? element.consumida : element.data,
                            label: names[index],
                            borderColor: colors[index],
                            pointBackgroundColor: colors[index],
                            fill: false,
                            id: index + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${element.numserie}`
                        }
                    )
                })
            }
        }
    }, [props])


    useEffect(() => {
        setGraph(
            <Chart dataFilter={dataPack} LineOptions={LineOptions} graphStyle={'line'} />
        )
    }, [props])

    return (
        <>
            {!props.loadingData ?
                graph
                :
                <Loader />
            }
        </>
    )
}
