import React from 'react'
import { Avatar, Grid } from '@mui/material'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { IconDeleteAlarm } from 'utils/icon-centrocontrol'
import changeLogo from 'assets/img/gifs/change-photo-profile.png'

export const PreviewLogoProfile = ({ idProject, setOpen, logos, activateDropZone, activateDelete }) => {
    const logosHeader = []
    const logosFooter = []

    for (const key in logos) {
        if (logos.hasOwnProperty(key)) {
            const elemento = logos[key]
            if (elemento[0] === 1) {
                logosHeader.push(key)
            } else if (elemento[0] === 2) {
                logosFooter.push(key)
            }
        }
    }

    return (
        <>
            {!idProject && <Grid item xs={2} md={2} lg={2} xl={2} />}
            <Grid item xs={!idProject ? 7 : 9} md={!idProject ? 7 : 9} lg={!idProject ? 7 : 9} xl={!idProject ? 7 : 9}>
                <div className="avatar-container">
                    {logos && Object.keys(logos).length < 3 &&
                        <div title="Subir imagen" className='button-add mb-3' onClick={() => setOpen(true)}>
                            {/* <IconNewObjectBig className='icon-hover-with-text' /> */}
                            <img src={changeLogo} alt="newImg" />
                        </div>
                    }
                </div>
            </Grid>
            {logosHeader?.length > 0 &&
                <>
                    <Grid item xs={12} className='flex-center justify-center m-2'>
                        <h3>Logos cabecera:</h3>
                    </Grid>
                    {logosHeader.map((logo, index) => {
                        return (
                            <React.Fragment key={index}>
                                <span className="d-flex">
                                    <Grid item xs={!idProject ? 7 : 9} md={!idProject ? 7 : 9} lg={!idProject ? 7 : 9} xl={!idProject ? 7 : 9} className='flex-center justify-center mb-2'>
                                        <Avatar sx={{ bgcolor: 'white', width: 'auto', height: '5em', cursor: 'pointer', border: "1px solid #cbcbcb", borderRadius: '0' }} onClick={() => activateDropZone(logo, logos?.[logo]?.[0])}>
                                            <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={`data:${logos?.[logo]?.[1]};base64,${logos?.[logo]?.[2]}`} alt='logo' />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3} xl={3} className='flex-center justify-center'>
                                        <ButtonStyledIcon className='boton-delete-alarm' title='Eliminar imagen' onClick={() => activateDelete(logo)}>
                                            <IconDeleteAlarm className='boton-delete-hijo' />
                                        </ButtonStyledIcon>
                                    </Grid>
                                </span>
                            </React.Fragment>
                        )
                    })}
                </>
            }
            {logosFooter?.length > 0 &&
                <>
                    <Grid item xs={12} className='flex-center justify-center m-2'>
                        <h3>Logos pie de página:</h3>
                    </Grid>
                    {logosFooter.map((logo, index) => {
                        return (
                            <React.Fragment key={index}>
                                <span className="d-flex">
                                    <Grid item xs={!idProject ? 7 : 9} md={!idProject ? 7 : 9} lg={!idProject ? 7 : 9} xl={!idProject ? 7 : 9} className='flex-center justify-center mb-2'>
                                        <Avatar sx={{ bgcolor: 'white', width: 'auto', height: '5em', cursor: 'pointer', border: "1px solid #cbcbcb", borderRadius: '0' }} onClick={() => activateDropZone(logo, logos?.[logo]?.[0])}>
                                            <img className="pt-2 pb-2 pr-3 pl-3" style={{ maxWidth: '10em', height: '5em' }} src={`data:${logos?.[logo]?.[1]};base64,${logos?.[logo]?.[2]}`} alt='logo' />
                                        </Avatar>
                                    </Grid>
                                    <Grid item xs={3} md={3} lg={3} xl={3} className='flex-center justify-center'>
                                        <ButtonStyledIcon className='boton-delete-alarm' title='Eliminar imagen' onClick={() => activateDelete(logo)}>
                                            <IconDeleteAlarm className='boton-delete-hijo' />
                                        </ButtonStyledIcon>
                                    </Grid>
                                </span>
                            </React.Fragment>
                        )
                    })}
                </>
            }
        </>
    )
}