
import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import { Grid } from '@mui/material'
import moment from 'moment'
import { headersScada } from 'services/headers'
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { DeviceDate } from 'utils/DeviceDate/DeviceDate'
import { dispositivos, urls } from 'utils/constant'
import { ArrowDirection, DeviceVisualizer } from './ScadaConsumptionComponent/DeviceVisualizer'
import { MenuGeneralConsumo } from '../Consumption/GraphConsumption/MenuGeneralConsumo'
import { ScadaPrincipalGraphs } from './ScadaPrincipalGraphs'
import { ReportScada } from './ReportScada'
import "./StyleConsumption.scss"

export const ScadaData = ({ data, currentDevice, loading, setLoading, loadingCard, loose }) => {
    const token = sessionStorage?.token
    const location = useLocation()
    const idCcMaster = location.pathname.split('/')[4]
    const [start, setStart] = useState(moment())
    const [end, setEnd] = useState(moment())
    const [daily, setDaily] = useState("days")
    const [energyData, setEnergyData] = useState()
    const [activeTab, setActiveTab] = useState(0)


    const fetchEnergy = async () => {
        let url
        if (loose === 0) {
            url = `getHistoricalDataScada/${data?.numserie}?idCCMaster=${idCcMaster}&iniDate=${start.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupDate=${daily}`
        } else {
            url = `getHistoricalDataScada/${data?.numserie}?iniDate=${start.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupDate=${daily}`
        }
        if (url) {
            try {
                const res = await fetch(
                    urls.scada + url,
                    { method: "GET", headers: headersScada(token) }
                )
                if (res.status === 200) {
                    const json = await res.json()
                    setEnergyData(json)
                } else {
                    return []
                }

            } catch (err) {
                console.error("ERROR. API Scada", err)
            }
        }
    }

    useEffect(() => {
        setEnergyData([])
        fetchEnergy()
    }, [data, daily, end, start])

    return (
        <Grid container spacing={0} className="containter-scada-data">
            <Grid item md={12} lg={12} className="scada-data-icon-nick-visualizer" >
                <span>
                    {data?.id_tipo_dispositivo && (
                        <span className="scada-data-icon-nick">
                            <ImageDevice id_type_device={data?.id_tipo_dispositivo} width={30} />
                            <h3 className="scada-data-nick"> {data?.nick}</h3>
                        </span>
                    )}
                </span>
                <Grid item md={1} className="scada-data-visualizer">
                    <DeviceVisualizer device={data?.id_tipo_dispositivo} style={{ height: 50, marginRight: "2%" }} />
                    <ArrowDirection style={{ height: 30 }} direction={data?.activo} />
                </Grid>
            </Grid><Grid item md={12} lg={12}>
                <div className="grayDivider scada-data-gray-divider" />
                <span >
                    <DeviceDate
                        device={data}
                        loading={loading}
                        data={currentDevice?.currentData}
                        masterPermission={data}
                    />
                </span>
            </Grid>
            <Grid item md={12} lg={12} className="containter-scada-principal-graphs">
                {data?.id_tipo_dispositivo !== dispositivos.ccm1 && data?.id_tipo_dispositivo !== dispositivos.ccm2 &&
                    <MenuGeneralConsumo activeTab={activeTab} id_tipo_dispositivo={data?.id_tipo_dispositivo} seleccionarTab={setActiveTab} />
                }
                <ScadaPrincipalGraphs data={data} loose={loose} loadingCard={loadingCard} loading={loading} setLoading={setLoading} currentDevice={currentDevice} activeTab={activeTab} triphasic={data?.id_tipo_dispositivo !== dispositivos.ccm1 && data?.id_tipo_dispositivo !== dispositivos.ccm2} />
            </Grid>
            <Grid item xs={12} md={12} className="scada-data-report-container">
                <ReportScada
                    energyData={energyData}
                    data={data}
                    start={start}
                    end={end}
                    setStart={setStart}
                    setEnd={setEnd}
                    daily={daily}
                    setDaily={setDaily}
                    tipo={daily}
                    loose={loose}
                    loadingCard={loadingCard}
                />
            </Grid>
        </Grid>
    )
}