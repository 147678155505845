import { Button, Grid } from "@mui/material";
import { ArrowBack } from "utils/icon-centrocontrol";
import { iconoTipoScada } from "utils/Utils";

export const ScadaDeviceHeader = ({ props, type, data, handleButton }) => {

    return (
        <Grid item sm={12} lg={12} xl={12} className='header-auto'>
            <span className="component-header">
                {iconoTipoScada(type, data?.[0]?.data?.currentData?.soc)}
                <h3 className="pl-2">{type}</h3>
            </span>
            <span className="component-header arrow-back">
                <Button onClick={handleButton ? handleButton : props?.handleButton} className="button-volver" >
                    <ArrowBack />
                    <p>Volver</p>
                </Button>
            </span>
        </Grid>
    )
}