import React from "react"
import moment from "moment"
import { Card, Grid } from "@mui/material"
import { Loader } from "components/Loader/Loader"
import { isToday } from "utils/Utils"
import { datoNull } from "views/Scada/MABT/utils"
import { DoubleBarEneryGraph } from "../ScadaConsumptionEnergy/DoubleBarEneryGraph"
import ConsuptionPowerRealTime from "../ScadaConsumptionPower/PhasesActivepower"
import { TotalActivepower } from "../ScadaConsumptionPower/TotalActivePower"
import { TotalIntensity } from "../ScadaConsumptionIntensity/TotalIntensity"
import { TensionGraph } from "../ScadaConsumptionTension/TensionGraph"
import "./ScadaConsumptionMain.scss"

const ScadaConsumptionMain = (props) => {
  const prueba = moment(props?.data?.datetime)
  const sinConectar = !(
    moment().isSame(prueba, "day") &&
    moment().isSame(prueba, "year") &&
    moment().isSame(prueba, "month")
  )

  const today = isToday(props?.data?.datetime, true)


  return (
    <Card style={{ boxShadow: "0px 0px 0px" }}>
      {props?.activeTab === 0 && !props.loadingData ? (
        <Grid container columns={13} style={{ display: "flex", justifyContent: "center", alignItems: "start" }}>
          {props?.data?.i2 !== undefined && props?.data?.i3 !== undefined ? (
            <>
              <Grid item xs={13} md={6} lg={3} xl={2} className="mt-4">
                <h3
                  className="text-enerclic text-2xl font-bold mb-4">
                  Energía activa
                </h3>
                <DoubleBarEneryGraph
                  loose={props?.loose}
                  data={props.data}
                  idMaster={props.idMaster}
                  loadingData={props.loadingData}
                  device={props.device}
                />
              </Grid>
              <Grid item xs={13} md={6} lg={10} xl={4} className="mt-4">
                <TotalActivepower
                  sinConectar={sinConectar}
                  data={props.data}
                  idMaster={props.idMaster}
                  loadingData={props.loadingData}
                  graphData={props.graphData}
                  ccm={props.ccm}
                  today={today}
                />
              </Grid>
              <Grid item xs={13} md={13} lg={13} xl={7} className="mt-4">
                <ConsuptionPowerRealTime
                  sinConectar={sinConectar}
                  activeTab={props?.activeTab}
                  ccm={props.ccm}
                  data={props.data}
                  graphData={props?.graphData}
                  hiredPotency={props?.hiredPotency}
                  loading={props?.loading}
                  loadingData={props.loadingData}
                  loose={props?.loose}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={13}
                md={6}
                lg={6}
                xl={2}
                style={{ marginRight: "0px" }}>
                <Grid item md={12}>
                  <h3
                    className="text-enerclic text-2xl font-bold text-center mb-4">
                    Energía activa
                  </h3>
                  <DoubleBarEneryGraph
                    loose={props?.loose}
                    data={props?.data}
                    idMaster={props?.idMaster}
                    loadingData={props?.loadingData}
                    device={props?.device}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={13}
                md={7}
                lg={7}
                xl={4}
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  width: "100%",
                }}>
                <TotalActivepower
                  monofasico={true}
                  sinConectar={sinConectar}
                  data={props.data}
                  idMaster={props.idMaster}
                  loadingData={props.loadingData}
                  graphData={props.graphData}
                />
              </Grid>
              <Grid item xs={13} md={7} lg={7} xl={4}>
                <TotalIntensity
                  sinConectar={sinConectar}
                  data={props.data}
                  idMaster={props.idMaster}
                  loadingData={props.loadingData}
                  graphData={props?.intensityData}
                  mainResume={true}
                />
              </Grid>
              <Grid item xs={13} md={6} lg={6} xl={3}>
                <Grid container>
                  <Grid item md={13}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span style={{ textAlign: "center" }}>
                        <h3
                          className="text-enerclic text-2xl font-bold text-center">
                          Tensión
                        </h3>
                      </span>
                    </div>
                  </Grid>
                  <TensionGraph value={today && props?.data?.v1 ? props?.data?.v1 : today && props?.data?.vac ? props?.data?.vac : 0} />
                  <Grid
                    item
                    md={13}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      top: "-3em",
                    }}>
                    <h3
                      className="mb-0"
                      style={{
                        fontWeight: "bold",
                        width: "50%",
                        textAlign: "center",
                      }}>
                      220 V
                    </h3>
                    <h3
                      className="mb-0"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "50%",
                      }}>
                      250 V
                    </h3>
                  </Grid>
                  <Grid item md={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", top: "-14.5em" }}>
                    <h2 className={`${(props?.data?.v1 === 0 || props?.data?.vac === 0) && 'color-red'} mb-0`} style={{ width: "100%", textAlign: "center" }}>
                      {today && props?.data?.v1 ? datoNull(props?.data?.v1) + "V" : today && props?.data?.vac ? datoNull(props?.data?.vac) + "V" : " - "}
                    </h2>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      ) : (
        <Grid item lg={12} xl={12} className="mt-2 mb-6">
          <div className="justify-content-center">
            <Loader />
          </div>
        </Grid>
      )}
    </Card>
  );
};
export default ScadaConsumptionMain;
