import React, { useState } from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import "./styleEms.scss"
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { IconEditPencil } from 'utils/icon-centrocontrol'
import { IconDeleteAlarm } from 'utils/icon-centrocontrol'
import { ModalEditMaxPower } from './Modal/ModalEditMaxPower'
import { ModalDeleteEMS } from './Modal/ModalDeleteEMS'
import { IconWarning } from 'utils/icon-centrocontrol'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { formatDate } from 'utils/Utils'
import { Loader } from 'components/Loader/Loader'

export const MaxPower = ({ data, setKeySwitch, dataDevices }) => {
    const [modalEdit, setModalEdit] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [config, setConfig] = useState()

    const dataMaster = data?.map(item1 => {
        const item2 = dataDevices?.find(item2 => item2.numserie === item1.master?.numserie);
        if (item2) {
            return {
                ...item1,
                potmax: {
                    ...item1.potmax,
                    datetime: formatDate(item2.datetime)
                }
            };
        } else {
            return item1;
        }
    });

    return (
        <> {dataMaster.length <= 0 ? <Loader /> :
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                        <TableRow>
                            <StyledTableCellHead align="center">Máster</StyledTableCellHead>
                            <StyledTableCellHead align="center">Activo</StyledTableCellHead>
                            <StyledTableCellHead align="center">Potencia Máxima</StyledTableCellHead>
                            <StyledTableCellHead align="center">Fecha última actualización</StyledTableCellHead>
                            <StyledTableCellHead align="center">Reconexión Manual</StyledTableCellHead>
                            <StyledTableCellHead align="center"></StyledTableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && dataMaster && dataMaster?.map((elm, i) => {
                            return (
                                <StyledTableRow key={i}>
                                    <StyledTableCell className={`${!elm?.config?.enable && "max-power-table-master"}`} >{elm?.master?.nick}
                                        {!elm?.config?.enable && <HtmlTooltip title={<span>Máster con la potencia máxima deshabilitada</span>}  >
                                            <IconWarning widht={20} height={20} /></HtmlTooltip>}
                                    </StyledTableCell>
                                    <StyledTableCell align="center"><SwitchStyled id="habilitado" className="switch-extra-small mr-2" disabled={true} checked={elm?.potmax?.enable} /></StyledTableCell>
                                    <StyledTableCell align="center" >{elm?.potmax?.max_power}</StyledTableCell>
                                    <StyledTableCell align="center" >{elm?.potmax?.datetime}</StyledTableCell>
                                    <StyledTableCell align="center"><SwitchStyled id="habilitado" className="switch-extra-small mr-2" disabled={true} checked={elm?.potmax?.manual_conn} /></StyledTableCell>
                                    <StyledTableCell>
                                        <ButtonStyledIcon onClick={() => { setConfig(elm); setModalEdit(!modalEdit) }} className='boton-edit-profile'  >
                                            <IconEditPencil className='color-icon' />
                                        </ButtonStyledIcon>
                                        <ButtonStyledIcon onClick={() => { setConfig(elm); setModalDelete(true) }} className='boton-edit-profile button-delete-calendar'>
                                            <IconDeleteAlarm fill="#FF8A1F" className="color-icon" width="20" height="20" />
                                        </ButtonStyledIcon>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>}
            {modalEdit && <ModalEditMaxPower device={config} open={modalEdit} setOpen={setModalEdit} setKeySwitch={setKeySwitch} dataDevices={dataDevices} />}
            {modalDelete && <ModalDeleteEMS device={config} open={modalDelete} setOpen={setModalDelete} setKeySwitch={setKeySwitch} />}
        </>
    )
}
