import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { headersScada } from 'services/headers'
import { toKilowDate, toKilowPercent } from 'utils/functionsforGraphs'
import { IconPanel } from 'utils/icon-centrocontrol'
import { urls } from 'utils/constant'
import "../../SelfConsumption/styleSelfConsumption.scss"

export const CardInverter = (props) => {
    const token = sessionStorage?.token
    const [data, setData] = useState()
    const getInverter = props?.devices?.childrenDevices?.filter(device => device.id_tipo_dispositivo === 7)

    const fetchData = () => {
        fetch(urls.scada + `getCurrentData/${getInverter?.[0]?.numserie}?idCCMaster=${getInverter?.[0]?.master_serial}`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                } else {
                    return []
                }
            })
            .then(
                data => setData(data),
            )
            .catch(err => console.error("ERROR. API Scada", err))
    }

    useEffect(() => {
        if (props?.devices?.numserie) {
            if (getInverter?.[0]?.numserie !== undefined) {
                fetchData()
            }
        }
    }, [props?.devices?.numserie, props?.handleSelectedDevice, props?.setRefresh, props?.refresh])

    const handleClickProduction = () => {
        props?.seeWeb('production')
        props?.setRefresh(true)
        props?.setActiveTabDevice(0)
        props?.setActiveTab(5)
        setTimeout(() => {
            props?.setRefresh(false)
        }, 500);
    }

    return (
        <>
            <span className='card-arrow'>
                {props?.isMobile !== true ?
                    <span className='card-column '>
                        <>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                                    <div className={props.selfConsumption === "production" ? "Card-self-Active" : "Card-self"}
                                        onClick={() => {
                                            handleClickProduction();
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self">
                                                <IconPanel />
                                                <h2 className="title-card-self">
                                                    Generación
                                                </h2>
                                            </span>
                                            <span className="grid-Card-self">
                                                <span className="power-card-self-section">
                                                    <p className="element-card-self-mini" id="production">
                                                        Potencia: {toKilowDate(data?.produccion?.datetime, data?.produccion?.pact)} kW
                                                    </p>
                                                    <p className="element-card-sub-self">
                                                        Autoconsumo: {toKilowPercent(props?.importSelf)} %
                                                    </p>
                                                </span>
                                            </span>
                                        </span>
                                        <div className='divider-self' />
                                        <span className="cardData-self">
                                            <p className="element-card-self">
                                                Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                                            </p>
                                        </span>
                                    </div>
                                </Grid>
                            </div>
                            <div className="cardMargin-self">
                            </div><div className="cardMargin-self">
                            </div></>
                        <div className="cardMargin-self">
                        </div>
                    </span>
                    : <>
                        <span className='card-column small-card'>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12}>
                                    <div className='card-self-small'
                                        onClick={() => {
                                            handleClickProduction();
                                        }}>
                                        <span className="header-Card-self ">
                                            <span className="grid-Card-self card-mobile-card-menu ">
                                                <IconPanel alt="Panel Solar" />
                                                <p> Generación </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                                                </p>
                                            </span>
                                        </span>
                                        <div className={props.selfConsumption === "production" ? "divider-self-active-small" : "divider-self-small"} />
                                    </div>
                                </Grid>
                            </div>
                        </span>
                    </>
                }
            </span>
        </>
    )
}