export const CategoryType = (type) => {
    switch (type) {
        case 'consumption':
            return "Consumo"
        case 'self_consumption':
            return "Autoconsumo"
        case 'photovoltaic':
            return "Generación"
        case 'mabt':
            return "Distribución"
        case "lighting":
            return "Iluminaria"
        case "enerSIM":
            return "EnerSIM"
        case "IP-Meter":
            return "IP-Meter"
        case 0:
            return "Consumo"
        case 1:
            return "Autoconsumo"
        case 2:
            return "Generación"
        case 3:
            return "Distribución"
        case 4:
            return "Iluminaria"
        case 8:
            return "EnerSim"
        case 7:
            return "IP-Meter"
        default:
            return "No se conoce el tipo de instalación"
    }
}

export const TypeProject = (type) => {
    switch (type) {
        case 'consumption':
            return 0
        case 'self_consumption':
            return 1
        case 'photovoltaic':
            return 2
        case 'mabt':
            return 3
        case 'lighting':
            return 4
        case 'fairs_and_congresses':
            return 5;
        case 'energ_community':
            return 6
        case "enerSIM":
            return 8;
        case 0:
            return 0
        case 1:
            return 1
        case 2:
            return 2
        case 3:
            return 3
        case 4:
            return 4
        case 5:
            return 5;
        case 6:
            return 6
        default:
            return 0
    }
}

export const Inst_Type = (type) => {
    switch (type) {
        case 0:
            return "Consumo"
        case 1:
            return "Autoconsumo"
        case 2:
            return "Generación"
        case 3:
            return "Distribución"
        case 4:
            return "Iluminación"
        case 5:
            return "Ferias y Congresos"
        case 6:
            return "Energía Comunitaria"
        default:
            return "No conocemos el tipo de instalación"
    }
}

export const LogoPosition = (position) => {
    switch (position) {
        case 1:
            return "Header"
        case 2:
            return "Footer"
        case 3:
            return "Menu"
        default:
            return "No conocemos la posición"
    }
}

export const PermissionType = (permi) => {
    switch (permi) {
        case 'admin':
            return "Administrador"
        case 'user':
            return "Usuario"
        case 'instal':
            return "Instalador"
        case 'install':
            return "Instalador"
        default:
            return "Desconocido"
    }
}

export const parseDefaultImg = (logos, type) => {
    const newLogos = !logos ?
        []
        : Object.values(logos).map((logo) => {
            return LogoPosition(logo[0]) === type && (
                `data:${logo[1]};base64,${logo[2]}`
            )
        }).filter(Boolean)

    return newLogos
}