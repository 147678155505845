import React from 'react'
import moment from "moment"
import { Document, Page, Text, View } from '@react-pdf/renderer'
import { styles } from './TableDataCalendarCCMasterStyle'
import { convertWeekDay } from 'utils/Utils'

const hours = Array.from({ length: 25 }, (_, i) => `${i.toString().padStart(2, '0')}:00`)

const adjustEventTimes  = (events) => {
  return events.map(event => {
    if (event.sunrise) {
      return {
        ...event,
        hour: 5,
        min: 40
      }
    }
    if (event.sunset) {
      return {
        ...event,
        hour: 18,
        min: 59
      }
    }
    return event
  })
}

const RenderTable = (title, dates, data, master) => {

  let isDrawingLine = false
  let firstOnEvent = null

  const adjustedData = adjustEventTimes(data)

  return (
    <View style={styles.tableContainer}>
      <Text style={styles.tableTitleMaster}>{master}</Text>
      <Text style={styles.tableTitle}>{title}</Text>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={styles.dateCol}>
            <Text style={styles.tableCell}>Horas / Fechas</Text>
          </View>
          {hours.map((hour, index) => (
            <View style={styles.tableColHeader} key={index}>
              <Text style={styles.tableCell}>{hour}</Text>
            </View>
          ))}
        </View>

        {dates.map((date, dateIndex) => {
          let formattedDate = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
          let weekDay = moment(formattedDate).format('dddd').toLowerCase();
          let events = []

          adjustedData.forEach((data) => {
            let undefinedDates = ((data.start_date === "" && data.end_date === "") || (data.start_date === undefined && data.end_date === undefined))

            if(data.enable) {
              if (undefinedDates && data.week.length === 0 && data.day === formattedDate) {
                events.push(data)
              } else if ((undefinedDates && data.week.length > 0 && data.week.includes(convertWeekDay(weekDay)))) {
                events.push(data)
              } else if (!undefinedDates) {
                let formattedStartDate = moment(data.start_date).format('YYYY-MM-DD');
                let formattedEndDate = moment(data.end_date).format('YYYY-MM-DD');

                if ((data.week.length === 0 && data.day === formattedDate) ||
                    (data.week.length > 0 && data.week.includes(convertWeekDay(weekDay)))) {
                  if (formattedDate >= formattedStartDate && formattedDate <= formattedEndDate) {
                    events.push(data)
                  }
                }
              }
            }
          })

          return (
            <View style={styles.tableRow} key={dateIndex}>
              <View style={styles.dateCol}>
                <Text style={styles.tableCell}>{date}</Text>
              </View>
              {hours.map((_, hourIndex) => {
                let filteredEvents = events.filter(event => event.hour === hourIndex && (event.priority || !events.some(e => e.hour === hourIndex && e.priority)))
                let cellContent = null
                let cellStyle = null

                filteredEvents.map((event, index) => {
                  if (Number(event.internal_mode) === 100 && !firstOnEvent) {
                    cellContent = `${event.hour}:${event.min.toString().padStart(2, '0')}`
                    isDrawingLine = true
                    firstOnEvent = event
                  } else if (Number(event.internal_mode) === 103 && isDrawingLine) {
                    cellContent = `${event.hour}:${event.min.toString().padStart(2, '0')}`
                    isDrawingLine = false
                    firstOnEvent = null
                  }

                  return null
                })

                cellStyle = (isDrawingLine) ? 
                  styles.greenLine :  
                    ((filteredEvents.length > 0 && Number(filteredEvents[0].internal_mode) === 103 && cellContent) ? 
                      styles.redEvent : 
                      null)

                return (
                  <View style={styles.tableCol} key={hourIndex}>
                    <Text style={[styles.tableCell, cellStyle]}>
                      {cellContent}
                    </Text>
                  </View>
                )
              })}
            </View>
          )
        })}
      </View>
    </View>
  )
}

export const TableDataCalendarCCMaster = ({ relayOneData, relayTwoData, selectedMaster, dates }) => {

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        {relayOneData?.length === 0 && relayTwoData?.length === 0 ?
          <View style={styles.noDataContainer}>
            <Text style={styles.noDataText}>No hay datos disponibles</Text>
          </View> :
          <>
            {relayOneData?.length > 0 && RenderTable('Relay 1', dates, relayOneData, selectedMaster)}
            {relayTwoData?.length > 0 && RenderTable('Relay 2', dates, relayTwoData, selectedMaster)}
          </>
          }
      </Page>
    </Document>
  )
}
