import React, { useState, useEffect } from 'react'
import { PowerGraphsConsumption } from './PowerGraphsConsumption'
import { conversorFecha } from 'utils/functionsforGraphs'
import { Loader } from 'components/Loader/Loader'
import { moreOneDay } from 'utils/Utils'
import { GraphsBar } from 'components/Graph/GraphsBar'
import { dispositivos } from 'utils/constant'
import { consumidos } from 'utils/constant'
import { formatDateNoSecond } from 'utils/Utils'

export const GraphsConsumption = (props) => {
    const [checkboxSumPhases, setCheckboxSumPhases] = useState(true)
    const [checkBoxByPhases, setcheckBoxByPhases] = useState(false)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [labelEnergy, setLabelEnergy] = useState([])
    const [dataSet, setDataSet] = useState([])

    const handleCheckBox1 = () => {
        setCheckboxSumPhases(true)
        setcheckBoxByPhases(false)
    }

    const handleCheckBox2 = () => {
        setCheckboxSumPhases(false)
        setcheckBoxByPhases(true)
    }

    useEffect(() => {
        if (!moreOneDay(props?.start, props?.end)) {
            setActiveTabDevice(1)
        }
    }, [props?.start, props?.end])


    const EnergyConsumption = () => {
        setDataSet([])
        props?.devices && props?.devices?.forEach((dev, i) => {
            if (props?.updatedList.includes(Number(dev.numserie))) {
                if (dev.id_tipo_dispositivo === dispositivos.planta) {
                    setDataSet(prev => [...prev, {
                        Type: "bar",
                        label: dev.nick ? dev.nick : "Importada",
                        data: props?.consumption && props?.consumption?.filter(disp => disp.numserie === dev.numserie).map(device => device.data.consumo ? device.data.consumo.eaconsdia : device.data.eaconsdia),
                        backgroundColor: consumidos[i],
                        borderColor: consumidos[i],
                        pointBackgroundColor: consumidos[i],
                        borderWidth: 0,
                        fill: true,
                        id: "1",
                        numserie: "",
                        maintainAspectRatio: "true"
                    }])

                } else {
                    setDataSet(prev => [...prev, {
                        Type: "bar",
                        label: dev.nick ? dev.nick : "Importada",
                        data: props?.consumption && props?.consumption?.filter(disp => disp.numserie === dev.numserie).map(device => device?.data?.currentData ? device?.data?.currentData.eaconsdia : device.data.eaconsdia),
                        backgroundColor: consumidos[i],
                        borderColor: consumidos[i],
                        pointBackgroundColor: consumidos[i],
                        borderWidth: 0,
                        fill: true,
                        id: "1",
                        numserie: "",
                        maintainAspectRatio: "true"
                    }])
                }
            }
        })
    }


    useEffect(() => {
        setLabelEnergy([])
        setLabelEnergy((prev) => {
            const updatedLabelEnergy = [...prev];

            props?.consumption?.forEach((dev) => {
                if (!updatedLabelEnergy.includes(dev.data.consumo ? formatDateNoSecond(dev.data.consumo.datetime) : dev.data.currentData ? formatDateNoSecond(dev.data.currentData.datetime) : dev.data.datetime)) {
                    updatedLabelEnergy.push(dev.data.consumo ? formatDateNoSecond(dev.data.consumo.datetime) : dev.data.currentData ? formatDateNoSecond(dev.data.currentData.datetime) : dev.data.datetime);
                }
            });

            return updatedLabelEnergy;
        });
    }, [props.consumption]); // Ejecuta 


    useEffect(() => {
        EnergyConsumption()
    }, [props?.consumption, props?.updatedList])

    return (
        <>
            <div className="selected-graphs">
                <button className={activeTabDevice === 0 ? "button-general-active" : "button-general"} disabled={!moreOneDay(props?.start, props?.end)} selected={activeTabDevice === 0} onClick={() => setActiveTabDevice(0)}>
                    Potencia (kW)
                </button>
                <button className={activeTabDevice === 1 ? "button-general-active" : "button-general"} selected={activeTabDevice === 1} onClick={() => setActiveTabDevice(1)}>
                    Energía consumida (kWh)
                </button>
            </div >
            <div className='date-graphs-scada'>
                <p>
                    {conversorFecha(props.start, props.end, props?.titleButton)}
                </p>
            </div>
            {
                moreOneDay(props?.start, props?.end) &&
                <div className={`tab-item-wrapper  ${activeTabDevice === 0 ? 'active' : ''}`} index={0}>
                    <div className="my-2">
                        {props?.loading === true ? <Loader /> :
                            <PowerGraphsConsumption updatedList={props?.updatedList} data={props.data} deviceType={props.deviceType}
                                device={props?.device} start={props.start} end={props.end} groupData={props.groupData}
                                setStart={props.setStart} now={props.now} dataPack={props?.dataPack}
                                setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton}
                                setTitleButton={props.setTitleButton} handleClickRight={props.handleClickRight}
                                handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily}
                                checkboxSumPhases={checkboxSumPhases} setCheckboxSumPhases={setCheckboxSumPhases}
                                checkBoxByPhases={checkBoxByPhases} setcheckBoxByPhases={setcheckBoxByPhases}
                                handleCheckBox1={handleCheckBox1} handleCheckBox2={handleCheckBox2}
                                historicalData={props.historicalData} activeTabDevice={0} loading={props.loading}
                                axis={props?.axis} setAxis={props?.setAxis} name={props?.name}
                            />
                        }
                    </div>
                </div>
            }
            <div className={`tab-item-wrapper  ${activeTabDevice === 1 ? 'active' : ''}`} index={1}>
                <div className="my-2">
                    {props?.loading === true ? <Loader /> :
                        <GraphsBar dataSet={dataSet} labels={labelEnergy} />
                    }
                </div>
            </div>
        </>
    )
}
