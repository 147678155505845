export const standarDatasets = () => {

    let res = [];

    for (let i = 0; i < 288; i++) {
        res.push(0);
    };
    return res;
};

//CREAMOS NOSOTROS NUESTRO PROPIO LABEL CON LAS 24 HORAS
//A INTERVALOS DE 5 MIN, PARA ESTAR SEGUROS DE QUE MOSTRAMOS
//LA LÍNEA COMPLETA DE TIEMPO y saber si faltan horas en las
//DATAS recibidas.

export const standarLabel = () => {

    let res = [];

    for (let i = 0; i < 24; i++) {

        let n1 = null;

        if (i < 10) {
            n1 = `0${i}`;
        } else {
            n1 = `${i}`;
        };

        for (let k = 0; k < 60; k++) {

            let n2 = null;

            if (k < 10) {
                n2 = `0${k}`;
            } else {
                n2 = `${k}`;
            };

            res.push(`${n1}:${n2}`);
            k = k + 4;
        };
    };

    return res;
};

//Función que genera la data para una fase,
//y copia los valores de la original o asigna null
//comparando las horas de los label una a una.

const dataOk = (obj, label) => {

    if (obj !== undefined) {

        for (let i = 0; i < obj.data.length; i++) {

            let limit = 0;
            let aux1 = [];
            let aux2 = [];
            let aux3 = [];
            ///aquí se recurre al categories para no mostrar más datos 
            ///de los traídos por la consulta.
            for (let k = 0; k < obj.data[0].categories.length; k++) {

                let aux4 = obj?.data?.[i]?.categories?.[limit]?.slice(11);

                if (label[k] === aux4) {

                    aux1[k] = obj.data[i].data[0].data[limit];
                    aux2[k] = obj.data[i].data[1].data[limit];
                    aux3[k] = obj.data[i].data[2].data[limit];
                    limit = limit + 1;

                } else {

                    aux1[k] = null;
                    aux2[k] = null;
                    aux3[k] = null;
                }
            }

            obj.data[i].data[0].data = aux1;
            obj.data[i].data[1].data = aux2;
            obj.data[i].data[2].data = aux3;
        }

        return obj;

    } else {
        return obj;
    }
};

//Función que coge los .data ya completos a 24h
//y los pasa a formato procesable por el protocolo de la
//gráfica.

const dataFormat = (obj) => {

    let res = {
        data: [],
    };

    for (let i = 0; i < obj?.data?.length; i++) {

        res.data.push({
            nick: obj.data[i].nick,
            numserie: obj.data[i].numserie,
            data: [],
        });

        for (let k = 0; k < obj.data[i].data.length; k++) {

            res.data[i].data.push({
                name: obj.data[i].data[k].name,
                data: obj.data[i].data[k].data,
            });
        }
    };
    return res;
};

//Función que genera copias para poder manipularlas
//sin modificar el original.

const copy = (obj) => {

    if (obj !== undefined) {

        let res = {
            data: [],
        };

        for (let i = 0; i < obj.data.length; i++) {

            res.data[i] = obj.data[i];
        }

        return res

    } else {
        return obj;
    }
};

//Función que compara los .lenght recibidos.

const compareLength = (obj, serie) => {

    if (obj !== undefined) {

        let res = true;

        for (let i = 0; i < obj?.data?.length; i++) {

            for (let k = 0; k < obj?.data?.[i]?.data?.length; k++) {

                if (obj.data[i].data[k].data.length !== serie.length) {
                    res = false;
                }
            }
        }

        return res;

    } else {
        return obj;
    }
};


//elimina el sobrante del array tomando como referencia las horas que trae la consulta.


//Función inicial llamada desde el componente que inicia el proceso.

export const categoriesJoin = (obj) => {

    let correctLabel = standarLabel();

    if (compareLength(obj, correctLabel) !== true) {

        let copyObj = copy(obj);
        let correctData = dataOk(copyObj, correctLabel);
        let res = dataFormat(correctData);

        return res;
    } else {
        return obj
    };
};