import React, { useEffect } from 'react'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { CurrentTableProductionDesktop } from './CurrentTableProductionDesktop'
import { CurrentTableProductionMobile } from './CurrentTableProductionMobile'
import { dispositivos } from 'utils/constant'

export const CurrentTableProduction = (props) => {
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, { label: "Fecha", key: "datetime" }, { label: "Energía Vertida (kWh)", key: "eagendia" }, { label: "Potencia Activa (kW)", key: "pact" }]

    useEffect(() => {
        props?.setHeadersCsv(variableTableSelfConsumption)
    }, [])

    const headTable = ["Nombre", "Fecha", "Energía Generada (kWh)", "Potencia Activa (kW)"]
    const devices = [dispositivos.ccm1, dispositivos.ccm2, dispositivos.ccm3, dispositivos.ccm4]

    return (
        <>
            {props?.isMobile === true ?
                <CurrentTableProductionMobile
                    arrayDevices={devices}
                    deviceType={props?.deviceType}
                    selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    isMobile={props?.isMobile}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    open={props?.open}
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} />
                :
                <CurrentTableProductionDesktop arrayDevices={devices} headTable={headTable} data={props?.data1} checked={props?.checked} active={props?.active} loading={props?.loading} deviceType={props?.deviceType} />
            }
        </>
    )
}
