import React, { useState } from 'react'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Modal } from 'components/Modal/Modal'
import InputStyled from 'components/Input/InputStyled'
import { SelectStyled } from 'components/Select/SelectStyled'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'
import "../styleEms.scss"
import { useEffect } from 'react'

export const ModalConfigEMSSystem = ({ master, open, setOpen, setKeySwitch }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const [enableEMS, setEnableEMS] = useState(master?.config?.enable)
    const [modBus, setModbus] = useState(master?.config?.interv_req)
    const [gridPower, setGridPower] = useState(master?.config?.perc_marg)
    const [redux, setRedux] = useState(master?.config?.regi_redux)
    const [breaker, setBreaker] = useState(master?.config?.rele_used)
    const [relayConfig, setRelayConfig] = useState(master?.config?.rele_conf)
    const [relayTime, setRealyTime] = useState(master?.config?.rele_time)
    const [body, setBody] = useState([])

    const dispatch = useDispatch()

    const configBreaker = [{
        name: "Circuit Breaker in rele 1 - CcMaster",
        value: 0
    }, {
        name: "Circuit Breaker in rele  - CcMaster",
        value: 1
    }, {
        name: "Circuit Breaker in rele 1 and 2 - CcMaster",
        value: 2
    }
    ]

    const fetchEditEMSConfig = (numserie) => {
        setLoading(true)
        let status
        fetch(urls.scada + `emsConfig/${numserie}?idCcMaster=${numserie}`, {
            method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body)
        }).then(res => {
            if (res.status === 200) {
                status = res.status
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
            } else {
                status = res.status
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
        }).catch((error) => {
            console.error("Ha habido un erroe en API Scada", error)
        }).finally(() => {
            setLoading(false)
            setKeySwitch(Date.now())
            setOpen(false)
        })
    }

    const handleEdit = () => {
        fetchEditEMSConfig(master.master.numserie)
    }

    useEffect(() => {
        setBody({
            config: {
                "enable": enableEMS,
                "interv_req": modBus,
                "perc_marg": gridPower,
                "regi_redux": redux,
                "rele_used": breaker,
                "rele_conf": relayConfig,
                "rele_time": relayTime,
                "plant_type": master.master.inst_type
            }
        }
        )
    }, [master, enableEMS])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} open={open} setOpen={setOpen}
                disabled={loading || (enableEMS === master?.config?.enable)}
                title={`Configuración sistema EMS de  ${master?.master?.nick}`} loading={loading} functSave={() => handleEdit()} maxWidth="md">
                <Grid item xs={12} className="container-modal-max-power">
                    <span className="section-modal-mainance-ems">
                        <h4>Sistema EMS</h4>
                        <SwitchStyled id="habilitado" className="switch-small" checked={enableEMS} onClick={() => setEnableEMS(!enableEMS)} />
                    </span>
                    <span className="section-modal-mainance-ems">
                        <Grid item xs={12} xl={6} className="section-modal-mainance-ems">
                            <h4>Tiempo de Petición de Modbus (ms)</h4>
                        </Grid>
                        <Grid item xs={12} xl={6} >
                            <InputStyled
                                disabled={true}
                                fullWidth
                                defaultValue={master?.config?.interv_req}
                                Value={modBus}
                                onChange={(e) => { setModbus(e.target.value) }}
                                type="number"
                            />
                        </Grid>
                    </span>
                    <span className="section-modal-mainance-ems">
                        <Grid item xs={12} xl={6} className="section-modal-mainance-ems">
                            <h4>Potencia de Red (%)</h4>
                        </Grid>
                        <Grid item xs={12} xl={6}>
                            <InputStyled
                                disabled={true}
                                fullWidth
                                defaultValue={master?.config?.perc_marg}
                                Value={gridPower}
                                onChange={(e) => { setGridPower(e.target.value) }}
                                type="number"
                            />
                        </Grid>
                    </span>
                    <span className="section-modal-mainance-ems">
                        <h4>Registros modbus reducidos</h4>
                        <SwitchStyled id="habilitado" className="switch-small" disabled={true} checked={redux} onClick={() => setRedux(!redux)} />
                    </span>
                    <span className="section-modal-mainance-ems">
                        <h4>Circuito disyuntor</h4>
                        <SwitchStyled id="habilitado" className="switch-small" disabled={true} checked={breaker} onClick={() => setBreaker(!breaker)} />
                    </span>
                    <span className="section-modal-mainance-ems">
                        <Grid item xs={12} xl={6} className="section-modal-mainance-ems">
                            <h4>Configuración Circuito Disyuntor</h4>
                        </Grid>
                        <Grid item xs={12} xl={6} >
                            <FormControl size="small" className='width-100'>
                                <SelectStyled value={relayConfig} defaultValue={master.config?.rele_conf} disabled={true} onChange={(e) => setRelayConfig(e.target.value)}>
                                    {configBreaker.map((prm, i) => {
                                        return (
                                            <MenuItem value={prm.value} key={i}>
                                                {prm.name}
                                            </MenuItem>
                                        )
                                    })}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                    </span>
                    <span className="section-modal-mainance-ems">
                        <Grid item xs={12} xl={6} className="section-modal-mainance-ems">
                            <h4>Tiempo Relé (ms)</h4>
                        </Grid>
                        <Grid item xs={12} xl={6} >
                            <InputStyled
                                disabled={true}
                                fullWidth
                                defaultValue={master.config?.rele_time}
                                Value={relayTime}
                                onChange={(e) => { setRealyTime(e.target.value) }}
                                type="number"
                            />
                        </Grid>
                    </span>
                </Grid>
            </Modal >
        </>
    )
}
