import React, { useEffect, useState } from "react"
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap"
import { BulbAsyncRequest } from "../Bulb/BulbAsyncRequest"

const Plug = (props) => {
    let name = props.devices.name
    let nameSplit = name.split("_")
    const namePlug = nameSplit[0] + "_" + nameSplit[2]
    const [onOff, setOnOff] = useState(true)
    const [state, setState] = useState(200)

    // Modifica los valores para el post
    const handleBulb = () => {
        setOnOff(!onOff)
        if (onOff === true) {
            setState(200)
        } else {
            setState(201)
        }
        BulbAsyncRequest(props.devices.id_wifi, namePlug, state)
    }

    useEffect(() => {
        if (onOff === true) {
            setState(200)
        } else {
            setState(201)
        }
    }, [])

    return (
        <div className="mt-5 mr-5">
            <Row>
                <Col>
                    <Card className="text-center py-2">
                        <h1>{props.devices.nick}</h1>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-2 flex text-center">
                <Col>
                    <Card>
                        <CardHeader>
                            <h3>Apagado / Encendido</h3>
                        </CardHeader>
                        <CardBody>
                            <div className="power-switch flex">
                                <input type="checkbox"
                                    onClick={handleBulb} />
                                <div className="button">
                                    <svg className="power-off">
                                        <use xlinkHref="#line" className="line" />
                                        <use xlinkHref="#circle" className="circle" />
                                    </svg>
                                    <svg className="power-on">
                                        <use xlinkHref="#line" className="line" />
                                        <use xlinkHref="#circle" className="circle" />
                                    </svg>
                                </div>
                            </div>
                            <svg >
                                <symbol  viewBox="0 0 150 150" id="line">
                                    <line x1="75" y1="34" x2="75" y2="58" />
                                </symbol>
                                <symbol  viewBox="0 0 150 150" id="circle">
                                    <circle cx="75" cy="80" r="35" />
                                </symbol>
                            </svg>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default Plug
