import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import { useSelector } from "react-redux"
import Grid from "@mui/material/Grid"
import moment from "moment"
import { GlobalContext } from "context/GlobalContext"
import { instalaciones, urls, dispositivos } from "utils/constant"
import { MenuMobile } from "views/Scada/SelfConsumption/MenuMobile"
import { MenuScada } from "views/Scada/MABT/MenuScada"
import { Loader } from "components/Loader/Loader"
import { AuthFooter } from "views/Footer/AuthFooter"
import { DevicesUtils } from "views/Scada/Cards/DevicesUtils"
import { headersCentroControl, headersScada } from "services/headers"
import { nombreInstalacion } from "utils/Utils"
import { NavBar } from "views/NavBar/NavBar"
import Scada from "views/Scada/General/Scada"
import { Devices } from "views/Scada/Cards/Devices"
import "./ScadaScreen.scss"

export const ScadaScreen = (props) => {
  const [menuPack, setMenuPack] = useState({
    idCcMaster: null,
    anchorInfo: null,
    setAnchorInfo: null,
    masterPermission: null,
    setMasterPermission: null,
    actualProject: null,
    handleClickInfo: null,
    handleCloseInfo: null
  })
  let now = new Date()
  let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0))
  let ends = moment(startt).add(1, "days").subtract(1, "seconds")
  const [confirmSelfConsumption, setConfirmSelfConsumption] = useState([])
  const [selfConsumption, setSelfConsumption] = useState('general')
  const [activeTabCuadro, setActiveTabCuadro] = useState(false)
  const [masterPermission, setMasterPermission] = useState({})
  const [activeTabDevice, setActiveTabDevice] = useState()
  const [closeDevices, setCloseDevices] = useState(false)
  const [deviceSelected, setDeviceSelected] = useState()
  const [titleButton, setTitleButton] = useState('Hoy')
  const [resumeView, setResumeView] = useState(true)
  const [changeArrow, setChangeArrow] = useState(0)
  const [masterError, setMasterError] = useState()
  const [groupData, setgroupData] = useState(60)
  const [importSelf, setImportSelf] = useState()
  const [exportSelf, setExportSelf] = useState()
  const [refresh, setRefresh] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [visible, setVisible] = useState(true)
  const [devices, setDevices] = useState(null)
  const [idProject, setIdProject] = useState()
  const [projects, setProjects] = useState()
  const [start, setStart] = useState(startt)
  const [daily, setDaily] = useState("days")
  const [device, setDevice] = useState()
  const [loose, setLoose] = useState()
  const [end, setEnd] = useState(ends)
  const [data, setData] = useState()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { loadingApp, actualProject } = useContext(GlobalContext)
  const loadingSlice = useSelector((state) => state.loading.name)
  const isMobile = useMediaQuery({ maxWidth: 1680 })
  const token = sessionStorage?.token
  const idCcMaster = location.pathname.split('/')[4]
  const { id, numserieMaster } = params

  const toggleDevice = (tab) => setActiveTabDevice(tab)

  const handleSelectedDevice = (device1) => {
    setDevice(null)
    isMobile ? setCloseDevices(true) : setCloseDevices(null)
    setTimeout(() => {
      setDevice(device1)
    }, 100)
  }

  const fetchIdCcMaster = (numserie) => {
    fetch(urls.ccontrol + `type/${numserie}`, {
      method: "GET",
      headers: headersCentroControl(token)
    }).then((res) => {
      if (res.status === 200) {
        return res.json()
      } else if (res.status === 400) {
        navigate(`/auth/login`)
      } else {
        return []
      }
    }).then((data) => {
      setLoose(data)
      return data
    }).catch((err) => {
      console.error("ERROR. API de Centro de control", err)
    })
  }

  const fetchInfoPower = (loose) => {
    let url =
      loose !== 0 && loose !== 20
        ? `${numserieMaster}`
        : `${numserieMaster}?idCCMaster=${numserieMaster}`
    fetch(urls.scada + `getInfoPower/` + url, {
      method: "GET",
      headers: headersScada(token)
    })
      .then((res) => res.json())
      .then((data) => {
        setMasterError(data)
      })
      .catch((err) => {
        console.error("ERROR. API Scada", err)
      })
  }

  const handleClickLeft = () => {
    setChangeArrow(changeArrow + 1)
    if (titleButton === "Hoy") {
      setStart(moment(start).subtract(1, "days"))
      setEnd(moment(start).subtract(1, "days"))
    } else if (titleButton === "Ayer") {
      setStart(moment(start).subtract(1, "days"))
      setEnd(moment(start).subtract(1, "days"))
    } else if (titleButton === "Mes Actual") {
      setStart(moment(start).subtract(1, "months"))
      setEnd(moment(start).subtract(1, "months"))
    } else if (titleButton === "Año") {
      setStart(moment(start).subtract(1, "years"))
      setEnd(moment(start).subtract(1, "years"))
    } else if (titleButton === "Rango") {
      setStart(moment(start).subtract(1, "days"))
      setEnd(moment(start).subtract(1, "days"))
    } else if (titleButton === "Start") {
      setStart(moment(start).subtract(1, "days"))
      setEnd(moment(start).subtract(1, "days"))
    }
  }

  const handleClickRight = () => {
    setChangeArrow(changeArrow - 1)
    if (titleButton === "Hoy") {
      setStart(moment(start).add(1, "days"))
      setEnd(moment(start).add(1, "days"))
    } else if (titleButton === "Ayer") {
      setStart(moment(start).add(1, "days"))
      setEnd(moment(start).add(1, "days"))
    } else if (titleButton === "Mes Actual") {
      setStart(moment(start).add(1, "months").startOf("months"))
      setEnd(moment(start).add(1, "months").endOf("months"))
    } else if (titleButton === "Año") {
      setStart(moment(start).add(1, "years").startOf("year"))
      setEnd(moment(start).add(1, "years").endOf("year"))
    } else if (titleButton === "Rango") {
      setStart(moment(start).add(1, "days"))
      setEnd(moment(start).add(1, "days"))
    } else if (titleButton === "Start") {
      setStart(moment(start).add(1, "days"))
      setEnd(moment(start).add(1, "days"))
    }
  }

  const seeWeb = (type) => {
    if (type === "production") {
      setSelfConsumption("production")
    } else if (type === "red") {
      setSelfConsumption("red")
    } else if (type === "consumption") {
      setSelfConsumption("consumption")
    } else if (type === "batery") {
      setSelfConsumption("batery")
    } else if (type === "general") {
      setSelfConsumption("general")
    } else if (type === "alarmas") {
      setSelfConsumption("alarmas")
    } else if (type === "configuracion") {
      setSelfConsumption("configuracion")
    }
  }

  const seleccionarTab = (tab) => {
    setResumeView(tab === 0 ? true : false)
    seeWeb("general")
    setDeviceSelected()
    setActiveTab(tab)
    setActiveTabDevice(tab)
  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handleClick = (section, tab) => {
    seeWeb(section)
    setRefresh((current) => !current)
    toggle(tab)
  }

  useEffect(() => {
    window.scroll(0, 0)
  }, [activeTab, activeTabDevice])

  useEffect(() => {
    if (actualProject) {
      setMenuPack({
        idCcMaster: menuPack.idCcMaster,
        anchorInfo: menuPack.anchorInfo,
        setAnchorInfo: menuPack.anchorInfo,
        masterPermission: menuPack.masterPermission,
        setMasterPermission: menuPack.setMasterPermission,
        actualProject: actualProject,
        handleClickInfo: menuPack.handleClickInfo,
        handleCloseInfo: menuPack.handleCloseInfo
      })
      //change when refactor free acount
      if (actualProject?.groups) {
        actualProject?.groups?.forEach((grp) => {
          grp?.devices?.forEach((devices) => {
            setMasterPermission((prev) => ({
              ...prev,
              [devices?.numserie]: devices
            }))
          })
          grp?.subgroups?.forEach((subgroup) => {
            subgroup?.devices?.forEach((devices) => {
              setMasterPermission((prev) => ({
                ...prev,
                [devices?.numserie]: devices
              }))
            })
          })
        })
      } else {
        setMasterPermission((prev) => ({
          [actualProject?.numserie]: actualProject
        }))
      }
    }
  }, [actualProject])

  useEffect(() => {
    fetchIdCcMaster(idCcMaster)
  }, [idCcMaster])

  useEffect(() => {
    if (loose !== undefined) {
      fetchInfoPower(loose)
    }
  }, [loose, loadingSlice])

  useEffect(() => {
    if (idProject === undefined) {
      setIdProject(id)
    }
  }, [])

  useEffect(() => {
    if (idProject !== undefined) {
      localStorage.removeItem("idProject")
    }
  }, [idProject])

  useEffect(() => {
    if (devices) {
      let aux = devices?.childrenDevices?.filter(ads => Number(ads.id_tipo_dispositivo) === dispositivos.planta)
      setConfirmSelfConsumption(aux)
      window.document.title = `Scada - ${devices?.nick} - ${nombreInstalacion(devices?.inst_type)}`
      let numDevices = devices?.childrenDevices?.length - aux.length
      if ((devices?.id_tipo_dispositivo === dispositivos.ccmaster && numDevices > 1) ||
        (devices?.id_tipo_dispositivo !== dispositivos.ccmaster && devices?.childrenDevices?.length > 0)) {
        setDeviceSelected()
        setResumeView(true)
        setActiveTabDevice(0)
      } else if (devices?.id_tipo_dispositivo !== dispositivos.ccmaster && !devices?.childrenDevices?.length) {
        setDeviceSelected(devices)
        setResumeView(false)
        setActiveTabDevice(3)
      } else if (numDevices === 1) {
        setDeviceSelected(devices.childrenDevices[0])
        setResumeView(false)
        setActiveTabDevice(3)
      } else if (!activeTabDevice) {
        setActiveTabDevice(0)
      } else {
        setActiveTabDevice(activeTabDevice)
      }
    }
  }, [devices])

  return (
    <div>
      <div style={{ minHeight: "100vh" }}>
        <NavBar
          setRefresh={setRefresh}
          selfConsumption={selfConsumption}
          setSelfConsumption={setSelfConsumption}
          setMenuPack={setMenuPack}
        />
        {loadingApp === true ? (
          <Loader />
        ) : (
          <>
            {!data &&
              <Loader />
            }
            {data && devices?.inst_type !== instalaciones.mabt &&
              devices?.id_tipo_dispositivo !== dispositivos.ccmabt && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="pl-3 pt-2 navbar-scada scada-navbar-centro-control"
                >
                  <MenuScada
                    token={token}
                    activeTab={activeTab}
                    seleccionarTab={seleccionarTab}
                    devices={devices}
                    master={masterPermission?.[numserieMaster] ? masterPermission?.[numserieMaster] : masterPermission}
                    handleClick={handleClick}
                    masterError={masterError}
                    loose={loose}
                    activeTabCuadro={activeTabCuadro}
                    setActiveTabCuadro={setActiveTabCuadro}
                  />
                </Grid>
              )}
            <Grid container>
              <DevicesUtils
                loose={loose}
                handleSelectedDevice={handleSelectedDevice}
                setDevices={setDevices}
                devices={devices}
              />
              {isMobile &&
                confirmSelfConsumption.length > 0 && data &&
                (devices?.inst_type === instalaciones.selfConsumption ||
                  devices?.inst_type === instalaciones.photovoltaic) ? (
                <span className="banner-mobile-general">
                  <span>
                    <MenuMobile
                      className="menu-hamburguesa"
                      children={
                        <Devices
                          confirmSelfConsumption={confirmSelfConsumption}
                          isMobile={isMobile}
                          devices={devices}
                          device={device}
                          setDevices={setDevices}
                          setDevice={setDevice}
                          loose={loose}
                          setLoose={setLoose}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          handleSelectedDevice={handleSelectedDevice}
                          deviceSelected={device}
                          masterPermission={masterPermission}
                          seeWeb={seeWeb}
                          selfConsumption={selfConsumption}
                          visible={visible}
                          setVisible={setVisible}
                          importSelf={importSelf}
                          exportSelf={exportSelf}
                          resumeView={resumeView}
                          setResumeView={setResumeView}
                          toggleDevice={toggleDevice}
                          setActiveTabDevice={setActiveTabDevice}
                          setActiveTab={setActiveTab}
                        />
                      }
                    />
                  </span>
                </span>
              ) : !isMobile &&
                confirmSelfConsumption.length > 0 && data &&
                (devices?.inst_type === instalaciones.selfConsumption ||
                  devices?.inst_type === instalaciones.photovoltaic) ? (
                <Grid
                  item
                  xs={2}
                  md={2}
                  lg={2}
                  xl={2}
                  className={`${devices?.inst_type === instalaciones.selfConsumption
                    ? "devices"
                    : ""
                    } `}
                >
                  <Devices
                    confirmSelfConsumption={confirmSelfConsumption}
                    devices={devices}
                    device={device}
                    setDevices={setDevices}
                    setDevice={setDevice}
                    loose={loose}
                    setLoose={setLoose}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    handleSelectedDevice={handleSelectedDevice}
                    deviceSelected={device}
                    masterPermission={masterPermission}
                    seeWeb={seeWeb}
                    selfConsumption={selfConsumption}
                    visible={visible}
                    setVisible={setVisible}
                    importSelf={importSelf}
                    exportSelf={exportSelf}
                    resumeView={resumeView}
                    setResumeView={setResumeView}
                    toggleDevice={toggleDevice}
                    setActiveTabDevice={setActiveTabDevice}
                    setActiveTab={setActiveTab}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                className={`pr-2 ${isMobile &&
                  (devices?.inst_type === instalaciones.selfConsumption ||
                    devices?.inst_type === instalaciones.photovoltaic)
                  ? "d-flex justify-content-center"
                  : null
                  }`}
                xs={
                  !isMobile &&
                    confirmSelfConsumption.length > 0 &&
                    (devices?.inst_type === instalaciones.selfConsumption ||
                      devices?.inst_type === instalaciones.photovoltaic)
                    ? 10
                    : 12
                }
                md={
                  !isMobile &&
                    confirmSelfConsumption.length > 0 &&
                    (devices?.inst_type === instalaciones.selfConsumption ||
                      devices?.inst_type === instalaciones.photovoltaic)
                    ? 10
                    : 12
                }
                lg={
                  !isMobile &&
                    confirmSelfConsumption.length > 0 &&
                    (devices?.inst_type === instalaciones.selfConsumption ||
                      devices?.inst_type === instalaciones.photovoltaic)
                    ? 10
                    : 12
                }
                xl={
                  !isMobile &&
                    confirmSelfConsumption.length > 0 &&
                    (devices?.inst_type === instalaciones.selfConsumption ||
                      devices?.inst_type === instalaciones.photovoltaic)
                    ? 10
                    : 12
                }
                style={{ height: "100%" }}
              >
                {devices && (
                  <Scada
                    loose={loose}
                    setLoose={setLoose}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    startt={startt}
                    devices={devices}
                    device={device}
                    handleSelectedDevice={handleSelectedDevice}
                    masterPermission={masterPermission}
                    activeTabDevice={activeTabDevice}
                    toggleDevice={toggleDevice}
                    start={start}
                    end={end}
                    groupData={groupData}
                    setStart={setStart}
                    setEnd={setEnd}
                    setgroupData={setgroupData}
                    setTitleButton={setTitleButton}
                    titleButton={titleButton}
                    daily={daily}
                    setDaily={setDaily}
                    handleClickLeft={handleClickLeft}
                    handleClickRight={handleClickRight}
                    now={now}
                    closeDevices={closeDevices}
                    selfConsumption={selfConsumption}
                    setSelfConsumption={setSelfConsumption}
                    ends={ends}
                    data={data}
                    isMobile={isMobile}
                    setData={setData}
                    setImportSelf={setImportSelf}
                    setExportSelf={setExportSelf}
                    numserieMaster={numserieMaster}
                    setDevice={setDevice}
                    importSelf={importSelf}
                    setResumeView={setResumeView}
                    resumeView={resumeView}
                    confirmSelfConsumption={confirmSelfConsumption}
                    setDevices={setDevices}
                    deviceSelected={deviceSelected}
                    setDeviceSelected={setDeviceSelected}
                    seeWeb={seeWeb}
                    visible={visible}
                    setVisible={setVisible}
                    exportSelf={exportSelf}
                    projects={projects}
                    setProjects={setProjects}
                    menuPack={menuPack}
                    setActiveTabDevice={setActiveTabDevice}
                    handleOpenActAlarm={null}
                    masterError={masterError}
                    seleccionarTab={seleccionarTab}
                    activeTabCuadro={activeTabCuadro}
                    setActiveTabCuadro={setActiveTabCuadro}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <AuthFooter />
    </div>
  )
}