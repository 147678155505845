import React, { useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import InfiniteScroll from "react-infinite-scroll-component"
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { StyledTableCell, StyledTableCellOrdenado, StyledTableRow, StyledTableCellHead } from "components/Table/TableStyle"
import { Loader } from "components/Loader/Loader"
import { ButtonStyledIcon } from "components/Buttons/ButtonStyledIcon"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { ordenarDatos } from "utils/Utils"
import { IconData, IconEditPencil, IconWarning } from "utils/icon-centrocontrol"
import { FirmwareHistory } from "../TableHistorical/FirmwareHistory"
import { GlobalContext } from "context/GlobalContext"
import { EditInstall } from "../EditInstall/EditInstall"
import { ProgressBar } from "react-bootstrap"
import './TableConfigInstallation.scss'

/**
 * @param {{ dispositivos: [{}], token: string, data: [{}], setData: function, dataInstall[], filterMaster[], instalacioneSeleccionadas[], dispositivo[{}], scadaSelf: boolean }}
 * @param dispositivos Array de los dispositivos que tienen las instalaciones
 * @param token Token necesario de la llamada
 * @param dispositivo Array de dispositivos 
 * @param scadaSelf me dice si estoy o no en Scada de autoconsumo
 * @param data Array de objetos con los datos de la llamada getFirmware
 * @param setData funcion que setea data con los datos de la llamada getFirmware
 * @returns Devuelve la lista de masters del proyecto.
 */
export const TableConfigInstallation = ({ modalEditar, setModalEditar, dispositivos, token, scadaSelf, dataInstall, filterMaster, keySwitch, instalacioneSeleccionadas, dispositivo, setKeySwitch }) => {
  const [filas, setFilas] = useState(30)
  const [modalHistory, setModalHistory] = useState(false)
  const [hasMore, setHasMore] = useState(filterMaster?.length <= 30 ? false : true)
  const [configuracion, setConfiguracion] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
  const [tableHeight, setTableHeight] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const tableRef = useRef(null)
  const divRef = useRef(null)
  const { actualProject } = useContext(GlobalContext)

  const toggle = () => {
    setModalEditar(!modalEditar)
  }

  /**
   * @param {*} configuracion objeto con datos del master sobre el que se trabaja.
   * @description funcion que abre el modal editar y selecciona qué datos se le pasan por props.
   */
  const handleopenEdit = (configuracion) => {
    if (configuracion !== undefined) {
      let aux = dataInstall.find(
        (device) => device.numserie === configuracion.numserie
      )
      setConfiguracion(aux)
    }
    setModalEditar(true)
  }

  /**
   * @param {*} configuracion objeto con datos del master sobre el que se trabaja.
   * @description funcion que abre el modal historical y selecciona qué datos se le pasan por props.
   */
  const handleopenHistorical = (configuracion) => {
    if (configuracion !== undefined) {
      let aux = dataInstall?.find(
        (device) => device?.numserie === configuracion?.numserie
      )
      setConfiguracion(aux)
    }
    setModalHistory(true)
  }

  /**
   * @param device objeto con los datos del dispositivo con el que se trabaja.
   * @description extrae el número de alarmas activas.
   * @returns número de alarmas activas.
   */
  const alarma = useSelector((state) => state.alarma);
  const alarms = (device) => {
    const objetoEncontrado = alarma?.history.find(
      (objeto) => objeto.numserie === Number(device.numserie)
    )
    return objetoEncontrado?.alarmCount
  }

  const timeoutHandler = () => {
    if (isVisible) {
      setIsVisible(false)
      if (filas + 10 < filterMaster?.length) {
        setFilas(filas + 10)
        setIsVisible(false)
      } else if (filas <= filterMaster?.length) {
        setFilas(filterMaster?.length)
        setHasMore(false)
        setIsVisible(false)
      }
    }
  }

  const handleScroll = () => {
    const element = divRef?.current?.getBoundingClientRect()?.bottom - divRef?.current?.getBoundingClientRect()?.top - 30
    const currentPosition = document?.documentElement?.scrollTop + window?.innerHeight
    if (currentPosition >= element && hasMore) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.offsetHeight)
    }
  }, [filas])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      {!scadaSelf ?
        <div className='d-flex'>
          <TableContainer>
            {filterMaster?.length === 0 ?
              <h2 style={{ width: "100%", textAlign: "center", marginTop: "2%", }}>
                No se encontraron dispositivos tipo CcMaster.
              </h2>
              :
              <InfiniteScroll
                style={{ minHeight: tableHeight + 30 }}
                dataLength={filas}
                next={timeoutHandler()}
                hasMore={hasMore}
                loader={!isVisible && <h4 className="text-align-center">Cargando más instalaciones...</h4>}
              >
                <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      borderBottom: "2px solid #FF8A1F", borderRadius: "50px",
                    }}>
                    <TableRow>
                      <StyledTableCellOrdenado
                        className=""
                        align="start"
                        celda={"nick"}
                        style={{ margin: "0px", padding: "0px" }}
                        funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "nick"); }}
                        sortConfig={sortConfig}>
                        Instalación
                      </StyledTableCellOrdenado>
                      <StyledTableCell align="center" celda={"error"}
                        style={{ margin: "0px", padding: "0px", }}></StyledTableCell>
                      <StyledTableCellOrdenado align="center" celda={"version"}
                        style={{ margin: "0px", padding: "0px" }}
                        funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "version"); }}
                        sortConfig={sortConfig}>
                        Versión
                      </StyledTableCellOrdenado>
                      <StyledTableCellOrdenado
                        align="center"
                        celda={"tipo"}
                        funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "tipo"); }}
                        sortConfig={sortConfig}>
                        Tipo
                      </StyledTableCellOrdenado>
                      <StyledTableCellOrdenado align="center" celda={"status"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "status"); }} sortConfig={sortConfig}>
                        Estado
                      </StyledTableCellOrdenado>
                      <StyledTableCellOrdenado align="center" celda={"ult_act"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "ult_act"); }} sortConfig={sortConfig}>
                        Progreso Actualización                    </StyledTableCellOrdenado>
                      <StyledTableCellOrdenado align="center" celda={"ult_act"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "ult_act"); }} sortConfig={sortConfig}>
                        Última sincronización
                      </StyledTableCellOrdenado>
                      <StyledTableCellOrdenado align="center" celda={"enabled"} funcion={() => { ordenarDatos(filterMaster, sortConfig, setSortConfig, "enabled"); }} sortConfig={sortConfig}>
                        Alarmas
                      </StyledTableCellOrdenado>
                      <StyledTableCellHead align="center" celda={"numserie"}>
                        Acciones
                      </StyledTableCellHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterMaster && filterMaster.length !== 0 ?
                      filterMaster?.slice(0, filas).map((configuracion, i) => {
                        return (
                          (configuracion?.permissions === "admin" || configuracion?.permissions?.permissions === "admin") &&
                          <StyledTableRow style={{ padding: "0px", margin: "0px" }}>
                            <StyledTableCell align="center" style={{ maxWidth: "15em", margin: "0px", padding: "0px", }}>
                              {configuracion?.nick === "" || configuracion?.nick === null ? "-" : configuracion?.nick}
                            </StyledTableCell>
                            <StyledTableCell align="center" style={{ margin: "0px", padding: "0px" }}>
                              {configuracion.error &&
                                <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                  <IconWarning width={15} height={15} />
                                </HtmlTooltip>
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {configuracion?.version === "" || configuracion?.version === null ? "-" : configuracion?.version}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ maxWidth: "13em" }}>
                              {configuracion?.tipo === "" || configuracion?.tipo === null ? "-" : configuracion?.tipo}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span key={keySwitch}>   {configuracion?.status === "" || configuracion?.status === null ? "-" : configuracion?.status} </span>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="cell-with-center">
                              {configuracion.status === "Pendiente de actualización" ?
                                <ProgressBar className="progressBar progressbar-table-device" style={{ border: "2px solid #35A151" }} >
                                  <ProgressBar className='lineProgress' style={{ backgroundColor: "#35A151", fontSize: '10px', }} now={configuracion.progress} />
                                  <p className="progressbar-table-percent">{configuracion.progress}%</p>
                                </ProgressBar> : " - "
                              }
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span key={keySwitch}> {configuracion?.ult_act === "" || configuracion?.ult_act === null ? "-" : configuracion?.ult_act} </span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {alarms(configuracion)}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Grid container columns={15} className="table-config-cell-button">
                                <Grid item xs={12} md={12} lg={6} xl={5}>
                                  <ButtonStyledIcon style={{ height: "30px", width: "30px" }} className="boton-edit-alarm" title="Configuración" onClick={() => handleopenEdit(configuracion)} disabled={(configuracion.permissions === undefined || configuracion.permissions === "user") ? true : false} >
                                    <IconEditPencil className="boton-edit-hijo" />
                                  </ButtonStyledIcon>
                                </Grid>
                                <Grid item xs={12} md={12} lg={6} xl={5}>
                                  <ButtonStyledIcon
                                    className="boton-delete-alarm"
                                    title="Histórico"
                                    onClick={() => handleopenHistorical(configuracion)}
                                    disabled={configuracion.permissions === "user" ? true : false}
                                    style={{ height: "30px", width: "30px" }}
                                  >
                                    <IconData className="boton-delete-hijo" />
                                  </ButtonStyledIcon>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          </StyledTableRow>
                        )
                      })
                      :
                      <StyledTableRow
                        style={{
                          padding: "0px", margin: "0px",
                        }}></StyledTableRow>
                    }
                  </TableBody>
                </Table>
              </InfiniteScroll>
            }
          </TableContainer>
          <div ref={divRef}></div>
        </div>
        :
        scadaSelf ?
          <EditInstall
            instalacioneSeleccionadas={instalacioneSeleccionadas}
            toggle={toggle}
            data={dataInstall}
            dispositivos={dispositivos}
            token={token}
            configuracion={configuracion}
            modalEditar={modalEditar}
            setConfiguracion={setConfiguracion}
            setModalEditar={setModalEditar}
            actualProject={actualProject}
            dispositivo={dispositivo}
            scadaSelf={scadaSelf}
            setKeySwitch={setKeySwitch}
          />
          :
          <div className="justify-content-center">
            <Loader />
          </div>
      }
      {modalHistory && (
        <FirmwareHistory
          token={token}
          actualProject={actualProject}
          configuracion={configuracion}
          modalHistory={modalHistory}
          setModalHistory={setModalHistory}
        />
      )}
      {modalEditar && (
        <EditInstall
          instalacioneSeleccionadas={instalacioneSeleccionadas}
          toggle={toggle}
          data={dataInstall}
          dispositivos={dispositivos}
          token={token}
          configuracion={configuracion}
          modalEditar={modalEditar}
          setConfiguracion={setConfiguracion}
          setModalEditar={setModalEditar}
          actualProject={actualProject}
          dispositivo={dispositivo}
          setKeySwitch={setKeySwitch}
        />
      )}
    </>
  )
}