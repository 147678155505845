import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { Grid, Card, Table } from "@mui/material"
import { urls } from "utils/constant"
import { DeviceDate } from "utils/DeviceDate/DeviceDate"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { headersScada } from "services/headers"
import { IconWarning } from "utils/icon-centrocontrol"
import { toKiloW } from "utils/functionsforGraphs"

export const CardsinglePhase = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const actualRoute = location.pathname.split('/')[1]
    const token = sessionStorage?.token
    const idProject = location.pathname.split('/')[3]
    const numserieMaster = location.pathname.split('/')[4]
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [todayEnergy, setTodayEnergy] = useState()
    const [exportEnergy, setExportEnergy] = useState()
    const [totalPower, setTotalPower] = useState()
    const [errorVisible, setErrorVisible] = useState(false)
    const nameDevice = props?.devices?.nick ? props?.devices?.nick : data?.currentData?.nick

    const seeWeb = () => {
        props.handleSelectedDevice(props.devices)
        navigate(`/view/scada/${idProject}/${numserieMaster}/${props.devices.numserie}`)
    }

    let urlData
    if (props?.loose > 0) {
        urlData = `getCurrentData/${props.devices.numserie}`
    } else {
        urlData = `getCurrentData/${props.devices.numserie}?idCCMaster=${numserieMaster}`
    }

    const fetchData = () => {
        fetch(urls.scada + urlData, { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => {
                setData(data)
                setLoading(false)
            })
            .catch(err => {
                console.error("ERROR. API Scada", err)
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [props.devices.numserie, props?.loose])

    useEffect(() => {
        if (data?.currentData) {
            setTodayEnergy(data?.currentData?.eaconsdia)
            setExportEnergy(data?.currentData?.eagendia)
            setTotalPower(data?.currentData?.pact)
        }
    }, [data])

    const selectDevice = () => {
        seeWeb()
        props?.setDeviceSelected(props?.devices)
        fetchData()
        props?.setDevice(props?.devices)
        props?.setResumeView(false)
        if (props?.activeTabDevice !== 3) {
            props?.toggleDevice(3)
        }
    }

    useEffect(() => {
        if (data) {
            if ((data?.currentData?.intensidad_magneto >= 0 && data?.currentData?.intensidad_magneto !== null) || (data?.currentData?.potencia_contratada >= 0 && data?.currentData?.potencia_contratada !== null)) {
                setErrorVisible(false)
            } else {
                setErrorVisible(true)
            }
        }
    }, [data])

    return (
        <Grid
            className={props?.resumeView ? 'mt-3 ml-4' : "mt-3 ml-4 mr-0"}
            md={props?.resumeView ? 2 : 12}
        >
            <Grid container spacing={0}>
                <Grid item sm={12} lg={12} xl={12}>
                    <Card className={`card-box-alt p-4 custom-hover`}
                        onClick={() => selectDevice()}
                        style={{
                            cursor: actualRoute === "admin" ? '' : 'pointer',
                            borderRadius: '0rem',
                            borderLeft: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '5px solid #FF8A1F' : '1px solid #eee',
                            borderRight: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '5px solid #FF8A1F' : '1px solid #eee',
                            boxShadow: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '0px 5px 15px 2px #C4C4C4' : null,
                            height: '100%',
                        }}>
                        <Grid item sm={12} lg={12} xl={12} className="flex-center justify-start height-2-5">
                            <DropDownDevices
                                scada={true}
                                setLoading={setLoading}
                                device={props.devices}
                                masterPermission={props.masterPermission}
                                active={props.deviceSelected?.activo}
                                setDevices={props.setDevices}
                                loose={props?.loose}
                                instalacion={props?.masterPermission?.[props?.devices?.master_serial ? props?.devices?.master_serial : props?.devices?.mabt_serial ? props?.devices?.mabt_serial : props?.devices?.id_padre ? props?.devices?.id_padre : props?.devices?.numserie]}
                            />
                            <div className="ml-2">
                                <ImageDevice id_type_device={props?.devices?.id_tipo_dispositivo} />
                            </div>
                            {nameDevice?.length > 15 ?
                                <HtmlTooltip title={<span> {nameDevice} </span>}>
                                    <p className="font-weight-bold ml-2 mr-2 mb-0">
                                        {nameDevice.substr(0, 14) + '...'}
                                    </p>
                                </HtmlTooltip>
                                :
                                <p className="font-weight-bold ml-2 mr-2 mb-0">
                                    {nameDevice}
                                </p>
                            }
                            {errorVisible &&
                                <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                    <IconWarning width={20} height={20} />
                                </HtmlTooltip>
                            }
                        </Grid>
                        <div className="divider mt-1" />
                        <Grid item sm={12} lg={12} xl={12} className="d-flex justify-content-end">
                            <DeviceDate
                                device={props.devices}
                                loading={loading}
                                data={data?.currentData}
                                masterPermission={props?.masterPermission?.[props?.devices?.master_serial ? props?.devices?.master_serial : props?.devices?.numserie]}
                            />
                        </Grid>
                        <Grid container className="mt-2" style={{ textAlign: 'start' }}>
                            <Grid item sm={12} lg={12} xl={12}>
                                <h4>Potencia total : {toKiloW(totalPower)} kW</h4>
                            </Grid>
                            <Grid item sm={12} lg={12} xl={12}>
                                <h4>E. Importada : {toKiloW(todayEnergy)} kWh</h4>
                            </Grid>
                            <Grid item sm={12} lg={12} xl={12}>
                                <h4>E. Exportada : {toKiloW(exportEnergy)} kWh </h4>
                            </Grid>
                            <Table style={{ textAlign: 'center', padding: '0px', marginTop: '10px' }}>
                                <thead >
                                    <tr>
                                        <th style={{ width: '20%' }} className="p-0">{' '}</th>
                                        <th style={{ width: '20%' }} className="p-0">P(kW)</th>
                                        <th style={{ width: '20%' }} className="p-0">I(A)</th>
                                        <th style={{ width: '20%' }} className="p-0">V(V)</th>
                                        <th style={{ width: '20%' }} className="p-0">PF</th>
                                    </tr>
                                </thead>
                            </Table>
                        </Grid>
                        <div className="divider mt-1" />
                        <Grid container>
                            <Table>
                                <tbody style={{ fontSize: '13px' }}>
                                    <tr style={{ backgroundColor: '#EDEDED' }}>
                                        <td style={{ width: '20%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>Total</td>
                                        <td style={{ width: '20%', textAlign: 'center' }}>{toKiloW(data?.currentData?.pact)}</td>
                                        <td style={{ width: '20%', textAlign: 'center' }}>{toKiloW(data?.currentData?.it)}</td>
                                        <td style={{ width: '20%', textAlign: 'center' }}>{toKiloW(data?.currentData?.v1 !== null && data?.currentData?.v1 !== undefined ? data?.currentData?.v1 : data?.currentData?.vac)}</td>
                                        <td style={{ width: '20%', textAlign: 'center' }}>{toKiloW(data?.currentData?.pf1 !== null && data?.currentData?.pf1 !== undefined ? data?.currentData?.pf1 : data?.currentData?.pf)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Grid>
                    </Card>
                </Grid>
            </Grid >
        </Grid >
    )
}