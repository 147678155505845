import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { urls, dispositivos, instalaciones } from 'utils/constant'
import { CcMaster } from 'views/Scada/CcMaster/CcMaster.jsx'
import { Inverter } from 'views/Scada/Inverter/Inverter.jsx'
import { ScadaSelfConsumption } from 'views/Scada/SelfConsumption/ScadaSelfConsumption.jsx'
import { Meter } from 'views/Scada/Meter/Meter.jsx'
import { Mabt } from 'views/Scada/MABT/Mabt.jsx'
import Plug from 'views/Scada/Plug/Plug.jsx'
import Bulb from 'views/Scada/Bulb/Bulb.jsx'
import { headersScada } from 'services/headers'
import Maps from 'components/Mapa/MapShow.jsx'
import { ModalErrorConfig } from 'views/ModalResponse/ModalErrorConfig'
import { Loader } from 'components/Loader/Loader'

const Scada = (props) => {
  const location = useLocation()
  const idMaster = location.pathname.split('/')[4]
  const token = sessionStorage?.token
  const [device, setDevice] = useState(props?.devices)
  const [master, setMaster] = useState()
  const [loading, setLoading] = useState()
  const [openConfigError, setOpenConfigError] = useState(false)

  useEffect(() => {
    if (props.devices) {
      setDevice(props?.devices)
    }
  }, [props?.devices, device])

  let url
  if (props?.loose && props?.loose !== 0) {
    url = `getccm/${idMaster}`
  } else if (props?.loose) {
    url = `getccm/${idMaster}?idCCMaster=${idMaster}`
  } else {
    url = `getccm/${idMaster}?idCCMaster=${idMaster}`
  }

  const fecthDevices = () => {
    fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
    .then((res) => res.json())
    .then((data) => {
      setMaster(data)
      props?.setData(data)
    })
    .catch((err) => console.error('ERROR. API Scada', err))
  }

  useEffect(() => {
    if (props?.devices) {
      setLoading(true)
      fecthDevices()
    }
  }, [props?.devices, location.pathname, props?.refresh])

  useEffect(() => {
    setLoading(false)
    if (props?.loose === 0 && master?.childrenDevices < 1) {
      props?.setActiveTabDevice(4)
    }
  }, [props?.data])
  
  return (
    <>
      <div className="display-none">
        <Maps places={[]} zoom={5} className="display-none" />
      </div>
      {device && master && master?.inst_type === instalaciones.photovoltaic ? (
        <Inverter
          isMobile={props?.isMobile}
          loose={props?.loose}
          setLoose={props?.setLoose}
          setRefresh={props?.setRefresh}
          refresh={props?.refresh}
          startt={props.startt}
          selfConsumption={props.selfConsumption}
          master={master}
          setSelfConsumption={props.setSelfConsumption}
          device={[device]}
          devices={device}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          masterError={props?.masterError}
          masterPermission={props?.masterPermission}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          numserieMaster={props.numserieMaster}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          dispositivos={master}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
          childrenDevices={master.childrenDevices}
          setImportSelf={props?.setImportSelf}
          setExportSelf={props?.setExportSelf}
          importSelf={props?.importSelf}
        />
      ) : device &&
        master &&
        master?.inst_type === instalaciones.selfConsumption &&
        props?.confirmSelfConsumption.length > 0 ? (
        <ScadaSelfConsumption
          loose={props?.loose}
          setLoose={props?.setLoose}
          setRefresh={props?.setRefresh}
          refresh={props?.refresh}
          startt={props.startt}
          selfConsumption={props.selfConsumption}
          master={master}
          setSelfConsumption={props.setSelfConsumption}
          device={device}
          devices={device}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          dispositivos={master}
          setEnd={props.setEnd}
          masterError={props?.masterError}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          isMobile={props?.isMobile}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
          childrenDevices={master.childrenDevices}
          setImportSelf={props?.setImportSelf}
          setExportSelf={props?.setExportSelf}
          importSelf={props?.importSelf}
          ends={props?.ends}
          numserieMaster={props.numserieMaster}
          masterPermission={props?.masterPermission}
          setActiveTabDevice={props?.setActiveTabDevice}
        />
      ) : !loading &&
        master &&
        [
          dispositivos.ccm1,
          dispositivos.ccm2,
          dispositivos.ccm3,
          dispositivos.ccm4,
          dispositivos.ccmaster,
          dispositivos.analizador,
        ].includes(props?.devices?.id_tipo_dispositivo) ? (
        <CcMaster
          loose={props?.loose}
          setLoose={props?.setLoose}
          setRefresh={props?.setRefresh}
          refresh={props?.refresh}
          startt={props.startt}
          selfConsumption={props.selfConsumption}
          setActiveTabDevice={props?.setActiveTabDevice}
          master={master}
          setSelfConsumption={props.setSelfConsumption}
          device={device}
          devices={device}
          masterError={props?.masterError}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          dispositivos={master}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          isMobile={props?.isMobile}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
          childrenDevices={master.childrenDevices}
          setImportSelf={props?.setImportSelf}
          setExportSelf={props?.setExportSelf}
          importSelf={props?.importSelf}
          ends={props?.ends}
          numserieMaster={props.numserieMaster}
          setResumeView={props?.setResumeView}
          resumeView={props?.resumeView}
          setDevice={props?.setDevice}
          confirmSelfConsumption={props?.confirmSelfConsumption}
          setDevices={props?.setDevices}
          handleSelectedDevice={props?.handleSelectedDevice}
          deviceSelected={props?.deviceSelected}
          setDeviceSelected={props?.setDeviceSelected}
          masterPermission={props?.masterPermission}
          seeWeb={props?.seeWeb}
          visible={props?.visible}
          setVisible={props?.setVisible}
          exportSelf={props?.exportSelf}
          projects={props?.projects}
          setProjects={props?.setProjects}
          menuPack={props?.menuPack}
          handleOpenActAlarm={props?.null}
          seleccionarTab={props?.seleccionarTab}
          setActiveTabCuadro={props?.setActiveTabCuadro}
          activeTabCuadro={props?.activeTabCuadro}
        />
      ) : master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.inversor ? (
        <Inverter
          loose={props?.loose}
          setLoose={props?.setLoose}
          setRefresh={props?.setRefresh}
          refresh={props?.refresh}
          startt={props.startt}
          selfConsumption={props.selfConsumption}
          master={master}
          setSelfConsumption={props.setSelfConsumption}
          device={device}
          devices={device}
          masterError={props?.masterError}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          dispositivos={master}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          isMobile={props?.isMobile}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
          childrenDevices={master.childrenDevices}
          setImportSelf={props?.setImportSelf}
          setExportSelf={props?.setExportSelf}
          importSelf={props?.importSelf}
          ends={props?.ends}
          numserieMaster={props.numserieMaster}
          setResumeView={props?.setResumeView}
          resumeView={props?.resumeView}
          setDevice={props?.setDevice}
          confirmSelfConsumption={props?.confirmSelfConsumption}
          setDevices={props?.setDevices}
          handleSelectedDevice={props?.handleSelectedDevice}
          deviceSelected={props?.deviceSelected}
          masterPermission={props?.masterPermission}
          seeWeb={props?.seeWeb}
          visible={props?.visible}
          setVisible={props?.setVisible}
          exportSelf={props?.exportSelf}
          handleOpenActAlarm={props?.null}
        />
      ) : !loading &&
        device &&
        master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.planta ? (
        <ScadaSelfConsumption
          loose={props?.loose}
          setLoose={props?.setLoose}
          setRefresh={props?.setRefresh}
          refresh={props?.refresh}
          startt={props.startt}
          selfConsumption={props.selfConsumption}
          master={master}
          setSelfConsumption={props.setSelfConsumption}
          device={device}
          devices={device}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          dispositivos={master}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          numserieMaster={props.numserieMaster}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          isMobile={props?.isMobile}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
          childrenDevices={master.childrenDevices}
          setImportSelf={props?.setImportSelf}
          setExportSelf={props?.setExportSelf}
          importSelf={props?.importSelf}
          masterError={props?.masterError}
        />
      ) : master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.dataLogger ? (
        <div></div>
      ) : master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.bombilla ? (
        <Bulb
          loose={props?.loose}
          setLoose={props?.setLoose}
          device={[props.devices]}
          devices={props.devices}
          handleSelectedDevice={props.handleSelectedDevice}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          now={props.now}
          dispositivos={master}
          masterError={props?.masterError}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          numserieMaster={props.numserieMaster}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
        />
      ) : master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.enchufe ? (
        <Plug
          loose={props?.loose}
          setLoose={props?.setLoose}
          device={[props.devices]}
          devices={props.devices}
          handleSelectedDevice={props.handleSelectedDevice}
          activeTabDevice={props.activeTabDevice}
          toggleDevice={props.toggleDevice}
          now={props.now}
          dispositivos={master}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          numserieMaster={props.numserieMaster}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          daily={props.daily}
          setDaily={props.setDaily}
        />
      ) : !loading &&
        device &&
        master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.ccmabtm ? (
        <Meter
          loose={props?.loose}
          masterError={props?.masterError}
          setLoose={props?.setLoose}
          devices={device}
          startt={props.startt}
          start={props.start}
          end={props.end}
          groupData={props.groupData}
          setStart={props.setStart}
          now={props.now}
          setEnd={props.setEnd}
          setgroupData={props.setgroupData}
          titleButton={props.titleButton}
          setTitleButton={props.setTitleButton}
          numserieMaster={props.numserieMaster}
          handleClickRight={props.handleClickRight}
          handleClickLeft={props.handleClickLeft}
          dispositivos={master}
          daily={props.daily}
          setDaily={props.setDaily}
          closeDevices={props.closeDevices}
        />
      ) : !loading &&
        device &&
        master &&
        props?.devices?.id_tipo_dispositivo ===
          dispositivos.supervisor ? null : (
        loading && (
          <Grid item xs={12} md={12} lg={12} xl={12} className="text-center">
            <Loader />
          </Grid>
        )
      )}
      {!loading &&
        device &&
        master &&
        props?.devices?.id_tipo_dispositivo === dispositivos.ccmabt &&
        Object.entries(props?.masterPermission)?.length !== 0 && (
          <Mabt
            devices={device}
            token={token}
            masterPermission={props.masterPermission}
            master={master}
            isMobile={props?.isMobile}
            masterError={props?.masterError}
          />
        )}
      {openConfigError && (
        <ModalErrorConfig
          open={openConfigError}
          setOpenConfigError={setOpenConfigError}
          data={undefined}
        />
      )}
    </>
  )
}
export default Scada
