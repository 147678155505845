import InfiniteScroll from "react-infinite-scroll-component"
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { DropdownMaster } from '../../Master/MenuMaster/DropdownMaster'
import { ChangeBatery, BatteryCero } from 'utils/icon-centrocontrol'
import { getPercent, toKiloW, toKilowPercent } from 'utils/functionsforGraphs'
import { dateTableDevices } from 'utils/functionsforGraphs'
import { CsvDownloader } from "views/Scada/SelfConsumption/CsvDownloader"
import { StyledTableCellOrdenado } from 'components/Table/TableStyle'
import { changeBattery, ordenarDatos } from 'utils/Utils'
import { Loader } from 'components/Loader/Loader'
import Weather from 'views/Weather/Weather'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { StyledTableRow } from "components/Table/TableStyle"
import "views/Principal/StylePrincipal.scss"
import { GlobalContext } from "context/GlobalContext"
import { Grid } from "@mui/material"
import { urls } from "utils/constant"
import { headersScada } from "services/headers"
import { convertDatetime } from "utils/Utils"
import { instalaciones } from "utils/constant"
import { useSelector } from "react-redux"

export const TableDevices = ({ datos, masterPermission }) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idProject = location.pathname.split("/")[3]
    const [tableHeight, setTableHeight] = useState(0)
    const tableRef = useRef(null)
    const { actualProject } = useContext(GlobalContext)
    const [loadingInfo, setLoadingInfo] = useState(true)
    const [data, setData] = useState()
    const infoInstal = useSelector((state) => state.infoInstal)

    const fetchInfoCards = async (instal) => {
        let url = `getInfoCardDevice/${instal?.numserie}?idCCMaster=${instal?.numserie}`
        try {
            const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                json.nick = json?.nick ? json?.nick : instal.nick
                json.device = instal?.numserie
                json.numserie = instal?.numserie
                json.permissions = instal?.permissions?.permissions
                json.activo = instal?.activo
                json.master = instal
                json.error = infoInstal?.[instal?.numserie]?.error
                json.datetime = convertDatetime(json?.datetime)
                let alarms = masterPermission?.[instal?.numserie]?.alarms
                json.alarms = alarms?.filter(alarm => alarm?.numserie === instal?.numserie)
                return json
            } else {
                return false
            }
        } catch (err) {
            console.error(err)
        }
    }

    const getDevices = async () => {
        const plants = await Promise.all(datos?.flatMap(instal => instal?.inst_type === instalaciones.selfConsumption && fetchInfoCards(instal)))
        setData(plants.filter(Boolean))
    }

    const [filas, setFilas] = useState(30)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })

    useEffect(() => {
        getDevices()
    }, [infoInstal])

    useEffect(() => {
        if (data) {
            setLoadingInfo(false)
        }
    }, [data])

    useEffect(() => {
        if (tableRef.current) {
            setTableHeight(tableRef.current.offsetHeight)
        }
    }, [filas])

    const variableTableSelfConsumption = [
        { label: "instalaciones", key: "nick" },
        { label: "Fecha", key: "datetime" },
        { label: "Generación", key: "generada" },
        { label: "Consumida Red", key: "consumo" },
        { label: "Vertida Red", key: "vertida" },
        { label: "Batería", key: "soc" }
    ]

    const csvHeaders = variableTableSelfConsumption
    const [csv, setCSV] = useState([])

    useEffect(() => {
        setCSV([])
        data?.forEach(dat => {
            setCSV(prev => [...prev, {
                "Instalaciónes": dat?.nick,
                "Fecha": dat?.datetime,
                "Generación": dat?.eagendia_prod,
                "Consumida Red": dat?.eaconsdia_cons,
                "Vertida Red": dat?.eagendia_red,
                "Batería": dat?.soc,
            }])
        })
    }, [data])

    useEffect(() => {
        setFilas(30)
    }, [data])

    return (
        <Grid container className="d-flex flex-row mt-3">
            {loadingInfo ?
                <Grid item xs={12} className="d-flex justify-content-center">
                    <Loader />
                </Grid>
            :
                <>
                    <Grid item xs={12} className="d-flex justify-content-end ">
                        {csv !== undefined &&
                            <CsvDownloader historicalData={csv} headers={csvHeaders} separator={";"} style={{ width: "150px" }}
                                view="cc" name={`${actualProject?.nick ? actualProject.nick : "generico"}.csv`} />
                        }
                    </Grid>
                    <TableContainer component={Paper} style={{ marginTop: "15px" }}>
                        <InfiniteScroll
                            style={{ minHeight: tableHeight + 30 }}
                            dataLength={filas}
                            next={() => {
                                setFilas(filas + 30)
                            }}
                            hasMore={data?.length > filas}
                            loader={<Loader />}
                        >
                            <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table" className="table-device">
                                <TableHead style={{ borderRadius: '50px', position: "sticky", top: 0, zIndex: "1" }} >
                                    <TableRow className="row-table-device-sticky">
                                        <StyledTableCellHead align="right" style={{ padding: "5px 0px" }}>
                                        </StyledTableCellHead>
                                        <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig} >Instalaciones</StyledTableCellOrdenado>
                                        <StyledTableCellHead align="center" >Fecha</StyledTableCellHead>
                                        <StyledTableCellHead align="center" >Generación</StyledTableCellHead>
                                        <StyledTableCellHead align="center" >Consumida Red</StyledTableCellHead>
                                        <StyledTableCellHead align="center" >Vertida Red</StyledTableCellHead>
                                        <StyledTableCellHead align="center" >Batería</StyledTableCellHead>
                                        <StyledTableCellHead align="center" ></StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.slice(0, filas)?.map((master, i) => {
                                        return (
                                            master?.error !== 1 &&
                                            <StyledTableRow key={i}>
                                                <StyledTableCell align="center">
                                                    <DropdownMaster
                                                        token={token}
                                                        device={master?.numserie}
                                                        master={master?.master}
                                                        permissions={master?.permissions}
                                                        active={master?.activo}
                                                        instalacion={master?.master}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell className="table-master-row" >
                                                    <Link
                                                        to="#"
                                                        onMouseDown={(e) => {
                                                            if (e.button === 0 || e.button === 1) {
                                                                e.preventDefault()
                                                                window.open(`/view/scada/${idProject}/${master?.numserie}`)
                                                            }
                                                        }}
                                                        target="_blank" rel="noopener noreferrer" className="nick-master-selfConsumption-table">
                                                        {master?.nick}
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell className="data-datetime-table">{dateTableDevices(master?.datetime)}</StyledTableCell>
                                                <StyledTableCell >
                                                    <span className="cell-table-device">
                                                        <p className="data-table-device">{toKiloW(master?.eagendia_prod)} kW</p>
                                                        <ProgressBar className="progressBar progressbar-table-device" style={{ border: "2px solid #35A151" }} >
                                                            <ProgressBar className='lineProgress ' style={{ backgroundColor: '#35A151', fontSize: '10px' }} now={(master?.eagendia_prod === 0 || master?.eagendia_prod === null) ? 0 : getPercent(master?.eagendia_prod, master?.eaconsdia_cons)}
                                                            />
                                                            <p className="progressbar-table-percent">{getPercent(master?.eagendia_prod, master?.eaconsdia_cons)} %</p>
                                                        </ProgressBar>
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell >
                                                    <span className="cell-table-device">
                                                        <p className="data-table-device">{toKiloW(master?.eaconsdia_red)} kW</p>
                                                        <ProgressBar className="progressBar progressbar-table-device" style={{ border: "2px solid #3B9FD1" }}>
                                                            <ProgressBar className='lineProgress ' style={{ backgroundColor: "#3B9FD1", fontSize: '10px', }} now={(master?.eaconsdia_red === 0 || master?.eaconsdia_red === null) ? 0 : getPercent(master?.eaconsdia_red, master?.eaconsdia_cons)}
                                                            />
                                                            <p className="progressbar-table-percent" >{getPercent(master?.eaconsdia_red, master?.eaconsdia_cons)}%</p>
                                                        </ProgressBar>
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <span className="cell-table-device">
                                                        <p className="data-table-device progressbar-table-device">{toKiloW(master?.eagendia_red)} kW</p>
                                                        <ProgressBar className="progressBar progressbar-table-device" style={{ border: "2px solid #C2DBB3" }} >
                                                            <ProgressBar className='lineProgress' style={{ backgroundColor: "#C2DBB3", fontSize: '10px', }} now={(master?.eagendia_red === 0 || master?.eagendia_red === null) ? 0 : getPercent(master?.eagendia_red, master?.eagendia_prod)} />
                                                            <p className="progressbar-table-percent">{getPercent(master?.eagendia_red, master?.eagendia_prod)}%</p>
                                                        </ProgressBar>
                                                    </span>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div className="batery-change-table">
                                                        {master?.soc ?
                                                            <ChangeBatery change={changeBattery(master?.soc)} />
                                                            :
                                                            <span className="icon-batery-table">
                                                                <BatteryCero />
                                                            </span>
                                                        }
                                                        <p>{toKilowPercent(master?.soc)} %</p>
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Weather master={master.master} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </TableContainer>
                </>
            }
        </Grid>
    )
}