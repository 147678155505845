import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import AuthCode from 'react-auth-code-input'
import { urls } from 'utils/constant'
import { headersCentroControl } from 'services/headers'
import { Modal } from 'components/Modal/Modal'
import "./StyleVerificationCodeMaster.scss"
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const VerificationCodeMaster = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const idProject = location.pathname.split("/")[3]
    const [code, setCode] = useState()
    const [loading, setLoading] = useState(false)
    const [actionBody, setActionBody] = useState({})

    let url

    if (props?.master?.activo === 1 || props.order === 'delete') {
        url = `device`
    } else {
        url = `update/device/${props.action}`
    }

    useEffect(() => {
        setActionBody({
            "code": code,
            "numserie": props?.numserie,
            "action": props?.order,
            "id_project": idProject
        })
    }, [code])

    const deleteDevice = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + url, {
            method: props.method, redirect: 'follow', headers: headersCentroControl(token),
            body: JSON.stringify(actionBody)
        })
            .then(res => {
                setLoading(false)
                status = res.status
                return res.json()
            }).then(data => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
            })
            .catch(err => {
                console.error("ERROR. API Centro de Control", err);
            })
    }

    return (
        <>
            <Modal buttonAction='Eliminar' open={props.secondModal} setOpen={props?.setSecondModal} onClick={e => e.stopPropagation()} functSave={deleteDevice} loading={loading} title="Eliminar dispositivo">
                <Grid container>
                    <Grid item xs={12} md={12} lg={12} xl={12} id="verificationCode" className="mb-4 d-flex justify-center items-center">
                        <AuthCode characters={6} onChange={(e) => setCode(parseInt(e))} inputClassName='ios-verified' />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12} id="verificationCode">
                        <p className='text-center'>Comprueba tu bandeja de entrada y pega el código aquí</p>
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};