import React, { useState } from 'react'

import { Card, List, ListItem } from '@mui/material';
import { ActivePowerGraph } from './ActivePowerGraph';
import { ReactivePowerGraph } from './ReactivePowerGraph'
import { ApparentPowerGraph } from './ApparentPowerGraph';
import { IntensityGraph } from './IntensityGraph';


export const Graphs = (props) => {

    const [activeTabDevice, setActiveTabDevice] = useState('0')

    const toggleDevice = tab => setActiveTabDevice(tab)

    return (
        <Card className="shadow-m p-4 m-2">
            <List className="nav-tabs nav-tabs-warning d-flex justify-content-center" style={{ overflowX: window.width < 378 ? 'scroll' : null }}>
                <ListItem className='mr-auto'>
                    <h1> Lineas</h1>
                </ListItem>
                <ListItem button  selected={activeTabDevice === '0'} onClick={() => { toggleDevice('0'); }}>
                    <span className="font-weight-bold text-uppercase font-size-sm">Potencia Activa</span>
                </ListItem>
                <ListItem button  selected={activeTabDevice === '1'} onClick={() => { toggleDevice('1') }}>
                    <span className="font-weight-bold text-uppercase font-size-sm">Potencia Reactiva</span>
                </ListItem>
                <ListItem button  selected={activeTabDevice === '2'} onClick={() => { toggleDevice('2') }}>
                    <span className="font-weight-bold text-uppercase font-size-sm">Potencia Aparente</span>
                </ListItem>
                <ListItem button  selected={activeTabDevice === '3'} onClick={() => { toggleDevice('3') }}>
                    <span className="font-weight-bold text-uppercase font-size-sm">Corriente</span>
                </ListItem>
            </List>
            <div className={`tab-item-wrapper ${activeTabDevice === '0' ? 'active' : ''}`} index={0}>
                <div className="my-2">
                    <ActivePowerGraph activeTabDevice={activeTabDevice} device={props?.device} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart}
                        now={props.now} startt={props.startt} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                        handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily}
                    />
                </div>
            </div>
            <div className={`tab-item-wrapper ${activeTabDevice === '1' ? 'active' : ''}`} index={1}>
                <div className="text-center my-2">
                    <ReactivePowerGraph activeTabDevice={activeTabDevice} device={props?.device} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart}
                        now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton} startt={props.startt}
                        handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                </div>
            </div>
            <div className={`tab-item-wrapper ${activeTabDevice === '2' ? 'active' : ''}`} index={2}>
                <div className="text-center my-2">
                    <ApparentPowerGraph activeTabDevice={activeTabDevice} device={props?.device} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart}
                        now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton} startt={props.startt}
                        handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                </div>
            </div>
            <div className={`tab-item-wrapper ${activeTabDevice === '3' ? 'active' : ''}`} index={3}>
                <div className="text-center my-2">
                    <IntensityGraph activeTabDevice={activeTabDevice} device={props?.device} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart}
                        now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton} startt={props.startt}
                        handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                </div>
            </div>
        </Card>
    )
}
