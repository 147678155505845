import { useLocation } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import { FormControl, Grid, MenuItem } from '@mui/material'
import { urls } from 'utils/constant'
import { GlobalContext } from 'context/GlobalContext'
import { SelectStyled } from 'components/Select/SelectStyled'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { CategoryType } from 'utils/MiddleWareProjects'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalMoveDevice = ({ modal, setModal, ...props }) => {
    const location = useLocation()
    const idProject = location.pathname.split('/')[3]
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const { projectsCompleto, actualProject } = useContext(GlobalContext)
    const [selectedProject, setSelectedProject] = useState()
    const [groupSelected, setGroupSelected] = useState()
    const [grupos, setGrupos] = useState()
    const [subgrupos, setSubgrupos] = useState()
    const [moveGroups, setMoveGroups] = useState()
    const [body, setBody] = useState()
    const [loading, setLoading] = useState(false)
    const [subGroupSelected, setSubGroupSelected] = useState()
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const toggle = (e) => setModal(!modal)

    const fechGroupProject = (idproject) => {
        setLoading(true)
        if (selectedProject) {
            fetch(urls.ccontrol + `getInfoProjectIds/${idproject}`, { method: 'GET', headers: headersCentroControl(token) })
                .then(res => res.json())
                .then(data => {
                    setMoveGroups(data)
                })
                .catch(err => console.error("ERROR. API Centro de control", err))
        }
        setLoading(false)
    }

    const handleProject = (e) => {
        setSelectedProject(e.target.value)
        setGroupSelected("")
        setSubGroupSelected("")
        setIsButtonDisabled(false)
        if (selectedProject === idProject) {
            setIsButtonDisabled(true)
        }
    }

    const handleGrupo = (e) => {
        setGroupSelected(e.target.value)
        setSubGroupSelected()
        setIsButtonDisabled(false)
    }

    const hanleSubgroup = (e) => {
        setSubGroupSelected(e.target.value)
        setIsButtonDisabled(false)
    }

    const move = () => {
        setLoading(true)
        let status
        fetch(urls.ccontrol + `moveDevice`, {
            method: 'PATCH', headers: headersCentroControl(token),
            body: JSON.stringify(body)
        })
            .then(res => {
                setLoading(false)
                status = res.status
                return res.json()

            }).then(data => {
                dispatch(createToast({ status: status, message: data.message, reload: true }))
                toggle(data)
            }).catch(err => console.error("ERROR. API Centro de control", err))
    }


    useEffect(() => {
        if (projectsCompleto.length > 0 && projectsCompleto.message !== 'User without info masters.' && idProject !== "compartidoConmigo") {
            let project = projectsCompleto?.find(project => project?.id_project === Number(idProject))
            setSelectedProject(project?.id_project)
            if (selectedProject) {
                setGroupSelected()
                setSubGroupSelected()
            }
        }
        setIsButtonDisabled(true)
    }, [projectsCompleto, modal, setModal])

    useEffect(() => {
        subGroupSelected && subGroupSelected > 0 ?
            setBody({
                'id_project': parseInt(selectedProject),
                'id_group': parseInt(groupSelected),
                'id_subgroup': Number(subGroupSelected),
                "numserie": [props.master.numserie]
            })
            :
            setBody({
                'id_project': parseInt(selectedProject),
                'id_group': parseInt(groupSelected),
                "numserie": [props.master.numserie]
            })
    }, [subGroupSelected, groupSelected])

    useEffect(() => {
        if (selectedProject) {
            fechGroupProject(selectedProject)
        }
    }, [selectedProject])

    useEffect(() => {
        if (selectedProject && moveGroups) {
            setGrupos(moveGroups?.groups)
        }
        if (groupSelected) {
            grupos?.filter(grup => grup?.id_group === Number(groupSelected))?.forEach(sub => {
                setSubgrupos(sub?.subgroups)
            })
        }
    }, [moveGroups, groupSelected])

    useEffect(() => {
        if (groupSelected === props?.group?.id_group && subGroupSelected === props?.subgroup) {
            setIsButtonDisabled(true)
        } else if (groupSelected === props?.group?.id_group && subGroupSelected !== props?.subgroup) {
            setIsButtonDisabled(false)
        } else if (groupSelected === props?.group?.id_group) {
            setIsButtonDisabled(true)
        } else (
            setIsButtonDisabled(false)
        )
    }, [selectedProject, subGroupSelected, groupSelected])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={modal} setOpen={setModal} title="Mover dispositivo" disabled={isButtonDisabled} loading={loading} functSave={move} buttonAction="Mover">
                <Grid container className="pr-5 pl-5">
                    <>
                        <Grid item xs={12} className='pb-2'>
                            <FormControl className='width-100 pb-2' size="small">
                                <div className='pb-1'>
                                    <small className="color-black">
                                        Selecciona un proyecto:
                                    </small>
                                </div>
                                <SelectStyled value={selectedProject} onChange={handleProject}>
                                    {grupos && projectsCompleto?.map(prj => {
                                        return (
                                            <MenuItem value={prj?.id_project} key={prj?.id_project}>
                                                <span
                                                    className="d-flex align-items-center"
                                                >{prj?.nick}
                                                    <p className={`${prj?.id_project === actualProject?.id_project && props?.master?.error === 1 ? "information-text-move-device  th-data ml-2" : " th-data ml-2"} `} >{CategoryType(prj?.category)}</p></span></MenuItem>
                                        )
                                    })}
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                        {moveGroups && selectedProject && grupos?.length > 0 &&
                            <Grid item xs={12} className='pb-2'>
                                <FormControl className='width-100 pb-2' size="small">
                                    <div className='pb-1'>
                                        <small className="color-black">
                                            Selecciona un grupo:
                                        </small>
                                    </div>
                                    <SelectStyled value={groupSelected} onChange={handleGrupo}>
                                        {grupos?.map((grp) => {
                                            return (
                                                <MenuItem key={grp.id_group} value={grp.id_group}>|-{grp.nick}</MenuItem>
                                            )
                                        })}
                                    </SelectStyled>
                                </FormControl>
                            </Grid>}
                        {groupSelected && subgrupos?.length > 0 &&
                            <Grid item xs={12}>
                                <FormControl className='width-100 pb-2' size="small">
                                    <div className='pb-1'>
                                        <small className="color-black">
                                            Selecciona un subgrupo:
                                        </small>
                                    </div>
                                    <SelectStyled value={subGroupSelected} onChange={hanleSubgroup}>
                                        {subgrupos?.map((sub) => {
                                            return (
                                                <MenuItem key={sub.id_subgroup} value={sub.id_subgroup}>|-{sub.nick}</MenuItem>
                                            )
                                        })}
                                    </SelectStyled>
                                </FormControl>
                            </Grid>
                        }</>
                </Grid>
            </Modal>
        </>
    )
}