import React, { useState } from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import "./styleEms.scss"
import { IconMaintenance } from 'utils/icon-centrocontrol'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { ModalConfigEMSSystem } from './Modal/ModalConfigEMSSystem'
import { Loader } from 'components/Loader/Loader'

export const ConfiguracionEMS = ({ data, setKeySwitch }) => {
    const [openMaintance, setOpenMaintance] = useState(false)
    const [config, setConfig] = useState()

    return (
        <>
            {data?.length <= 0 ? <Loader /> :
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                            <TableRow>
                                <StyledTableCellHead align="center">Máster</StyledTableCellHead>
                                <StyledTableCellHead align="center">Activo</StyledTableCellHead>
                                <StyledTableCellHead align="center">Tiempo de Petición ModBus(ms)</StyledTableCellHead>
                                <StyledTableCellHead align="center">Potencia de Red(%)</StyledTableCellHead>
                                <StyledTableCellHead align="center">Registro Modbus Reducido </StyledTableCellHead>
                                <StyledTableCellHead align="center">Circuito disyuntor</StyledTableCellHead>
                                <StyledTableCellHead align="center">Configuración Circuito Disyuntor</StyledTableCellHead>
                                <StyledTableCellHead align="center">Tiempo Relé(ms)</StyledTableCellHead>
                                <StyledTableCellHead align="center"></StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data && data.map((elm, i) => {
                                return (
                                    <StyledTableRow key={i}>
                                        <StyledTableCell align="center" >{elm?.master?.nick}</StyledTableCell>
                                        <StyledTableCell align="center"><SwitchStyled id="habilitado" className="switch-extra-small mr-2" disabled={true} checked={elm?.config?.enable} /></StyledTableCell>
                                        <StyledTableCell align="center">{elm?.config?.interv_req}</StyledTableCell>
                                        <StyledTableCell align="center">{elm?.config?.perc_marg}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <SwitchStyled id="habilitado" className="switch-extra-small mr-2" checked={elm?.config?.regi_redux} disabled={true} /></StyledTableCell>
                                        <StyledTableCell align="center">
                                            <SwitchStyled id="habilitado" className="switch-extra-small mr-2" checked={elm?.config?.rele_used} disabled={true} /></StyledTableCell>
                                        <StyledTableCell align="center">{elm?.config?.rele_conf}</StyledTableCell>
                                        <StyledTableCell align="center">{elm?.config?.rele_time}</StyledTableCell>
                                        <StyledTableCell>
                                            <ButtonStyledIcon className='button-with-text' onClick={() => { setOpenMaintance(!openMaintance); setConfig(elm) }}  >
                                                <IconMaintenance className='icon-hover-with-text' />
                                            </ButtonStyledIcon>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>}
            {openMaintance && <ModalConfigEMSSystem master={config} open={openMaintance} setOpen={setOpenMaintance} setKeySwitch={setKeySwitch} />}
        </>
    )
}

