import { fromLatLon } from 'utm'
import moment from 'moment'
import { ChangeBatery, IconConsumptionProject, IconMabtProject, IconPanel, IconPhotovoltaicProject, IconRed, IconSelfConsumptionProject, Iconhome } from 'utils/icon-centrocontrol'
import { EnerSim, IconDeviceAnalyzer, IconDeviceCCM1, IconDeviceCCM1Inverted, IconDeviceCCM2, IconDeviceCCM2Inverted, IconDeviceCCM3, IconDeviceCCM3Inverted, IconDeviceCCM4, IconDeviceCCM4Inverted, IconDeviceContador, IconDeviceContador2, IconDeviceMaster, IconOffline, IconOnline, Lightbulb } from './icon-centrocontrol'
import "../views/LayOut/LayOut.scss"
import { alarmas } from './constant'

export function isMobile(tamano) {
    return tamano ? (window.innerWidth <= tamano || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) && window.innerWidth <= tamano : (window.innerWidth <= 780 || navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i)) && window.innerWidth <= 780;
}

export function contieneSoloNumeros(texto) {
    return /^\d+$/.test(texto)
}

export function esHoy(fecha) {
    const format = 'DD-MM-YYYY HH:mm:ss'
    return (moment(fecha, format).isSame(moment(), 'day') && moment(fecha, format).isSame(moment(), 'month') && moment(fecha, format).isSame(moment(), 'year'))
}

export function esHoyWithoutFormat(datetime) {
    const now = new Date()
    const fecha = moment(datetime)
    const isToday = (fecha.date() === now.getDate() && fecha.month() === now.getMonth() && fecha.year() === now.getFullYear())
    return isToday
}

export function compararFecha(fecha1, fecha2) {
    if (fecha1._d.getDate() !== fecha2._d.getDate() || fecha1._d.getMonth() !== fecha2._d.getMonth() || fecha1._d.getFullYear() !== fecha2._d.getFullYear()) {
        return false
    }
    return true
}

export const convertDatetime = (datetime) => {
    const now = new Date()
    const fecha = moment(datetime)
    const isToday = (fecha.date() === now.getDate() && fecha.month() === now.getMonth() && fecha.year() === now.getFullYear())
    const fechaParseada = moment(datetime).format('DD-MM-YYYY')
    const horaParseada = moment(datetime).format('HH:mm:ss')
    return datetime ? (isToday ? horaParseada : fechaParseada + ' ' + horaParseada) : '-'
}

export function nombreDispositivo(tipo_dispositivo) {
    switch (tipo_dispositivo) {
        case 0:
            return "CcMaster"
        case 1:
            return "CCM1"
        case 2:
            return "CCM2"
        case 3:
            return "CCM3"
        case 4:
            return "CCM4"
        case 5:
            return "Inversor"
        case 6:
            return "Analizador"
        case 7:
            return "Planta"
        case 8:
            return "Data logger"
        case 9:
            return "Batería"
        case 10:
            return "Ups"
        case 11:
            return "Bombilla"
        case 12:
            return "Enchufe"
        case 13:
            return "Sensor"
        case 14:
            return "Ventilación"
        case 15:
            return "Supervisor"
        case 16:
            return "Máquina Térmica"
        case 17:
            return "Termostato"
        case 18:
            return "Cargador de vehículo"
        case 19:
            return "Fototermia"
        case 20:
            return "Placa de control"
        case 21:
            return "Placa de medida"
        case 22:
            return "Persiana"
        case 23:
            return "VTN"
        default:
            return "No definido"
    }
}

export function nombreInstalacion(tipo_instalacion) {
    switch (tipo_instalacion) {
        case 0:
            return "Consumo"
        case "consumption":
            return "Consumo"
        case 1:
            return "Autoconsumo"
        case "self_consumption":
            return "Autoconsumo"
        case 2:
            return "Generación"
        case "photovoltaic":
            return "Generación"
        case 3:
            return "Distribución"
        case 4:
            return "Iluminación Pública"
        case "mabt":
            return "Distribución"
        case null:
            return "Consumo"
        default:
            return "No definido"
    }
}

export function iconoInstalacion(tipo_instalacion) {
    switch (tipo_instalacion) {
        case 0:
            return <IconConsumptionProject width="32" height="32" viewBox="0 0 21 23" />
        case 1:
            return <IconSelfConsumptionProject width="33" height="35" />
        case 2:
            return <IconPhotovoltaicProject width="36" height="36" viewBox="0 0 26 25" />
        case 3:
            return <IconMabtProject width="37" height="32" viewBox="0 0 27 22" />
        case 4:
            return <Lightbulb width="37" height="32" viewBox="0 0 27 22" />
        case null:
            return <IconConsumptionProject width="32" height="32" viewBox="0 0 21 23" />
        default:
            return <></>
    }
}

export function obtenerNumeroTipoDispositivo(tipo_de_dispositivo) {
    switch (tipo_de_dispositivo) {
        case "CCMASTER":
            return 0
        case "CCM_1":
            return 1
        case "CCM_2":
            return 2
        case "CCM_3":
            return 3
        case "CCM_4":
            return 4
        case "INVERSO":
            return 5
        case "ANALIZADOR":
            return 6
        case "BATERIA":
            return 9
        case "ENCHUFE":
            return 12
        case "BOMBILLA":
            return 12
        case "TIPO DESCONOCIDO":
            return 15
        case "DATALOGGER":
            return 16
        case "MABT_CONTROL":
            return 20
        case "MABT_MEDIDA":
            return 21
        case "MABT_VTN":
            return 23
        default:
            return 0
    }
}

export function iconoTipoScada(type, soc) {
    switch (type) {
        case "Generación":
            return <IconPanel />
        case "Red":
            return <IconRed />
        case "Consumo":
            return <Iconhome />
        case "Batería":
            return <ChangeBatery change={changeBattery(soc > 0 ? soc : 0)} width="30" height="30" />
        default:
            return <></>
    }
}

export function iconoCategoria(category, admin) {
    switch (category) {
        case "consumption":
            return <IconConsumptionProject width={admin ? "26" : "34"} height={admin ? "28" : "35"} viewBox="0 0 21 23" />
        case "self_consumption":
            return <IconSelfConsumptionProject width={admin ? "26" : "34"} height={admin ? "28" : "35"} />
        case "photovoltaic":
            return <IconPhotovoltaicProject width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 26 25" />
        case "mabt":
            return <IconMabtProject width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 27 22" />
        case "lighting":
            return <Lightbulb width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 27 22" />
        case "enerSIM":
            return <EnerSim width={admin ? "26" : "34"} height={admin ? "28" : "23"} />
        case 0:
            return <IconConsumptionProject width={admin ? "26" : "34"} height={admin ? "28" : "35"} viewBox="0 0 21 23" />
        case 1:
            return <IconSelfConsumptionProject width={admin ? "26" : "34"} height={admin ? "28" : "35"} />
        case 2:
            return <IconPhotovoltaicProject width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 26 25" />
        case 3:
            return <IconMabtProject width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 27 22" />
        case 4:
            return <Lightbulb width={admin ? "26" : "36"} height={admin ? "28" : "35"} viewBox="0 0 27 22" />
        default:
            return <></>
    }
}

//Diferentes categorias del proyecto
export const categories = [
    {
        nick: "Consumo",
        value: 0,
        emoticon: <IconConsumptionProject width="26" height="28" viewBox="0 0 21 23" />
    },
    {
        nick: "Autoconsumo",
        value: 1,
        emoticon: <IconSelfConsumptionProject width="26" height="28" />
    },
    {
        nick: "Generación",
        value: 2,
        emoticon: <IconPhotovoltaicProject width="26" height="28" viewBox="0 0 24 24" />
    },
    {
        nick: "Distribución",
        value: 3,
        emoticon: <IconMabtProject width="26" height="28" viewBox="0 0 27 22" />
    },
    {
        nick: "Iluminación Pública",
        value: 4,
        emoticon: <Lightbulb />
    },
    {
        nick: "enerSIM",
        value: 8,
        emoticon: <EnerSim />
    },

]


export const positions = [
    {
        position: "Cabecera",
        value: 1
    },
    {
        position: "Pie de página",
        value: 2
    }
]

// Formatea el nombre del mes
export const obtenerNombreMes = (numeroMes) => {
    const nombresMeses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ]
    if (numeroMes >= 1 && numeroMes <= 12) {
        return nombresMeses[numeroMes - 1]
    } else {
        return "Mes inválido"
    }
}

export function nombreAlarma(tipo_alarma) {
    switch (tipo_alarma) {
        case alarmas.conexionDataLogger:
            return "Conexión"
        case alarmas.comunicacion:
            return "Comunicación"
        case alarmas.tramo:
            return "Tramo"
        case alarmas.proteccion:
            return "Protección"
        case alarmas.manual:
            return "Manual"
        default:
            return "No definido"
    }
}

export const truncString = (string, length) => {
    if (string && string.length > length) {
        return string.substring(0, length - 3) + '...'
    }
    return string
}

export const ordenarDatos = (datos, configuracion, setConfiguracion, key, functionValue) => {
    let direction = 'asc'
    if (configuracion.key === key && configuracion.direction === 'desc') {
        direction = 'asc'
    } else if (configuracion.key === key && configuracion.direction === 'asc') {
        direction = 'desc'
    }
    setConfiguracion({ key, direction })
    if (direction === 'asc') {
        datos.sort((a, b) => {
            let aSort = functionValue ? functionValue(a[key]) : typeof a[key] === 'string' ? String((a[key])?.toLowerCase()) : a[key]
            let bSort = functionValue ? functionValue(b[key]) : typeof b[key] === 'string' ? String((b[key])?.toLowerCase()) : b[key]
            if (aSort > bSort) {
                return 1
            }
            if (aSort < bSort) {
                return -1
            }
            return 0
        })
    } else if (direction === 'desc') {
        datos.sort((a, b) => {
            let aSort = functionValue ? functionValue(a[key]) : typeof a[key] === 'string' ? String((a[key])?.toLowerCase()) : a[key]
            let bSort = functionValue ? functionValue(b[key]) : typeof b[key] === 'string' ? String((b[key])?.toLowerCase()) : b[key]
            if (aSort > bSort) {
                return -1
            }
            if (aSort < bSort) {
                return 1
            }
            return 0
        })
    } else {
        return 1
    }
}

export const orderAsc = (datos, key) => {
    datos.sort((a, b) => {
        let aSort = String((a[key])?.toLowerCase())
        let bSort = String((b[key])?.toLowerCase())
        if (aSort > bSort) {
            return 1
        }
        if (aSort < bSort) {
            return -1
        }
        return 0
    })
}

export const orderDesc = (datos, key) => {
    datos.sort((a, b) => {
        let aSort = String((a[key])?.toLowerCase())
        let bSort = String((b[key])?.toLowerCase())
        if (aSort > bSort) {
            return -1
        }
        if (aSort < bSort) {
            return 1
        }
        return 0
    })
}

export const isUTM = (coord) => {
    const utmRegex = /^(\d+(\.\d+)?)\s(\d+(\.\d+)?)\s(\d{1,2})([A-Za-z])$/
    return utmRegex.test(coord)
}

export const isLatLngString = (str) => {
    const regex = /^-?\d+(\.\d+)?\s+-?\d+(\.\d+)?$/
    if (!regex.test(str?.trim())) {
        return false;
    }
    const [latitude, longitude] = str.trim().split(' ');
    const lat = parseFloat(latitude);
    const lng = parseFloat(longitude);
    // Comprueba el rango válido para latitud (-90 a 90) y longitud (-180 a 180)
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
        return false;
    }
    return true;
}

export const getUTMCoordinates = (latitude, longitude) => {
    const { easting, northing, zoneNum, zoneLetter } = fromLatLon(Number(latitude), Number(longitude))
    return `${easting} ${northing} ${zoneNum}${zoneLetter}`
}

export const mostrarFecha = (fecha) => {
    const now = moment()
    const then = moment(fecha);
    // Comprobar si la fecha es de hoytoKilowDate
    if (now.isSame(then, 'day')) {
        // Comprobar si la fecha es hace menos de una hora
        if (now.diff(then, 'minutes') < 60) {
            return now.diff(then, 'minutes') + ' minutos';
        } else {
            return now.diff(then, 'hours') + ' horas';
        }
    } else {
        return then.format('DD-MM-YYYY HH:mm');
    }
}

export const showDate = (date) => {
    const format = 'DD-MM-YYYY HH:mm:ss'
    const now = moment()
    const then = moment(date, format)
    if (now.isSame(then, 'day')) {
        return date ? then.format('HH:mm') : '-'
    } else {
        return date ? then.format('DD-MM-YYYY HH:mm') : '-'
    }
}

export const showTime = (date) => {
    const format = 'DD-MM-YYYY HH:mm:ss'
    const then = moment(date, format)
    return then?._isValid ? then.format('HH:mm') : ''
}

export const showFullDate = (date) => {
    const format = 'DD-MM-YYYY HH:mm:ss'
    const then = moment(date, format)
    return then.format('DD-MM-YYYY HH:mm')
}

export const isToday = (date, withoutFormat) => {
    const format = 'DD-MM-YYYY HH:mm:ss'
    const now = moment()
    const then = withoutFormat ? moment(date) : moment(date, format)
    if (now.isSame(then, 'day')) {
        return true
    } else {
        return false
    }
}

export const changeBattery = (soc) => {
    let incremento = Math.floor(soc / 10)
    let change = 18 - (incremento * 1.36)
    return change
}

//filtra el proyecto buscando el número de serie proporcionado para extraer el tipo 
//de permiso de dicho dispositivo.
/**
 * @param {*} project proyecto actual.
 * @param {*} num número de serie del dispositivo del que se quiere averiguar el tipo de permiso.
 * @returns tipo de permiso del dispositivo consultado.
 */
export const devicePermission = (project, num) => {
    let res = null
    if (project?.devices?.length !== 0) {
        res = project?.devices?.find(device => device?.numserie === num)
    }
    if (res === null && project.groups.length !== 0) {
        project.groups.forEach((group) => {
            if (res === null && group.devices.length > 0) {
                res = group.devices.find(device => device.numserie === num)
            }
            if ((res === null || res === undefined) && group.subgroups.length > 0) {
                group.subgroups.forEach(subGroup => {
                    if ((res === null || res === undefined) && subGroup.devices.length > 0) {
                        res = subGroup.devices.find(device => device.numserie === num)
                    }
                })
            }
        })
    }
    return res
}

/**
 * 
 * @param {*} project proyeto actual.
 * @returns array de dispositivos con error de configuración que contiene el proyecto.
 */
export const ErrorDevices = (project, infoInstal) => {
    if (project?.groups !== undefined) {
        let res = []
        if (project?.groups?.length !== 0) {
            for (let i = 0; i < project?.groups?.length; i++) {
                if (project?.groups?.[i]?.devices?.length > 0) {
                    for (let k = 0; k < project?.groups?.[i]?.devices?.length; k++) {
                        let obj = project?.groups?.[i]?.devices?.[k]
                        res.push(obj)
                    }
                } else if (project?.groups?.[i]?.subgroups?.length !== 0) {
                    for (let l = 0; l < project?.groups?.[i]?.subgroups?.length; l++) {
                        if (project.groups?.[i]?.subgroups?.[l]?.devices?.error !== 0) {
                            for (let k = 0; k < project?.groups?.[i]?.subgroups?.[l]?.devices?.length; k++) {
                                let obj = project?.groups?.[i]?.subgroups?.[l]?.devices?.[k]
                                res.push(obj)
                            }
                        }
                    }
                }
            }
        }
        return res
    }
}

export const typePermissions = (permission) => {
    switch (permission) {
        case "admin":
            return "Administrador"
        case "user":
            return "Usuario"
        case "instal":
            return "Instalador"
        case null:
            return "  "
        default:
            return "No definido"
    }
}

export const stateUpdateInstal = (state) => {
    switch (state) {
        case 1:
            return "Actualización finalizada"
        case -1:
            return "Error en el proceso de actualización"
        case -2:
            return "Error en los parámetros enviados al equipo"
        case -3:
            return "Error en los parámetros enviados al HTTPS"
        case -4:
            return "Error en comunicacion inicial con dipositivo"
        default:
            return "No definido"
    }
}

export const stateGlobal = (state1, state2) => {
    if ((state1 === 100 && state2 === 100) || (state1 === 102 && state2 === 100) || (state1 === 100 && state2 === 102) || (state1 === 102 && state2 === 102)) {
        return 'on'
    } else if ((state1 === 103 && state2 === 103) || (state1 === 103 && state2 === 101) || (state1 === 101 && state2 === 103) || (state1 === 101 && state2 === 101)) {
        return 'off'
    } else if ((state1 === 103 && state2 === 100) || (state1 === 101 && state2 === 100) || (state1 === 101 && state2 === 102) || (state1 === 103 && state2 === 102)) {
        return 'relay2'
    } else if (state1 === 300 && state2 === 300) {
        return 'notSelected'
    } else if ((state1 === 100 && state2 === 103) || (state1 === 100 && state2 === 101) || (state1 === 102 && state2 === 101) || (state1 === 102 && state2 === 103)){
        return 'relay1'
    } else {
        return 'notSelected'
    }
}

export const booleanWhole = (whole) => {
    if (whole === "true") {
        return true
    } else {
        return false
    }
}

export const orderForNick = (a, b) => {
    if (a.nick < b.nick) {
        return -1
    }
    if (a.nick > b.nick) {
        return 1
    }
    return 0
}

export const orderForInstallation = (a, b) => {
    if (a.instalacion < b.instalacion) {
        return -1
    }
    if (a.instalacion > b.instalacion) {
        return 1
    }
    return 0
}

export const CustomValidation = (email, password) => {
    const result = []
    // Verificar la longitud mínima de 8 caracteres alfanuméricos
    if (password?.length < 8) {
        result.push(1)
    }
    // Verificar si contiene al menos una mayúscula, una minúscula, un número y un carácter especial
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /[0-9]/.test(password)
    if (!hasUpperCase || !hasLowerCase || !hasNumber) {
        result.push(2)
    }
    //Verificar si contiene cuatro letras seguidas del correo electrónico
    const emailUser = email ? email?.toLowerCase() : null
    // Verificar si la contraseña contiene partes (al menos 4 caracteres seguidos) del email
    for (let i = 0; i <= emailUser?.length - 4; i++) {
        const subset = emailUser?.substring(i, i + 4)
        if (password?.toLowerCase()?.includes(subset)) {
            result.push(3)
        }
    }
    return result
}

export const formatDate = (dateTime) => {
    if (dateTime) {
        if (dateTime.includes("T")) {
            const dateTotal = dateTime?.split("T");
            const date = dateTotal?.[0]?.split("-")
            const year = date?.[0]
            const month = date?.[1]
            const day = date?.[2]
            const newDate = `${day}-${month}-${year}`
            const hours = dateTotal?.[1]?.split(":")
            const hour = hours?.[0]
            const min = hours?.[1]
            const seg = hours?.[2]?.split(".")?.[0]
            const newHours = `${hour}:${min}:${seg}`
            return newDate + " " + newHours
        } else {
            return dateTime
        }
    } else {
        return (" - ")
    }
}

export const formatDateNoSecond = (dateTime) => {
    if (dateTime) {
        if (dateTime.includes("T")) {
            const dateTotal = dateTime?.split("T");
            const date = dateTotal?.[0]?.split("-")
            const year = date?.[0]
            const month = date?.[1]
            const day = date?.[2]
            const newDate = `${day}-${month}-${year}`
            const hours = dateTotal?.[1]?.split(":")
            const hour = hours?.[0]
            const min = hours?.[1]
            const newHours = `${hour}:${min}`
            return newDate + " " + newHours
        } else {
            return dateTime
        }
    }
}

export const FormatFecha = (date) => {
    const fecha = new Date(date)
    const dia = fecha.getDate()
    const mes = fecha.getMonth() + 1
    const ano = fecha.getFullYear()
    return `${dia < 10 ? '0' : ''}${dia}-${mes < 10 ? '0' : ''}${mes}-${ano}`
}

export const NameMode = (mode) => {
    if (mode === 100 || mode === 101) {
        return 1
    } else {
        return 2
    }
}

export const WeekWednesday = (week) => {
    let primerIndiceM = week.indexOf("M")
    if (primerIndiceM !== -1) {
        let segundoIndiceM = week.indexOf("M", primerIndiceM + 1)
        if (segundoIndiceM !== -1) {
            week[segundoIndiceM] = "X"
        }
    }
    return week
}

export const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    let month = today.getMonth() + 1
    let day = today.getDate()
    if (month < 10) {
        month = `0${month}`
    }
    if (day < 10) {
        day = `0${day}`
    }
    return `${year}-${month}-${day}`
}

export const formatMinCalendar = (time) => {
    const [hours, min] = time.split(":")
    const minFormat = min.padStart(2, "0")
    return `${hours}:${minFormat}`
}

export const changeWednesday = (array) => {
    const index = array?.indexOf("miercoles")
    if (index !== -1) {
        array[index] = "x"
    }
    return array
}

export const transformData = (data) => {
    const relayTrans = data.map(entry => ({
        ...entry,
        internal_mode: entry.internal_mode === 0 || entry.internal_mode === undefined ? 103 : entry.internal_mode
    }))
    return relayTrans
}

export function getStatus(num) {
    let colorClass
    let status
    let icon
    switch (num) {
        case 0:
            colorClass = "text-[#E91E1E]"
            status = "No activada"
            icon = <IconOffline />
            break
        case 1:
            colorClass = "text-[#73BF45]"
            status = "Activada"
            icon = <IconOnline color="#73BF45" width="17" />
            break
        case 2:
            colorClass = "text-[#E91E1E]";
            status = "Activación pendiente";
            icon = <IconOffline />;
            break;
        case 3:
            colorClass = "text-[#E91E1E]"
            status = "Cancelada"
            icon = <IconOffline />
            break
        case 4:
            colorClass = "text-[#E91E1E]"
            status = "Cancelación pendiente"
            icon = <IconOffline />
            break
        case 5:
            colorClass = "text-[#E91E1E]"
            status = "Petición denegada"
            icon = <IconOffline />
            break
        case 6:
            colorClass = "text-[#73BF45]"
            status = "Preactivada"
            icon = <IconOnline color="#73BF45" width="17" />
            break
        case 99:
            colorClass = "text-[#E91E1E]"
            status = "Error"
            icon = <IconOffline />
            break
        default:
            colorClass = "text-[#E91E1E]"
            status = "No activada"
            icon = <IconOffline />
            break
    }
    return { colorClass, status, icon }
}

export const getDayOfWeek = (index) => {
    const daysOfWeek = ['LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM']
    if (index >= 0 && index < daysOfWeek.length) {
        return daysOfWeek[index]
    } else {
        return null
    }
}

export const getMonth = (index) => {
    const months = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'];
    if (index >= 0 && index < months.length) {
        return months[index];
    } else {
        return null
    }
}

export const bytesToMegabytes = (bytes) => {
    const result = bytes / 1024
    return result.toFixed(2)
}

export function resultCalendar(array1, array2) {
    const length1 = array1?.length
    const length2 = array2?.length
    return (length1 * 12) - length2
}

export const OpenToScada = (type, id, numserie) => {
    switch (type) {
        case 0:
            return `/scada/consumption/${id}/${numserie}`
        case 2:
            return `/scada/photovoltaic/${id}/${numserie}`
        case 3:
            return `/scada/distribution/${id}/${numserie}`
        case 4:
            return `/scada/lighting/${id}/${numserie}`
        default:
            return `/view/scada/${id}/${numserie}`
    }
}

export const getNameVariable = (deviceType) => {
    switch (deviceType) {
        case 4:
            return [
                { name: 'Corriente', value: 'i' },
                { name: 'Tensión', value: 'v' },
                { name: 'Factor de potencia', value: 'pf' },
                { name: 'Energia activa', value: 'eact' },
                { name: 'Energia reactiva', value: 'ereact' },
                { name: 'Potencia activa', value: 'pac' },
                { name: 'Potencia reactiva', value: 'pre' },
                { name: 'Potencia aparente', value: 'pap' },
                { name: 'Distorsión armónica voltaje', value: 'thdv' },
                { name: 'Distorsión armónica intensidad', value: 'thdi' },
                { name: 'Corriente fundamental', value: 'if' },
                { name: 'Tensión fundamental', value: 'vf' },
                { name: 'Temperatura', value: 'temp' },
                { name: 'Frecuencia', value: 'freq' },
                { name: 'Energía aparente', value: 'eapa' }
            ]
        case 3:
            return [
                { name: 'Corriente', value: 'i' }
            ]
        case 2:
            return [
                { name: 'Corriente', value: 'i' },
                { name: 'Tensión', value: 'v' },
                { name: 'Factor de potencia', value: 'pf' },
                { name: 'Energia activa', value: 'eact' },
                { name: 'Energia reactiva', value: 'ereact' },
                { name: 'Potencia activa', value: 'pac' },
                { name: 'Potencia reactiva', value: 'pre' },
                { name: 'Potencia aparente', value: 'pap' },
                { name: 'Distorsión armónica voltaje', value: 'thdv' },
                { name: 'Distorsión armónica intensidad', value: 'thdi' },
                { name: 'Temperatura', value: 'temp' },
                { name: 'Frecuencia', value: 'freq' },
                { name: 'Energía aparente', value: 'eapa' }
            ]
        case 1:
            return [{ name: 'Corriente', value: 'i' }]
        default:
            return []
    }
}

export const getLineVariable = (varName, deviceType) => {
    switch (varName) {
        case 'eact':
            return deviceType === 4 ?
                [
                    { name: 'Total Q1', value: '_q14' }, { name: 'Linea 1 Q1', value: '1_q14' }, { name: 'Linea 2 Q1', value: '2_q14' }, { name: 'Linea 3 Q1', value: '3_q14' },
                    { name: 'Total Q2', value: '_q23' }, { name: 'Linea 1 Q2', value: '1_q23' }, { name: 'Linea 2 Q2', value: '2_q23' }, { name: 'Linea 3 Q2', value: '3_q23' }
                ]
                :
                [{ name: 'Consumida', value: '_q14' }, { name: 'Generada', value: '_q23' }]
        case 'ereact':
            return deviceType === 4 ?
                [
                    { name: 'Total Q1', value: '_q1' }, { name: 'Total Q2', value: '_q2' }, { name: 'Total Q3', value: '_q3' }, { name: 'Total Q4', value: '_q4' },
                    { name: 'Linea 1 Q1', value: '1_q1' }, { name: 'Linea 1 Q2', value: '1_q2' }, { name: 'Linea 1 Q3', value: '1_q3' }, { name: 'Linea 1 Q4', value: '1_q4' },
                    { name: 'Linea 2 Q1', value: '2_q1' }, { name: 'Linea 2 Q2', value: '2_q2' }, { name: 'Linea 2 Q3', value: '2_q3' }, { name: 'Linea 2 Q4', value: '2_q4' },
                    { name: 'Linea 3 Q1', value: '3_q1' }, { name: 'Linea 3 Q2', value: '3_q2' }, { name: 'Linea 3 Q3', value: '3_q3' }, { name: 'Linea 3 Q4', value: '3_q4' },
                ]
                :
                [{ name: 'Total Q1', value: '_q1' }, { name: 'Total Q2', value: '_q2' }, { name: 'Total Q3', value: '_q3' }, { name: 'Total Q4', value: '_q4' }]
        case 'eapa':
            return deviceType === 4 ? [{ name: 'Total', value: '' }, { name: 'Linea 1', value: '1' }, { name: 'Linea 2', value: '2' }, { name: 'Linea 3', value: '3' }] : [{ name: 'Total', value: '-1' }]
        default:
            return deviceType === 4 ? [{ name: 'Linea 1', value: '1' }, { name: 'Linea 2', value: '2' }, { name: 'Linea 3', value: '3' }] : [{ name: 'Total', value: '-1' }]
    }
}

export const getNameByVariable = (resultantVar, deviceType, name) => {
    const nameVars = getNameVariable(deviceType)
    const nameVarObj = nameVars.find(item => item.value === resultantVar)
    if (name) {
        return nameVarObj ? nameVarObj.name : null
    }
    return nameVarObj ? nameVarObj.value : null
}

export const getLineByVariable = (resultantVar, deviceType, name) => {
    const lineVars = getLineVariable(resultantVar, deviceType)
    const lineVarObj = lineVars?.length === 1 ? lineVars[0] : lineVars.find(item => item.value === resultantVar)
    if (name) {
        return lineVarObj ? lineVarObj.name : null
    }
    return lineVarObj ? lineVarObj.value : null
}

export const ActionHistoricalCalendar = (action) => {
    switch (action) {
        case "update_Calendar":
            return "Actualizado"
        case "create_Calendar":
            return "Creado"
        case "delete_Calendar":
            return "Eliminado"
        default:
            break;
    }
}

export const ModelCalendar = (mode) => {
    switch (mode) {
        case 100:
            return "Encendido"
        case 101:
            return "Pulso apagado"
        case 102:
            return "Pulso encendido"
        case 103:
            return "Apagado"
        default:
            break
    }
}

export const devices = [
    { id: "1", device_type: 1, icon_type: 1, name: 'CCM1', content: <IconDeviceCCM1 /> },
    { id: "2", device_type: 1, icon_type: 11, name: 'CCM1Inverted', content: <IconDeviceCCM1Inverted /> },
    { id: "3", device_type: 2, icon_type: 2, name: 'CCM2', content: <IconDeviceCCM2 /> },
    { id: "4", device_type: 2, icon_type: 22, name: 'CCM2Inverted', content: <IconDeviceCCM2Inverted /> },
    { id: "5", device_type: 3, icon_type: 3, name: 'CCM3', content: <IconDeviceCCM3 /> },
    { id: "6", device_type: 3, icon_type: 33, name: 'CCM3Inverted', content: <IconDeviceCCM3Inverted /> },
    { id: "7", device_type: 4, icon_type: 4, name: 'CCM4', content: <IconDeviceCCM4 /> },
    { id: "8", device_type: 4, icon_type: 44, name: 'CCM4Inverted', content: <IconDeviceCCM4Inverted /> },
    { id: "9", device_type: 0, icon_type: 0, name: 'MASTER', content: <IconDeviceMaster /> },
    { id: "10", device_type: 25, icon_type: 25, name: 'Differential', content: <IconDeviceContador /> },
    { id: "11", device_type: 26, icon_type: 26, name: 'Differential-2', content: <IconDeviceContador2 /> },
    { id: "12", device_type: 6, icon_type: 6, name: 'Analyzer', content: <IconDeviceAnalyzer /> }
]

export const deviceStatus = {
    Devices: {
        name: "Devices",
        items: devices
    },
    Relay1: {
        name: "Relay1",
        items: []
    }
}

export const switchConfig = (number) => {
    switch (number) {
        case 11:
            return 1
        case 22:
            return 2
        case 33:
            return 3
        case 44:
            return 4
        case 0:
            return 0
        default:
            return number
    }
}

export const getUniqueID = () => {
    return `id-${Math.random().toString(36).substr(2, 9)}`
}

export const getIconComponent = (iconType, customName, customNumserie) => {
    switch (iconType) {
        case 1:
            return { device_type: 1, icon_type: 1, name: customName || 'CCM1', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM1 /> }
        case 11:
            return { device_type: 1, icon_type: 11, name: customName || 'CCM1Inverted', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM1Inverted /> }
        case 2:
            return { device_type: 2, icon_type: 2, name: customName || 'CCM2', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM2 /> }
        case 22:
            return { device_type: 2, icon_type: 22, name: customName || 'CCM2Inverted', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM2Inverted /> }
        case 3:
            return { device_type: 3, icon_type: 3, name: customName || 'CCM3', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM3 /> }
        case 33:
            return { device_type: 3, icon_type: 33, name: customName || 'CCM3Inverted', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM3Inverted /> }
        case 4:
            return { device_type: 4, icon_type: 4, name: customName || 'CCM4', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM4 /> }
        case 44:
            return { device_type: 4, icon_type: 44, name: customName || 'CCM4Inverted', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceCCM4Inverted /> }
        case 0:
            return { device_type: 0, icon_type: 0, name: customName || 'MASTER', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceMaster /> }
        case 25:
            return { device_type: 25, icon_type: 25, name: customName || 'Differential', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceContador /> }
        case 26:
            return { device_type: 26, icon_type: 26, name: customName || 'Differential-2', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceContador2 /> }
        case 6:
            return { device_type: 6, icon_type: 6, name: customName || 'Analyzer', numserie: customNumserie || undefined, isMoved: true, content: <IconDeviceAnalyzer /> }
        default:
            return null
    }
}

export const compareIconTypesWithPosition = (layoutData, configuration) => {
    // Verificar si las longitudes del array principal son diferentes
    if (layoutData?.length !== configuration?.length) {
        return false
    }

    // Verificar cada subarray
    let isEqual = true

    layoutData?.forEach((layoutSubArray, i) => {
        const configSubArray = configuration[i]

        if (layoutSubArray?.length !== configSubArray?.length) {
            isEqual = false
            return
        }

        layoutSubArray?.forEach((subArray, j) => {
            const configNestedSubArray = configSubArray[j]

            if (Array?.isArray(subArray) !== Array?.isArray(configNestedSubArray)) {
                isEqual = false
                return
            }

            if (Array?.isArray(subArray) && Array?.isArray(configNestedSubArray)) {
                if (subArray?.length !== configNestedSubArray?.length) {
                    isEqual = false
                    return
                }

                subArray?.forEach((layoutItem, k) => {
                    const configItem = configNestedSubArray[k]

                    if (layoutItem.icon_type !== configItem.icon_type) {
                        isEqual = false
                        return
                    }
                })
            }
        })
    })

    return isEqual
}

export function convertWeekDay(weekDay) {
    switch (weekDay) {
        case "lunes":
            return "lunes"
        case "martes":
            return "martes"
        case "miércoles":
            return "x"
        case "jueves":
            return "jueves"
        case "viernes":
            return "viernes"
        case "sábado":
            return "sabado"
        case "domingo":
            return "domingo"
        default:
            return "No definido"
    }
}

export const breakMin = (min) => {
    const minutosEnUnDia = 1440
    const minutosEnUnaHora = 60
    const dias = Math.floor(min / minutosEnUnDia)
    const horasRestantes = min % minutosEnUnDia
    const horas = Math.floor(horasRestantes / minutosEnUnaHora)
    const minutosRestantes = horasRestantes % minutosEnUnaHora

    let result = ''
    if (dias > 0) {
        result += `${dias} dias \n`
    }
    if (horas > 0) {
        result += `${horas} horas \n`
    }
    result += `${minutosRestantes} minutos`
    return result
}

export const moreOneDay = (fecha1, fecha2) => {
    const date1 = new Date(fecha1);
    const date2 = new Date(fecha2);

    const time1 = date1.getTime();
    const time2 = date2.getTime();

    const diffTime = Math.abs(time2 - time1);
    const diffDays = diffTime / (1000 * 60 * 60 * 24);
    return diffDays < 1;
}

export const getClassNameItem = (iconType) => {
    switch (iconType) {
        case 1:
            return "ccm1-style";
        case 11:
            return "ccm1-inverted-style";
        case 2:
            return "ccm2-style";
        case 22:
            return "ccm2-inverted-style";
        case 3:
            return "ccm4-style";
        case 33:
            return "ccm4-inverted-style";
        case 4:
            return "ccm4-style";
        case 44:
            return "ccm4-inverted-style";
        default:
            return "relay-draggable";
    }
}

export const initialColumns = (relayId) => {
    return Array.from({ length: 12 }, (_, index) => ({
        id: `${relayId}-column-${index + 1}`,
        name: `Column ${index + 1}`,
        items: [],
    }))
}

export const restoreDevice = (device) => {
    switch (device?.icon_type) {
        case 1:
            return { ...device, id: "1", name: 'CCM1', content: <IconDeviceCCM1 />, numserie: undefined, isMoved: false }
        case 11:
            return { ...device, id: "2", name: 'CCM1Inverted', content: <IconDeviceCCM1Inverted />, numserie: undefined, isMoved: false }
        case 2:
            return { ...device, id: "3", name: 'CCM2', content: <IconDeviceCCM2 />, numserie: undefined, isMoved: false }
        case 22:
            return { ...device, id: "4", name: 'CCM2Inverted', content: <IconDeviceCCM2Inverted />, numserie: undefined, isMoved: false }
        case 3:
            return { ...device, id: "5", name: 'CCM3', content: <IconDeviceCCM3 />, numserie: undefined, isMoved: false }
        case 33:
            return { ...device, id: "6", name: 'CCM3Inverted', content: <IconDeviceCCM3Inverted />, numserie: undefined, isMoved: false }
        case 4:
            return { ...device, id: "7", name: 'CCM4', content: <IconDeviceCCM4 />, numserie: undefined, isMoved: false }
        case 44:
            return { ...device, id: "8", name: 'CCM4Inverted', content: <IconDeviceCCM4Inverted />, numserie: undefined, isMoved: false }
        case 0:
            return { ...device, id: "9", name: 'MASTER', content: <IconDeviceMaster />, numserie: undefined, isMoved: false }
        case 25:
            return { ...device, id: "10", name: 'Differential', content: <IconDeviceContador />, numserie: undefined, isMoved: false }
        case 26:
            return { ...device, id: "11", name: 'Differential-2', content: <IconDeviceContador2 />, numserie: undefined, isMoved: false }
        case 6:
            return { ...device, id: "12", name: 'Analyzer', content: <IconDeviceAnalyzer />, numserie: undefined, isMoved: false }
        default:
            return device
    }
}

export const areAllArraysEmpty = (arr) => {
    return arr?.every(subArray =>
        Array?.isArray(subArray) && subArray?.every(nestedArray =>
            Array?.isArray(nestedArray) && nestedArray?.length === 0
        )
    )
}

export const areArraysEqual = (arr1, arr2) => {
    if (arr1?.length !== arr2?.length) return false
    for (let i = 0; i < arr1?.length; i++) {
        if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
            if (!areArraysEqual(arr1[i], arr2[i])) return false
        } else if (arr1[i] !== arr2[i]) {
            return false
        }
    }
    return true
}

export const deviceName = (id) => {
    switch (id) {
        case 0:
            return "CCMáster";
        case 1:
            return "CCM1";
        case 2:
            return "CCM2";
        case 3:
            return "CCM3";
        case 4:
            return "CCM4";
        case 5:
            return "Inverter";
        case 6:
            return "Analyzer";
        case 7:
            return "Plant";
        case 9:
            return "Batery";
        case 11:
            return "Light";
        case 12:
            return "Plug";
        default:
            return "Desconocido";
    }
}

export const areArraysEqualEms = (arr1, arr2) => {
    let variable = true
    arr1?.forEach(ele => {
        const eleFind = arr2?.find(arr2ele => (arr2ele?.numserie === ele?.numserie && (arr2ele?.id_entry === ele?.id_entry || arr2ele?.ID_entry === ele?.id_entry)))
        if ((ele?.cont_order !== eleFind?.cont_order && ele?.cont_order !== eleFind?.control_order) || ele?.mode !== eleFind?.mode) {
            variable = false
        }
    })
    return variable
}

export const hours = Array?.from({ length: 25 }, (_, i) => `${i.toString().padStart(2, '0')}:00`)

export const adjustEventTimes = (events) => {
    return events?.map(event => {
        switch(true) {
            case event?.sunrise:
                return {
                    ...event,
                    hour: 5,
                    min: 40
                }
            case event?.sunset:
                return {
                    ...event,
                    hour: 18,
                    min: 59
                }
            default:
                return event
        }   
    })
}

export const formatTime = (event) => {
    const formattedHour = event.hour.toString().padStart(2, '0'); // Asegurarse de que la hora tenga dos dígitos
    const formattedMinute = event.min.toString().padStart(2, '0'); // Asegurarse de que los minutos tengan dos dígitos
    return `${formattedHour}:${formattedMinute}`;
}