import moment from 'moment'

export const toKiloW = (data) => {
    if (data === undefined || (!data && data !== 0) || data === '-' || data === undefined || data === null || data === " - " || isNaN(data)) {
        return '-'
    } else {
        return Number(data)?.toFixed(2)
    }
}

export const toKiloWName = (name) => {
    if (!name || name === '-' || name === undefined || name === null || name === " - " || isNaN(name)) {
        return '-'
    } else {
        return name
    }
}

export const toKilowDate = (date, value) => {
    const now = new Date()
    const fecha = moment(date)
    const isToday = (fecha.date() === now.getDate() && fecha.month() === now.getMonth() && fecha.year() === now.getFullYear())
    if (!isToday || date === '-' || date === undefined || date === null || date === " - " || value === undefined || value === "-" || value === " - " || isNaN(value)) {
        return ' - '
    } else {
        return value?.toFixed(2)
    }
}

export const toKilowPercent = (value) => {
    if (value !== '-' && value !== ' - ' && value !== undefined && value !== null) {
        return Number(value)?.toFixed(1)
    } else if (!value) {
        return '-'
    } else {
        return value
    }
}

export const toKiloWFecha = (numero, daily) => {
    if (daily === "months") {
        let miFecha = new Date();
        if (0 < numero && numero <= 12) {
            miFecha.setDate(1);
            miFecha.setMonth(numero - 1);
            return new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(miFecha).toLocaleUpperCase(0);
        } else {
            return null;
        }
    } else {
        return numero
    }
}

export const conversorFecha = (fechaInicio, fechaFin, tipo) => {
    switch (tipo) {
        case "Año":
            return new Intl.DateTimeFormat('es-ES', { year: 'numeric' })?.format(fechaInicio)?.toLocaleUpperCase(0)
        case "Mes Actual":
            return new Intl.DateTimeFormat('es-ES', { month: 'long' })?.format(fechaInicio).toLocaleUpperCase(0)
        case "Rango":
            return fechaInicio?.format('DD-MM-YYYY') + '    -    ' + fechaFin?.format('DD-MM-YYYY')
        default:
            return fechaInicio?.format('DD-MM-YYYY')
    }
}

export const dateTableDevices = (datetime) => {
    let now = new Date()
    const fecha = datetime?.split(' ')[0]
    const fechaNow = moment(now).format("DD-MM-YYYY")
    if (fecha === fechaNow) {
        let time = datetime?.split(' ')[1]
        return time
    } else {
        return datetime
    }
}

export const getPercent = (variable, total) => {
    if (variable === 0) {
        return '0.0'
    }
    let result = Math.abs((variable * 100) / total);
    if (total !== 0 && total !== undefined && total !== null && variable !== undefined && variable !== 0) {
        if (!isNaN(result) && result > 100) {
            return 100.0
        } else {
            return Number(Math.abs(result)).toFixed(1)
        }
    } else {
        return ' - '
    }
}

export const getPercentGraphs = (variable, total) => {
    if (variable === 0) {
        return '0.0'
    }
    let result = (variable * 100) / total;
    if (total !== 0 && total !== undefined && total !== null && variable !== undefined) {
        if (!isNaN(result) && result > 100) {
            return 100.0
        } else {
            return Number(Math.abs(result)).toFixed(1)
        }
    } else {
        return ' - '
    }
}

export const result = (data, numserie) => {
    let filter = data?.filter(item => Number(item?.numserie) === Number(numserie))
        .sort((a, b) => new Date(b.datetime) - new Date(a.datetime))
        .shift()
    return filter
}