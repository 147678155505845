import React, { useState, useEffect } from 'react'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import { TableContainer, TableHead, TableRow, TableBody } from '@mui/material'
import { Table, Button } from "@mui/material"
import { variableTitles } from 'utils/Translate/variableTitles'
import TableCell from '@mui/material/TableCell'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { CSVLink } from "react-csv"
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'

export const StyledTableCellHead = ({ style, children, className, scope }) => {
    return (
        <TableCell
            align="center" 
            style={style}
            className={className} 
            scope={scope}
            sx={{ 
                backgroundColor: '#fe5000',
                color: 'black',
                border: "1px solid #e0e0e0",
                fontSize: 12,
                fontWeight: "bold",
                padding: "15px 30px"
            }}
        >
            {children}
        </TableCell>
    )
}

const StyledTableCell = ({ style, children, className, scope }) => {
    return (
        <TableCell
            align="center" 
            style={style} 
            className={className} 
            scope={scope}
            sx={{ 
                fontSize: 12,
                borderRight: "1px solid #e0e0e0",
                padding: "15px 30px"
            }}
        >
            {children}
        </TableCell>
    )
}

export const HistoricalDataTable = (props) => {
    const token = sessionStorage?.token
    const [historicalData, setHistoricalData] = useState()

    useEffect(() => {
        if (props.device) {
            const fetchData = async () => {
                if (props.device) {
                    fetch(
                        urls.scada + `getRegisterData/${props?.device?.numserie}?idCCMaster=${props?.device?.mabt_serial}&iniDate=${props.start.format("YYYY-MM-DD")}&endDate=${props.end.format("YYYY-MM-DD")}&groupData=60`, {
                        method: 'GET',
                        headers: headersScada(token)
                    })
                        .then(res => {
                            if (res.status === 204) {
                            } else {
                                return res.json()
                            }
                        })
                        .then(data => {
                            setHistoricalData(data)
                        })
                        .catch(err => {
                            console.error("ERROR. API Scada", err);
                        })
                }
            }
            fetchData()
        }
    }, [props.device, props.start, props.end])

    const fields = ['datetime', 'i1', 'v1', 'i2', 'v2', 'i3', 'v3', 'ine', 'vtn', 'pf1', 'pf2', 'pf3', 'eact1_q14', 'eact2_q14', 'eact3_q14', 'eact1_q23', 'eact2_q23',
        'eact3_q23', 'ereact1_q1', 'ereact1_q2', 'ereact1_q3', 'ereact1_q4', 'ereact2_q1', 'ereact2_q2', 'ereact2_q3', 'ereact2_q4', 'ereact3_q1', 'ereact3_q2', 'ereact3_q3', 'ereact3_q4',
        'eact1_f', 'eact2_f', 'eact3_f', 'ereact1_f', 'ereact2_f', 'ereact3_f', 'pac1', 'pac2', 'pac3', 'pre1', 'pre2', 'pre3', 'pap1', 'pap2', 'pap3', 'thdv1', 'thdi1', 'thdv2', 'thdi2',
        'thdv3', 'thdi3', 'if1', 'vf1', 'if2', 'vf2', 'if3', 'vf3', 'temp', 'freq1', 'freq2', 'freq3', 'eact_q14', 'eact_q23', 'ereact_q1', 'ereact_q2', 'ereact_q3', 'ereact_q4', 'eapa', 'eapa1',
        'eapa2', 'eapa3']

    let headers = []

    if (historicalData) {
        headers = Object.keys(historicalData?.[0]).filter(value => fields.includes(value))
    }

    return (
        <div className="table-responsive-md" style={{ overflowX: 'auto' }}>
            <TableContainer>
                <Table sx={{ minWidth: 700 }} aria-label="customized table" >
                    <TableHead style={{ borderBottom: '2px solid #fe5000', borderRadius: '50px' }}>
                        <TableRow>
                            <StyledTableCellHead align="center" style={{ fontWeight: '900', color: 'black', verticalAlign: 'middle', position: 'sticky', top: '0', textTransform: 'capitalize' }}>
                                <Button size='small' >
                                    {historicalData &&
                                        <CSVLink data={historicalData} separator={";"} style={{ color: 'white' }} filename={`${props?.device?.nick}_${props?.start}_${props?.end}.csv`}>
                                            <HtmlTooltip title={<span>Descargar datos</span>}>
                                                <FontAwesomeIcon icon={faFileDownload} size="lg" color='#858585' />
                                            </HtmlTooltip>
                                        </CSVLink>
                                    }
                                </Button>
                            </StyledTableCellHead>
                            {headers.map(field => {
                                let separators = variableTitles[field]?.split(" ");
                                const sep = separators?.filter((sep) => sep !== "-")
                                return (
                                    sep?.[0] !== 'Fecha' ?
                                        <StyledTableCellHead align="center" style={{ fontWeight: '900', color: 'black', verticalAlign: 'middle', position: 'sticky', top: '0', textTransform: 'capitalize' }}>
                                            {sep?.length <= 2 && <span >{sep[0]} <br /> {sep[1]}</span>}
                                            {sep?.length >= 3 && sep.length <= 4 && <span >{sep[0]} <br />{sep[1]}<br /> {sep[2]}{' '}{sep[3]}</span>}
                                            {sep?.length >= 5 && <span >{sep[0]} <br /> {sep[1]}<br /> {sep[2]}{' '}<br />{sep[3]}<br />{sep[4]}{' '}{sep[5]} </span>}
                                        </StyledTableCellHead>
                                        :
                                        <>
                                            <StyledTableCellHead align="center" style={{ fontWeight: '900', color: 'black', verticalAlign: 'middle', position: 'sticky', top: '0', textTransform: 'capitalize' }}>
                                                Fecha
                                            </StyledTableCellHead>
                                            <StyledTableCellHead align="center" style={{ fontWeight: '900', color: 'black', verticalAlign: 'middle', position: 'sticky', top: '0', textTransform: 'capitalize' }}>
                                                Hora
                                            </StyledTableCellHead>
                                        </>
                                )

                            })}

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {historicalData && historicalData.map((values) => {
                            return (
                                <TableRow>
                                    <StyledTableCell align="center">
                                        <span className="text-nowrap">
                                            <FontAwesomeIcon icon={faInfoCircle} size="lg" color='#858585' />
                                        </span>
                                    </StyledTableCell>

                                    {historicalData && headers.map(field => {
                                        return (
                                            field !== 'datetime' ?
                                                <StyledTableCell align="center"><span className="text-nowrap">{values[field]}</span></StyledTableCell>
                                                :
                                                <>
                                                    <StyledTableCell align="center"><span className="text-nowrap">{values[field].split(' ')[0]}</span></StyledTableCell>
                                                    <StyledTableCell align="center"><span className="text-nowrap">{values[field].split(' ')[1]}</span></StyledTableCell>

                                                </>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
