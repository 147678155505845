import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { urls } from "utils/constant"
import { DeviceDate } from "utils/DeviceDate/DeviceDate"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import { ImageDevice } from "utils/ImageDevice/ImageDevice"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { headersScada } from "services/headers"
import { IconWarning } from "utils/icon-centrocontrol"
import { toKiloW } from "utils/functionsforGraphs"
import { Card, Grid, Table } from "@mui/material"

export const CardTriphasic = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const actualRoute = location.pathname.split('/')[1]
    const token = sessionStorage?.token
    const idProject = location.pathname.split('/')[3]
    const numserieMaster = location.pathname.split('/')[4]
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    //Muestro el dispositivo
    const seeWeb = () => {
        if (Number(props.devices.master_serial) === Number(numserieMaster)) {
            props.handleSelectedDevice(props.devices);
            navigate(`/view/scada/${idProject}/${numserieMaster}/${props.devices.numserie}`)
        }
    }

    let url

    if (numserieMaster && props?.loose === 0) {
        url = `getCurrentData/${props?.devices?.numserie}?idCCMaster=${numserieMaster}`
    } else if (props?.loose > 0) {
        url = `getCurrentData/${props?.devices?.numserie}`
    } else {
        url = `getCurrentData/${props?.devices?.numserie}?idCCMaster=${numserieMaster}`
    }

    const [todayEnergy, setTodayEnergy] = useState()
    const [totalPower, setTotalPower] = useState()
    const [exportEnergy, setExportEnergy] = useState()
    const [activePower1, setActivePower1] = useState()
    const [activePower2, setActivePower2] = useState()
    const [activePower3, setActivePower3] = useState()
    const [errorVisible, setErrorVisible] = useState(false)
    const nameDevice = props?.devices?.nick ? props?.devices?.nick : data?.currentData?.nick

    const fetchData = (numserie) => {
        fetch(urls.scada + url,
            { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => {
                setData(data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.error("ERROR. API Scada", err)
            })
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [props?.devices?.numserie])

    useEffect(() => {
        if (data) {
            setTodayEnergy(toKiloW(data?.currentData?.eaconsdia))
            setTotalPower(toKiloW(data?.currentData?.pact))
            setExportEnergy(toKiloW(data?.currentData?.eagendia))
            setActivePower1(toKiloW(data?.currentData?.pact1))
            setActivePower2(toKiloW(data?.currentData?.pact2))
            setActivePower3(toKiloW(data?.currentData?.pact3))
        }
    }, [data])

    const selectDevice = () => {
        seeWeb()
        props?.setDeviceSelected(props?.devices)
        fetchData()
        props?.setDevice(props?.devices)
        props?.setResumeView(false)
        if (props?.activeTabDevice !== 3) {
            props?.toggleDevice(3)
        }
    }

    useEffect(() => {
        if (data) {
            if ((data?.currentData?.intensidad_magneto >= 0 && data?.currentData?.intensidad_magneto !== null) || (data?.currentData?.potencia_contratada >= 0 && data?.currentData?.potencia_contratada !== null)) {
                setErrorVisible(false)
            } else {
                setErrorVisible(true)
            }
        }
    }, [data])

    return (
        <Grid
            md={props?.resumeView ? 2 : 12}
            className={props?.resumeView ? 'mt-3 ml-4' : "mt-3 ml-4 mr-0"}
            style={{ height: '100%' }}
        >
            <Grid item style={{ height: '100%' }}>
                <Card
                    className={`card-box-alt p-4 custom-hover`}
                    onClick={() => selectDevice(data)}
                    style={{
                        cursor: actualRoute === "admin" ? '' : 'pointer',
                        borderRadius: '0rem',
                        borderLeft: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '5px solid #FF8A1F' : '1px solid #eee',
                        borderRight: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '5px solid #FF8A1F' : '1px solid #eee',
                        boxShadow: Number(props?.deviceSelected?.numserie) === Number(props?.devices.numserie) ? '0px 5px 15px 2px #C4C4C4' : null,
                        height: '100%',
                    }}
                >
                    <Grid item sm={12} lg={12} xl={12} className="flex-center justify-start height-2-5">
                        <DropDownDevices
                            scada={true}
                            setLoading={setLoading}
                            device={props.devices}
                            masterPermission={props.masterPermission}
                            active={props.deviceSelected?.activo}
                            setDevices={props.setDevices}
                            setRefresh={props?.setRefresh}
                            refresh={props?.refresh}
                            loose={props?.loose}
                            instalacion={props?.masterPermission?.[props?.devices?.master_serial ? props?.devices?.master_serial : props?.devices?.mabt_serial ? props?.devices?.mabt_serial : props?.devices?.id_padre ? props?.devices?.id_padre : props?.devices?.numserie]}
                        />
                        <div className="ml-2">
                            <ImageDevice id_type_device={props?.devices?.id_tipo_dispositivo} />
                        </div>
                        {nameDevice?.length > 20 ?
                            <HtmlTooltip title={<span> {nameDevice} </span>}>
                                <p className="font-weight-bold ml-2 mr-2 mb-0">
                                    {nameDevice.substr(0, 17) + '...'}
                                </p>
                            </HtmlTooltip>
                            :
                            <p className="font-weight-bold ml-2 mr-2 mb-0">
                                {nameDevice}
                            </p>
                        }
                        {errorVisible &&
                            <HtmlTooltip title={<span>Revise su configuración.</span>}>
                                <IconWarning width={20} height={20} />
                            </HtmlTooltip>
                        }
                    </Grid>
                    <div className="divider mt-1" />
                    <Grid item sm={12} lg={12} xl={12} className="d-flex justify-content-end">
                        <DeviceDate
                            device={props.devices}
                            loading={loading}
                            data={data?.currentData}
                            masterPermission={props?.masterPermission?.[props?.devices?.master_serial ? props?.devices?.master_serial : props?.devices?.numserie]}
                        />
                    </Grid>
                    <Grid container className="mt-2" style={{ textAlign: 'start' }}>
                        <Grid item sm={12} lg={12} xl={12}>
                            <h4>Potencia total : {toKiloW(totalPower)} kW</h4>
                        </Grid>
                        {/* IMPLEMENTAR LÓGICA PARA POTENCIA IMPORTADA Y EXPORTADA */}
                        <Grid item sm={12} lg={12} xl={12}>
                            <h4>E. Importada : {toKiloW(todayEnergy)} kWh</h4>
                        </Grid>
                        <Grid item sm={12} lg={12} xl={12}>
                            <h4>E. Exportada : {toKiloW(exportEnergy)} kWh </h4>
                        </Grid>
                        <Table style={{ textAlign: 'center', padding: '0px', marginTop: '10px' }}>
                            <thead >
                                <tr>
                                    <th style={{ width: '10%' }} className="p-0">{' '}</th>
                                    <th style={{ width: '22%' }} className="p-0">P(kW)</th>
                                    <th style={{ width: '22%' }} className="p-0">I(A)</th>
                                    <th style={{ width: '22%' }} className="p-0">V(V)</th>
                                    <th style={{ width: '22%' }} className="p-0">PF</th>
                                </tr>
                            </thead>
                        </Table>
                    </Grid >
                    <div className="divider mt-1" />
                    <Grid container sm={12} lg={12} xl={12}>
                        <Table>
                            <tbody style={{ fontSize: '13px' }}>
                                <tr style={{ backgroundColor: '#EDEDED' }}>
                                    <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>L1</td>
                                    <td style={{ width: '22%' }}>{toKiloW(activePower1)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.i1)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.v1 !== undefined && data?.currentData?.v1 !== null ? data?.currentData?.v1 : data?.currentData?.vac1)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.pf1)}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>L2</td>
                                    <td style={{ width: '22%' }}>{toKiloW(activePower2)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.i2)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.v2 !== undefined && data?.currentData?.v2 !== null ? data?.currentData?.v2 : data?.currentData?.vac2)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.pf2)}</td>
                                </tr>
                                <tr style={{ backgroundColor: '#EDEDED' }}>
                                    <td style={{ width: '10%', textAlign: 'start', color: '#FF8A1F', fontWeight: 'bold' }}>L3</td>
                                    <td style={{ width: '22%' }}>{toKiloW(activePower3)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.i3)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.v3 !== undefined && data?.currentData?.v3 !== null ? data?.currentData?.v3 : data?.currentData?.vac3)}</td>
                                    <td style={{ width: '22%' }}>{toKiloW(data?.currentData?.pf3)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Grid>
                </Card >
            </Grid >
        </Grid >
    )
}