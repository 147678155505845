import React, { useEffect } from 'react'
import { urls } from "utils/constant.jsx"
import { useLocation } from "react-router-dom"
import { headersScada } from 'services/headers'

export const DevicesUtils = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const numserieMaster = location.pathname.split('/')[4]
    let url

    if (props?.loose !== undefined) {
        if (props?.loose === 0) {
            url = `getccm/${numserieMaster}?idCCMaster=${numserieMaster}`
        } else if (props?.loose === 0 && Number(props?.deviceSelected?.id_tipo_dispositivo) === 20) {
            url = `getccm/${numserieMaster}?idCCMaster=${numserieMaster}`
        } else if (props?.loose > 0) {
            url = `getccm/${numserieMaster}`
        } else {
            url = `getccm/${numserieMaster}?idCCMaster=${numserieMaster}`
        }
    }

    const fecthDevices = () => {
        if (url !== undefined) {
            fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            .then(res => res.json())
            .then(data => props?.setDevices(data))
            .catch(err => console.error("ERROR. API Scada", err))
        }
    }

    useEffect(() => {
        fecthDevices()
    }, [numserieMaster, props?.loose])

    return (
        <></>
    )
}