import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { headersScada } from "services/headers"
import { urls } from "utils/constant"
import { Mabt } from "./Mabt"
import { NavBar } from "views/NavBar/NavBar"
import { AuthFooter } from "views/Footer/AuthFooter"
import Maps from "components/Mapa/MapShow"
import { Loader } from "components/Loader/Loader"
import { GlobalContext } from "context/GlobalContext"

export const MabtController = () => {
    const token = sessionStorage?.token
    const location = useLocation()
    const idMaster = location.pathname.split('/')[4]
    const url = `/${idMaster}?idCCMaster=${idMaster}`
    const [devices, setDevices] = useState()
    const [masterError, setMasterError] = useState()
    const [loading, setLoading] = useState(true)
    const { actualProject } = useContext(GlobalContext)
    const loadingSlice = useSelector((state) => state.loading.name)
    
    const fecthDevices = () => {
        fetch(urls.scada + 'getccm' +  url, { method: 'GET', headers: headersScada(token)})
        .then((res) => res.json())
        .then((data) => {
            data.permissions = actualProject.permissions
            setDevices(data)
        })
        .catch((err) => console.error('ERROR. API Scada', err))
        .finally(() => {
            setLoading(false)
        })
    }

    const fetchInfoPower = () => {
        fetch(urls.scada + 'getInfoPower' + url, {method: "GET", headers: headersScada(token)})
        .then((res) => res.json())
        .then((data) => {
            setMasterError(data)
        })
        .catch((err) => {
            console.error("ERROR. API Scada", err)
        })
    }  

    useEffect(() => {
        fetchInfoPower()
    }, [loadingSlice])

    useEffect(() => {
        if(actualProject?.permissions) {
            setLoading(true)
            fecthDevices()
        }
    }, [actualProject])

    return (
        <>
            <Maps places={[]} zoom={5} className="display-none" />
            <div className="container-scada-principal">
                <NavBar/>
                {loading ? 
                    <Loader/>
                :
                    <Mabt
                        devices={devices}
                        token={token}
                        masterPermission={devices}
                        masterError={masterError}
                    />
                }
            </div>
            <AuthFooter/>
        </>
    )

}