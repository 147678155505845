import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import batery from 'assets/img/icons/consumption/batery.png'
import { headersScada } from 'services/headers'
import { ChangeBatery, Iconhome, IconRed, IconPanel } from 'utils/icon-centrocontrol'
import { toKiloW, toKilowDate, toKilowPercent } from 'utils/functionsforGraphs'
import { changeBattery } from 'utils/Utils'
import { urls } from 'utils/constant'
import "../styleSelfConsumption.scss"

export const CardSelfConsumption = ({ devices, refresh, seeWeb, setRefresh, setActiveTabDevice, setActiveTab, isMobile, selfConsumption, importSelf, exportSelf }) => {
    const token = sessionStorage?.token
    const [data, setData] = useState()
    const getSelfConsumption = devices?.childrenDevices?.filter(device => device.id_tipo_dispositivo === 7)

    const fetchData = () => {
        fetch(urls.scada + `getCurrentData/${getSelfConsumption?.[0]?.numserie}?idCCMaster=${getSelfConsumption?.[0]?.master_serial}`,
            { method: 'GET', headers: headersScada(token) })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                } else {
                    return []
                }
            }).then(
                data => setData(data),

            ).catch(err => console.error("ERROR. API Scada", err))
    }

    useEffect(() => {
        if (devices?.numserie) {
            if (getSelfConsumption?.[0]?.numserie !== undefined) {
                fetchData()
            }
        }
    }, [devices?.numserie, refresh])

    const handleClick = (section) => {
        seeWeb(section)
        setRefresh(current => !current)
        setActiveTabDevice(0)
        setActiveTab(5)
    }

    const handleUpdate = () => {
        if (getSelfConsumption?.[0]?.numserie !== undefined) {
            fetchData()
        }
    }

    let totalEnergy = data?.consumo?.eaconsdia ? data?.consumo?.eaconsdia : ' - '

    return (
        <>
            <span className='card-arrow'>
                {isMobile !== true ?
                    <span className='card-column '>
                        <>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }} onClick={handleUpdate}>
                                    <div className={selfConsumption === "consumption" ? "Card-self-Active" : "Card-self"}
                                        onClick={() => {
                                            handleClick('consumption');
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self">
                                                <Iconhome />
                                                <h2 className="title-card-self">
                                                    Consumo
                                                </h2>
                                            </span>
                                            <span className="grid-Card-self">
                                                <p className="element-card-self-mini" id="consumption">
                                                    Potencia: {toKilowDate(data?.consumo?.datetime, data?.consumo?.pact)} kW
                                                </p>
                                            </span>
                                        </span>
                                        <div className='divider-self' />
                                        <span className="cardData-self">
                                            <p className="element-card-self">
                                                Consumida: {toKilowDate(data?.consumo?.datetime, totalEnergy)} kWh
                                            </p>
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                                    <div className={selfConsumption === "red" ? "Card-self-Active" : "Card-self"}
                                        onClick={() => {
                                            handleClick('red');
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self">
                                                <IconRed />
                                                <h2 className="title-card-self">
                                                    Red
                                                </h2>
                                            </span>
                                            <span className="grid-Card-self">
                                                <span className="power-card-self-section">
                                                    <p className="element-card-self-mini" id="red">
                                                        Potencia: {toKilowDate(data?.red?.datetime, data?.red?.pact)} kW
                                                    </p>
                                                    {importSelf === 100 ?
                                                        <p className="element-card-sub-self  blueImport">
                                                            Exportando: {toKilowPercent(exportSelf)} %
                                                        </p>
                                                        :
                                                        <p className="element-card-sub-self  blueImport">
                                                            Importando: {toKilowPercent(exportSelf)} %
                                                        </p>
                                                    }
                                                </span>
                                            </span>
                                        </span>
                                        <div className='divider-self' />
                                        <span className="cardData-self">
                                            <p className="element-card-self">
                                                Importada: {toKilowDate(data?.red?.datetime, data?.red?.eaconsdia)} kWh
                                            </p>
                                            <p className="element-card-self">
                                                Exportada: {toKilowDate(data?.red?.datetime, data?.red?.eagendia)} kWh
                                            </p>
                                        </span>
                                    </div>
                                </Grid>
                                <Grid item sm={12} lg={12} xl={12} style={{ margin: "10px 0px" }}>
                                    <div className={selfConsumption === "production" ? "Card-self-Active" : "Card-self"}
                                        onClick={() => {
                                            handleClick('production');
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self">
                                                <IconPanel />
                                                <h2 className="title-card-self">
                                                    Generación
                                                </h2>
                                            </span>
                                            <span className="grid-Card-self">
                                                <span className="power-card-self-section">
                                                    <p className="element-card-self-mini" id="production">
                                                        Potencia: {toKilowDate(data?.produccion?.datetime, data?.produccion?.pact)} kW
                                                    </p>
                                                    <p className="element-card-sub-self">
                                                        Autoconsumo: {toKilowPercent(importSelf)} %
                                                    </p>
                                                </span>
                                            </span>
                                        </span>
                                        <div className='divider-self' />
                                        <span className="cardData-self">
                                            <p className="element-card-self">
                                                Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                                            </p>
                                        </span>
                                    </div>
                                </Grid>
                            </div>
                            <div className="cardMargin-self">
                            </div><div className="cardMargin-self">
                            </div></>
                        <div className="cardMargin-self">
                            <Grid item sm={12} lg={12} xl={12} className={data?.bateria ? null : "active-Batery"} style={{ margin: "10px 0px" }}>
                                <div id="card-batery" className={data?.bateria && selfConsumption === "batery" ? "Card-self-Active" : data?.bateria ? "Card-self" : "card-self-batery"}
                                    onClick={
                                        data?.bateria ? () => {
                                            handleClick('batery')
                                        } : null}>
                                    <span className="header-Card-self">
                                        <span className="grid-Card-self">
                                            <ChangeBatery change={changeBattery(data?.bateria?.soc > 0 ? data?.bateria?.soc : 0)} width="20" height="20"
                                                id={'batery'} alt="Batery" />
                                            <h2 className="title-card-self">
                                                Baterías
                                            </h2>
                                        </span>
                                        <span className="grid-Card-self">
                                            <span className="power-card-self-section">
                                                <p className="element-card-self-mini" id="production">
                                                    Potencia: {toKiloW(data?.bateria?.p)} kW
                                                </p>
                                                <p className="element-card-sub-self  blueImport" >
                                                    {data?.bateria && data?.bateria?.p > 0 ? "Cargando" : data?.bateria && data?.bateria?.p >> 0 ? "Descargando" :
                                                        null
                                                    }
                                                </p>
                                            </span>
                                        </span>
                                    </span>
                                    <div className='divider-self' />
                                    <span className="cardData-self" >
                                        <p className="element-card-self">
                                            Carga: {toKilowPercent(data?.bateria?.soc) + "%"}
                                        </p>
                                    </span>
                                </div>
                            </Grid>
                        </div>
                    </span>
                    : <>
                        <span className='card-column small-card'>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12}>
                                    <div className='card-self-small'
                                        onClick={() => {
                                            handleClick('consumption');
                                        }}>
                                        <span container className="header-Card-self">
                                            <span className="grid-Card-self card-mobile-card-menu">
                                                <Iconhome />
                                                <p> Consumo </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Consumo: {toKilowDate(data?.consumo?.datetime, totalEnergy)} kWh
                                                </p>
                                            </span>
                                        </span>
                                        <div className={selfConsumption === "consumption" ? "divider-self-active-small" : "divider-self-small"} />
                                    </div>
                                </Grid>
                            </div>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12}>
                                    <div className='card-self-small'
                                        onClick={() => {
                                            handleClick('production');
                                        }}>
                                        <span className="header-Card-self ">
                                            <span className="grid-Card-self card-mobile-card-menu ">
                                                <IconPanel alt="Panel Solar" />
                                                <p> Generación </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Generada: {toKilowDate(data?.produccion?.datetime, data?.produccion?.eagendia)} kWh
                                                </p>
                                            </span>
                                        </span>
                                        <div className={selfConsumption === "production" ? "divider-self-active-small" : "divider-self-small"} />
                                    </div>
                                </Grid>
                            </div>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12}>
                                    <div className='card-self-small'
                                        onClick={() => {
                                            handleClick('red');
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self card-mobile-card-menu">
                                                <IconRed alt="Torre de alta tensíon" />
                                                <p> Red </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Importada: {toKilowDate(data?.red?.datetime, data?.red?.eaconsdia)} kWh
                                                </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Exportada: {toKilowDate(data?.red?.datetime, data?.red?.eagendia)} kWh
                                                </p>
                                            </span>
                                        </span>
                                        <div className={selfConsumption === "red" ? "divider-self-active-small" : "divider-self-small"} />
                                    </div>
                                </Grid>
                            </div>
                            <div className="cardMargin-self">
                                <Grid item sm={12} lg={12} xl={12}>
                                    <div className='card-self-small'
                                        onClick={() => {
                                            handleClick('batery');
                                        }}>
                                        <span className="header-Card-self">
                                            <span className="grid-Card-self card-mobile-card-menu">
                                                <img src={batery} alt="bateria" />
                                                <p> Batería </p>
                                                <p className="element-card-self data-card-self-mobile">
                                                    Carga: {toKiloW(data?.bateria?.soc) + "%"}
                                                </p>
                                            </span>
                                        </span>
                                        <div className={selfConsumption === "batery" ? "divider-self-active-small" : "divider-self-small"} />
                                    </div>
                                </Grid>
                            </div>
                        </span>
                    </>
                }
            </span>
        </>
    )
}


