import React, { useEffect, useState } from 'react'
import { Grid } from "@mui/material"
import { Bar } from 'react-chartjs-2'
import { Loader } from 'components/Loader/Loader.jsx'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
    Filler,
)

export const ConsumptionEnergyGraph = ({
    graphData,
    start,
    loadingData,
}) => {
    const chartRef = React.useRef(null);
    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
            chartRef.current.resetZoom();
        };
    };
    const LineOptions = 
        {
            responsive: true,
            maintainAspectRatio: false,
            animations: {
                radius: {
                    duration: 400,
                    easing: 'linear',
                    loop: (context) => context.active
                }
            },
            hoverRadius: 6,
            hoverOpacity: 0.6,
            interaction: {
                mode: 'nearest',
                intersect: false,
                axis: 'x'
            },
            scales: {
                x: {
                    grid: {
                        drawOnChartArea: true,
                        drawTicks: false,
                    },
                },
                y: {
                    grid: {
                        drawOnChartArea: true,
                        drawTicks: false,
                    },
                    suggestedMax: 1,
                    ticks: {
                        callback: function (value, index, ticks) {
                            return value.toFixed(2) + ' kWh';
                        },
                    }
                },
                y2: {
                    position: 'right',
                    grid: {
                        drawOnChartArea: false,
                        drawTicks: false,
                    },
                    ticks: {
                        callback: function (value, index, ticks) {
                            return '';
                        },
                    }
                },
            },
            plugins: {
                legend: {
                    labels: {
                        padding: 10, //default is 10
                        usePointStyle: true,
                        pointStyle: 'circle',
                    },
                    display: true,
                    position: 'bottom',
                    align: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        label: function (context) {
                            let label = context.dataset.label || '';

                            if (context.parsed.y !== null) {
                                label += ' : ';
                                return label += new Intl.NumberFormat().format(context.parsed.y) + ' kWh';
                            }
                        }
                    }
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    speed: 0.5,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    mode: 'x',
                }
            },
        }

    const labels = graphData.map(objeto => objeto.datetime)
    const energiaConsumida = graphData.map(objeto => objeto.eaconsdia)
    const energiaGenerada = graphData.map(objeto => objeto.eagendia)
    const dataFilter = {
        labels: labels,
        datasets: [
            {
                Type: 'bar',
                data: energiaConsumida,
                label: 'E.Importada',
                backgroundColor: '#3B9FD1',
                borderColor: '#3B9FD1',
                pointBackgroundColor: '#3B9FD1',
                borderWidth: 0,
                fill: true,
                id: '1',
                numserie: '',
                maintainAspectRatio: 'true',
            },
            {
                Type: 'bar',
                data: energiaGenerada,
                label: 'E.Exportada',
                backgroundColor: '#B4DA9D',
                borderColor: '#B4DA9D',
                pointBackgroundColor: '#B4DA9D',
                borderWidth: 0,
                fill: true,
                id: '2',
                numserie: '',
                maintainAspectRatio: 'true',
            }
        ],
    }

    const [graph, setGraph] = useState(
        <Bar
            style={{ width: '100%', height: '20em' }}
            data={dataFilter}
            options={LineOptions}
            ref={chartRef}
        />
    )

    useEffect(() => {
        handleResetZoom()
    }, [graph])

    useEffect(() => {
        setGraph(
            <Bar
                style={{ width: '100%', height: '20em' }}
                data={dataFilter}
                options={LineOptions}
                ref={chartRef}
            />
        )
    }, [graphData])


    return (
        <>
            {loadingData ?
                <Loader />
                :
                <Grid container md={12}>
                    {graph}
                </Grid>
            }
        </>
    );
};