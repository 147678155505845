import React, { useEffect, useState } from 'react'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { useLocation } from 'react-router-dom'
import { ScadaGeneralInverter } from './Tabs/ScadaGeneralInverter'
import { ScadaProduction } from 'views/Scada/SelfConsumption/Production/ScadaProduction'
import { Alarms } from 'views/Alarms/Alarms'
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { OnOff } from '../CcMaster/tabs/OnOff'

export const Inverter = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split("/")[4]
    const [childresData, setChildresData] = useState()
    const [loading, setLoading] = useState()
    const numserieSelfConsumption = props?.childrenDevices?.filter(device => device.id_tipo_dispositivo === 7)
    const [deviceType, setDeviceType] = useState(props?.device?.[0]?.id_tipo_dispositivo)
    const fetchChildrens = async (numserie) => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `getccm/${numserie}?idCCMaster=${idMaster}`,
                { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            props.setLoading(true)
            console.error("ERROR. API Scada", err);
        }
        setLoading(false)
    }

    const handleButton = () => props.setSelfConsumption('general')

    useEffect(() => {
        setChildresData([])
        numserieSelfConsumption?.forEach(item => {
            fetchChildrens(item?.numserie).then(data =>
                setChildresData(prev => [...prev, data]))
        })
    }, [props?.master?.numserie, props?.childrenDevices])

    const inverter = true

    return (
        <>
            {props?.activeTabDevice === 0 && props.selfConsumption === 'production' ?
                <ScadaProduction isMobile={props?.isMobile} inverter={inverter} ssetRefresh={props?.setRefresh} refresh={props?.refresh} startt={props.startt} data={childresData?.[0]?.produccion} handleButton={handleButton} device={props?.devices} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart}
                    now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton} handleClickRight={props.handleClickRight} masterPermission={props?.masterPermission}
                    handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} childrenDevices={props.childrenDevices} deviceType={deviceType} setDeviceType={setDeviceType} />
                : props?.activeTabDevice === 0 ?
                    <ScadaGeneralInverter
                        masterPermission={props?.masterPermission}
                        isMobile={props?.isMobile}
                        setRefresh={props?.setRefresh}
                        refresh={props?.refresh}
                        startt={props.startt}
                        master={props?.master}
                        numserieSelfConsumption={numserieSelfConsumption}
                        loading={loading}
                        handleButton={handleButton}
                        device={props?.devices}
                        start={props.start}
                        end={props.end}
                        groupData={props.groupData}
                        setStart={props.setStart}
                        now={props.now}
                        setEnd={props.setEnd}
                        setgroupData={props.setgroupData}
                        titleButton={props.titleButton}
                        setTitleButton={props.setTitleButton}
                        handleClickRight={props.handleClickRight}
                        handleClickLeft={props.handleClickLeft}
                        daily={props.daily}
                        setDaily={props.setDaily}
                        childrenDevices={numserieSelfConsumption}
                    /> : null
            }
            {props.activeTabDevice === 1 &&
                <Alarms instalacioneSeleccionadas={[props.numserieMaster]} dispositivos={[props.dispositivos]} />
            }
            {props.activeTabDevice === 2 &&
                <ConfiguracionList scadaSelf={true} instalacioneSeleccionadas={[props.numserieMaster]} dispositivo={[props.dispositivos]} scada={true} masterError={props?.masterError} />
            }
            {props.activeTabDevice === 4 &&
                <OnOff activeTab={props?.activeTabDevice} isMobile={props?.isMobile} />
            }
        </>
    )
}