import React, { useEffect } from "react"
import { Table } from '@mui/material'
import { toKilowPercent, toKiloWFecha } from 'utils/functionsforGraphs'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableCellHead } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const HistoricalTableBateryDesktop = (props) => {

    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    return (
        <>
            <>

                <div className="boder-table">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead style={{ borderRadius: '50px' }}>
                                <TableRow>
                                    <StyledTableCellHead align="center" >Nombre</StyledTableCellHead>
                                    {props?.daily === "hours" ?
                                        <StyledTableCellHead align="center" >
                                            <div>Hora</div>
                                        </StyledTableCellHead> :
                                        props?.daily === "days" ?
                                            <StyledTableCellHead align="center" >
                                                <div>Fecha</div>
                                            </StyledTableCellHead>
                                            : props?.daily === "months" ?
                                                <StyledTableCellHead align="center" >
                                                    <div>Mes</div>
                                                </StyledTableCellHead>
                                                : props?.daily === "years" ?
                                                    <StyledTableCellHead align="center">
                                                        <div>Año</div>
                                                    </StyledTableCellHead>
                                                    : null
                                    }
                                    <StyledTableCellHead align="center" >
                                        <div>Carga Máximo (%)</div>
                                    </StyledTableCellHead>
                                    <StyledTableCellHead align="center" >
                                        <div>Carga Mínimo (%)</div>
                                    </StyledTableCellHead>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props?.data && props?.data?.map((device, i) => {
                                    return (
                                        <>
                                            {props?.checked.indexOf(device.data.numserie) >= 0 || props?.activo === true ?
                                                <StyledTableRow key={i}>
                                                    <StyledTableCell >{device?.data.nick}</StyledTableCell>
                                                    <StyledTableCell align="center" >{toKiloWFecha(device?.data.datetime, props?.daily)}</StyledTableCell>
                                                    <StyledTableCell >{toKilowPercent(device?.data?.maxsoc) + " % "}</StyledTableCell>
                                                    <StyledTableCell >{toKilowPercent(device?.data?.minsoc) + " % "}</StyledTableCell>
                                                </StyledTableRow>
                                                : null
                                            }
                                        </>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

            </>

        </>
    )
}