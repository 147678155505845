import { ButtonMenu } from "components/Buttons/ButtonMenu"

import React from "react"

export const MenuInformesConsumo = ({ activeTab, seleccionarTab, graphVisible }) => {

    return (
        <>
            <ButtonMenu disabled={!graphVisible} tab={activeTab === 0} onClick={() => { seleccionarTab(0) }}>
                Potencia (kW)
            </ButtonMenu>
            <ButtonMenu disabled={!graphVisible} tab={activeTab === 1} onClick={() => { seleccionarTab(1) }}>
                Intensidad (A)
            </ButtonMenu>
            <>
                <ButtonMenu disabled={!graphVisible} tab={activeTab === 3} onClick={() => { seleccionarTab(3) }}>
                    Tensión (V)
                </ButtonMenu>
                <ButtonMenu tab={activeTab === 2} onClick={() => seleccionarTab(2)}>
                    Energía (kWh)
                </ButtonMenu>
            </>

        </>
    )
}