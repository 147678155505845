import React, { useState, useEffect } from 'react'
import { urls } from 'utils/constant'
import { Loader } from 'components/Loader/Loader.jsx'
import { headersScada } from 'services/headers'
import { useLocation } from 'react-router-dom'
import { result } from 'utils/functionsforGraphs'
import { Grid } from "@mui/material"
import { GraphsConsumption } from './GraphsConsumption/GraphsConsumption.jsx'
import { CurrentTableConsumption } from './Tables/CurrentTableConsumption.jsx'
import { HistoricalTableConsumption } from './Tables/HistoricalTableConsumption.jsx'
import house from 'assets/img/icons/consumption/house.svg'
import { ScadaDeviceHeader } from 'views/Scada/SelfConsumption/ScadaDeviceHeader.jsx'
import moment from 'moment'
import "views/Scada/SelfConsumption/styleSelfConsumption.scss"
import { Filter } from 'views/Scada/FilterScada/FilterScada.jsx'
import { useDebounce } from 'use-debounce'
import { convertDatetime } from 'utils/Utils.jsx'
import CalendarComponent from 'components/Calendar/CalendarComponent.jsx'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha.jsx'
import { CsvDownloader } from '../CsvDownloader.jsx'
import { isToday } from 'utils/Utils.jsx'
import { formatDate } from 'utils/Utils.jsx'

export const ScadaConsumption = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split('/')[4]
    const [deviceType, setDeviceType] = useState(7)
    const [historicalData, setHistoricalData] = useState([])
    const [loading, setLoading] = useState(true)
    const [finalData, setFinalData] = useState([])
    const [headersCsv, setHeadersCsv] = useState()
    const [selectedDevice, setSelectedDevice] = useState()
    const [consumption, setConsumption] = useState([])
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [checked, setChecked] = useState([])
    const [activo, setActivo] = useState(true)
    const [updatedList, setUpdatedList] = useState([])
    const [axis, setAxis] = useState(null)
    const [name, setName] = useState()
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectDaily, setSelectDaily] = useState("day")
    const toggleDevice = tab => setActiveTabDevice(tab)
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, (props?.daily === "hours" && isToday(props?.start) === true) ? { label: "Fecha", key: "fecha" } : { label: "Fecha", key: "datetime" }, { label: "Energía Consumida (kWh)", key: "eaconsdia" }];
    const fechaNow = moment(props?.now).format("DD-MM-YYYY")
    let historicalCSV = historicalData?.map(device => device.data)
    let FinalDataCSV = finalData?.map(device => device?.data)
    let finalDataCSV2 = FinalDataCSV?.map(device => {
        let obj = Object.assign({}, device?.consumo ? device?.consumo : device?.currentData)
        if (isToday(props?.start)) {
            obj.datetime = convertDatetime(obj?.datetime)
        }
        return obj
    })
    const [debouncedConsumption] = useDebounce(consumption, 200)
    let consumo = props?.childrenDevices?.filter(dev => dev.id_tipo_dispositivo === 7)


    const [dataPack, setDataPack] = useState({
        devices: [props?.data?.[0]?.devices?.[0]],
        deviceType: props?.data?.[0]?.deviceType,
        activeTabDevice: 0
    })

    const deviceExtractor = (serie, type) => {
        let res = serie?.filter(device => device.deviceType === type)
        return {
            devices: res?.[0]?.devices,
            deviceType: type,
            activeTabDevice: activeTabDevice
        }
    }


    let url

    useEffect(() => {
        setDataPack(deviceExtractor(props?.data, deviceType))
    }, [deviceType, props?.start, props?.daily, activeTabDevice])

    const fetchData = async (numserie, start, end) => {
        if (isToday(props?.start) && props?.daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idMaster}`
        }
        else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${props?.daily}`
        }
        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const arrayData = () => {
        setLoading(true)
        setHistoricalData([])
        let filterDeviceByType = props?.data?.filter(item => item?.deviceType === deviceType)
        if (finalData.length === 0 || props.today || filterDeviceByType[0]?.devices?.length > 0) {
            let primeraPosicion = true
            filterDeviceByType?.forEach(elm => {
                elm?.devices?.forEach(device => {
                    if (isToday(props?.start) && props.daily === "days") {
                        fetchData(device).then(item => {
                            let nick = consumo?.[0].nick
                            if (Number(consumo?.[0].id_tipo_dispositivo) === 7) {
                                setName(nick)
                            }
                            if (historicalData && historicalData?.[0]) {
                            } if (primeraPosicion) {
                                setFinalData([{ nick: item?.currentData?.nick ? item?.currentData?.nick : nick, id_tipo_dispositivo: item?.consumo ? 7 : item?.currentData.id_tipo_dispositivo, data: item, numserie: item?.consumo?.numserie ? item?.consumo?.numserie : item?.currentData?.numserie }])
                                setHistoricalData(prev => [...prev, { nick: item?.consumo?.nick, id_tipo_dispositivo: item?.consumo?.id_tipo_dispositivo, data: item?.currentData ? item.currentData : item.consumo, numserie: item?.consumo?.numserie, datetime: formatDate(item?.currentData ? item?.currentData?.datetime : item?.consumo?.datetime) }])
                                primeraPosicion = false
                            } else {
                                setFinalData(prev => [...prev, { nick: item?.currentData?.nick ? item?.currentData?.nick : nick, id_tipo_dispositivo: item?.currentData?.id_tipo_dispositivo, data: item, numserie: item?.consumo?.numserie ? item?.consumo?.numserie : item?.currentData?.numserie }])
                                setHistoricalData(prev => [...prev, { nick: item?.consumo?.nick, id_tipo_dispositivo: item?.consumo?.id_tipo_dispositivo, data: item?.currentData ? item.currentData : item.consumo, numserie: item?.consumo?.numserie, datetime: formatDate(item?.currentData ? item.currentData.datetime : item.consumo.datetime) }])
                            }
                        })
                    } else {
                        fetchData(device, props?.start, props?.end, props?.daily).then(item => {
                            item?.forEach(item2 => {
                                setHistoricalData(prev => [...prev, { nick: item2?.nick, data: item2, numserie: item2?.numserie }])
                            })
                        })
                    }
                })
            })
        }
        setLoading(false)
    }

    const finalCSV = finalDataCSV2?.filter(dev => updatedList?.includes(dev?.numserie))
    const historCSV = historicalCSV?.filter(dev => updatedList?.includes(dev?.numserie))

    useEffect(() => {
        arrayData()
    }, [deviceType, activeTabDevice, props?.end, props?.start, keySwitch])

    useEffect(() => {
        setConsumption([])
        if (isToday(props?.start) && props?.daily === "days") {
            const setObj = new Set();
            const uniqs = finalData?.map(device => result(finalData, device?.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device?.numserie)) {
                    setObj.add(device?.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setConsumption(noRepeat)
        } else {
            setConsumption(historicalData)
        }
    }, [finalData, historicalData])


    return (
        <Grid className="d-flex flex-column scada-component mt-3" item sm={12} lg={12} xl={12}>
            <ScadaDeviceHeader props={props} icon={house} setDeviceType={setDeviceType} type="Consumo"></ScadaDeviceHeader>
            <span className="filter-component-calendar">
                <div>
                    <CalendarComponent
                        maxDate={props?.end}
                        setStart={props?.setStart}
                        setEnd={props?.setEnd}
                        setDaily={props?.setDaily}
                        setSelectDaily={setSelectDaily}
                    />
                </div>
                <div className="ml-3 d-flex align-items-center">
                    <SelectorFecha
                        daily={props?.daily}
                        setDaily={props?.setDaily}
                        tipo={props?.daily}
                        setKeySwitch={setKeySwitch}
                        selectDaily={selectDaily}
                    />
                    {consumption && consumption?.length > 0 &&
                        <CsvDownloader
                            className="csvDonwloader"
                            today={isToday(props?.start)}
                            daily={props?.daily}
                            props={props}
                            historicalData={historCSV !== undefined && historicalData !== undefined ? historCSV : historicalData}
                            finalData={finalCSV !== undefined && finalData !== undefined ? finalCSV : finalData}
                            start={props?.start}
                            end={props?.end}
                            setHeadersCsv={setHeadersCsv}
                            headersCsv={headersCsv}
                        />
                    }
                </div>
            </span>
            <span className='listItem-componente'>
                <ul className="nav nav-tabs" >
                    <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 0 ? "active-button" : null} `} selected={activeTabDevice === 0} onClick={() => { toggleDevice(0); setDeviceType(7) }}>
                        Consumo
                    </li>
                    {props?.data?.filter(device => Number(device.deviceType) === 2).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 1 ? "active-button" : null} `} selected={activeTabDevice === 1} onClick={() => { toggleDevice(1); setDeviceType(2) }}>
                            CCM2
                        </li>
                    }
                    {props?.data?.filter(device => Number(device.deviceType) === 4).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 2 ? "active-button" : null} `} onClick={() => { toggleDevice(2); setDeviceType(4) }}>
                            CCM4
                        </li>
                    }
                    {props?.data?.filter(device => Number(device.deviceType) === 12).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt ${activeTabDevice === 3 ? "active-button" : null} `} onClick={() => { toggleDevice(3); setDeviceType(12) }}>
                            Enchufes
                        </li>
                    }
                </ul>
            </span>
            <span className="filter-scada-component">
                <Filter selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={setHeadersCsv}
                    headersCsv={headersCsv}
                    variableTableSelfConsumption={variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={loading}
                    setLoading={setLoading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props?.device}
                    setRefresh={props?.setRefresh}
                    activo={activo}
                    setActivo={setActivo}
                    data1={debouncedConsumption}
                    checked={checked}
                    setChecked={setChecked}
                    updatedList={updatedList}
                    setUpdatedList={setUpdatedList}
                    props={props}
                    daily={props?.daily}
                    isMobile={props?.isMobile}
                    setConsumption={setConsumption}
                    axis={axis}
                    deviceType={deviceType}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            </span>
            <div className="table-card ">
                {(consumption?.length === 0) ?
                    <Loader />
                    :
                    <>
                        {moment(props?.startt?.format('YYYY-MM-DD')).isAfter(props?.start?.format('YYYY-MM-DD')) || props?.daily !== "days" ?
                            <HistoricalTableConsumption isMobile={props?.isMobile} activo={activo} checked={updatedList} variableTableSelfConsumption={variableTableSelfConsumption} setHeadersCsv={setHeadersCsv} headersCsv={headersCsv} historicalData={historicalData} loading={loading} start={props?.start} daily={props?.daily} setDaily={props?.setDaily} data={props?.data} device={props.device} end={props?.end} childrenDevices={props.childrenDevices} deviceType={deviceType}
                                setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd} dataPack={dataPack}
                                setActivo={setActivo} data1={debouncedConsumption} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props} finalData={finalData} axis={axis} setAxis={setAxis} setConsumption={setConsumption} setFinalData={setFinalData}
                            />
                            :
                            <CurrentTableConsumption isMobile={props?.isMobile} activo={activo} setHeadersCsv={setHeadersCsv} checked={updatedList} headersCsv={headersCsv} daily={props?.daily} setFinalData={setFinalData} finalData={finalData} deviceType={deviceType} data={props?.data} loading={loading} device={props.device} start={props?.start} end={props?.end} setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} variableTableSelfConsumption={variableTableSelfConsumption} historicalData={true} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd}
                                setActivo={setActivo} data1={debouncedConsumption} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props} setConsumption={setConsumption} dataPack={dataPack}
                                consumption={consumption} axis={axis} deviceconsumption={consumo} />
                        }
                    </>
                }
            </div>
            <div className="graph-card ">
                <GraphsConsumption updatedList={updatedList} checked={checked} data={props?.data} deviceType={deviceType} device={props?.devices}
                    start={props?.start} end={props?.end} groupData={props?.groupData} setStart={props?.setStart} now={props?.now} dataPack={dataPack}
                    setEnd={props?.setEnd} setgroupData={props?.setgroupData} titleButton={props?.titleButton} setTitleButton={props?.setTitleButton}
                    handleClickRight={props?.handleClickRight} handleClickLeft={props?.handleClickLeft} daily={props?.daily} setDaily={props?.setDaily}
                    historicalData={historicalData} setHistoricalData={setHistoricalData} setRefresh={props?.setRefresh} refresh={props?.refresh} finalData={finalData}
                    axis={axis} setAxis={setAxis} loading={loading} name={name} consumption={consumption} devices={props?.childrenDevices.filter(dev => dev.id_tipo_dispositivo === deviceType)} />
            </div>
        </Grid>
    )
}