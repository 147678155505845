import React from 'react'
import { Line } from 'react-chartjs-2'

export const GraphsLine = ({ data, options, labels }) => {
    const type = data?.[0]?.label?.includes('Intensidad') ? ' A' : data?.[0]?.label?.includes('Tensión') ? 'V' : ' kW'
    
    const LineOptions =
    {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    font: {
                        size: 9
                    },
                    callback: function (value) {
                        return value.toFixed(2) + type
                    }
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
                display: true,
                position: 'bottom',
                align: 'center'
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label
                        return label + ' : ' + new Intl.NumberFormat().format(context.parsed.y) + type
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    speed: 0.5,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1
                    },
                    mode: 'x'
                }
            }
        }
    }

    const dataFilter = {
        labels,
        datasets: data
    }

    return (
        <div>
            <Line
                style={{ width: '100%', height: '20em' }}
                data={dataFilter}
                options={options ? options : LineOptions}
            />
        </div>
    )
}
