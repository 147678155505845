import React, { useState, useEffect } from 'react'
import { Table } from '@mui/material'
import { toKiloW, toKiloWFecha } from 'utils/functionsforGraphs'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { StyledTableCell } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const HistoricalTableConsumptionMobile = (props) => {
    const [data1, setData1] = useState()

    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    useEffect(() => {
        if (props.data) {
            let set = new Set(props.data.map(JSON.stringify))
            let arrSinDuplicaciones = Array.from(set).map(JSON.parse);
            setData1(arrSinDuplicaciones)
        }
    }, [props.data])

    return (
        <div >
            {data1 && data1?.map((device, i) => {
                return (
                    <>
                        {(props?.checked?.indexOf(device?.data?.numserie) >= 0 || props?.activo === true) &&
                            <TableContainer className="boder-table">
                                <Table aria-label="customized table">
                                    <TableHead >
                                        <TableRow className="title-mobile-table">
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <> <StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[0]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{device?.data?.nick}</StyledTableCell>
                                        </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[1]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile">{toKiloWFecha(device?.data.datetime, props?.daily)}</StyledTableCell>
                                            </StyledTableRow>
                                            <StyledTableRow key={i} className="data-row-mobile">
                                                <StyledTableCell className="data-table-mobile title">{props?.headTable?.[2]}</StyledTableCell>
                                                <StyledTableCell className="data-table-mobile data">{toKiloW(device?.data?.eaconsdia)}</StyledTableCell>
                                            </StyledTableRow>
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </>
                )
            })}
        </div>
    )
}