import React, { useEffect, useState } from "react"
import { SketchPicker } from 'react-color'
import { BulbAsyncRequest } from "./BulbAsyncRequest"

const Colors = (props) => {
    let bulb_id = props?.device?.numserie?.toString().substring(12, 16)
    let bulb_SN = `BUL_00${bulb_id}`
    const [color, setColor] = useState()
    const [a, setA] = useState(color?.a)
    const [h, setH] = useState(color?.h)
    const [v, setV] = useState(color?.v)
    const [s, setS] = useState(color?.s)
    const [command, setCommand] = useState()

    useEffect(() => {
        setH(color?.h)
        setA(color?.a)
        setV(color?.v)
        setS(color?.s)
        if (color?.h !== h) {
            setCommand(250)
            setColor(h)
        } else if (color?.s !== s) {
            setCommand(251)
            setColor(s)
        } else if (color?.v !== v) {
            setCommand(252)
            setColor(v)
        } else if (color?.a !== a) {
            setCommand(253)
            setColor(a)
        }
    }, [color])

    const handleSelectedColor = () => {
        BulbAsyncRequest(props.device.master_serial, bulb_SN, color, command)
    }

    return (
        <div>
            <div onMouseUp={() => handleSelectedColor()}>
                <SketchPicker
                    color={color}
                    onChange={(e) => setColor(e.hsv)}
                />
            </div>
        </div>
    )
}

export default Colors
