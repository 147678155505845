import React from "react"
import { Select } from "@mui/material"

export const SelectStyled = ({ value, onChange, children, className, id, disabled, label, labelId, defaultValue }) => {

  return (
    <Select id={`select${id}`} defaultValue={defaultValue} className={`style-textfield-text ${className}`} label={label} labelId={labelId} value={value} onChange={onChange} disabled={disabled}>
      {children}
    </Select>
  )
}