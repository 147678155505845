import React, { useContext, useEffect, useState } from 'react'
import { useLocation, Link } from "react-router-dom"
import { Table } from "@mui/material"
import { ImageDevice } from 'utils/ImageDevice/ImageDevice'
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices.jsx"
import moment from "moment"
import { urls } from "utils/constant.jsx"
import { headersScada } from 'services/headers'
import { toKiloW } from 'utils/functionsforGraphs'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { ordenarDatos } from 'utils/Utils'
import { Loader } from "components/Loader/Loader"
import "views/Principal/StylePrincipal.scss"
import { StyledTableCellOrdenado } from 'components/Table/TableStyle'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { StyledTableRow } from 'components/Table/TableStyle'
import { dispositivos } from 'utils/constant'
import { showDate } from 'utils/Utils'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { OpenToScada } from 'utils/Utils'
import { GlobalContext } from 'context/GlobalContext'

export const RegisterDataTable = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idProject = location.pathname.split("/")[3]
    const [loading, setLoading] = useState(false)
    const [reloadFetch, setReloadFetch] = useState(1)
    const [registerData, setRegisterData] = useState([])
    const [data3, setData3] = useState()
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
    const { actualProject } = useContext(GlobalContext)

    const fetchData = async (master) => {
        let url
        if (master?.infoDevice?.id_tipo_dispositivo !== dispositivos.ccmabt) {
            url = `getHistoricalData/${master?.infoDevice?.numserie}?${master?.infoDevice?.cloud ? '' : `idCCMaster=${master?.infoDevice?.id_padre}`}&iniDate=${props.start.format("YYYY-MM-DD")}&endDate=${props.end.format("YYYY-MM-DD")}&groupDate=days`
        } else {
            url = `getHistoricalDataScada/${master?.infoDevice?.numserie}?idCCMaster=${master?.infoDevice?.id_padre ? master?.infoDevice?.id_padre : master?.infoDevice?.numserie}&iniDate=${props.start.format("YYYY-MM-DD")}&endDate=${props.end.format("YYYY-MM-DD")}&groupDate=days&scadaType=mabt&typeMabt=total`
        }
        if (master && master?.infoDevice?.id_tipo_dispositivo !== 7 && master?.infoDevice?.id_tipo_dispositivo !== 12 && master?.infoDevice?.id_tipo_dispositivo !== 11) {
            try {
                const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
                const json = await res.json()
                if (res.status !== 204) {
                    if (master?.infoDevice?.id_tipo_dispositivo !== dispositivos.ccmabt) {
                        let info = master.infoDevice
                        info.permissions = info?.permissions?.permissions
                        return {
                            nick: master.nick,
                            nick_master: master.nick_master ? master.nick_master : master.nick,
                            eaconsdia: json?.[0]?.eaconsdia,
                            eagendia: json?.[0]?.eagendia,
                            id_tipo_dispositivo: master?.infoDevice?.id_tipo_dispositivo,
                            infoDevice: info
                        }
                    } else {
                        return {
                            nick: master.nick,
                            nick_master: master.nick_master,
                            eaconsdia: json?.[0]?.eaconsdia,
                            eagendia: json?.[0]?.eagendia,
                            data: json[0],
                            id_tipo_dispositivo: json?.infoDevice?.id_tipo_dispositivo,
                            infoDevice: master.infoDevice
                        }
                    }
                } else {
                    return []
                }
            } catch (err) {
                console.error("ERROR. API Scada", err)
            }
        }
    }

    const cargarDatosTabla = async () => {
        setLoading(true)
        try {
            let datos = await Promise.all(props?.data?.flatMap(master => { return fetchData(master) }))
            datos = datos.filter(elemento => elemento !== undefined)
            setRegisterData(datos)
        } catch (error) {
            setLoading(false)
            console.error("Error al guardar los datos:", error)
        }
        setLoading(false)
    }

    useEffect(() => {
        cargarDatosTabla()
    }, [props?.start, props?.end])

    useEffect(() => {
        if (registerData) {
            let set = new Set(registerData.map(JSON.stringify))
            let arrSinDuplicaciones = Array.from(set).map(JSON.parse)
            arrSinDuplicaciones = arrSinDuplicaciones.filter(item => item?.nick)
            setData3(arrSinDuplicaciones)
        }
    }, [registerData])

    let variableTableSelfConsumption =
        data3 && data3?.[0]?.infoDevice?.id_tipo_dispositivo === dispositivos.ccmabt ? [
            { label: "Dispositivo", key: "nick" },
            { label: "Fecha", key: "fecha" },
            { label: "fecha)", key: "fecha" },
            { label: "P. Activa R Max (kW)", key: "pac1_max" },
            { label: "P. Activa R Min (kW)", key: "pac1_min" },
            { label: "P. Activa S Max (kW)", key: "pac2_max" },
            { label: "P. Activa S Min (kW)", key: "pac2_min" },
            { label: "P. Activa T Max (kW)", key: "pac3_max" },
            { label: "P. Activa T Min (kW)", key: "pac3_min" },
            { label: "Corriente R Max", key: "i1_max" },
            { label: "Corriente S Max", key: "i2_max" },
            { label: "Corriente T Max", key: "i3_max" },
            { label: "Tensión R Max (V)", key: "v1_max" },
            { label: "Tensión S Max (V)", key: "v2_max" },
            { label: "Tensión T Max (V)", key: "v3_max" },] :
            [
                { label: "Dispositivo", key: "nick" },
                { label: "Fecha", key: "fecha" },
                { label: "Energía Consumida (kWh)", key: "eaconsdia" },
                { label: "Energía Exportada (kWh)", key: "eagendia" }]


    useEffect(() => {
        props?.setCsvData([])
        data3?.forEach(master => {
            if (data3[0]?.infoDevice?.id_tipo_dispositivo === dispositivos.ccmabt) {
                props?.setCsvData(prev => [...prev, {
                    "Dispositivo": master?.nick,
                    "Instalación": master?.nick_master,
                    "fecha": master?.data?.fecha,
                    "P. Activa R Max (kW)": master?.data?.pac1_max,
                    "P. Activa R Min (kW)": master?.data?.pac1_min,
                    "P. Activa S Max (kW)": master?.data?.pac2_max,
                    "P. Activa S Min (kW)": master?.data?.pac2_min,
                    "P. Activa T Max (kW)": master?.data?.pac3_max,
                    "P. Activa T Min (kW)": master?.data?.pac3_min,
                    "Corriente R Max (A)": master?.data?.i1_max,
                    "Corriente S Max (A)": master?.data?.i2_max,
                    "Corriente T Max (A)": master?.data?.i3_max,
                    "Tensión R Max (V)": master?.data?.v1_max,
                    "Tensión S Max (V)": master?.data?.v2_max,
                    "Tensión T Max (V)": master?.data?.v3_max,
                }
                ])
            } else {
                props?.setCsvData(prev => [...prev, {
                    "Dispositivo": master?.nick,
                    "Instalación": master?.nick_master,
                    "Energía Consumida (kWh)": master?.eaconsdia,
                    "Energía Exportada (kWh)": master?.eagendia
                }])
            }
        })
    }, [data3])

    useEffect(() => {
        props?.setCsvHeaders(variableTableSelfConsumption)
    }, [])


    return (
        <>
            {loading ?
                <div className="justify-content-center">
                    <Loader />
                </div>
                :
                <TableContainer>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead >
                            <TableRow style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                                {data3 && (data3[0]?.infoDevice?.id_tipo_dispositivo !== dispositivos.ccmabt) &&
                                    <StyledTableCellHead align="center" style={{ padding: "5px 0px" }}>

                                    </StyledTableCellHead>
                                }
                                <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(data3, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig} >

                                </StyledTableCellOrdenado>
                                <StyledTableCellOrdenado align="center" celda={'nick'} funcion={() => { ordenarDatos(data3, sortConfig, setSortConfig, 'nick') }} sortConfig={sortConfig} >
                                    Dispositivo
                                </StyledTableCellOrdenado>
                                <StyledTableCellOrdenado align="center" celda={'nick_master'} funcion={() => { ordenarDatos(data3, sortConfig, setSortConfig, 'nick_master') }} sortConfig={sortConfig} >
                                    Instalacion
                                </StyledTableCellOrdenado>
                                <StyledTableCellHead align="center" >
                                    Fecha
                                </StyledTableCellHead >
                                {data3 && (data3?.[0]?.infoDevice?.id_tipo_dispositivo === dispositivos.ccmabt) &&
                                    <>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> R Max (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> R Min (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> S Max (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> S Min (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> T Max (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                P. Activa <br /> T Min (kW)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> R Max (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> S Max (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Corriente <br /> T Max (A)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión <br /> R Max (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión <br /> S Max (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center">
                                            <div className="d-flex justify-content-center align-items-center">
                                                Tensión  <br /> T Max (V)<br />
                                            </div>
                                        </StyledTableCellHead>
                                    </>
                                }
                                <StyledTableCellHead align="center">
                                    Energía <br /> Importada (kWh)
                                </StyledTableCellHead>
                                <StyledTableCellHead align="center">
                                    Energía <br /> Exportada (kWh)
                                </StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data3 && data3?.map((master, i) => {
                                return (
                                    <>
                                        <StyledTableRow>
                                            {(master?.infoDevice?.id_tipo_dispositivo !== dispositivos.ccmabt) &&
                                                <StyledTableCell align="center" >
                                                    <DropDownDevices
                                                        setLoading={setLoading}
                                                        device={master.infoDevice}
                                                        updateCcM={master?.data?.currentData}
                                                        masterPermission={props?.masterPermission}
                                                        active={master.infoDevice?.activo}
                                                        instalacion={master}
                                                        reloadFetch={reloadFetch}
                                                        setReloadFetch={setReloadFetch}

                                                        modalAdvance={true}
                                                        index={i}
                                                        start={props.start}
                                                        end={props.end} />
                                                </StyledTableCell>
                                            }
                                            <StyledTableCell align="center"  >
                                                <ImageDevice id_type_device={master.infoDevice?.id_tipo_dispositivo} width={20} />
                                            </StyledTableCell>
                                            <StyledTableCell align="center" >
                                                <Link
                                                    to="#"
                                                    onMouseDown={(e) => {
                                                        if (e.button === 0 || e.button === 1) {
                                                            e.preventDefault()
                                                            window.open(OpenToScada(actualProject.category, idProject, master?.infoDevice?.id_padre ? master?.infoDevice?.id_padre : master?.infoDevice?.numserie))
                                                        }
                                                    }}
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    <HtmlTooltip style={{ textAlign: "center" }} title={<span>{master.nick}</span>} arrow classes={{ tooltip: "p-3" }}>
                                                        <span> {master?.nick?.length > 20 ? master.nick.substr(0, 19) + '...' : master?.nick}</span>
                                                    </HtmlTooltip>
                                                </Link>
                                            </StyledTableCell >
                                            <StyledTableCell align="center">
                                                <div className='flex justify-center'>
                                                    <Link
                                                        to="#"
                                                        onMouseDown={(e) => {
                                                            if (e.button === 0 || e.button === 1) {
                                                                e.preventDefault()
                                                                window.open(OpenToScada(actualProject.category, idProject, master?.infoDevice?.id_padre ? master?.infoDevice?.id_padre : master?.infoDevice?.numserie))
                                                            }
                                                        }}
                                                        target="_blank" rel="noopener noreferrer">
                                                        <HtmlTooltip style={{ textAlign: "center" }} title={<span>{master.nick_master}</span>} arrow classes={{ tooltip: "p-3" }}>
                                                            <span>{master.nick_master?.length > 20 ? master.nick_master?.substr(0, 19) + "..." : master?.nick_master}</span>
                                                        </HtmlTooltip>
                                                    </Link>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <span className="text-nowrap">
                                                    {master?.infoDevice?.id_tipo_dispositivo === dispositivos.ccmabt ? (master?.data?.fecha ? showDate(master?.data?.fecha) : '-') : moment(props.start).format("DD-MM-YYYY")}
                                                </span>
                                            </StyledTableCell>
                                            {
                                                master?.infoDevice?.id_tipo_dispositivo === dispositivos.ccmabt &&
                                                <>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac1_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac1_min)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac2_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac2_min)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac3_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.pac3_min)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.i1_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.i2_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.i3_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.v1_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.v2_max)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        {toKiloW(master?.data?.v3_max)}
                                                    </StyledTableCell>
                                                </>
                                            }
                                            <StyledTableCell align="center" >
                                                <div style={{ padding: "16px" }}>
                                                    <span className="text-nowrap" >
                                                        {toKiloW(master?.eaconsdia)}
                                                    </span>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <span className="text-nowrap">
                                                    {toKiloW(master?.eagendia)}
                                                </span>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </>
                                )
                            })}
                        </TableBody>
                    </Table >
                </TableContainer >
            }
        </>
    )
}
