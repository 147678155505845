import React, { useState, useEffect, useRef } from 'react'
import InfiniteScroll from "react-infinite-scroll-component"
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableCellOrdenado, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { Loader } from "components/Loader/Loader"
import { headersScada } from 'services/headers'
import { nombreDispositivo, nombreAlarma, ordenarDatos, getNameByVariable, getLineByVariable } from 'utils/Utils'
import { IconEditPencil, IconDeleteAlarm, IconOk, IconXMark } from 'utils/icon-centrocontrol'
import { urls, dispositivos, valueMax } from "utils/constant"
import { EliminarAlarma } from '../DeleteAlarm/EliminarAlarma'
import { EditarAlarma } from '../EditAlarm/EditarAlarma'

/**
 * @param {{ token: string, actualizarAlarma: Boolean, setActualizarAlarma: Function, instalacioneSeleccionadas: Boolean, tipoAlarma: Number, tipoDispositivo: Number }} 
 * @param token Token necesario de la llamada
 * @param actualizarAlarma Estado que indica si se deben recargar la lista de alarmas
 * @param setActualizarAlarma Funcion apara cambiar el estado de actualizar las alarmas
 * @param instalacioneSeleccionadas Instalaciones de las que se gestionan las alarmas
 * @param tipoAlarma Estado que guarda la informacion del tipo de alarma
 * @param tipoDispositivo Estado que guarda la informacion del tipo de dispositivo
 * @returns devuelve la tabla con las configuraciones de las alarmas
 */
export const TablaConfigAlarms = ({ loadingFetch, actualizarAlarma, setActualizarAlarma, token, instalacioneSeleccionadas, tipoAlarma, tipoDispositivo }) => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [filas, setFilas] = useState(30)
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [configuracion, setConfiguracion] = useState(false)
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
    const [tableHeight, setTableHeight] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    const tableRef = useRef(null)
    const divRef = useRef(null)

    /** 
     * @returns Devuelve las configuraciones de las alarmas de las instalaciones seleccionadas
     */
    const fetchDataConfigAlarms = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + 'alarms/getConfiguredAlarms', { method: 'POST', headers: headersScada(token), body: JSON.stringify({ 'devices': instalacioneSeleccionadas }) })
            if (res.status === 200) {
                let json = await res.json()
                if (tipoAlarma !== undefined && tipoAlarma !== -1) {
                    json = json?.filter(ele => ele?.alarm_id === tipoAlarma)
                }
                if (tipoDispositivo !== undefined && tipoDispositivo !== -1) {
                    json = json?.filter(ele => ele?.device_type === tipoDispositivo)
                }
                let datos = []
                json.forEach((ele) => {
                    let existe = false
                    datos.forEach((dat) => {
                        if (dat.alarm_id === ele.alarm_id && dat.device_type === ele.device_type && dat.numInstalacion === ele.numInstalacion && dat.threshold_time === ele.threshold_time && dat?.upper_value === ele?.upper_value && dat?.lower_value === ele?.lower_value && dat?.variable === ele?.variable) {
                            existe = true
                            dat?.numserie?.push(ele?.numserie)
                            dat?.nick?.push(ele?.nick)
                            dat?.mabt_output?.push(ele?.mabt_output)
                            dat?.id_alarm?.push(ele?.id_alarm)
                        }
                    })
                    if (!existe) {
                        if (ele?.lower_value !== undefined) {
                            datos?.push({ id_alarm: [ele?.id_alarm], expected_value: ele?.expected_value, upper_value: ele?.upper_value, lower_value: ele?.lower_value, variable: ele?.variable, alarm_id: ele?.alarm_id, device_type: ele?.device_type, enabled: ele?.enabled, instalacion: ele?.instalacion, numInstalacion: ele?.numInstalacion, nick: [ele?.nick], numserie: [ele?.numserie], threshold_time: ele?.threshold_time, mabt_output: [ele?.mabt_output] })
                        } else {
                            datos?.push({ alarm_id: ele?.alarm_id, device_type: ele?.device_type, enabled: ele?.enabled, instalacion: ele?.instalacion, numInstalacion: ele?.numInstalacion, nick: [ele?.nick], numserie: [ele?.numserie], threshold_time: ele?.threshold_time, mabt_output: [ele?.mabt_output] })
                        }
                    }
                })
                if (datos?.length <= filas) {
                    setHasMore(false)
                }
                setData(datos)
            } else {
                setData([])
                setHasMore(false)
            }
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        } finally {    
            setLoading(false)
        }
    }

    const timeoutHandler = () => {
        if (isVisible) {
            setIsVisible(false)
            if (filas + 10 < data.length) {
                setFilas(filas + 10)
                setIsVisible(false)
            } else if (filas <= data.length) {
                setFilas(data.length)
                setHasMore(false)
                setIsVisible(false)
            }
        }
    }

    const handleScroll = () => {
        const element = divRef?.current?.getBoundingClientRect()?.bottom - divRef?.current?.getBoundingClientRect()?.top - 30
        const currentPosition = document?.documentElement?.scrollTop + window?.innerHeight
        if (currentPosition >= element && hasMore) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    useEffect(() => {
        setFilas(30)
        fetchDataConfigAlarms()
    }, [instalacioneSeleccionadas, tipoAlarma, tipoDispositivo, actualizarAlarma])

    useEffect(() => {
        if (tableRef.current && !loading) {
            setTableHeight(tableRef.current.offsetHeight)
        }
    }, [filas, loading])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <>
            {!loading && !loadingFetch && (!data || data.length === 0) &&
                <h4 align='center' className='mt-4'>
                    No hay ninguna configuración de alarma
                </h4>
            }
            {!loading && !loadingFetch && data && data.length > 0 &&
                <div className='d-flex' >
                    <TableContainer>
                        <InfiniteScroll
                            style={{ minHeight: tableHeight + 30 }}
                            dataLength={filas}
                            next={timeoutHandler()}
                            hasMore={hasMore}
                            loader={!isVisible && <h4 className='flex justify-center'>Cargando más alarmas...</h4>}
                        >
                            <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
                                    <TableRow>
                                        <StyledTableCellOrdenado align="center" celda={'instalacion'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'instalacion') }} sortConfig={sortConfig}>Instalación</StyledTableCellOrdenado>
                                        <StyledTableCellOrdenado align="center" celda={'device_type'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'device_type') }} sortConfig={sortConfig}>Tipo Dispositivo</StyledTableCellOrdenado>
                                        <StyledTableCellHead align="center" celda={'nick'}>Dispositivo</StyledTableCellHead>
                                        <StyledTableCellOrdenado align="center" celda={'alarm_id'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'alarm_id') }} sortConfig={sortConfig}>Tipo Alarma</StyledTableCellOrdenado>
                                        <StyledTableCellOrdenado align="center" celda={'threshold_time'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'threshold_time') }} sortConfig={sortConfig}>Configuración</StyledTableCellOrdenado>
                                        <StyledTableCellOrdenado align="center" celda={'enabled'} funcion={() => { ordenarDatos(data, sortConfig, setSortConfig, 'enabled') }} sortConfig={sortConfig}>Habilitado</StyledTableCellOrdenado>
                                        <StyledTableCellHead align="center" celda={'numserie'}>Acciones</StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data && data.length > 0 &&
                                        data?.slice(0, filas).map((configuracion, i) => {
                                            return (
                                                <StyledTableRow key={i} style={{ padding: "0px", margin: "0px" }}>
                                                    <StyledTableCell align="left" style={{ maxWidth: '15em' }}>
                                                        {configuracion?.instalacion}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {nombreDispositivo(configuracion?.device_type)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left" style={{ maxWidth: '13em' }}>
                                                        {configuracion?.nick?.sort()?.map((nick, i) => {
                                                            if (i + 1 === configuracion?.nick?.length) {
                                                                return nick ? nick : (configuracion?.device_type === dispositivos.ccmabtv ? 'VTN' : 'Sin nombre' + (configuracion?.mabt_output[i] ? ' ' + configuracion?.mabt_output[i] : ''))
                                                            } else {
                                                                return nick ? nick + ", " : (configuracion?.device_type === dispositivos.ccmabtv ? 'VTN' : 'Sin nombre' + (configuracion?.mabt_output[i] ? ' ' + configuracion?.mabt_output[i] : '') + ', ')
                                                            }
                                                        })}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {nombreAlarma(configuracion?.alarm_id)}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {configuracion?.lower_value ? 
                                                            <div className='text-align-center'>
                                                                <div>Variable: {getNameByVariable(configuracion?.variable, configuracion?.device_type, true)} {getLineByVariable(configuracion?.variable, configuracion?.device_type, true)}</div>
                                                                <div>Valor mín: {configuracion?.lower_value === valueMax ? '-' : configuracion?.lower_value}</div>
                                                                <div>Valor max: {configuracion?.upper_value === valueMax ? '-' : configuracion?.upper_value}</div>
                                                                <div>Valor esperado: {configuracion?.expected_value === valueMax ? '-' : configuracion?.expected_value}</div>
                                                            </div>
                                                        :
                                                            <div className='text-align-center'>
                                                                <div>Temporización: {configuracion?.threshold_time} (Min)</div>
                                                            </div>
                                                        }
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <div className='flex-center justify-center'>
                                                            {configuracion?.enabled ?
                                                                <IconOk />
                                                            :
                                                                <IconXMark />
                                                            }
                                                        </div>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <Grid container columns={15}>
                                                            <Grid item xs={2} md={0} lg={0} xl={2} />
                                                            <Grid item xs={5} md={15} lg={6} xl={5}>
                                                                <ButtonStyledIcon className='boton-edit-alarm' title='Editar' onClick={() => { setConfiguracion(configuracion); setModalEditar(true) }}>
                                                                    <IconEditPencil className='boton-edit-hijo' />
                                                                </ButtonStyledIcon>
                                                            </Grid>
                                                            <Grid item xs={1} md={0} lg={1} xl={1} />
                                                            <Grid item xs={5} md={15} lg={6} xl={5}>
                                                                <ButtonStyledIcon className='boton-delete-alarm' title='Borrar' onClick={() => { setConfiguracion(configuracion); setModalEliminar(true) }}>
                                                                    <IconDeleteAlarm className='boton-delete-hijo' />
                                                                </ButtonStyledIcon>
                                                            </Grid>
                                                            <Grid item xs={2} md={0} lg={0} xl={2} />
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </TableContainer>
                    <div ref={divRef}></div>
                </div>
            }
            {(loading || loadingFetch) &&
                <div className="justify-content-center">
                    <Loader/>
                </div>
            }    
            {modalEliminar && <EliminarAlarma setActualizarAlarma={setActualizarAlarma} token={token} configuracion={configuracion} modalEliminar={modalEliminar} setModalEliminar={setModalEliminar} />}
            {modalEditar && <EditarAlarma data={data} setActualizarAlarma={setActualizarAlarma} token={token} configuracion={configuracion} modalEditar={modalEditar} setModalEditar={setModalEditar} />}
        </>
    )
}