import React from "react"
import { Grid } from "@mui/material"
import MarkerClusterer from "views/MapCentroControl/google/MarkerClusterer"
import Marker from "views/MapCentroControl/google/Marker"
import Map from "views/MapCentroControl/google/Map"

const Maps = ({ places, zoom, className, onClickMarker }) => {

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12} className={`${className} mapaConfiguracion-container`}>
        <Map
          zoom={zoom}
          center={{ lat: places[0]?.lat ? places[0]?.lat : 40, lng: places[0]?.lng ? places[0]?.lng : 10 }}
          events={{ onBoundsChangerd: arg => arg }}>
          <MarkerClusterer>
            {places.map((position, index) => {
              return (
                <Marker key={'marca' + index} title={"Instalación: " + position.title} position={{ lat: position.lat, lng: position.lng }} events={{ onClick: () => onClickMarker(position.ns) }} />
              )
            })}
          </MarkerClusterer>
        </Map>
      </Grid>
    </Grid>
  )
}
export default Maps
