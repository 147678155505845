import React from "react"
import { Grid } from "@mui/material"

export const MenuMabt = ({activeTab, seleccionarTab}) => {
    
    return (
        <Grid container className="menu-mabt">
            <Grid item xs={12} md={12} lg={12} xl={12} className="card-list-tab-mabt">
                <div selected={activeTab === 0} onClick={() => { seleccionarTab(0) }}>
                    <span className={activeTab === 0 ? "list-item-icon active-button" : "list-item-icon"} >
                        Informe Total
                    </span>
                </div>
                <div selected={activeTab === 1} onClick={() => { seleccionarTab(1) }}>
                    <span className={activeTab === 1 ? "list-item-icon active-button" : "list-item-icon"} >
                        Informe Medidores
                    </span>
                </div>
            </Grid>
        </Grid>
    )
}
