import { StyleSheet } from "@react-pdf/renderer";
 
export const styles = StyleSheet.create({
    page: {
      padding: 2,
      fontSize: 10,
      flexDirection: 'column',
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    table: {
      display: 'table',
      width: 'auto',
      textAlign: 'left',
      margin: '0 auto',
    },
    tableHeader: {
      flexDirection: 'row',
      backgroundColor: '#e0e0e0',
    },
    tableTitle: {
      fontSize: 12,
      marginTop: 15,
      marginBottom: 15,
      textAlign: 'center',
    },
    tableTitleMaster: {
      fontSize: 14,
      marginTop: 15,
      textAlign: 'center',
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCell: {
      fontSize: 5,
      width: '30px',
      textAlign: 'center',
      padding: 1,
    },
    tableColHeader: {
      width: '30px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#e0e0e0',
      textAlign: 'center',
      paddingTop: '6px',
    },
    tableCol: {
      width: '30px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 1,
    },
    dateCol: {
      width: '40px',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      backgroundColor: '#f5f5f5',
      textAlign: 'center',
      padding: '4px 4px',
    },
    redEvent: {
      backgroundColor: '#fcdede',
      marginTop: 3,
      height: "8px",
      color: '#E0041D',
      textAlign: 'center',
    },
    greenEvent: {
      color: 'green',
      textAlign: 'center',
    },
    greenLine: {
      backgroundColor: '#b7f7b7',
      marginTop: 3,
      color: "#017D1B",
      height: "8px",
      textAlign: 'center',
    },
    noDataContainer: {
      flex: 1,
    },
    noDataText: {
      textAlign: 'center',
      fontSize: 14,
      marginTop: 15,
      marginBottom: 15,
    },
  })