import React, { useEffect } from 'react'
import { Grid, FormControlLabel, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableCellHead, StyledTableRow } from 'components/Table/TableStyle'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { dispositivos } from 'utils/constant'
import "../EditInstall/EditInstall.scss"

/**
 * @param {{ option: number, subOption: number, configOrder: [{}], setConfigOrder: function, copyData: function, iTDefine: function, compareOrder: function, apply:boolean }} 
 * @param option Valor que establece la opción seleccionada.
 * @param subOption Valor que establece la sub opción seleccionada.
 * @param configOrder copia de configData que se irá modificando según se editen los datos, para enviarlo al final del proceso.
 * @param setConfigOrder función que setea configOrder.
 * @param copyData función que hace copia de configData cuando se quiere resetear configOrder
 * @param iTDefine función que traduce la subOpcion al tipo de planta que corresponde.
 * @returns Devuelve tabla de configuración de planta.
 */
export const EditPlant = ({
    option,
    subOption,
    configOrder,
    setConfigOrder,
    copyData,
    setApply,
    iTDefine,
    compareOrder,
    apply
}) => {

    /**
     * @description compara una nueva copia de la respuesta original con el objeto que estamos configurando
     * para mandar la petición de cambio.
     */
    const comparer = () => {
        let copia = copyData()
        let aux = true
        configOrder.forEach((device, key) => {
            if (device.tipo !== copia[key].tipo
                || device.nick !== copia[key].nick
                || device.potencia_contratada !== copia[key].potencia_contratada
                || device.activo !== copia[key].activo
                || device.inst_type !== copia[key].inst_type
            ) {
                aux = false
            }
        })
        setApply(aux)
    }

    useEffect(() => {
        comparer()
    }, [configOrder])

    /**
     * @description Función que marca o desmarca la opción "PRODUCCIÓN" para
     * el dispositivos indicado
     * @param {*} event evento recogido del input check
     * @param {*} index posición del elemento correspondiente dentro de 
     * configOrder
     */
    const productionMarker = (event, index) => {
        let aux = []
        configOrder.forEach((device) => {
            let obj = Object.assign({}, device)
            aux.push(obj)
        })
        if (event.target.checked === false) {
            aux[index].tipo = null
        } else {
            aux[index].tipo = 'produccion'
            aux[index].activo = 1
        }
        setConfigOrder(aux)
    }

    /**
     * @description Función que marca o desmarca la opción "ACTIVO" para
     * el dispositivos indicado
     * @param {*} event evento recogido del input check
     * @param {*} index posición del elemento correspondiente dentro de 
     * configOrder
     */
    const activeMarker = (event, index) => {
        let aux = []
        configOrder.forEach((device) => {
            let obj = Object.assign({}, device)
            aux.push(obj)
        })
        if (event.target.checked === false) {
            aux[index].activo = 1
        }
        else {
            aux[index].activo = 0
            aux[index].tipo = null
        }
        setConfigOrder(aux)
    }

    /**
     * @description Función que marca o desmarca la opción "CONSUMO" para
     * el dispositivos indicado
     * @param {*} event evento recogido del input check
     * @param {*} index posición del elemento correspondiente dentro de 
     * configOrder
     */
    const redMarker = (event, index) => {
        let aux = []
        configOrder.forEach((device) => {
            let obj = Object.assign({}, device)
            aux.push(obj)
        })
        if (event.target.checked === false) {
            aux[index].tipo = null
        } else {
            aux[index].tipo = 'red'
            aux[index].activo = 1
        }
        setConfigOrder(aux)
    }

    /**
     * UseEffect que está atento a subOption, y modifica configrOrder en 
     * función de si se ha elegido "CONSUMO", "PRODUCCIÓN" O "AUTOCONSUMO",
     * para desmarcar los dispositivos incompatibles con dicha opción o los 
     * que tengan marcada la opuesta..
     */
    useEffect(() => {
        const copia = copyData();
        let aux2 = iTDefine(subOption);
        //filtramos el tipo de instalación entrante, y al cambiar la subOpción(tipo de planta),
        //buscamos el master y le aplicamos el cambio.
        let aux3 = copia?.find(device => device.id_tipo_dispositivo === dispositivos.ccmaster)
        aux3.inst_type = aux2
        if (option === 1) {
            switch (subOption) {
                case 1:
                    copia.forEach((device, key) => {
                        if (device.tipo === "produccion") {
                            copia[key].tipo = null;
                        }
                    })
                    setConfigOrder(copia)
                    break
                case 2:
                    copia.forEach((device, key) => {
                        if (device.tipo === "red") {
                            copia[key].tipo = null
                        }
                    })
                    setConfigOrder(copia)
                    break
                case 3:
                    setConfigOrder(copia)
                    break
                default:
                    break
            }
        }
    }, [subOption])

    return (
        <>
            <TableContainer style={{ border: '1px solid #e0e0e0' }}>
                <Table aria-label="customized table">
                    <TableHead style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                        <TableRow style={{ padding: "0px", margin: "0px" }}>
                            <StyledTableCellHead align="center" >
                                Model
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center" >
                                Nombre
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center" >
                                Serial
                            </StyledTableCellHead>
                            {option !== 2 &&
                                <>
                                    <StyledTableCell align="center" >
                                        Punto Frontera
                                    </StyledTableCell>
                                    <StyledTableCell align="center" style={{ padding: '0px' }}>
                                        Producción
                                    </StyledTableCell>
                                </>
                            }
                            <StyledTableCellHead align="center" >
                                Consumo
                            </StyledTableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {configOrder !== undefined && configOrder.length > 0 ?
                            configOrder.map(((device, key) => {
                                if (device.id_tipo_dispositivo !== dispositivos.ccmaster) {
                                    return (
                                        <StyledTableRow >
                                            <StyledTableCell align="center" className="cell-word-table-editplant">
                                                {device.model !== undefined && device.model !== null ? device.model : '-'}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="cell-word-table-editplant" >
                                                {device.nick !== undefined && device.nick !== null ? device.nick : '-'}
                                            </StyledTableCell>
                                            <StyledTableCell align="center" className="cell-word-table-editplant">
                                                {device.numserie !== undefined && device.numserie !== null ? device.numserie : '-'}
                                            </StyledTableCell>
                                            {option !== 2 &&
                                                <>
                                                    <StyledTableCell align="center" >
                                                        <FormControlLabel
                                                            style={{ margin: "0px" }}
                                                            control={
                                                                <label
                                                                    className="checkbox path checkInstallations checkbox-display-group check-pasos"
                                                                    style={{ stroke: device.tipo === 'red' ? "#FF8A1F" : "#fff" }}
                                                                >
                                                                    <input type="checkbox"
                                                                        className="checkbox-editInstall checbox-edit-plan"
                                                                        name={`Red`}
                                                                        style={{
                                                                            background: "#fff",
                                                                            width: "18px",
                                                                            height: "18px",
                                                                            margin: '0px',
                                                                            stroke: device.tipo === `red` ? "#FF8A1F" : "#fff",
                                                                            color: device.tipo === `red` ? "#FF8A1F" : "#fff",
                                                                        }}
                                                                        checked={device.tipo === "red" ? true : false}
                                                                        onChange={(event) => redMarker(event, key)}
                                                                        disabled={device.id_tipo_dispositivo === dispositivos.inversor || subOption === 2 ? true : false}
                                                                    />
                                                                </label>
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <FormControlLabel
                                                            style={{ margin: "0px" }}
                                                            control={
                                                                <label
                                                                    className="checkbox path checkInstallations checkbox-display-group check-pasos"
                                                                    style={{ stroke: device.tipo === 'produccion' ? "#FF8A1F" : "#fff" }}
                                                                >
                                                                    <input type="checkbox"
                                                                        className="checkbox-editInstall checbox-edit-plan"
                                                                        name={'Producción'}
                                                                        style={{
                                                                            background: "#fff",
                                                                            width: "18px",
                                                                            height: "18px",
                                                                            margin: '0px',
                                                                            stroke: device.tipo === 'produccion' ? "#FF8A1F" : "#fff",
                                                                            color: device.tipo === 'produccion' ? "#FF8A1F" : "#fff",
                                                                        }}
                                                                        checked={device.tipo === 'produccion' ? true : false}
                                                                        onChange={(event) => productionMarker(event, key)}
                                                                        disabled={subOption === 1 ? true : false}
                                                                    />
                                                                </label>
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <FormControlLabel
                                                            style={{ margin: "0px" }}
                                                            control={
                                                                <label
                                                                    className="checkbox path checkInstallations checkbox-display-group check-pasos"
                                                                    style={{ stroke: device.tipo === 'red' ? "#FF8A1F" : "#fff" }}
                                                                >
                                                                    <input type="checkbox"
                                                                        className="checkbox-editInstall checbox-edit-plan"
                                                                        name={`Activo`}
                                                                        style={{
                                                                            background: "#fff",
                                                                            width: "18px",
                                                                            height: "18px",
                                                                            margin: '0px',
                                                                            stroke: device.activo === 0 ? "#FF8A1F" : "#fff",
                                                                            color: device.activo === 0 ? "#FF8A1F" : "#fff",
                                                                        }}
                                                                        checked={device.activo === 0 ? true : false}
                                                                        onChange={(event) => {
                                                                            activeMarker(event, key)
                                                                        }
                                                                        }
                                                                    />
                                                                </label>
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                </>
                                            }
                                        </StyledTableRow>
                                    )
                                } else {
                                    return null
                                }
                            }))
                            :
                            null
                        }
                    </TableBody>
                </Table>
            </TableContainer >
            <Grid item xs={12} className="button-apply-table d-flex justify-content-flex-end" >
                <ButtonStyled size="small" className="" variant="" disabled={apply} onClick={() => compareOrder()}>Aplicar</ButtonStyled>
            </Grid>
        </>
    )
}