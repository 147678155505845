import React, { useState } from 'react'
import InfiniteScroll from "react-infinite-scroll-component"
import { InputAdornment, TableCell, TextField, Grid, Table, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { ButtonStyled } from 'components/Buttons/ButtonStyled'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconExit, IconEditPencil, IconWarning, IconSave } from 'utils/icon-centrocontrol'
import { validatorNumber, validatorInput } from 'utils/ValidatorForm'
import { dispositivos } from 'utils/constant'
import './EditDataDevice.scss'
import "../EditInstall/EditInstall.scss"

/**
 * @param configOrder: [{}] | setConfigOrder : function | setAnswer : function , configData: [{}] | fetchOrder : function | compareOrder: function  | apply:boolean
 * @param configOrder copia de configData que se irá modificando según se editen los datos, para enviarlo al final del proceso.
 * @param setConfigOrder función que setea configOrder.
 * @param setAnswersetea setea la respuesta que se muestra en el textArea de EditInstall
 * @param configData array de objetos obtenidos de la consulta original de EditInstall.
 * @param fetchOrder llamada que envía los datos modificados para sobreescribir en la base de datos.
 * @returns Devuelve listado de dispositivos en formato input para poder modificarlos.
 */
export const EditDataDevice = ({
    configOrder,
    stateOriginal,
    statePrev,
    setApply,
    copyData,
    compareOrder,
    loading
}) => {
    const [filas, setFilas] = useState(30)
    const [editingState, setEditingState] = useState({})
    const [hasChanges, setHasChanges] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [keyInput, setKeyInput] = useState(Date.now() + 'input')
    const devicePadre = configOrder?.find(ele => ele.id_tipo_dispositivo === dispositivos.ccmaster)

    const comparer = () => {
        let copia = copyData()
        let hasChanges = false
        configOrder.forEach((device, index) => {
            if (device.nick !== copia[index].nick || device.potencia_contratada !== copia[index].potencia_contratada) {
                hasChanges = true
            }
        })
        setApply(() => hasChanges)
    }

    const handleApply = (key) => {
        let changes = false
        configOrder.forEach((device, index) => {
            if (device.nick !== stateOriginal[index].nick || device.potencia_contratada !== stateOriginal[index].potencia_contratada) {
                changes = true
            }
            if (device.nick !== statePrev[index].nick || device.potencia_contratada !== statePrev[index].potencia_contratada) {
                statePrev[index].nick = device.nick
                statePrev[index].potencia_contratada = device.potencia_contratada
            }
        })
        setHasChanges(changes)
        comparer(key)
        setEditingState((prev) => ({ ...prev, [key]: false }))
    }

    const handleEditar = (key) => {
        setEditingState((prev) => ({
            ...prev,
            [key]: {
                nick: configOrder[key]?.nick || '',
                potencia_contratada: configOrder[key]?.potencia_contratada || ''
            }
        }))
    }

    const handleCancelar = (key) => {
        let cambio = { target: { value: statePrev[key].nick } }
        handleChange(configOrder[key], cambio, key, "nick")
        cambio.target.value = statePrev[key].potencia_contratada
        handleChange(configOrder[key], cambio, key, "potencia_contratada")
        setKeyInput((Date.now() + 'input'))
        handleApply(key)
    }

    const handleChange = (device, e, key, field) => {
        const { value } = e.target
        device[field] = value === '' ? null : value
        setEditingState((prev) => ({ ...prev, [key]: { ...prev[key], [field]: value } }))
    }

    return (
        <>
            <InfiniteScroll
                style={{
                    minHeight: 150,
                    width: '100%'
                }}
                dataLength={filas}
                next={() => {
                    setFilas(filas + 10)
                }}
            >
                <TableContainer >
                    <Table aria-label="customized table">
                        <TableHead style={{ borderBottom: '2px solid black', borderRadius: '50px' }} >
                            <TableRow >
                                <StyledTableCellHead align="center" >
                                    Nombre
                                </StyledTableCellHead>
                                <StyledTableCellHead align="center" >
                                    Potencia Max(W)
                                </StyledTableCellHead>
                                <StyledTableCellHead align="center" >
                                    Editar
                                </StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        {configOrder && configOrder?.map((device, key) => {
                            return (
                                <>
                                    <TableRow key={keyInput}>
                                        <TableCell
                                            key={device.numserie}
                                            className={`cell-word-table-editplant ${key === 0 || key % 2 === 0 ? "back-black" : null
                                                }`}
                                            align="center"
                                        >
                                            <TextField
                                                size="small"
                                                id={`${key} ${device.nick} ${device.potencia_contratada}`}
                                                value={device.nick}
                                                helperText={validatorInput(device.nick) ? "Caracteres extraños" : (!device.nick ? "Por favor, rellene el campo" : "")}
                                                onChange={(e) => {
                                                    handleChange(device, e, key, 'nick');
                                                    if (validatorInput(device.nick) || !device.nick) {
                                                        setDisabled(true)
                                                    } else {
                                                        setDisabled(false)
                                                    }
                                                }}
                                                placeholder='-'
                                                style={{
                                                    fontSize: "12px",
                                                    border: 'none',
                                                    width: '100%',
                                                    background: 'transparent',
                                                    textAlignLast: 'center'
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {(device?.potencia_contratada !== 0 && !device?.potencia_contratada) && device.id_tipo_dispositivo !== 0 && (['red', 'consumo'].includes(device.tipo) || devicePadre?.inst_type === null || devicePadre?.inst_type === 0) &&
                                                                <HtmlTooltip title={<span>Falta configuración de la potencia contratada.</span>}>
                                                                    <IconWarning width={20} height={20} />
                                                                </HtmlTooltip>
                                                            }
                                                        </InputAdornment>
                                                    )
                                                }}
                                                disabled={!editingState[key]}
                                            />
                                        </TableCell>
                                        <TableCell align="center" className={`cell-word-table-editplant ${key === 0 || key % 2 === 0 ? "back-black" : null}`}>
                                            {(['red', 'consumo'].includes(device.tipo) || devicePadre?.inst_type === null || devicePadre?.inst_type === 0) && device.id_tipo_dispositivo !== 0 &&
                                                <TextField
                                                    size="small"
                                                    id={`${key}`}
                                                    value={device.potencia_contratada}
                                                    helperText={validatorNumber(device.potencia_contratada) ? "Por favor, escriba un número" : ""}
                                                    placeholder='-'
                                                    name="pMax"
                                                    onChange={(e) => {
                                                        handleChange(device, e, key, 'potencia_contratada')
                                                        if (validatorNumber(device.potencia_contratada)) {
                                                            setDisabled(true)
                                                        } else {
                                                            setDisabled(false)
                                                        }
                                                    }}
                                                    style={{
                                                        textAlignLast: 'center',
                                                        border: 'none',
                                                        width: '100%',
                                                        color: 'black',
                                                        fontSize: "12px",
                                                        fontWeight: 'bold',
                                                        background: 'transparent',
                                                        WebkitAppearance: 'textfield',
                                                        MozAppearance: 'spinner-textfield',
                                                    }}
                                                    disabled={!editingState[key]}
                                                />
                                            }
                                        </TableCell>
                                        <StyledTableCell align="center" className={`cell-word-table-editplant ${key === 0 || key % 2 === 0 ? "back-black" : null}`} >
                                            {editingState[key] ?
                                                <>
                                                    <ButtonStyledIcon className='boton-icon mr-1' title={'Guardar'} onClick={() => handleApply(key)} disabled={disabled}>
                                                        <IconSave className='color-icon' />
                                                    </ButtonStyledIcon>
                                                    <ButtonStyledIcon className='boton-icon-red mr-1' title={'Cancelar'} backgroundColor='RED' onClick={() => handleCancelar(key)}>
                                                        <IconExit className='color-icon-red' />
                                                    </ButtonStyledIcon>
                                                </>
                                                :
                                                <ButtonStyledIcon className='boton-icon mr-1' title={'Editar'} onClick={() => handleEditar(key)}>
                                                    <IconEditPencil className='color-icon' />
                                                </ButtonStyledIcon>
                                            }
                                        </StyledTableCell>
                                    </TableRow >
                                </>
                            )
                        })}
                    </Table>
                </TableContainer>
                <Grid item xs={12} className="button-apply-table py-2">
                    <ButtonStyled loading={loading} size="small" className="" variant="" disabled={!hasChanges} onClick={() => compareOrder()}>Aplicar</ButtonStyled>
                </Grid>
            </InfiniteScroll >
        </>
    )
}