import React, { useState } from "react"
import Grid from '@mui/material/Grid'
import { CSVLink } from 'react-csv'
import { Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { SelectorFecha } from "views/SelectorCalendar/SelectorFecha"
import { esHoy } from "utils/Utils"
import { cambiarCabecera } from "utils/funcionesCsv"
import ConsuptionTable from "views/Scada/Consumption/GraphConsumption/ConsuptionTable"
import { convertirHistoricoGraficaVtn } from "./utils"
import { Loader } from "components/Loader/Loader"
import { GraficaMabt } from "./GraficaMabt"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import CalendarComponent from "components/Calendar/CalendarComponent"

export const ScadaVtn = ({ data, loading, graphData, energyData, start, device, mabt, end, setStart, setEnd, daily, setDaily, groupDate }) => {
    const rango = groupDate !== 'day'
    const [selectDaily, setSelectDaily] = useState("day")

    const datosGrafica = rango ? convertirHistoricoGraficaVtn(energyData) : graphData

    return (
        <Grid container>
            <Grid item xl={12}>
                <div className='flex justify-end'>
                    <div className="mr-2">
                        <CalendarComponent
                            setDaily={setDaily}
                            maxDate={end}
                            setStart={setStart}
                            setEnd={setEnd}
                            setSelectDaily={setSelectDaily}
                        />
                    </div>
                    <div className="mr-2">
                        <SelectorFecha
                            daily={daily}
                            setDaily={setDaily}
                            tipo={daily}
                            selectDaily={selectDaily}
                        />
                    </div>
                    <div className="item">
                        <Button size='small'>
                            <CSVLink data={energyData ? cambiarCabecera(energyData, mabt, false, true) : []} separator={";"} style={{ color: 'white' }} filename={`${device?.nick ? device?.nick : 'VTN'}.csv`}>
                                <HtmlTooltip title={<span>Descargar datos</span>}>
                                    <FontAwesomeIcon name='faFileDownload' style={{ color: (energyData && energyData.length > 0) ? '#FF8A1F' : '#ADADAD', opacity: 0.6, fontSize: '25px' }} icon={faFileDownload} />
                                </HtmlTooltip>
                            </CSVLink>
                        </Button>
                    </div>
                </div>
            </Grid>
            {!loading ?
                <>
                    <Grid item xl={12} className='mt-3'>
                        <GraficaMabt datosGrafica={datosGrafica} escalaTipo={'V'} tipoGrafica={rango ? 'vtn' : 'pact'} />
                    </Grid>
                    <Grid item xl={12} className='mt-3'>
                        <ConsuptionTable
                            daily={daily}
                            data={data?.currentData}
                            energyData={energyData}
                            start={start}
                            hoy={esHoy(start)}
                        />
                    </Grid>
                </>
                :
                <Grid item xs={12} md={12} lg={12} xl={12}>
                    <div className="justify-content-center">
                        <Loader />
                    </div>
                </Grid>
            }
        </Grid>
    )
}
