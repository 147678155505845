import { Grid } from "@mui/material"
import { Loader } from "components/Loader/Loader"
import React, { useContext, useEffect, useState } from "react"
import { headersScada } from "services/headers"
import { urls } from "utils/constant"
import { CalendarCcMaster } from "views/Scada/CcMaster/tabs/Calendar/CalendarCcMaster"
import { TableManualCalendar } from "./TableManualCalendar"
import { result } from "utils/functionsforGraphs"
import { isMobile } from "utils/Utils"
import { GlobalContext } from "context/GlobalContext"
import { HistoricalCalendar } from "./HistoricalCalendar"

export const CalendarList = ({ instalacioneSeleccionadas, centroControl, dispositivos, masterPermission }) => {
    const token = sessionStorage?.token;
    const { updateDevice } = useContext(GlobalContext)
    const [data, setData] = useState()
    const [activeTab, setActiveTab] = (useState(0))
    const [updateCalendar, setUpdateCalendar] = useState(false)
    const [relay, setRelay] = useState([
        {
            ID_entry: 1,
            name: "Salida 1"
        },
        {
            ID_entry: 2,
            name: "Salida 2"
        },
    ])
    const [loading, setLoading] = useState(true)
    const [loadingPro, setLoadingPro] = useState(true)
    const [manualControl, setManualControl] = useState([])
    const [tableManual, setTableManual] = useState([])
    const [loadingAlias, setLoadingAlias] = useState(false)
    const [error, setError] = useState()
    const [success, setSuccess] = useState()
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [pulse, setPulse] = useState(false)
    const [loadingOn, setLoadingOn] = useState(false)
    const mobile = isMobile(1200)

    const fetchCalendar = async (idCcMaster) => {
        try {
            const res = await fetch(urls.scada + `calendar/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json
            } else
                return []
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchRelay = async (idCcMaster) => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `calendarState/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json
            } else
                return []
        } catch (err) { console.error("ERROR. API Scada", err) }
        finally {
            setLoading(false)
        }
    }

    const arrayCalendar = async () => {
        setLoadingPro(true)
        setData([])
        let result = await Promise.all(instalacioneSeleccionadas?.map(device => fetchCalendar(device)))
        const arrayNew = result.flatMap(array => array)
        const updatedItem = arrayNew.map(obj => {
            const dispositivo = masterPermission[Number(obj?.SN_device)]
            if (dispositivo) {
                obj.nick_padre = dispositivo.nick;
            }
            return obj;
        })
        setData(updatedItem)
        setLoadingPro(false)
    }

    const masterData = () => {
        instalacioneSeleccionadas.forEach(master => {
            const dispositivoEncontrado = dispositivos?.some(device => device?.numserie === Number(master));
            if (dispositivoEncontrado) {
                fetchRelay(master).then(relay => {
                    dispositivos?.filter(device => device?.numserie === Number(master)).forEach(dev => {
                        setManualControl(prev => [...prev, { ...relay, numserie: dev.numserie, nick: dev.nick, id_tipo_dispositivo: dev.id_tipo_dispositivo }])
                    })
                })
            }
        }
        )
    }

    const checkedInternal = (mode) => {
        if (mode === 103 || mode === 101 || mode === 0) {
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        if (manualControl) {
            const setObj = new Set();
            const uniqs = manualControl?.map(device => result(manualControl, device.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device.numserie)) {
                    setObj.add(device.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            const resultado = noRepeat.filter(objeto => instalacioneSeleccionadas.includes(objeto.numserie.toString()));
            setTableManual(resultado)
        }
    }, [manualControl, instalacioneSeleccionadas, keySwitch])

    useEffect(() => {
        if (activeTab === 1) {
            arrayCalendar()
        } else if (activeTab === 0) {
            masterData()
        }
    }, [updateCalendar, activeTab, updateDevice, instalacioneSeleccionadas])

    return (<>
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className="menu-configuracion">
                <div selected={activeTab === 0} onClick={() => { setActiveTab(0); }}>
                    <span className={activeTab === 0 ? "list-item-icon active-button" : "list-item-icon"}>
                        Control manual
                    </span>
                </div>
                <div selected={activeTab === 1} onClick={() => { setActiveTab(1); }}>
                    <span className={activeTab === 1 ? "list-item-icon active-button" : "list-item-icon"}>
                        Programación                    </span>
                </div>
                <div selected={activeTab === 2} onClick={() => { setActiveTab(2); }}>
                    <span className={activeTab === 2 ? "list-item-icon active-button" : "list-item-icon"}>
                        Histórico                    </span>
                </div>
            </Grid>
        </>
        {((loading === true && activeTab === 0) || (loadingPro && activeTab === 1)) && <Loader />}
        {!loading && activeTab === 0 &&
            <TableManualCalendar masterPermission={masterPermission} setTableManual={setTableManual}
                setLoadingOn={setLoadingOn} loadingOn={loadingOn}
                loadingAlias={loadingAlias} setLoadingAlias={setLoadingAlias} error={error}
                checkedInternal={checkedInternal} manualControl={tableManual} setError={setError} success={success}
                setSuccess={setSuccess} keySwitch={keySwitch} setKeySwitch={setKeySwitch}
                loading={loading} setLoading={setLoading}
                pulse={pulse} setPulse={setPulse} isMobile={mobile} centroControl={centroControl} setUpdateCalendar={setUpdateCalendar}
            />
        }
        {!loadingPro && activeTab === 1 &&
            <CalendarCcMaster data={data} master={dispositivos.filter(dev => dev.id_tipo_dispositivo === 0)} setUpdateCalendar={setUpdateCalendar} relay={relay} loading={loading} setLoading={setLoading} centroControl={true} setRelay={setRelay} installations={instalacioneSeleccionadas} />
        }
        {
            activeTab === 2 &&
            <HistoricalCalendar master={dispositivos.filter(dev => dev.id_tipo_dispositivo === 0)} setUpdateCalendar={setUpdateCalendar} data={data} />
        }
    </>)
}