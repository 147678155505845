import React from 'react'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableCellHead } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { toKiloW, toKilowPercent } from 'utils/functionsforGraphs'
import { convertDatetime } from 'utils/Utils'

export const CurrentTableBateryDesktop = (props) => {

    return (
        <div className="boder-table">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead style={{ borderRadius: '50px' }}>
                        <TableRow>
                            <StyledTableCellHead align="center">
                                <div>Nombre</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Fecha</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Intensidad (A)</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Potencia (kW)</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Votaje (V)</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Carga (%)</div>
                            </StyledTableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.data && props?.data?.map((device, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {(props?.checked?.indexOf(device?.data?.currentData?.numserie) >= 0 || props?.activo === true) &&
                                        <StyledTableRow key={i}>
                                            <StyledTableCell >
                                                {device?.data?.currentData?.nick !== undefined && device?.data?.currentData?.nick !== null ?
                                                    device?.data?.currentData?.nick
                                                    :
                                                    '-'}
                                            </StyledTableCell>
                                            <StyledTableCell >
                                                {convertDatetime(device?.data?.currentData?.datetime)}
                                            </StyledTableCell>
                                            <StyledTableCell >{
                                                toKiloW(device?.data?.currentData?.i)
                                            }</StyledTableCell>
                                            <StyledTableCell >{
                                                toKiloW(device?.data?.currentData?.p)}
                                            </StyledTableCell>
                                            <StyledTableCell >{
                                                toKiloW(device?.data?.currentData?.v)}
                                            </StyledTableCell>
                                            <StyledTableCell >{
                                                toKilowPercent(device?.data?.currentData?.soc) + "%"}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}