import { ButtonMenu } from "components/Buttons/ButtonMenu"
import { esHoy } from "utils/Utils"
import React from "react"

export const MenuDescripcionMabt = ({ activeTab, seleccionarTab, start, grafica, visible, medidores }) => {


    return (
        <>
            <ButtonMenu className='mb-2'disabled={grafica && !visible} tab={activeTab === 0} onClick={() => seleccionarTab(0)} >
                P. Activa (kW)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && (!esHoy(start) || !visible)} tab={activeTab === 3} onClick={() => seleccionarTab(3)} >
                P. Reactiva (kW)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && !visible} tab={activeTab === 1} onClick={() => seleccionarTab(1)} >
                Corriente (A)
            </ButtonMenu>
            <ButtonMenu className='mb-2' disabled={grafica && !visible} tab={activeTab === 2} onClick={() => seleccionarTab(2)}>
                Tensión (V)
            </ButtonMenu>
            {(grafica && medidores === 1) &&
                <>
                    <ButtonMenu className='mb-2' tab={activeTab === 5} onClick={() => seleccionarTab(5)} >
                        Energía Exportada (kWh)
                    </ButtonMenu>
                    <ButtonMenu className='mb-2' tab={activeTab === 6} onClick={() => seleccionarTab(6)} >
                        Energía Importada (kWh)
                    </ButtonMenu>
                </>
            }
            {(grafica && !medidores) &&
                <ButtonMenu className='mb-2' tab={activeTab === 4} onClick={() => seleccionarTab(4)} >
                    Energía (kWh)
                </ButtonMenu>
            }
        </>
    )
}