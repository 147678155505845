import React, { useEffect, useState } from "react"
import { Chart } from "../../../SelfConsumption/GraphsUtils/LineChart"
import { urls } from "utils/constant.jsx"
import moment from "moment"
import { headersScada } from "services/headers"

export const DoubleBarEneryGraph = (props) => {
  const token = sessionStorage?.token
  const yesterday = moment().subtract(1, "days").format("DD-MM-YYYY")
  const now = moment().format("DD-MM-YYYY")
  const [dataBar, setDataBar] = useState()
  const [seriesData, setSeriesData] = useState({eacons: [], eagen: []})
  let url = `getDailyEnergyData/${props.data?.numserie ? props?.data?.numserie : props?.device?.numserie}${props?.loose === 0 ? '?idCCMaster='+props?.idMaster+'&' : '?'}iniDate=${moment().subtract(1, "days").format("YYYY-MM-DD")}&endDate=${moment().format("YYYY-MM-DD")}`

  const fetchData = () => {
    fetch(urls.scada + url, {
      method: "GET",
      headers: headersScada(token)
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        }
      })
      .then((data) => {
        let newOBject = {data: {}, nick: data?.nick, numserie: data?.numserie}
        newOBject.data[yesterday] = data?.data?.[yesterday] ? data?.data?.[yesterday] : data?.[yesterday] ? data?.[yesterday] : {fecha: yesterday, eacons: 0, eagen: 0}
        newOBject.data[now] = data?.data?.[now] ? data?.data?.[now] : data?.[now] ? data?.[now] : {fecha: now, eacons: 0, eagen: 0} 
        setDataBar(newOBject)
      })
      .catch((err) =>
        console.error("ERROR. API Scada", err)
      )
  }

  const eaconsFilter = (num) => {
    let aux = 0.0001
    if (num !== null && num !== undefined && num !== 0) {
      aux = num
    }
    return aux
  }

  useEffect(() => {
    let aux = []
    let res = { eacons: [], eagen: [] }
    if (dataBar !== undefined && dataBar !== null) {
      if (dataBar?.data) {
        let claves = Object.keys(dataBar.data);
        for (let i = 0; i < claves.length; i++) {
          let clave = claves[i]
          aux.push(dataBar.data[clave])
        }
      } else {
        let claves = Object.keys(dataBar);
        for (let i = 0; i < claves.length; i++) {
          let clave = claves[i]
          aux.push(dataBar[clave])
        }
      }
      for (let i = 0; i < aux.length; i++) {
        if (aux[i]?.fecha === moment().subtract(1, "days").format("DD-MM-YYYY").toString() || aux[i]?.fecha === moment().format("DD-MM-YYYY").toString()) {
          res.eacons.push(eaconsFilter(aux[i].eacons))
          res.eagen.push(eaconsFilter(aux[i].eagen))
        }
      }
    }
    setSeriesData({
      eacons: res.eacons,
      eagen: res.eagen
    })
  }, [props, dataBar])

  useEffect(() => {
    if (props?.data?.numserie || props?.device?.numserie) {
      fetchData()
    }
  }, [props?.idMaster, props?.data?.numserie, props?.device?.numserie])

  const LineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animations: {
      radius: {
        duration: 400,
        easing: "linear",
        loop: (context) => context.active
      }
    },
    hoverRadius: 6,
    hoverOpacity: 0.6,
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "x"
    },
    scales: {
      x: {
        grid: {
          drawOnChartArea: true,
          drawTicks: false
        }
      },
      y: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false
        },
        suggestedMax: 1,
        ticks: {
          callback: function (value, index, ticks) {
            return value + " kWh"
          }
        }
      },
      y2: {
        position: "right",
        grid: {
          drawOnChartArea: false,
          drawTicks: false
        },
        ticks: {
          callback: function (value, index, ticks) {
            return ""
          }
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          padding: 10,
          usePointStyle: true,
          pointStyle: "circle"
        },
        display: true,
        position: "bottom",
        align: "end"
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || ""
            if (context.parsed.y !== null) {
              label += " : "
              return (label += new Intl.NumberFormat().format(context.parsed.y) + " kWh")
            }
          }
        }
      }
    },
    zoom: {
      pan: {
        enabled: true,
        mode: "x",
        speed: 0.5
      },
      zoom: {
        wheel: {
          enabled: true,
          speed: 0.1
        },
        mode: "x"
      }
    }
  }

  const dataFilter = {
    labels: [
      moment().subtract(1, "days").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    ],
    datasets: [
      {
        Type: "bar",
        data: null,
        label: "Importada",
        backgroundColor: "#3B9FD1",
        borderColor: "#3B9FD1",
        pointBackgroundColor: "#3B9FD1",
        borderWidth: 0,
        fill: true,
        id: "1",
        numserie: "",
        maintainAspectRatio: "true"
      },
      {
        Type: "bar",
        data: null,
        label: "Exportada",
        backgroundColor: "#B4DA9D",
        borderColor: "#B4DA9D",
        pointBackgroundColor: "#B4DA9D",
        borderWidth: 0,
        fill: true,
        id: "1",
        numserie: "",
        maintainAspectRatio: "true"
      }
    ]
  }

  const [graph, setGraph] = useState()

  useEffect(() => {
    dataFilter.datasets[0].data = seriesData?.eacons
    dataFilter.datasets[1].data = seriesData?.eagen
    setGraph(
      <Chart
        dataFilter={dataFilter}
        LineOptions={LineOptions}
        graphStyle={"bar"}
      />
    )
  }, [props, props.idMaster, props.data?.numserie, seriesData])


  return <>{graph}</>
}
