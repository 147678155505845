// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devices-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  gap: 20px;
}

.relay-main-container {
  overflow-x: auto;
  height: fit-content;
  background-color: #F6F6F6;
  border: 5px solid #C3C5C6;
}

.relay-div {
  min-width: 100%;
  width: min-content;
}

.relay-container {
  background: #C3C5C6;
  padding: 0 30px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 150px 0;
  border-top: 13px solid #e0e0e0;
  border-bottom: 13px solid #e0e0e0;
  width: fit-content;
  min-width: 100%;
}

.device-draggable {
  -webkit-user-select: none;
          user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relay-draggable {
  -webkit-user-select: none;
          user-select: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ccm1-style {
  margin-bottom: 39px;
}

.ccm1-inverted-style {
  margin-top: 19px;
}

.ccm2-style {
  margin-bottom: 39px;
}

.ccm2-inverted-style {
  margin-top: 19px;
}

.ccm4-style {
  margin-bottom: 39px;
}

.ccm4-inverted-style {
  margin-top: 42px;
}`, "",{"version":3,"sources":["webpack://./src/views/LayOut/LayOut.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,cAAA;EAAA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,8BAAA;EACA,iCAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;UAAA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,yBAAA;UAAA,iBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".devices-container {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr); \n  gap: 20px; \n}\n\n.relay-main-container {\n  overflow-x: auto;\n  height: fit-content;\n  background-color: #F6F6F6;\n  border: 5px solid #C3C5C6;\n}\n\n.relay-div {\n  min-width: 100%;\n  width: min-content;\n}\n\n.relay-container {\n  background: #C3C5C6;\n  padding: 0 30px; \n  height: 48px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  margin: 150px 0;\n  border-top: 13px solid #e0e0e0;\n  border-bottom: 13px solid #e0e0e0;\n  width: fit-content; \n  min-width: 100%; \n}\n\n.device-draggable {\n  user-select: none;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.relay-draggable {\n  user-select: none;\n  background: transparent;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.ccm1-style {\n  margin-bottom: 39px;;\n}\n\n.ccm1-inverted-style {\n  margin-top: 19px;\n}\n\n.ccm2-style {\n  margin-bottom: 39px;\n}\n\n.ccm2-inverted-style {\n  margin-top: 19px;\n}\n\n.ccm4-style {\n  margin-bottom: 39px;\n}\n\n.ccm4-inverted-style {\n  margin-top: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
