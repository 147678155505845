// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-table-configuration-ems {
  margin: 15px 0px;
}

.config-ems-system-buttom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container-modal-max-power {
  padding: 15px 30px;
}

.section-modal-edit-mp {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.section-modal-edit-mp > span {
  margin: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-modal-edit-mp > span > * {
  margin: 0px 15px;
}

.input-mp-modal {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.input-mp-modal > * {
  margin: 0px 15px;
}

.section-modal-mainance-ems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px;
  height: 37.375px;
}

.section-modal-mainance-ems > * {
  margin: 0px 10px;
}

.container-no-data {
  margin: 30px 0px;
  text-align: center;
}

.max-power-table-master {
  margin-top: 5px;
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/views/EMS/styleEms.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;AACJ;;AAKA;EACI,kBAAA;AAFJ;;AAKA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AAFJ;;AAKA;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;;AAKA;EACI,gBAAA;AAFJ;;AAMA;EACI,mBAAA;EACA,2BAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,gBAAA;AAHJ;;AAUA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AAPJ;;AAUA;EACI,gBAAA;AAPJ;;AAUA;EACI,gBAAA;EACA,kBAAA;AAPJ;;AAUA;EACI,eAAA;EACA,wBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAPJ","sourcesContent":[".container-table-configuration-ems {\n    margin: 15px 0px\n}\n\n.config-ems-system-buttom {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n}\n\n////////////MODAL EDIT MAX POWER\n/// \n\n.container-modal-max-power {\n    padding: 15px 30px\n}\n\n.section-modal-edit-mp {\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: row;\n    align-items: center;\n    flex-wrap: wrap;\n    margin-bottom: 30px\n}\n\n.section-modal-edit-mp>span {\n    margin: 0px 30px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.section-modal-edit-mp>span>* {\n    margin: 0px 15px\n}\n\n\n.input-mp-modal {\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.input-mp-modal>* {\n    margin: 0px 15px;\n}\n\n\n///////////////MAINTANCE EMS\n/// \n/// \n.section-modal-mainance-ems {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    margin: 15px 0px;\n    height: 37.375px\n}\n\n.section-modal-mainance-ems>* {\n    margin: 0px 10px\n}\n\n.container-no-data {\n    margin: 30px 0px;\n    text-align: center;\n}\n\n.max-power-table-master {\n    margin-top: 5px;\n    display: flex !important;\n    flex-wrap: nowrap;\n    justify-content: center;\n    align-items: center;\n    padding-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
