import React, { useEffect, useState } from "react"
import { DialogContent, Grid, FormControl, FormControlLabel, MenuItem, Table, TableContainer, TableHead, TableBody, TableRow } from "@mui/material"
import { SelectStyled } from "components/Select/SelectStyled"
import { SwitchStyled } from "components/Switch/SwitchStyled"
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { ButtonModal } from "components/Buttons/ButtonModal"
import { ButtonStyledIcon } from "components/Buttons/ButtonStyledIcon"
import { Modal } from "components/Modal/Modal"
import { headersCentroControl } from 'services/headers'
import { IconDeleteAlarm } from 'utils/icon-centrocontrol'
import { urls } from "utils/constant.jsx"
import "../EditInstall/EditInstall.scss"
import { useDispatch } from "react-redux"
import { createToast } from "reduxSlice/toastSlice"

/**
 * @param {{ token: string, open: boolean, setOpen: function, dispositivos : [{}],  data:[{}]}} 
 * @param token Token necesario para las llamadas.
 * @param open estado quee stablece si el modal permanece abierto o no.
 * @param setOpen función que setea modalHistory.
 * @param dispositivos array de objetos con los datos de los masters seleccionados.
 * @param data datos de actualización
 * @returns modal de mantenimiento.
 */
export const MaintenanceModal = ({ token, open, setOpen, dispositivos, data, setKeySwitch }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState(dispositivos)
    const [dispositivoNoSeleccionado, setDispositivoNoSeleccionado] = useState([])
    const [allSelected, setAllSelected] = useState(true)
    const [checkedItems, setCheckedItems] = useState({})
    const [activeTab, setActiveTab] = useState(0)
    const [versionTo, setVersionTo] = useState('')
    const [versions, setVersions] = useState([])
    const [option, setOption] = useState(1)
    const [filteredMasters, setFilteredMasters] = useState([])

    /**
     * @description array con las opciones posibles, un string que monstrará en el select y un valor que determina
     * lo renderizado y las acciones posteriores.
     */
    const optionStrings = [
        {
            string: 'Actualizar firmware',
            valor: 1
        }
    ]

    /**
     * @description función que selecciona o desselecciona todos los dispositivos en bloque.
     */
    const selectAll = () => {
        let res = {}
        if (allSelected === true) {
            filteredMasters.forEach(dispo => {
                res = {
                    ...res,
                    [dispo.numserie]: dispo.status !== "Pendiente de actualización" ? true : false
                }
            })
            setDispositivoSeleccionado(filteredMasters)
            setDispositivoNoSeleccionado([])
        } else {
            filteredMasters.forEach(dispo => {
                res = {
                    ...res,
                    [dispo.numserie]: false
                }
            })
            setDispositivoSeleccionado([])
            setDispositivoNoSeleccionado(filteredMasters)
        }
        setCheckedItems(res)
    }

    /**
     * 
     * @param {*} numserie número de serie del dispositivo sobre el que se actua.
     * @param {*} estado boolean que indica si queremos marcar desmarcar el dispositivo en la columna que proceda.
     * @description función que mapea en busca del dispositivo indicado y lo mueve de seleccionados a no seleccionados
     * o viceversa en función de los param recibidos.
     */
    const select = (numserie, estado) => {
        numserie = Number(numserie)
        let seleccionados = dispositivoSeleccionado
        let noSeleccionados = dispositivoNoSeleccionado
        if (numserie && estado === true) {
            seleccionados.push(filteredMasters.find(ele => ele.numserie === numserie))
            noSeleccionados = dispositivoNoSeleccionado.filter(dispo => dispo?.numserie !== numserie)
            setDispositivoSeleccionado(seleccionados)
            setDispositivoNoSeleccionado(noSeleccionados)
        } else if (numserie && estado === false) {
            seleccionados = dispositivoSeleccionado.filter(dispo => dispo?.numserie !== numserie)
            noSeleccionados.push(filteredMasters.find(ele => ele?.numserie === numserie))
            setDispositivoSeleccionado(seleccionados)
            setDispositivoNoSeleccionado(noSeleccionados)
        }
    }

    /**
     * @param {*} event evento recibido desde la etiqueta renderizada.
     * @description setea checkedItems en busca del indicado mediante el evento y los marca true o false en 
     * función de la orden recibida, para que se considere marcado o no.
     */
    const handleChange = (event) => {
        setCheckedItems({
            ...checkedItems,
            [event.target.name]: event.target.checked
        })
        select(event.target.name, event.target.checked)
    }

    /**
     * 
     * @param {*} ele objeto con los datos del dispositivo sobre el que se va a trabajar.
     *  
     * @returns devuelve true o false en función de si el dispositivo está actualizado a 
     * la versión de firmware seleccionada mediante el select previo. 
     */
    const updatedfilter = (ele) => {
        let aux = dispositivoSeleccionado.filter(dispo => dispo?.numserie === ele?.numserie)[0]
        if (aux !== undefined) {
            let res = false
            if (aux !== undefined && aux.master_FW === versionTo) {
                handleChange({ target: { name: aux.numserie.toString(), checked: false } })
                res = true
            }
            return res
        }
    }

    /**
     * 
     * @param {} serie listado de dispositivos que se quieren actualizar
     *  
     * @returns devuelve resultado de la petición, recibida o error en el proceso. 
     */
    const fetchOrder = async (serie) => {
        let status
        if (serie !== undefined) {
            try {
                const res = await fetch(urls.ccontrol + 'addUpgrade', { method: 'POST', headers: headersCentroControl(token), body: JSON.stringify(serie) })
                status = res.status
                if (res.status === 200) {
                    dispatch(createToast({ status: status, message: "Petición enviada correctamente", reload: false }))
                    setOpen(false)
                    setKeySwitch(Date.now())
                    return true
                } else {
                    dispatch(createToast({ status: status, message: "Error al enviar la petición", reload: false }))
                    setOpen(false)
                    return false
                }
            } catch (err) {
                console.error("ERROR. API Centro de control", err)
            }
        }
    }

    /**
     * @description actua dependiendo de la opción seleccionada:
     * actualizar firmware : monta un array de objetos con los datos de los dispositivos selecconados
     *  para su actualización y la versión seleccionada de firmware a la que actualizar.
     * sin mas opciones por ahora.
     */
    const sendOrder = () => {
        let aux = []
        switch (option) {
            case 1:
                dispositivoSeleccionado.forEach(device => aux.push({
                    nick: device.nick,
                    numserie: device.numserie,
                    actualVersion: device.master_FW,
                    updateTo: versionTo
                }))
                break
            default:
                break
        }

        fetchOrder(aux)
    }

    /**
     * @description consigue las versiones seleccionables del listado en la base de datos.
     * @returns array de strings.
     */
    const fetchDataFwVersions = async () => {
        setLoading(true)
        try {
            let res = await fetch(urls.ccontrol + `getversionsFW`, { method: 'GET', headers: headersCentroControl(token) })
            if (res.status === 200) {
                let json = await res.json();
                setVersionTo(json[json.length - 1])
                setVersions(json)
            } else {
                setVersions([])
            }
            setLoading(false)
            return true
        } catch (err) {
            console.error("ERROR. API centro de control", err)
        }
    }

    const hasAnyTrue = (obj) => {
        for (let key in obj) {
            if (obj[key] === true) {
                return false
            }
        }
        return true;
    }

    /**
     * @description lanza fetchDataFwVersions si el array versions está vacío, 
     * para evitar duplicidades por si se ha vuelto a atrás y se ha regresado a esta opción de nuevo.
     */
    useEffect(() => {
        if (option === 1 && versions.length === 0) {
            fetchDataFwVersions()
        }
    }, [option])

    /**
     * @description ambos useEffect resetean los dispositivos seleccionados al moverse entre renderizados.
     * 
     */
    useEffect(() => { selectAll() }, [filteredMasters, allSelected])

    /**
     * @description selecciona todos los dispositivos cuando se renderiza el listado por primera vez.
     */
    useEffect(() => {
        if (activeTab === 0) {
            setAllSelected(true)
            selectAll()
        }
    }, [activeTab])

    useEffect(() => {
        let aux = dispositivos.filter(device => device.permissions !== 'user').map(device => {
            const statusDevice = data.find(status => status.numserie.toString() === device.numserie.toString())
            if (statusDevice) {
                return {
                    ...device,
                    status: statusDevice.status,
                    version: statusDevice.version
                }
            }
            return device
        })
        setFilteredMasters(aux)
    }, [dispositivos, data])

    return (
        <div>
            <Modal fullWidth={true} maxWidth={window.innerWidth < 665 ? window.innerWidth : 'sm'} open={open} setOpen={setOpen} title="Mantenimiento" footer={
                <Grid container className='pr-5 pl-5'>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='modal-footer flex justify-center'>
                            {activeTab === 0 &&
                                <ButtonModal
                                    fullWidth={true}
                                    loading={loading}
                                    messageLoading={false}
                                    className="button-cancel-modal mb-4"
                                    onClick={() => setOpen(!open)}>
                                    {window.innerWidth < 1200 ? 'Canc' : 'Cancelar'}
                                </ButtonModal>
                            }
                            {activeTab !== 0 &&
                                <ButtonModal
                                    onClick={() => setActiveTab(activeTab - 1)}
                                    fullWidth={true}
                                    loading={loading}
                                    className="button-cancel-modal mb-4">
                                    {window.innerWidth < 1200 ? 'Atr' : 'Atrás'}
                                </ButtonModal>
                            }
                            <div style={{ width: '2em' }}></div>
                            <>
                                {activeTab === 2 ?
                                    <ButtonModal
                                        disabled={(option === '' || (option === 1 && versionTo === '') || dispositivoSeleccionado.length === 0) ? true : false}
                                        fullWidth={true}
                                        loading={loading}
                                        className="button-ok-modal mb-4"
                                        onClick={() => sendOrder()}
                                    >{window.innerWidth < 1200 ? 'Act' : 'Actualizar'}</ButtonModal>
                                    : activeTab === 0 ?
                                        <ButtonModal
                                            disabled={(option === '' || (option === 1 && versionTo === '') || dispositivoSeleccionado.length === 0) ? true : false}
                                            fullWidth={true}
                                            loading={loading}
                                            className="button-ok-modal mb-4"
                                            onClick={() => setActiveTab(activeTab + 1)}>
                                            {window.innerWidth < 1200 ? 'Sig' : 'Siguiente'}
                                        </ButtonModal>
                                        :
                                        <ButtonModal
                                            disabled={(option === '' || (option === 1 && versionTo === '') || dispositivoSeleccionado.length === 0 || hasAnyTrue(checkedItems)) ? true : false}
                                            fullWidth={true}
                                            loading={loading}
                                            className="button-ok-modal mb-4"
                                            onClick={() => setActiveTab(activeTab + 1)}>
                                            {window.innerWidth < 1200 ? 'Sig' : 'Siguiente'}
                                        </ButtonModal>
                                }
                            </>
                        </div>
                    </Grid>
                </Grid>
            } onClick={(e) => e.stopPropagation} >
                {activeTab === 0 &&
                    <DialogContent className="d-flex">
                        <Grid item xs={12} md={6} lg={6} className='pb-2'>
                            <FormControl className='width-100 pb-2 pr-2' size="small">
                                <div className='pb-1'>
                                    <small className="color-black">
                                        Opciones:
                                    </small>
                                </div>
                                <SelectStyled
                                    value={option}
                                    onChange={(e) => setOption(e.target.value)}
                                    autoWidth
                                >
                                    {
                                        optionStrings.map((elm, key) => {
                                            if (elm) {
                                                return (
                                                    <MenuItem value={elm.valor}>{elm.string}</MenuItem>
                                                )
                                            } else {
                                                return null
                                            }
                                        })
                                    }
                                </SelectStyled>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} >
                            {option === 1 &&
                                <FormControl className='width-100 pb-2' size="small">
                                    <div className='pb-1'>
                                        <small className="color-black">
                                            Elige versión:
                                        </small>
                                    </div>
                                    <SelectStyled
                                        value={versionTo}
                                        onChange={(e) => setVersionTo(e.target.value)}
                                        autoWidth
                                    >
                                        {versions.length > 0 &&
                                            versions.map((elm, key) => {
                                                if (elm) {
                                                    return (
                                                        <MenuItem value={elm}>v.{elm}</MenuItem>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </SelectStyled>
                                </FormControl>
                            }
                        </Grid>
                    </DialogContent>
                }
                {option === 1 &&
                    (activeTab === 1 || activeTab === 2) &&
                    <DialogContent className="p-4 edit-maintance-content-table">
                        <h3 className="mb-3  text-center font-weight-lighter">
                            {activeTab === 1 ? 'Selecciona' : 'Revisa'} los dispositivos que deseas actualizar a la versión:
                            <small style={{ fontWeight: 'bold', fontSize: '15px', marginLeft: "5px" }}>{versionTo}</small>
                        </h3>
                        <TableContainer style={{ maxHeight: '30em', border: '1px solid #e0e0e0' }}>
                            <Table aria-label="customized table">
                                <TableHead style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                                    <TableRow style={{ padding: "0px", margin: "0px" }}>
                                        <StyledTableCellHead align="center" >
                                            <p style={{ width: '100%', textAlign: 'left', fontWeight: 'bold' }}>Dispositivo</p>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center" >
                                            <p style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>Nº serie</p>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center" >
                                            <p style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>Fw</p>
                                        </StyledTableCellHead>
                                        <StyledTableCellHead align="center" >
                                            {activeTab === 1 && <SwitchStyled name={allSelected} checked={allSelected}
                                                className='switch-small'
                                                onChange={() => setAllSelected(!allSelected)} />}
                                        </StyledTableCellHead>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeTab === 1 ?
                                        (filteredMasters.map((ele, index) => {
                                            return (
                                                <StyledTableRow key={index} style={{ padding: "0px", marginBottom: "0px" }}>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'left' }}>{ele.nick}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'center' }}>{ele.numserie}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'center' }}>{ele.master_FW ? ele.master_FW : ele?.version ? ele?.version : '-'}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <FormControlLabel
                                                            style={{ marginLeft: "15px", margin: '5px' }}
                                                            control={
                                                                <label
                                                                    className="checkbox path checkInstallations checkbox-display-group check-pasos"
                                                                    style={{ stroke: checkedItems[ele.numserie] ? "#FF8A1F" : "#fff" }}
                                                                >
                                                                    {dispositivoSeleccionado.length !== 0 ?
                                                                        (updatedfilter(ele) === false ?
                                                                            (<SwitchStyled name={ele.numserie} checked={ele.status !== "Pendiente de actualización" ? checkedItems[ele.numserie] : false}
                                                                                className='switch-small'
                                                                                onChange={handleChange} disabled={ele.status === "Pendiente de actualización"} />

                                                                            )
                                                                            :
                                                                            (ele.master_FW !== versionTo ?
                                                                                <SwitchStyled name={ele.numserie} checked={ele.status !== "Pendiente de actualización" ? checkedItems[ele.numserie] : false}
                                                                                    className='switch-small'
                                                                                    onChange={handleChange}
                                                                                    disabled={ele.status === "Pendiente de actualización"} />
                                                                                :
                                                                                <p>Actualizado</p>
                                                                            )
                                                                        )
                                                                        :
                                                                        (ele.master_FW !== versionTo ?
                                                                            <SwitchStyled name={ele.numserie} checked={ele.status !== "Pendiente de actualización" ? checkedItems[ele.numserie] : false}
                                                                                className='switch-small'
                                                                                onChange={handleChange}
                                                                                disabled={ele.status === "Pendiente de actualización"} />

                                                                            :
                                                                            <p>Actualizado</p>
                                                                        )
                                                                    }
                                                                </label>
                                                            }
                                                        />
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }))
                                        :
                                        (dispositivoSeleccionado.map((ele, index) => {
                                            return (
                                                <StyledTableRow style={{ padding: "0px", marginBottom: "0px" }}>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'left' }}>{ele.nick}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'center' }}>{ele.numserie}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <p style={{ width: '100%', textAlign: 'center' }}>{ele.master_FW ? ele.master_FW : ele?.version ? ele?.version : '-'}</p>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center" >
                                                        <Grid item md={12}>
                                                            <ButtonStyledIcon className='boton-historical-install' title='Eliminar' onClick={() => handleChange({ target: { name: ele.numserie, checked: false } })}>
                                                                <IconDeleteAlarm className='boton-historical-hijo' />
                                                            </ButtonStyledIcon>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                }
            </Modal>

        </div >
    )
}