import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { validatorEmail, validatorPass } from 'utils/ValidatorForm'
import { urls } from "utils/constant.jsx"
import { iniciarSesion } from 'views/User/Functions/Sesion'
import { headersCentroControl } from 'services/headers'
import { createToast } from 'reduxSlice/toastSlice'
import { useDispatch } from 'react-redux'

export const ModalSoporte = ({open1, setOpen1}) => {
  const [pass, setPass] = useState()
  const [count, setCount] = useState()
  const [loading, setLoading] = useState()
  const [validMail, setValidMail] = useState(true)
  const [validPass, setValidPass] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const token = sessionStorage?.token
  const dispatch = useDispatch()

  const toggle = () => setOpen1(!open1)

  const supportAction = () => {
    let status

    setLoading(true)

    fetch(urls.ccontrol + `impersonate`, {
      method: 'POST', headers: headersCentroControl('bearer ' + token),
      body: JSON.stringify({ "pass": pass, "email": count })
    }).then(res => {
      setLoading(false)
      if (res.status === 200) {
        status = res.status
        return res.json()
      } else if (res.status === 400) {
        status = res.status
        return res.json()
      } else {
        status = res.status
        throw res
      }
 
    }).then(data => {
      dispatch(createToast({ status: status, message: data.message }))
      if (data.token) {
        sessionStorage.setItem('emailImpersonate', sessionStorage.email)
        sessionStorage.setItem('tokenImpersonate', data.token)
        window.open('/projects/inicio')
        iniciarSesion(count, data.token, data)
        sessionStorage.removeItem('tokenImpersonate')
        sessionStorage.removeItem('emailImpersonate')
        toggle()
      } else {
      }
    })
      .catch(err =>
        console.error("ERROR. API Scada", err)
      )
  }

  const validateEmail = () => {
    setValidMail(validatorEmail(count))
  }

  const validatePass = (e) => {
    setPass(e)
    setValidPass(validatorPass(e))
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      supportAction()
      toggle()
    }
  }

  useEffect(() => {
    setCount()
    setPass()
    setValidMail(true)
    setValidPass(false)
  }, [open1])

  useEffect(() => {
    if (!validMail || !count) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [validMail, count])

  useEffect(() => {
    if (validPass || !pass) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [validPass, pass])

  return (
    <>
      <div className="d-flex align-items-center justify-content-center flex-wrap">
        <Modal loading={loading} onClick={(e) => e.stopPropagation()} classes={{ paper: 'rounded-lg' }} open={open1} setOpen={setOpen1} aria-labelledby="form-dialog-title" title="Acceder al proyecto" functSave={supportAction} disabled={isValid} buttonAction="Acceder">
          <Grid container className="pr-5 pl-5">
            <Grid className='pb-3' item xs={12} md={12} lg={12} xl={12}>
              <div className='pb-1'>
                <small className="color-black">
                  Cuenta del cliente:
                </small>
              </div>
              <InputStyled
                fullWidth
                value={count}
                onChange={(e) => setCount(e)}
                error={!validMail}
                errorMessage={'Email inválido'}
                onBlur={validateEmail}
                maxLength={50}
                onKeyDown={handleKeyDown}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12} >
              <div className='pt-2 pb-1'>
                <small className="color-black">
                  Contraseña de la cuenta de soporte:
                </small>
              </div>
              <InputStyled
                fullWidth
                type="password"
                autocomplete="off"
                value={pass}
                error={validPass}
                onChange={(e) => validatePass(e)}
                onKeyDown={handleKeyDown}
              />
            </Grid>
          </Grid>
        </Modal>
      </div >
    </>
  )
}