import React, { useEffect, useState } from 'react'
import { Grid } from "@mui/material"
import Chart from 'react-apexcharts'
import moment from 'moment'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const ActivePowerGraph = (props) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(true)
    const [graphData, setGraphData] = useState()
    const [initDate, setInitDate] = useState(`${moment().format("YYYY-MM-DD")}`)
    const [endDate, setEndDate] = useState(`${moment().format("YYYY-MM-DD")}`)
    const [endTime, setEndTime] = useState(`${moment().format("HH:mm")}`)

    useEffect(() => {
        if (moment(props.startt.format('YYYY-MM-DD')).isAfter(props.start.format('YYYY-MM-DD'))) {
            setLoading(true)
            setInitDate(`${props.start.format("YYYY-MM-DD")}`)
            setEndDate(`${props.end.format("YYYY-MM-DD")}`)
            setEndTime('23:59')
            setLoading(false)
        } else {
            setLoading(true)
            setInitDate(`${moment().format("YYYY-MM-DD")}`)
            setEndDate(`${moment().format("YYYY-MM-DD")}`)
            setEndTime(`${moment().format("HH:mm")}`)
            setLoading(false)
        }
    }, [props.start, props.end])



    useEffect(() => {
        if (props?.device && props.activeTabDevice === '0' && endDate && initDate) {
            const fetchDataGraph = async () => {
                setLoading(true)
                if (props?.device?.mabt_serial && props?.device?.id_tipo_dispositivo && props?.device?.numserie) {
                    fetch(urls.scada + `chart/getDataChartsCurves/${props?.device.numserie}?idCCMaster=${props?.device?.mabt_serial}`, {
                        method: 'POST', redirect: 'follow', headers: headersScada(token),
                        body: JSON.stringify({
                            "iniDate": initDate,
                            "endDate": endDate,
                            "iniTime": `00:00`,
                            "endTime": endTime,
                            "interval": "5",
                            "axis": [{
                                "idDeviceType": `${props?.device?.id_tipo_dispositivo}`,
                                "fields": ['pac1', 'pac2', 'pac3'],
                                "numseries": [`${props?.device?.numserie}`],
                                "unit": "kWh"
                            }]
                        })
                    })
                        .then(res => res.json())
                        .then(data => {
                            setGraphData(data);
                            setLoading(false)
                        })
                        .catch(err => {
                            console.error("ERROR. API Scada", err);
                            setLoading(true);
                        })
                }
            }
            fetchDataGraph()
        }

    }, [props?.device, props.activeTabDevice, endDate, initDate])

    const activePowerData = {

        series: [
            { name: "Potencia L1", data: graphData?.data?.[0]?.data },
            { name: "Potencia L2", data: graphData?.data?.[1]?.data },
            { name: "Potencia L3", data: graphData?.data?.[2]?.data }
        ],
        options: {
            chart: {
                events: { click: (event) => event.preventDefault(), },
                id: "activePower1",
                height: 350,
                type: "line",
                zoom: { enabled: true },
                animations: { enabled: false },
                toolbar: { autoSelected: 'pan', show: false }
            },
            xaxis: {
                categories: graphData?.categories,
                tickAmount: 40,
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return value.split(' ')[1]
                        }
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return (value / 1000).toFixed(2) + ' kW'
                        }
                    }
                }
            },
            dataLabels: { enabled: false },
            stroke: { width: 2, curve: "straight" },
        },
        seriesLine: [
            { name: "Potencia L1", data: graphData?.data?.[0]?.data },
            { name: "Potencia L2", data: graphData?.data?.[1]?.data },
            { name: "Potencia L3", data: graphData?.data?.[2]?.data }
        ],
        optionsLine: {
            legend: { show: false, },
            chart: {
                id: 'activePower2', height: 170, type: 'line',
                brush: { target: 'activePower1', enabled: true },
                selection: {
                    enabled: true,
                    xaxis: { min: graphData?.categories?.length - 24, max: graphData?.categories?.length },
                },
            },

            stroke: { width: 2, curve: "straight" },
            xaxis: {
                categories: graphData?.categories,
                tickAmount: 40,
                tooltip: { enabled: false },
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null && value !== 0) {
                            return value.split(' ')[1]
                        }
                    }
                }
            },
            yaxis: {
                tickAmount: 2,
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return (value / 1000).toFixed(2) + ' kW'
                        }
                    }
                }
            },
        },
    }

    return (
        <>
            <Grid container>
                <Grid item lg={12} className='mt-2'>
                    {!loading && props.activeTabDevice === '0' ?
                        <>
                            <Chart id='activePower1' options={activePowerData.options} series={activePowerData.series} type="line" width={"100%"} height={320} />
                            <Chart id='activePower2' options={activePowerData.optionsLine} series={activePowerData.seriesLine} type="line" width={"100%"} height={170} />
                        </>
                        :
                        <h2 className='text-center' style={{ height: '650px' }}>CARGANDO</h2>

                    }
                </Grid>

            </Grid>
        </>
    )
}
