import React, { useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material"
import { StyledTableCell, StyledTableCellOrdenado, StyledTableRow } from 'components/Table/TableStyle'
import { ordenarDatos } from "utils/Utils"
import { datoNull } from "./utils"

export const TablaMabt = ({ datosTabla }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null })
    const [filas, setFilas] = useState(30)
    const tableRef = useRef(null)

    return (
        <TableContainer className="boder-table">
            <InfiniteScroll
                dataLength={filas}
                next={() => {
                    setFilas(filas + 20)
                }}
                hasMore={datosTabla?.length > filas}
                loader={<h4 align='center'>Cargando más datos...</h4>}
            >
                <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead >
                        <TableRow>
                            {datosTabla && datosTabla.length > 0 && Object.entries(datosTabla[0]).map(([key, value]) => (
                                <StyledTableCellOrdenado align="center" celda={key} funcion={() => { ordenarDatos(datosTabla, sortConfig, setSortConfig, key) }} sortConfig={sortConfig}>{key}</StyledTableCellOrdenado>
                            ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosTabla && datosTabla.length > 0 &&
                            datosTabla?.slice(0, filas).map((dato, i) => {
                                return (
                                    <StyledTableRow
                                        style={{ padding: "0px", margin: "0px" }}
                                        key={i + 'tablaAlarm'}
                                    >
                                        {Object.entries(dato).map(([key, value]) => (
                                            <StyledTableCell align="left">
                                                {datoNull(value)}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </InfiniteScroll>
            {(!datosTabla || datosTabla.length === 0) &&
                <h4 align='center'>
                    No hay ningún dato en este momento
                </h4>
            }
        </TableContainer>
    )
}