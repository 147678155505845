import React, { useState, useEffect, useContext } from 'react'
import { urls } from 'utils/constant'
import { Loader } from 'components/Loader/Loader.jsx'
import { headersScada } from 'services/headers'
import { useLocation } from 'react-router-dom'
import { Grid } from "@mui/material"
import { GraphsRed } from './GraphsRed/GraphsRed.jsx'
import { HistoricalTableRed } from './Tables/HistoricalTableRed.jsx'
import { CurrentTableRed } from './Tables/CurrentTableRed.jsx'
import electricTower from 'assets/img/icons/consumption/electricTower.svg'
import moment from 'moment'
import { ScadaDeviceHeader } from 'views/Scada/SelfConsumption/ScadaDeviceHeader.jsx'
import "views/Scada/SelfConsumption/styleSelfConsumption.scss"
import { Filter } from 'views/Scada/FilterScada/FilterScada.jsx'
import { result } from 'utils/functionsforGraphs'
import { GlobalContext } from 'context/GlobalContext'
import { useDebounce } from 'use-debounce'
import { convertDatetime } from 'utils/Utils.jsx'
import CalendarComponent from 'components/Calendar/CalendarComponent.jsx'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha.jsx'
import { CsvDownloader } from '../CsvDownloader.jsx'
import { isToday } from 'utils/Utils.jsx'
import { formatDate } from 'utils/Utils.jsx'

export const ScadaRed = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split('/')[4]
    const [deviceType, setDeviceType] = useState(props?.data?.[0]?.deviceType)
    const [historicalData, setHistoricalData] = useState()
    const [loading, setLoading] = useState(true)
    const [finalData, setFinalData] = useState([])
    const [headersCsv, setHeadersCsv] = useState()
    const [selectedDevice, setSelectedDevice] = useState()
    const [activeTabDevice, setActiveTabDevice] = useState(Number(props?.device?.id_tipo_dispositivo) === 6 ? 0 : Number(props?.device?.id_tipo_dispositivo) === 2 ? 1 : 2)
    const toggleDevice = tab => setActiveTabDevice(tab)
    const [red, setRed] = useState([])
    let diff = props?.end.diff(props?.start, "days")
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, (props?.daily === "hours" && isToday(props?.start) === true) ? { label: "Fecha", key: "fecha" } : { label: "Fecha", key: "datetime" },
    { label: "Energía Vertida (kWh)", key: "eagendia" }, { label: "Energía Consumida (kWh)", key: "eaconsdia" },]
    const fechaNow = moment(props?.now).format("DD-MM-YYYY")
    const [checked, setChecked] = useState([]);
    const [activo, setActivo] = useState(true)
    const [updatedList, setUpdatedList] = useState([])
    const [children, setChildren] = useState([])
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectDaily, setSelectDaily] = useState("day")


    let historicalCSV = historicalData?.map(device => device?.data)
    let FinalDataCSV = finalData?.map(device => device?.data)
    let finalDataCSV2 = FinalDataCSV?.map(device => {
        let obj = Object.assign({}, device?.currentData)
        if (isToday(props?.start)) {
            obj.datetime = convertDatetime(obj?.datetime)
        }
        return obj
    })
    const { updateDevice } = useContext(GlobalContext)
    const [debouncedRed] = useDebounce(red, 200)

    const fetchData = async (numserie, start, end, daily) => {
        let url
        if (isToday(props?.start) && props?.daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idMaster}`
        }
        else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${daily}`
        }

        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                setLoading(false)
                const json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const arrayData = () => {
        let filterDeviceByType = props?.data?.filter(ele => ele.deviceType === deviceType)
        if (filterDeviceByType?.length > 0) {
            setHistoricalData([])
            setRed([])
            if (isToday(props?.start) && props?.daily === "days") {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(dev => {
                        fetchData(dev).then(item => {
                            setFinalData(prev => [...prev, { nick: item?.currentData?.nick, id_tipo_dispositivo: item?.currentData?.id_tipo_dispositivo, data: item, numserie: item?.currentData?.numserie, datetime: item?.currentData?.datetime }])
                            setHistoricalData(prev => [...prev, { nick: item?.currentData?.nick, id_tipo_dispositivo: item?.currentData?.id_tipo_dispositivo, data: item.currentData, numserie: item?.currentData?.numserie, datetime: formatDate(item?.currentData?.datetime) }])
                        })
                    })
                })
            } else {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(device => {
                        fetchData(device, props?.start, props?.end, props?.daily).then(item => {
                            item?.forEach(item2 => {
                                setHistoricalData(prev => [...prev, { nick: item2?.nick, data: item2, numserie: item2?.numserie }])
                            })
                        })
                    })
                })
            }
        }
    }

    const finalCSV = finalDataCSV2?.filter(dev => updatedList?.includes(dev?.numserie))
    const historCSV = historicalCSV?.filter(dev => updatedList?.includes(dev?.numserie))

    useEffect(() => {
        arrayData()
    }, [activeTabDevice, props?.end, props?.start, keySwitch])

    useEffect(() => {
        setFinalData([])
    }, [updateDevice])

    useEffect(() => {
        setRed([])
        if (isToday(props?.start) && props?.daily === "days") {
            const setObj = new Set();
            const uniqs = finalData?.map(device => result(finalData, device.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device.numserie)) {
                    setObj.add(device.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setRed(noRepeat)
        } else {
            setRed(historicalData)
        }
    }, [finalData, historicalData])

    useEffect(() => {
        const serialNumbers = props?.data?.flatMap(item => item.devices);
        const filteredArray = props?.childrenDevices?.filter(item => serialNumbers.includes(item.numserie));
        setChildren(filteredArray)
    }, [props?.childrenDevices])

    return (
        <Grid className="d-flex flex-column scada-component mt-3" item sm={12} lg={12} xl={12}>
            <ScadaDeviceHeader props={props} icon={electricTower} type="Red" />
            <span className="filter-component-calendar card-component-calendar">
                <div>
                    <CalendarComponent
                        maxDate={props?.end}
                        setStart={props?.setStart}
                        setEnd={props?.setEnd}
                        setDaily={props?.setDaily}
                        setSelectDaily={setSelectDaily}
                    />
                </div>
                <div className="ml-3 d-flex align-items-center">
                    <SelectorFecha
                        daily={props?.daily}
                        setDaily={props?.setDaily}
                        tipo={props?.daily}
                        setKeySwitch={setKeySwitch}
                        selectDaily={selectDaily}
                    />
                    {red && red.length > 0 &&
                        <CsvDownloader
                            className="csvDonwloader"
                            today={isToday(props?.start)}
                            daily={props?.daily}
                            props={props}
                            historicalData={historCSV !== undefined && historicalData !== undefined ? historCSV : historicalData}
                            finalData={finalCSV !== undefined && finalData !== undefined ? finalCSV : finalData}
                            start={props?.start}
                            end={props?.end}
                            setHeadersCsv={setHeadersCsv}
                            headersCsv={headersCsv}
                        />
                    }
                </div>
            </span>
            <span className='listItem-componente'>
                <ul className="nav nav-tabs" >
                    {props?.data?.filter(device => Number(device.deviceType) === 6).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 0 ? "active-button" : null} `} selected={activeTabDevice === 0} onClick={() => { toggleDevice(0); setDeviceType(7) }}>
                            Analizadores
                        </li>
                    }
                    {props?.data?.filter(device => Number(device.deviceType) === 2).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 1 ? "active-button" : null} `} selected={activeTabDevice === 1} onClick={() => { toggleDevice(1); setDeviceType(2) }}>
                            CCM2
                        </li>
                    }
                    {props?.data?.filter(device => Number(device.deviceType) === 4).length > 0 &&
                        <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 2 ? "active-button" : null} `} selected={activeTabDevice === 2} onClick={() => { toggleDevice(2); setDeviceType(4) }}>
                            CCM4
                        </li>
                    }
                </ul>
            </span>
            <span className="filter-scada-component">
                <Filter selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={setHeadersCsv}
                    headersCsv={headersCsv}
                    variableTableSelfConsumption={variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={loading}
                    setLoading={setLoading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device}
                    setRefresh={props?.setRefresh}
                    activo={activo}
                    setActivo={setActivo}
                    data1={debouncedRed}
                    checked={checked}
                    setChecked={setChecked}
                    updatedList={updatedList}
                    setUpdatedList={setUpdatedList}
                    props={props}
                    daily={props?.daily}
                    isMobile={props?.isMobile}
                    finalData={finalData}
                    master={props?.master}
                    masterPermission={props?.masterPermission}
                />
            </span>
            <div className="table-card " >
                {(red?.length === 0) ?
                    <Loader />
                    :
                    <>
                        {moment(props?.startt?.format('YYYY-MM-DD')).isAfter(props?.start?.format('YYYY-MM-DD')) || props?.daily !== "days" ?
                            <HistoricalTableRed isMobile={props?.isMobile} activo={activo} checked={updatedList} variableTableSelfConsumption={variableTableSelfConsumption} setHeadersCsv={setHeadersCsv} headersCsv={headersCsv} historicalData={historicalData} loading={loading} start={props?.start} daily={props?.daily} setDaily={props?.setDaily} data={props?.data} device={props.device} end={props?.end} childrenDevices={props.childrenDevices} deviceType={deviceType}
                                setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd}
                                setActivo={setActivo} setRed={setRed} data1={debouncedRed} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props}
                            />
                            :
                            <CurrentTableRed isMobile={props?.isMobile} activo={activo} setHeadersCsv={setHeadersCsv} checked={updatedList} headersCsv={headersCsv} daily={props?.daily} setFinalData={setFinalData} finalData={finalData} deviceType={deviceType} data={props?.data} loading={loading} device={props.device} start={props?.start} end={props?.end} setRefresh={props?.setRefresh} refresh={props?.refresh} now={fechaNow} selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                                dataSliced={props?.device} devices={props?.device} HeadersCsv={setHeadersCsv} variableTableSelfConsumption={variableTableSelfConsumption} historicalData={true} icon="text" setLoading={setLoading} setStart={props?.setStart} setEnd={props?.setEnd}
                                setActivo={setActivo} data1={debouncedRed} setChecked={setChecked} updatedList={updatedList} setUpdatedList={setUpdatedList} props={props}
                                setRed={setRed} />
                        }
                    </>
                }
            </div>
            <div className="graph-card">
                <GraphsRed childrenDevices={children}
                    updatedList={updatedList} data={props.data} deviceType={deviceType}
                    device={props?.devices} start={props?.start} end={props?.end}
                    groupData={props.groupData} setStart={props.setStart} now={props.now}
                    setEnd={props.setEnd} setgroupData={props.setgroupData}
                    titleButton={props?.titleButton} setTitleButton={props?.setTitleButton}
                    loading={loading} daily={props?.daily} setDaily={props?.setDaily}
                    historicalData={historicalData} diff={diff}
                    red={red}
                    setRefresh={props?.setRefresh} refresh={props?.refresh}
                    devices={props?.childrenDevices.filter(dev => dev.id_tipo_dispositivo === deviceType)}
                />
            </div>
        </Grid>
    )
}
