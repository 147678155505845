import React, { useEffect } from 'react'
import { HistoricalTableBateryDesktop } from './HistoricalTableBateryDesktop'
import { HistoricalTableBateryMobile } from './HistoricalTableBateryMobile'
import { useDebounce } from 'use-debounce';

export const HistoricalTableBatery = (props) => {
    const [debouncedData] = useDebounce(props?.historicalData, 200);
    const headTable = ["Nombre", "Fecha", "Carga Max (kWh)", "Carga Min (kWh)"]

    useEffect(() => {
        props?.setHeadersCsv(props?.variableTableSelfConsumption)
    }, [])

    return (
        <>
            {props?.isMobile === true ?
                <HistoricalTableBateryMobile selectedDevice={props?.selectedDevice}
                    inverter={props?.inverter}
                    setSelectedDevice={props?.setSelectedDevice}
                    daily={props?.daily}
                    end={props?.end}
                    isMobile={props?.isMobile}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    setHeadersCsv={props?.setHeadersCsv}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    data={debouncedData}
                    icon="text"
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable}
                    checked={props?.checked} active={props?.active} />
                :
                <HistoricalTableBateryDesktop
                    inverter={props?.inverter}
                    checked={props?.checked} setHeadersCsv={props?.setHeadersCsv} daily={props?.daily} headTable={headTable} data={debouncedData} active={props?.active} loading={props?.loading} noData={props?.noData} />
            }
        </>
    )
}
