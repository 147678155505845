import React from 'react'
import { Droppable } from '@hello-pangea/dnd'
import DraggableItem from './DraggableItem'
import "./LayOut.scss"

const DropContainer = ({ columnId, column, direction, isActive, disabled }) => {

  return (
    <Droppable droppableId={columnId} key={columnId} direction={direction}>
      {(provided) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={columnId === "Devices" ? "devices-container" : "relay-container px-3 flex-1"}
        >
          {column?.items?.map((item, index) => (
            <DraggableItem key={item?.id} item={item} index={index} columnId={columnId} isActive={isActive} disabled={disabled} />
          ))}
          {provided?.placeholder}
        </div>
      )}
    </Droppable>
  )
}
export default DropContainer
