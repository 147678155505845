import React from 'react'
import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { Loader } from 'components/Loader/Loader.jsx'
import { StyledTableCell } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import { toKiloW } from 'utils/functionsforGraphs'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { convertDatetime } from 'utils/Utils'

export const CurrentTableProductionMobile = (props) => {

    return (
        <>
            {props?.loading ?
                <Loader />
                :
                <div >
                    {props?.data && props?.data?.map((device, i) => {
                        return (
                            <>
                                {(props?.checked?.indexOf(device?.data?.currentData?.numserie) >= 0 || props?.activo === true) &&
                                    <TableContainer className="boder-table">
                                        <Table aria-label="customized table">
                                            <TableHead >
                                                <TableRow className="title-mobile-table">
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <> <StyledTableRow key={i} className="data-row-mobile">
                                                    <StyledTableCell className="data-table-mobile title">{props?.headTable?.[0]}</StyledTableCell>
                                                    <StyledTableCell className="data-table-mobile">{device?.data?.currentData?.nick}</StyledTableCell>
                                                </StyledTableRow>
                                                    <StyledTableRow key={i} className="data-row-mobile">
                                                        <StyledTableCell className="data-table-mobile title">{props?.headTable?.[1]}</StyledTableCell>
                                                        <StyledTableCell className="data-table-mobile">{convertDatetime(device?.data?.currentData?.datetime)}</StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow key={i} className="data-row-mobile">
                                                        <StyledTableCell className="data-table-mobile title">{props?.headTable?.[2]}</StyledTableCell>
                                                        <StyledTableCell className="data-table-mobile data">{(props?.arrayDevices.includes(props?.deviceType)) ?
                                                            toKiloW(device?.data?.currentData?.eaconsdia)
                                                            : toKiloW(device?.data?.currentData?.eagendia)}</StyledTableCell>
                                                    </StyledTableRow>
                                                    <StyledTableRow key={i} className="data-row-mobile">
                                                        <StyledTableCell className="data-table-mobile title">{props?.headTable?.[3]}</StyledTableCell>
                                                        <StyledTableCell className="data-table-mobile">{toKiloW(device?.data?.currentData?.pact ? device?.data?.currentData?.pact : device?.data?.currentData?.pact1)}</StyledTableCell>
                                                    </StyledTableRow>
                                                </>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </>
                        )
                    })}
                </div>
            }
        </>

    )
}