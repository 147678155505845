import React from 'react'
import { Table } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { StyledTableCell, StyledTableCellHead } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { toKiloW } from 'utils/functionsforGraphs'
import { convertDatetime } from 'utils/Utils'

export const CurrentTableConsumptionDesktop = (props) => {

    return (
        <div className="boder-table">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead style={{ borderRadius: '50px' }}>
                        <TableRow>
                            <StyledTableCellHead align="center">
                                <div>Nombre</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Fecha</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Energía Consumida (kWh)</div>
                            </StyledTableCellHead>
                            <StyledTableCellHead align="center">
                                <div>Potencia Activa (kW)</div>
                            </StyledTableCellHead>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props?.data && props?.data?.map((device, i) => {
                            return (
                                <React.Fragment key={i}>
                                    {Number(props?.deviceType) === 7 ?
                                        <>
                                            {(props?.checked?.indexOf(device?.data?.consumo?.numserie) >= 0 || props?.activo === true) &&
                                                <StyledTableRow StyledTableRow>
                                                    <StyledTableCell >{device?.nick}</StyledTableCell>
                                                    <StyledTableCell >{convertDatetime(device?.data?.consumo ? device?.data?.consumo?.datetime : device?.data?.currentData?.datetime)}</StyledTableCell>
                                                    <StyledTableCell >{toKiloW(device?.data?.consumo?.eaconsdia)}</StyledTableCell>
                                                    <StyledTableCell >{device?.data?.consumo?.pact ? toKiloW(device?.data?.consumo?.pact) : toKiloW(device?.data?.consumo?.pact1)}</StyledTableCell>
                                                </StyledTableRow>
                                            }
                                        </>
                                        :
                                        <>
                                            {(props?.checked?.indexOf(device?.data?.currentData?.numserie) >= 0 || props?.activo === true) &&
                                                <StyledTableRow>
                                                    <StyledTableCell >{device?.data?.currentData?.nick}</StyledTableCell>
                                                    <StyledTableCell >{convertDatetime(device?.data?.consumo ? device?.data?.consumo?.datetime : device?.data?.currentData?.datetime)}</StyledTableCell>
                                                    <StyledTableCell >{toKiloW(device?.data?.currentData?.eaconsdia)}</StyledTableCell>
                                                    <StyledTableCell >{toKiloW(device?.data?.currentData?.pact)}</StyledTableCell>
                                                </StyledTableRow>
                                            }
                                        </>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
