import moment from 'moment'
import React from 'react'
import { HistoricalDataTable } from './HistoricalDataTable'
import { TableData } from './TableData'

export const Tables = (props) => {
    return (
        <>
            {moment(props.startt.format('YYYY-MM-DD')).isAfter(props.start.format('YYYY-MM-DD')) ?
                <HistoricalDataTable closeDevices={props.closeDevices} device={props?.device} data={props.data} loading={props.loading} startt={props.startt}
                    start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart} now={props.now}
                    setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                :
                <TableData closeDevices={props.closeDevices} device={props?.device} data={props?.data} loading={props.loading} startt={props.startt}
                    start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart} now={props.now}
                    setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                    handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
            }
        </>
    )
}
