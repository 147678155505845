import React, { useState } from 'react'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const ModalDeleteEMS = ({ device, open, setOpen, setKeySwitch }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()



    const handleRemove = () => {
        setLoading(true)
        let status
        fetch(urls.scada + `emsConfig/${device.master.numserie}?idCcMaster=${device.master.numserie}`, {
            method: 'DELETE', headers: headersScada(token)
        }).then(res => {
            if (res.status === 200) {
                status = res.status
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
            } else {
                status = res.status
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
        }).catch((error) => {
            console.error("Ha habido un erroe en API Scada", error)
        }).finally(() => {
            setLoading(false)
            setKeySwitch(Date.now())
            setOpen(false)
        })

    }

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} disabled={loading} title={`Eliminar"${device?.master?.nick}"`} loading={loading} functSave={handleRemove} buttonAction="Eliminar" >
                <Grid item xs={12} className="text-center mt-2">
                    <Grid item xs={12} className="text-center mt-2">
                        <span>
                            <h3 style={{ fontSize: '1.2rem' }} className="font-weight-lighter">
                                ¿Deseas eliminar esta configuración?
                            </h3>
                        </span>
                    </Grid>
                    <hr className='m-0' />
                </Grid>
            </Modal>
        </>
    )
}
