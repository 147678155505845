import React, { useEffect, useState } from 'react'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { Grid } from '@mui/material'
import { ConfiguracionEMS } from './ConfiguracionEMS'
import { GeneralControlEms } from './GeneralControlEms'
import "./styleEms.scss"
import { MaxPower } from './MaxPower'
import { Loader } from 'components/Loader/Loader'
import { IconWarning } from 'utils/icon-centrocontrol'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'

export const Ems = ({ dispositivos, master, centroControl }) => {
    const token = sessionStorage?.token
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingControl, setLoadingControl] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [dataDevices, setDataDevices] = useState([])

    const fetchEms = async (numserie) => {
        try {
            const res = await fetch(urls.scada + `emsConfig/${numserie}?idCcMaster=${numserie}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Centro Control", err)
        }
    }

    const arrayEms = async () => {
        setLoading(true)
        setData([])
        if (master?.length > 0 && centroControl) {
            let result = await Promise.all(master?.map(device => fetchEms(device?.numserie).then(response => ({ ...response, master: device, }))))
            const arrayNew = result?.flatMap(array => array)
            setData(arrayNew)
        } else {
            let result = await fetchEms(master?.numserie).then(response => ({ ...response, master: master, }))
            setData([result])
        }
        setLoading(false)
    }

    const hasConfigEnableFalse = data?.length > 0 && data?.some(item => item?.config && item?.config?.enable === false);

    const getEmsDevice = async (numserie) => {
        try {
            const res = await fetch(urls.scada + `emsDevices/${numserie}?idCCMaster=${numserie}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                let json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchEmsDevices = async (numserie, body) => {
        try {
            const res = await fetch(urls.scada + `emsDevices/${numserie}?idCCMaster=${numserie}`, { method: 'POST', headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                let json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const arrayEmsDevices = async () => {
        setLoadingControl(true)
        setDataDevices([])
        let resultado
        let fecthMaster
        if (centroControl) {
            let result = await Promise.all(master?.map(dev => getEmsDevice(dev?.numserie)))
            const arrayNew = result?.flatMap(array => array)
            const numerosDeSerieFiltrados = arrayNew
                .filter(device => (device?.cloud === undefined))
                .map(device => device?.numserie);
            resultado = {
                devices: numerosDeSerieFiltrados
            }
        } else {
            let result = await getEmsDevice(master?.numserie)
            const numerosDeSerieFiltrados = result?.filter(device => (device?.cloud === undefined))?.map(device => device?.numserie);
            resultado = {
                devices: numerosDeSerieFiltrados
            }
        }
        if (centroControl) {
            fecthMaster = await Promise.all(master?.map(dev => fetchEmsDevices(dev?.numserie, resultado)))
        } else {
            fecthMaster = await fetchEmsDevices(master?.numserie, resultado)
        }
        const arrayNew = fecthMaster.flatMap(array => array)
        setDataDevices(arrayNew)
        setLoadingControl(false)
    }

    useEffect(() => {
        arrayEms()
        arrayEmsDevices()
    }, [master, keySwitch])

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={12} lg={12} xl={12} className="menu-configuracion">
                    <div selected={activeTab === 0} onClick={() => { setActiveTab(0) }}>
                        <span className={activeTab === 0 ? "list-item-icon active-button" : "list-item-icon"} >
                            Configuración EMS
                        </span>
                    </div>
                    <div selected={activeTab === 1} onClick={() => { setActiveTab(1) }}>
                        <span className={activeTab === 1 ? "list-item-icon active-button" : "list-item-icon"} >
                            Control General
                        </span>
                    </div>

                    <div selected={activeTab === 3} onClick={() => { setActiveTab(3) }}>
                        <span className={activeTab === 3 ? "list-item-icon active-button" : "list-item-icon"} >
                            Potencia Máxima
                            {hasConfigEnableFalse &&
                                <HtmlTooltip title={<span>Hay uno o más masters con la potencia máxima deshabilitada</span>}  >
                                    <IconWarning width={20} height={20} />
                                </HtmlTooltip>
                            }
                        </span>
                    </div>
                </Grid>
            </Grid>
            <>
                {loading ? <Loader /> :
                    <div className="container-table-configuration-ems">
                        {activeTab === 0 && <ConfiguracionEMS data={data} master={master} setKeySwitch={setKeySwitch} />}
                        {activeTab === 1 && <GeneralControlEms loading={loadingControl} master={centroControl ? master : [master]} devices={dispositivos} dataDevices={dataDevices} setKeySwitch={setKeySwitch} />}
                        {activeTab === 3 && <MaxPower data={data} setKeySwitch={setKeySwitch} dataDevices={dataDevices} />}
                    </div>
                }
            </>
        </>
    )
}

