import React, { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import { ImageDevice } from "utils/ImageDevice/ImageDevice.jsx"
import { urls } from "utils/constant.jsx"
import { headersScada } from "services/headers.jsx"
import { DeviceDate } from "utils/DeviceDate/DeviceDate.jsx"
import { CSVLink } from "react-csv"
import moment from "moment"
import ScadaConsumptionMain from "./ScadaConsumptionMain/ScadaConsumptionMain.jsx"
import { PowerLineGraph } from "./ScadaConsumptionPower/PowerLineGraph.jsx"
import { IntensityLineGraph } from "./ScadaConsumptionIntensity/IntensityLineGraph.jsx"
import { ConsumptionEnergyGraph } from "./ScadaConsumptionEnergy/EnergyBarGraph.jsx"
import PhasesTensionGraph from "./ScadaConsumptionTension/PhasesTensionGraph.jsx"
import IntensityRadialGraphs from "./ScadaConsumptionIntensity/IntensityRadialGraphs.jsx"
import ConsuptionTable from "./ConsuptionTable.jsx"
import { DropDownDevices } from "views/Devices/MenuDevices/DropDownDevices"
import { ArrowDirection, DeviceVisualizer } from "../ScadaConsumptionComponent/DeviceVisualizer.jsx"
import { dispositivos } from "utils/constant.jsx"
import { TensionLineGraph } from "./ScadaConsumptionTension/TensionLineGraphs.jsx"
import { compararFecha } from "utils/Utils.jsx"
import { SelectorFecha } from "views/SelectorCalendar/SelectorFecha.jsx"
import { esHoy } from "utils/Utils.jsx"
import { cambiarCabecera } from "utils/funcionesCsv.jsx"
import { isMobile } from "utils/Utils.jsx"
import { OjoConsumo } from "utils/icon-centrocontrol.jsx"
import { MenuInformesConsumo } from "./MenuInformesConsumo.jsx"
import { MenuGeneralConsumo } from "./MenuGeneralConsumo.jsx"
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip.jsx"
import { IconDownloadFile } from "utils/icon-centrocontrol.jsx"
import { Loader } from "components/Loader/Loader.jsx"
import CalendarComponent from "components/Calendar/CalendarComponent.jsx"

export const GaugesCharts = (props) => {
  const token = sessionStorage?.token
  const [loading, setLoading] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [loadingGraph, setLoadingGraph] = useState(true)
  const [hiredPotency, setHiredPotency] = useState()
  const [graphData, setGraphData] = useState()
  const [intensityData, setIntensityData] = useState()
  const [tensionData, setTensionData] = useState()
  const [energyData, setEnergyData] = useState()
  const [activeTab, setActiveTab] = useState(0)
  const [graphVisible, setGraphVisible] = useState(true)
  const [oneDay, setOneDay] = useState(true)
  const [start, setStart] = useState(moment())
  const [end, setEnd] = useState(moment())
  const [groupDate, setGroupDate] = useState("day")
  const [daily, setDaily] = useState("days")
  const mobile = isMobile(1535)

  const fetchEnergy = async () => {
    setLoading(true)
    try {
      let res = null
      if (props?.device?.master_serial === undefined && props?.id_padre !== undefined && props?.device?.numserie !== undefined
      ) {
        res = await fetch(
          urls.scada + `getHistoricalDataScada/${props?.device?.numserie}?iniDate=${start.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupDate=${daily}`,
          { method: "GET", headers: headersScada(token) }
        )
      } else if (props?.device?.numserie) {
        res = await fetch(
          urls.scada + `getHistoricalDataScada/${props?.device?.numserie}?idCCMaster=${props?.device?.master_serial ? Number(props?.device?.master_serial) : Number(props?.devices?.numserie)}&iniDate=${start.format("YYYY-MM-DD")}&endDate=${end?.format("YYYY-MM-DD")}&groupDate=${daily}`,
          { method: "GET", headers: headersScada(token) }
        )
      }
      if (res.status === 200) {
        const json = await res.json()
        setEnergyData(json)
      } else {
        return []
      }
      setLoading(false)
    } catch (err) {
      console.error("ERROR. API Scada", err)
    }
  }

  const [activeTabDevice, setActiveTabDevice] = useState(0)
  const [activeTabGraph, setActiveTabGraph] = useState(0)
  const toggleDevice = (tab) => setActiveTabDevice(tab)
  const [graphVisibility, setGraphVisibility] = useState(true)

  let url
  if (props?.loose === 0) {
    url = `chart/getDataChartsCurves/${props?.device?.numserie}?idCCMaster=${props?.idMaster}`
  } else {
    url = `chart/getDataChartsCurves/${props?.device?.numserie}`
  }

  useEffect(() => {
    if (props.data && !props.data.messagge && props.data?.currentData?.potencia_contratada !== " - ") {
      setHiredPotency(props.data?.currentData?.potencia_contratada)
    } else {
      setHiredPotency(0)
    }
  }, [])

  const fetchDataGraph = () => {
    let auxToday = start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? true : false;
    let aux = props?.device?.id_tipo_dispositivo === dispositivos.ccm2 ? ["pact1"] : props?.device?.id_tipo_dispositivo === dispositivos.ccm1 ? ["pact"] : ["pact", "pact1", "pact2", "pact3"];
    if (props?.device) {
      setLoadingGraph(true)
      if (props?.loose !== undefined) {
        fetch(urls.scada + url, {
          method: "POST",
          redirect: "follow",
          headers: headersScada(token),
          body: JSON.stringify({
            iniDate: `${start.format("YYYY-MM-DD")}`,
            endDate: `${end.format("YYYY-MM-DD")}`,
            iniTime: `00:00`,
            endTime: auxToday ? `${moment().format("HH:mm")}` : `23:59`,
            interval: "5",
            axis: [{
              idDeviceType: Number(props?.device?.id_tipo_dispositivo ? props?.device?.id_tipo_dispositivo : props?.id_tipo_dispositivo),
              fields: aux,
              numseries: [`${props?.device?.numserie}`],
              unit: "kW",
            },
            ],
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setGraphData(data)
            setLoadingGraph(false)
            setLoading(false)
            setLoadingData(false)
          })
          .catch((err) => {
            console.error("ERROR. API Scada", err)
            setLoadingGraph(true)
          })
      }
    }
  }

  const fetchDataIntensity = () => {
    let auxToday = start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? true : false;
    let aux = props?.device?.id_tipo_dispositivo === 2 ? ["i1"] : props?.device?.id_tipo_dispositivo === dispositivos.ccm1 ? ["i_med"] : ["i1", "i2", "i3"]
    if (props?.device) {
      setLoadingGraph(true);
      fetch(urls.scada + url, {
        method: "POST",
        redirect: "follow",
        headers: headersScada(token),
        //Le paso los valores
        body: JSON.stringify({
          iniDate: `${start.format("YYYY-MM-DD")}`,
          endDate: `${end.format("YYYY-MM-DD")}`,
          iniTime: `00:00`,
          endTime: auxToday ? `${moment().format("HH:mm")}` : `23:59`,
          interval: "5",
          axis: [
            { idDeviceType: Number(props?.device?.id_tipo_dispositivo), fields: aux, numseries: [`${props?.device?.numserie}`], unit: "A", },
          ],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setIntensityData(data)
          setLoadingData(false)
        })
        .catch((err) => {
          console.error("ERROR. API Scada", err)
          setLoadingGraph(true)
        })
    }
  }

  const fetchDataTension = () => {
    let auxToday = start.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? true : false
    let aux = props?.device?.id_tipo_dispositivo === 2 ? ["v1"] : props?.device?.id_tipo_dispositivo === dispositivos.ccm1 ? ["vac"] : ["v1", "v2", "v3"]
    if (props?.device) {
      setLoadingGraph(true);
      fetch(urls.scada + url, {
        method: "POST",
        redirect: "follow",
        headers: headersScada(token),
        //Le paso los valores
        body: JSON.stringify({
          iniDate: `${start.format("YYYY-MM-DD")}`,
          endDate: `${end.format("YYYY-MM-DD")}`,
          iniTime: `00:00`,
          endTime: auxToday ? `${moment().format("HH:mm")}` : `23:59`,
          interval: "5",
          axis: [
            {
              idDeviceType: Number(props?.device?.id_tipo_dispositivo),
              fields: aux,
              numseries: [`${props?.device?.numserie}`],
              unit: "A",
            },
          ],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setTensionData(data)
          setLoadingGraph(false)
        })
        .catch((err) => {
          console.error("ERROR. API Scada", err)
          setLoadingGraph(true)
        })
    }
  }

  const selectorFecha = (tipo) => {
    if (tipo === "day" && (daily !== "days" || daily !== "hours")) {
      setDaily("days")
      setGroupDate(tipo)
    } else if (tipo === "month" && (daily !== "days" || daily !== "months")) {
      setDaily("months")
      setGroupDate(tipo)
    } else if (tipo === "year" && (daily !== "months" || daily !== "years")) {
      setDaily("months")
      setGroupDate(tipo)
    }
  }

  useEffect(() => {
    if (props?.device && !props?.resumeView) {
      let visible = compararFecha(start, end)
      setGraphVisible(visible)
      if (props.device.id_tipo_dispositivo === dispositivos.ccmabtv) {
        setGraphData()
        fetchDataGraph()
      } else if (!visible) {
        setActiveTabGraph(2)
      } else {
        if (activeTabGraph === 0) {
          setGraphData()
          fetchDataGraph()
        } else if (activeTabGraph === 1) {
          setIntensityData()
          fetchDataIntensity()
        } else if (activeTabGraph === 3) {
          setTensionData()
          fetchDataTension()
        }
      }
    }
  }, [props.idMaster, props?.device, activeTabGraph, props?.activeTabDevice, start])

  useEffect(() => {
    if (props?.activeTabDevice === 3) {
      fetchEnergy()
    }
  }, [start, daily, props?.device])


  useEffect(() => {
    if (start?.format("DD-MM-YYYY") !== end?.format("DD-MM-YYYY")) {
      setOneDay(false)
    } else {
      setOneDay(true)
    }
  }, [start, end])

  return (
    <Grid container spacing={0} className="pl-3 pr-3">
      {props?.activeTabDevice === 3 && props?.device !== undefined && (
        <Grid item md={12} lg={12}>
          <Grid item md={12} lg={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "10px", boxShadow: "0px 0px 0px" }}>
            <span>
              {props?.device?.id_tipo_dispositivo && (
                <span style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  {props?.device?.id_tipo_dispositivo === dispositivos.ccmabtm &&
                    <DropDownDevices device={props?.device} masterPermission={props?.masterPermission} />
                  }
                  {props?.device?.id_tipo_dispositivo !== dispositivos.ccmabtm && props?.device?.id_tipo_dispositivo !== dispositivos.ccmabtv &&
                    <ImageDevice id_type_device={props?.device?.id_tipo_dispositivo} width={30} />
                  }
                  <h3 className="ml-2 mr-2"> {props?.device?.nick}</h3>
                </span>
              )}
            </span>
            {props?.devices?.id_tipo_dispositivo !== dispositivos.ccmabt && (
              <Grid item md={1} style={{ display: "flex", alignItems: "flex-start", marginTop: "20px" }}>
                <DeviceVisualizer device={props?.device?.id_tipo_dispositivo} style={{ height: 50, marginRight: "2%" }} />
                <ArrowDirection style={{ height: 30 }} direction={props?.devices?.activo} />
              </Grid>
            )}
          </Grid>
          <div className="grayDivider mb-3" />
          <Grid item md={12} lg={12}>
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              {!props?.resumeView && props?.device?.id_tipo_dispositivo !== dispositivos.ccmabtm && props?.device?.id_tipo_dispositivo !== dispositivos.ccmabtv && (
                <DeviceDate
                  device={props?.devices}
                  loading={loading}
                  data={props?.data?.currentData}
                  masterPermission={props?.masterPermission[props?.device?.master_serial ? props?.device?.master_serial : props?.device?.numserie]}
                />
              )}
            </span>
          </Grid>
          <Grid item md={12} lg={12}>
            {props?.data?.currentData?.i2 !== undefined && props?.data?.currentData?.i3 !== undefined && props?.device?.id_tipo_dispositivo !== dispositivos.ccmabtv && (
              <MenuGeneralConsumo id_tipo_dispositivo={props?.devices.id_tipo_dispositivo} activeTab={activeTab} seleccionarTab={setActiveTab} />
            )}
          </Grid>
          {(props.data === undefined || energyData === undefined) &&
            <Loader />
          }
          {props.data && energyData && (
            <>
              <Grid item md={12} style={{ display: "flex", alignItems: props?.data?.i2 !== undefined && props?.data?.i3 !== undefined ? "center" : "none", justifyContent: props?.data?.i2 !== undefined && props?.data?.i3 !== undefined ? "center" : "none" }}>
                {activeTab === 0 && (
                  /* potencia */
                  <Grid item md={12} index={0} style={{ height: mobile ? 'auto' : '29em' }}>
                    <ScadaConsumptionMain
                      intensityData={intensityData}
                      ccm={props?.devices}
                      loose={props?.loose}
                      activeTab={activeTab}
                      device={props?.device}
                      data={props?.data?.currentData}
                      graphData={graphData}
                      loading={loading}
                      idMaster={props?.idMaster}
                      loadingData={loadingData}
                      hiredPotency={hiredPotency}
                    />
                  </Grid>
                )}
                {activeTab === 1 && (
                  /* intensidad */
                  <Grid item md={12} index={1}>
                    <IntensityRadialGraphs activeTab={activeTab} data={props.data.currentData} loading={loading} ccm={props?.devices} />
                  </Grid>
                )}
                {activeTab === 2 && (
                  /* Tension */
                  <Grid item md={12} index={2}>
                    <PhasesTensionGraph data={props.data.currentData} ccm={props?.devices} loading={props?.loadingData} />
                  </Grid>
                )}
                {/* imagen dispositivo */}
              </Grid>
              {/*INFORMES*/}
              <Grid item xs={12}
                style={{ padding: window.innerWidth < 1536 ? "3%" : "0%" }}>
                {/*CASILLA TÍTULO*/}
                <ul className="nav nav-tabs list-general mb-5">
                  <li className="nav-item" button selected={activeTabDevice === 0} onClick={() => { toggleDevice(0) }}>
                    <span className="nav-link active list-p">Informes</span>
                  </li>
                </ul>
                {/*BOTONES DE SELECCIÓN*/}
                <Grid item xs={12} md={12} className={`tab-item-wrapper active`} index={0}>
                  <Grid container>
                    <Grid item xs={12} md={8}>
                      <MenuInformesConsumo id_tipo_dispositivo={props?.devices?.id_tipo_dispositivo} activeTab={activeTabGraph} seleccionarTab={setActiveTabGraph} graphVisible={graphVisible} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <div style={{ display: "flex", justifyContent: window.innerWidth > 1536 ? "flex-end" : "center", marginTop: "1%", marginBottom: window.innerWidth > 1536 ? "0%" : "5%", marginLeft: window.innerWidth > 1300 && window.innerWidth < 1500 ? "10%" : "0%" }}>
                        <div style={{ marginRight: "0.5em" }}>
                          <CalendarComponent
                            maxDate={end}
                            setStart={setStart}
                            setEnd={setEnd}
                            selectorFecha={selectorFecha}
                          />
                        </div>
                        <div >
                          <SelectorFecha
                            daily={daily}
                            setDaily={setDaily}
                            tipo={groupDate}
                          />
                        </div>
                        <CSVLink data={energyData ? cambiarCabecera(energyData, props?.mabt, false, false, esHoy(start)) : []} separator={";"} style={{ color: "white", marginTop: "3px" }} filename={`${props.device.nick}._${start ? start?.format("DD-MM-YYYY") : " "}_${end ? end?.format("DD-MM-YYYY") : " "}.csv`}>
                          <HtmlTooltip title={<span>Descargar datos</span>}>
                            <IconDownloadFile />
                          </HtmlTooltip>
                        </CSVLink>
                      </div>
                    </Grid>
                  </Grid>
                  {/*GRÁFICAS*/}
                  <Grid item xs={12} md={12}
                    style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", marginBottom: "15px" }}>
                    <span style={{ height: 30, marginRight: "10px", cursor: "pointer" }} className="d-flex align-items-center" onClick={() => setGraphVisibility(!graphVisibility)} >
                      <OjoConsumo onClick={() => setGraphVisibility(!graphVisibility)} />
                    </span>
                    {!oneDay && <h3 className="pr-2" >Desde</h3>}
                    <h3 className="pr-2">{start?.format("DD-MM-YYYY")}</h3>
                    {!oneDay && <h3>hasta</h3>}
                    <h3 className="pl-2">{start?.format("DD-MM-YYYY") !== end?.format("DD-MM-YYYY") && end?.format("DD-MM-YYYY")} </h3>
                  </Grid>
                  {graphVisibility && (
                    <Grid item xs={12} md={12} className={"powerMargins"}>
                      {activeTabGraph === 0 && (
                        <PowerLineGraph data={props.data} idMaster={props.idMaster} loadingData={loadingGraph} graphData={graphData} activeTab={activeTabGraph} />
                      )}
                      {activeTabGraph === 1 && (
                        <IntensityLineGraph data={props.data} idMaster={props.idMaster} loadingData={loadingGraph} graphData={intensityData} activeTab={activeTabGraph} />
                      )}
                      {activeTabGraph === 3 && (
                        <TensionLineGraph data={props.data} idMaster={props.idMaster} loadingData={loadingGraph} graphData={tensionData} activeTab={activeTabGraph} />
                      )}
                      {activeTabGraph === 2 && (
                        <ConsumptionEnergyGraph start={start} loadingData={loading} graphData={energyData} activeTab={activeTabGraph} />
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item style={{ marginTop: "20px", marginBottom: "10px" }}>
                  <ConsuptionTable
                    daily={daily}
                    data={props?.data?.currentData}
                    energyData={energyData}
                    loading={loading}
                    start={start}
                    hoy={esHoy(start)}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Grid>
  )
}