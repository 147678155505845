import React, { useEffect, useState } from "react"
import { Grafica } from "components/Graph/Grafica"

export const colores_graficas = [
    ['rgb(20, 20, 255)', 'rgb(20, 20, 255, 0.2)'],
    ['rgb(60, 120, 255)', 'rgb(60, 120, 255, 0.2)'],
    ['rgb(60, 250, 255)', 'rgb(60, 250, 255, 0.2)'],
    ['rgb(90, 80, 255)', 'rgb(90, 80, 255, 0.2)'],
    ['rgb(90, 190, 255)', 'rgb(90, 190, 255, 0.2)'],
]

export const GraphConLine = ({ datosGrafica, escalaTipo, category, updatedList }) => {
    const [graficaDatos, setGraficaDatos] = useState()

    function funcionTickY(value) {
        return value.toFixed(2) + ' ' + escalaTipo;
    }


    useEffect(() => {
        let grafica = []
        let i = 0
        datosGrafica?.forEach(datos => {
            if (updatedList?.indexOf(datos?.numserie) >= 0) {
                grafica.push({
                    label: datos?.name,
                    data: datos?.data,
                    borderColor: colores_graficas?.[i]?.[0],
                    backgroundColor: colores_graficas?.[i]?.[0],
                    pointRadius: 0
                })
            }
            i = i + 1
        })
        setGraficaDatos(grafica)
    }, [datosGrafica, updatedList])



    return (
        <div style={{ height: '30em' }}>
            {graficaDatos &&
                <Grafica
                    tipo='line' labels={category} datos={graficaDatos} posicion="bottom" zoom={true}
                    maintainAspectRatio={false} scalesX={true} scalesY={true} funcionTickY={funcionTickY}
                />
            }
        </div>
    )
}