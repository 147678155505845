import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { AuthFooter } from "views/Footer/AuthFooter"
import { NavBar } from "views/NavBar/NavBar"
import { ProjectsListNew } from "views/Project/ProjectsListNew"

const Projects = () => {
  const token = sessionStorage?.token

  return (
    <>
      <NavBar/>
      <div className="main-content d-flex flex-column">
        <div className="">
          <Routes>
            <Route path='inicio' element={!localStorage.getItem(token) ? <Navigate to="/auth/login" replace /> : <ProjectsListNew />} />
            <Route path="*" element={<Navigate to="/projects" replace />} />
          </Routes>
        </div>
      </div>
      <AuthFooter />
    </>
  )
}

export default Projects
