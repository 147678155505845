import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { updateLoading } from 'reduxSlice/loadingSlice'
import { headersCentroControl, headersScada } from 'services/headers'
import { Loader } from "components/Loader/Loader"
import { Modal } from 'components/Modal/Modal'
import { TerminalMQTT } from '../TerminalMQTT/terminalMQTT'
import { HtmlTooltip } from "components/Tooltip/HtmltoolTip"
import { CategoryType } from 'utils/MiddleWareProjects'
import { IconWarning } from 'utils/icon-centrocontrol'
import { ConfiguracionPlantaMabt } from '../../Mabt/ConfiguracionPlantaMabt'
import { EditDataDevice } from '../EditDevice/EditDataDevice'
import { EditPlant } from '../EditPlant/EditPlant'
import { urls, instalaciones, dispositivos } from 'utils/constant'
import './EditInstall.scss'

export const EditInstall = ({ token, configuracion, scadaSelf, modalEditar, setModalEditar, actualProject, instalacioneSeleccionadas, dispositivo }) => {
    const [loadingMabt, setLoadingMabt] = useState(false)
    const [option, setOption] = useState(scadaSelf ? 1 : 0)
    const [inversor, setInversor] = useState(false)
    const optionAction = [
        {
            string: 'Configurar planta',
            valor: 1
        },
        {
            string: 'Configurar dipositivo',
            valor: 2
        }
    ]

    const optionStrings = (configuracion.id_tipo_dispositivo === 0 || configuracion?.error)
        ? optionAction
        : optionAction.filter(option => option.valor === 2)
    const [configData, setConfigData] = useState([])
    const [configOrder, setConfigOrder] = useState([])
    const [stateOriginal, setStateOriginal] = useState([])
    const [statePrev, setStatePrev] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingAp, setLoadingAp] = useState(false)
    const [subOption, setSubOption] = useState(0)
    const [subOptionStrings, setSubOptionStrings] = useState([])
    const [answer, setAnswer] = useState("")
    const [apply, setApply] = useState(true)
    const [instType, setInstType] = useState()
    const [MABT, setMABT] = useState()
    let dispatch = useDispatch()

    let mensajesError = [
        'Introduzca un número válido',
        'No se admiten decimales',
        'Máximo 1000000',
        'Introduzca un número positivo'
    ]

    const errorComparer = (string) => {
        let res = false
        if (string === mensajesError[0]
            || string === mensajesError[1]
            || string === mensajesError[2]
            || string === mensajesError[3]) {
            res = true
        }
        return res
    }

    const inversorMap = (serie) => {
        let res = false
        serie.forEach((device) => {
            if (device.id_tipo_dispositivo === dispositivos.inversor) {
                res = true
            }
        })
        setInversor(res)
    }

    const defaultOptions = (serie) => {
        let aux = serie.filter(device => device.id_tipo_dispositivo === 0);
        switch (aux[0]?.inst_type) {
            case instalaciones.consumption:
                setSubOption(1)
                setInstType('Consumo')
                break
            case instalaciones.photovoltaic:
                setSubOption(2)
                setInstType('Producción')
                break
            case instalaciones.selfConsumption:
                setSubOption(3)
                setInstType('Autoconsumo')
                break
            case undefined:
                setSubOption(0)
                break
            case null:
                setSubOption(0)
                break
            default:
                setSubOption(0)
                break
        }
    }

    const fetchConfig = async (master) => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + `getConfigPlant/${master.numserie}`,
                {
                    method: 'GET',
                    headers: headersCentroControl(token)
                })
            if (res.status === 200) {
                let json = await res.json()
                json = json?.length === 0 ? [] : json
                setConfigData(json)
                inversorMap(json)
                defaultOptions(json)
                setConfigOrder(copyData(json))
                setStateOriginal(copyData(json))
                setStatePrev(copyData(json))
            } else {
                setConfigData([])
                setConfigOrder([])
                setStateOriginal([])
                setStatePrev([])
            }
            return true
        } catch (err) {
            console.error("No se ha podido traer datos de la API", err)
        } finally {
            setLoading(false)
        }
    }

    const copyData = (res) => {
        let aux = []
        let toMap = res ? res : configData
        toMap.forEach((device, index) => {
            let obj = Object.assign({}, device)
            if (obj.activo === null) {
                obj.activo = 0
            }
            aux[index] = obj
        })
        return aux
    }

    const fetchOrder = async (serie, master) => {
        setLoadingAp(true)
        setAnswer("")
        let objectSerie = [...serie]
        if (master?.id_tipo_dispositivo !== dispositivos.ccmaster) {
            objectSerie.forEach(ele => {
                ele.master_FW = null
                ele.firmware = null
            })
        }
        let url
        let status
        if (master?.loose === 0 || master?.loose === 20 || master?.id_tipo_dispositivo === 0 || master?.id_tipo_dispositivo === 20 || configuracion?.id_tipo_dispositivo === 0 || configuracion?.id_tipo_dispositivo === 20) {
            url = `updateDevicePlant/${configuracion.numserie ? configuracion.numserie : master.numserie}?idCCMaster=${configuracion.numserie ? configuracion.numserie : master.numserie}`
        } else {
            url = `updateDevicePlant/${configuracion.numserie ? configuracion.numserie : master.numserie}`
        }
        fetch(urls.scada + url, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(objectSerie) })
            .then(res => {

                if (res.status === 200) {
                    status = res.status
                    setConfigData(configOrder)
                    setStateOriginal(copyData(configOrder))
                    setStatePrev(copyData(configOrder))
                    setConfigOrder(copyData(configOrder))
                    return res.json()
                } else {
                    status = res.status
                    fetchConfig(configuracion?.version ? configuracion : master)
                    return []
                }
            }).then(data => {
                if (status === 200) {
                    setAnswer(data)
                } else {
                    setAnswer([{ message: "Ha habido un error al conectar con el dispositivo" }])
                }
            }).catch(err => {
            }).finally(() => {
                setLoadingAp(false)
                dispatch(updateLoading())
            })
        setApply(true)
    }

    const iTDefine = (num) => {
        let aux = null
        switch (num) {
            case 1:
                aux = instalaciones.consumption
                break
            case 2:
                aux = instalaciones.photovoltaic
                break
            case 3:
                aux = instalaciones.selfConsumption
                break
            default:
                aux = null
        }
        return aux
    }

    const compareOrder = () => {
        let copia = copyData()
        let aux = []
        configOrder.forEach((device, key) => {
            if (
                (
                    device.tipo !== copia[key].tipo
                    || device.nick !== copia[key].nick
                    || device.potencia_contratada !== copia[key].potencia_contratada
                    || device.activo !== copia[key].activo
                    || device.inst_type !== copia[key].inst_type
                )
                && errorComparer(device.potencia_contratada) === false
            ) {
                device.firmware = configuracion?.version ? configuracion?.version : device?.master_FW
                if (device.id_tipo_dispositivo === dispositivos.ccmaster) {
                    device.variable = 'update_config_plant'
                } else {
                    device.variable = 'update_config_devices'
                }
                aux.push(device)
            }
        })
        let master = dispositivo?.filter(dev => dev?.id_tipo_dispositivo === dispositivos.ccmaster)
        if (aux.length > 0) {
            fetchOrder(aux, master?.[0])
        }
        else {
            setAnswer('No hay cambios que aplicar.')
        }
    }


    useEffect(() => {
        if (option !== 0 && !scadaSelf) {
            fetchConfig(configuracion)
        } else if (scadaSelf) {
            fetchConfig(dispositivo?.[0])
        }
    }, [option, scadaSelf])


    /**
     * @description establece las subOpciones en base a la opción seleccionada,
        y al vaor de inversor. 
        Filtra si hay inversor en la instalación, en cuyo caso deshabilita la opción Consumo.
        Es un switch pensando en añadir nuevas opciones en un futuro.
     */
    useEffect(() => {
        if (option === 1) {
            setSubOptionStrings([
                (!inversor &&
                    (
                        {
                            string: 'Consumo',
                            valor: 1
                        }
                    )
                ), {
                    string: 'Producción',
                    valor: 2
                },
                {
                    string: 'Autoconsumo',
                    valor: 3
                }
            ])
        }
    }, [inversor, option])

    /**
    * @description función que cambia la opción seleccionada
    */
    const changeOptions = (e) => {
        setOption(e.target.value)
        setConfigOrder(copyData)
        setStateOriginal(copyData)
        setStatePrev(copyData)
        setApply(true)
        setSubOption(0)
    }

    const fetchMabt = async (numSerie) => {
        let url = `getccm/${numSerie}?idCCMaster=${numSerie}`
        try {
            const res = await fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
            const json = await res.json()
            setLoadingMabt(false)
            return json
        }
        catch (err) {
            console.error(err)
        }
    }

    /**
     * @description Saca el dispositivo en el cual coincide con el seleccionado
     */
    useEffect(() => {
        async function fetchData() {
            if (configuracion?.tipo === "MABT") {
                let dis
                setLoadingMabt(true)
                dis = await fetchMabt(configuracion.numserie)
                setMABT([dis])
            }
        }
        fetchData()
    }, [configuracion])

    return (
        <>
            {!scadaSelf &&
                <Modal
                    maxWidth="xl"
                    open={modalEditar}
                    setOpen={setModalEditar}
                    title="Configuración por MQTT"
                    footer=" "
                >
                    <Grid container className="mb-5">
                        <Grid item xs={12}>
                            {configuracion.tipo !== "MABT" ?
                                <span className={configuracion.tipo === "MABT" ? "edit-install-content-mabt" : "edit-install-content"}>
                                    <span className={`${configuracion.tipo === "MABT" ? "edit-install-cotent-mabt" : null} edit-install-content-data`} style={{ paddingLeft: "30px" }}  >
                                        <div className="edit-install-content-numserie">
                                            <h3>{configuracion.nick}</h3>
                                            <span className="edit-install-content-VerNun">
                                                <p className="edit-install-content-bold">FW</p>
                                                <p >{configuracion?.version}v</p>
                                            </span>
                                            <span className="edit-install-content-VerNun">
                                                <p className="edit-install-content-bold">NS</p>
                                                <p>{configuracion?.numserie}</p>
                                            </span>
                                        </div>
                                        <>
                                            <div className="edit-install-content-select " >
                                                <FormControl sx={{ minWidth: 200 }} >
                                                    <InputLabel
                                                        sx={{ color: 'black !important' }}
                                                        id="demo-simple-select-autowidth-label"
                                                    >Opciones</InputLabel>
                                                    <Select
                                                        className="edit-install-content-formControl"
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        labelStyle={{ color: 'black' }}
                                                        value={option}
                                                        onChange={(e) => { changeOptions(e) }}
                                                        autoWidth
                                                        label="Opciones"
                                                        sx={{
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                border: '2px solid #F59C2F',
                                                            },
                                                        }}>
                                                        {optionStrings.map((elm, key) => {
                                                            return (
                                                                <MenuItem
                                                                    key={'menuItem' + key}
                                                                    value={elm.valor}
                                                                >
                                                                    <span style={{ marginRight: '5px' }}>{elm.string}</span>
                                                                    {elm.valor === 1 && configuracion?.permissions?.error === 1 && (
                                                                        <HtmlTooltip
                                                                            style={{ marginBottom: '3px' }}
                                                                            title={<span> {instType !== undefined &&
                                                                                `El tipo de instalación '${instType}', no 
                                                                        coincide con el tipo de proyecto 
                                                                        '${CategoryType(actualProject?.category)}`
                                                                            }</span>
                                                                            }
                                                                        >
                                                                            <IconWarning width={15} height={15} />
                                                                        </HtmlTooltip>
                                                                    )}
                                                                    {elm.valor === 2 && configuracion?.permissions?.error === 2 && (
                                                                        <HtmlTooltip
                                                                            style={{ marginBottom: '3px' }}
                                                                            title={<span>Revise la configuración</span>
                                                                            }
                                                                        >
                                                                            <IconWarning width={15} height={15} />
                                                                        </HtmlTooltip>
                                                                    )}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                {option !== 0 && option !== '' && option !== 2 &&
                                                    <FormControl sx={{ minWidth: 200 }} >
                                                        <InputLabel sx={{ color: 'black !important' }} id="demo-simple-select-autowidth-label">Tipos de planta</InputLabel>
                                                        <Select
                                                            className="edit-install-content-formControl"
                                                            labelId="demo-simple-select-autowidth-label"
                                                            id="demo-simple-select-autowidth"
                                                            sx={{
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    border: '2px solid #F59C2F',
                                                                },
                                                            }}
                                                            Select={true}
                                                            value={subOption}
                                                            onChange={(e) => setSubOption(e.target.value)}
                                                            autoWidth
                                                            label="Tipos de planta"
                                                        >
                                                            {subOptionStrings.map((elm, key) => {
                                                                return (
                                                                    <MenuItem value={elm.valor}>{elm.string}</MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                }
                                            </div>
                                            <span className="edit-install-content-table">
                                                {(option !== 0 && option !== '' && subOption !== 0 && subOption !== '') || (option === 2) ?
                                                    (!loading ?
                                                        (option === 1 && configOrder !== undefined ?
                                                            <EditPlant
                                                                option={option}
                                                                subOption={subOption}
                                                                configOrder={configOrder}
                                                                setConfigOrder={setConfigOrder}
                                                                stateOriginal={stateOriginal}
                                                                statePrev={statePrev}
                                                                copyData={copyData}
                                                                setApply={setApply}
                                                                iTDefine={iTDefine}
                                                                compareOrder={compareOrder}
                                                                apply={apply}
                                                                loading={loadingAp}
                                                            />
                                                            :
                                                            (option === 2 &&
                                                                <EditDataDevice
                                                                    loading={loading}
                                                                    configOrder={configOrder}
                                                                    answer={answer}
                                                                    setAnswer={setAnswer}
                                                                    configData={configData}
                                                                    fetchOrder={fetchOrder}
                                                                    stateOriginal={stateOriginal}
                                                                    statePrev={statePrev}
                                                                    copyData={copyData}
                                                                    setApply={setApply}
                                                                    mensajesError={mensajesError}
                                                                    errorComparer={errorComparer}
                                                                    compareOrder={compareOrder}
                                                                    apply={apply}
                                                                />
                                                            )
                                                        )
                                                        :
                                                        <div className="justify-content-center">
                                                            <Loader />
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                }
                                            </span>
                                        </>
                                    </span>
                                    {configuracion.tipo !== "MABT" &&
                                        <Grid item xs={12} lg={6} className="edit-install-content-terminal" >
                                            <TerminalMQTT answer={answer} setAnswer={setAnswer} master={configOrder} numserieMaster={configuracion?.numserie} />
                                        </Grid>
                                    }
                                </span>
                                :
                                <span className="d-flex justify-content-center align-items-center">
                                    <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingLeft: "3rem" }}>
                                        {!loadingMabt && MABT ?
                                            <ConfiguracionPlantaMabt instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivo={MABT} modal={true} />
                                            :
                                            <div className="justify-content-center">
                                                <Loader />
                                            </div>
                                        }
                                    </Grid>
                                </span>
                            }
                        </Grid>
                    </Grid>
                </Modal>
            }
            {scadaSelf && !loading ?
                <EditDataDevice
                    loading={loading}
                    configOrder={configOrder}
                    answer={answer}
                    setAnswer={setAnswer}
                    configData={configData}
                    fetchOrder={fetchOrder}
                    stateOriginal={stateOriginal}
                    statePrev={statePrev}
                    copyData={copyData}
                    setApply={setApply}
                    mensajesError={mensajesError}
                    errorComparer={errorComparer}
                    compareOrder={compareOrder}
                    apply={apply}
                />
                :
                !scadaSelf && !loading ?
                    null
                    :
                    <div className="justify-content-center">
                        <Loader />
                    </div>
            }
        </>
    )
}