import React, { useState, useEffect } from 'react'
import { conversorFecha } from 'utils/functionsforGraphs'
import { SocGraphsBattery } from './SocGraphsBattery'
import { VoltageGraphsBattery } from './VoltageGraphsBattery'
import { CurrentGraphsBattery } from './CurrentGraphsBattery'
import { PowerGraphsBattery } from './PowerGraphsBattery'
import { Loader } from 'components/Loader/Loader'

export const GraphsBattery = (props) => {
    const [activeTabDevice, setActiveTabDevice] = useState(0)

    useEffect(() => {
        if (props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango") {
            setActiveTabDevice(1)
        }
    }, [props?.titleButton])

    return (
        <>
            <div className="selected-graphs">
                <button className={activeTabDevice === 0 ? "button-general-active" : "button-general"} disabled={(props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango" || props?.diff > 0) ? true : false} selected={activeTabDevice === 0} onClick={() => setActiveTabDevice(0)}>
                    Carga batería (%)
                </button>
                <button className={activeTabDevice === 3 ? "button-general-active" : "button-general"} disabled={(props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango" || props?.diff > 0) ? true : false} selected={activeTabDevice === 3} onClick={() => setActiveTabDevice(3)}>
                    Tensión (V)
                </button>
                <button className={activeTabDevice === 1 ? "button-general-active" : "button-general"} disabled={(props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango" || props?.diff > 0) ? true : false} selected={activeTabDevice === 1} onClick={() => setActiveTabDevice(1)}>
                    Potencia (kW)
                </button>
                <button className={activeTabDevice === 2 ? "button-general-active" : "button-general"} disabled={(props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.titleButton === "Rango" || props?.diff > 0) ? true : false} selected={activeTabDevice === 2} onClick={() => setActiveTabDevice(2)}>
                    Corriente (A)
                </button>
            </div>
            {props?.diff === 0 &&
                <div className='date-graphs-scada'>
                    <p>
                        {conversorFecha(props.start, props.end, props?.titleButton)}
                    </p>
                </div>
            }
            {props?.loading === true ? <Loader /> :
                props?.diff === 0 &&
                <>
                    <div className={`tab-item-wrapper  ${activeTabDevice === 0 ? 'active' : ''}`} index={0}>
                        <div className="my-2">
                            <SocGraphsBattery updatedList={props?.updatedList} device={props?.device} data={props.data} deviceType={props.deviceType} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart} now={props.now}
                                loading={props.loading} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton} activeTabDevice={0}
                                handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} historicalData={props.historicalData}
                                bateria21={props?.bateria21} />
                        </div>
                    </div>
                    <div className={`tab-item-wrapper  ${activeTabDevice === 3 ? 'active' : ''}`} index={3}>
                        <div className=" my-2">
                            <VoltageGraphsBattery updatedList={props?.updatedList} data={props.data} deviceType={props.deviceType} device={props?.device} start={props.start} end={props.end} groupData={props.groupData}
                                loading={props.loading} setStart={props.setStart} now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                                handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} activeTabDevice={3} historicalData={props.historicalData} />
                        </div>
                    </div>
                    <div className={`tab-item-wrapper  ${activeTabDevice === 1 ? 'active' : ''}`} index={1}>
                        <div className="my-2">
                            <PowerGraphsBattery updatedList={props?.updatedList} data={props.data} deviceType={props.deviceType} device={props?.device} start={props.start} end={props.end} groupData={props.groupData}
                                loading={props.loading} setStart={props.setStart} now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                                handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} activeTabDevice={1} historicalData={props.historicalData} />
                        </div>
                    </div>
                    <div className={`tab-item-wrapper  ${activeTabDevice === 2 ? 'active' : ''}`} index={2}>
                        <div className=" my-2">
                            <CurrentGraphsBattery updatedList={props?.updatedList} data={props.data} deviceType={props.deviceType} device={props?.device} start={props.start} end={props.end} groupData={props.groupData}
                                loading={props.loading} setStart={props.setStart} now={props.now} setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                                handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} activeTabDevice={2} historicalData={props.historicalData} />
                        </div>
                    </div>
                </>

            }
        </>
    )
}
