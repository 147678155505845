import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Loader } from 'components/Loader/Loader.jsx'
import { useLocation } from 'react-router-dom'
import { urls } from 'utils/constant'
import { headersScada } from 'services/headers'
import { GraphConLine } from './GraphBatteryV1'
import { result } from 'utils/functionsforGraphs'

export const SocGraphsBattery = (props) => {
    const location = useLocation()
    const idMaster = location.pathname.split("/")[4]
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const [powerData, setPowerData] = useState([])
    const [datosGrafica, setDatosGrafica] = useState()
    const [category, setCategory] = useState([])
    const [hours, setHours] = useState([])

    useEffect(() => {
        if ((props.titleButton === "Rango" && props.start <= props.end) || (props.titleButton !== "Rango")) {
            setPowerData(undefined)
            setLoading(true)
        }
    }, [props?.start, props?.end])

    const fetchData = async (numserie) => {
        setLoading(true)
        try {
            const res = await fetch(urls.scada + `chart/getDataChartsCurves/${numserie}?idCCMaster=${idMaster}`, {
                method: 'POST', redirect: 'follow', headers: headersScada(token),
                body: JSON.stringify(
                    {
                        "iniDate": props?.start?.format("YYYY-MM-DD"),
                        "endDate": props?.end?.format("YYYY-MM-DD"),
                        "iniTime": `00:00`,
                        "endTime": moment().format("YYYY-MM-DD") === props?.end?.format("YYYY-MM-DD") ? moment().format("HH:mm") : `23:59`,
                        "interval": "5",
                        "axis": [{
                            "idDeviceType": 9,
                            "fields": ["soc"],
                            "numseries": [`${numserie}`],
                            "plantType": "bateria",
                            "unit": "kWh"
                        }]
                    }
                )
            })
            const json = await res?.json()
            if (res?.ok) {
                setLoading(false)
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props?.data !== undefined && props?.deviceType !== undefined) {
            setPowerData([])
            props?.data?.forEach(elm => {
                if (Number(elm?.deviceType) === Number(props?.deviceType)) {
                    elm?.devices?.forEach(device => {
                        fetchData(device).then(item => {
                            setHours(item?.categories)
                            item?.data?.forEach(item2 => {
                                setPowerData(prev => [...prev, { data: item2?.data, numserie: Number(item2?.numserie), name: item2?.nick }])
                            })
                        })
                    })
                }
            })
        }
    }, [props?.dataPack, props?.start, props?.end])

    useEffect(() => {
        let extracHours = hours?.map(item => item.split(' ')[1])
        setCategory(extracHours)
    }, [hours])

    useEffect(() => {
        if (powerData && props?.deviceType !== undefined) {
            const setObj = new Set();
            const uniqs = powerData?.map(device => result(powerData, device?.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device.numserie)) {
                    setObj.add(device.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setDatosGrafica(noRepeat)
        }
    }, [powerData])

    return (
        <>
            {loading ?
                <Loader />
                :
                <GraphConLine datosGrafica={datosGrafica} escalaTipo="%" category={category} updatedList={props?.updatedList} activeTabDevice={props?.activeTabDevice} />
            }
        </>
    )
}