import React, { useState, useEffect } from 'react'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { CurrentTableConsumptionMobile } from './CurrentTableConsumptionMobile'
import { CurrentTableConsumptionDesktop } from './CurrentTableConsumptionDesktop'

export const CurrentTableConsumption = (props) => {
    const [name, setName] = useState()
    const headTable = ["Nombre", "Fecha", "Energía Consumida (kWh)", "Potencia Activa (kW)"]
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, { label: "Fecha", key: "datetime" }, { label: "Energía Consumida (kWh)", key: "eaconsdia" }, { label: "Potencia Activa (kW)", key: "pact" }]

    useEffect(() => {
        setName(props?.deviceconsumption?.[0]?.nick)
    }, [props?.refresh, props.consumption])

    useEffect(() => {
        props?.setHeadersCsv(variableTableSelfConsumption)
    }, [])

    return (
        <>
            {props?.isMobile === true ?
                <CurrentTableConsumptionMobile selectedDevice={props?.selectedDevice}
                    setSelectedDevice={props?.setSelectedDevice}
                    end={props?.end}
                    start={props?.start}
                    dataSliced={props?.device}
                    devices={props?.device}
                    HeadersCsv={props?.setHeadersCsv}
                    isMobile={props?.isMobile}
                    headersCsv={props?.headersCsv}
                    variableTableSelfConsumption={props?.variableTableSelfConsumption}
                    historicalData={true}
                    icon="text"
                    loading={props?.loading}
                    setStart={props?.setStart}
                    setEnd={props?.setEnd}
                    device={props.device} headTable={headTable} data={props?.consumption} checked={props?.checked} active={props?.active}
                    deviceType={props?.deviceType}
                    data1
                    deviceconsumption={props?.deviceconsumption}
                />
                :
                <CurrentTableConsumptionDesktop deviceType={props?.deviceType} deviceconsumption={props?.deviceconsumption} name={name} headTable={headTable} data={props?.consumption} checked={props?.checked} active={props?.active} loading={props.loading} />
            }
        </>
    )
}
