import React, { useEffect, useState } from 'react'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import '../ScadaGeneral.scss'

export const GeneralEnergyGraphs = (props) => {
    const [loading, setLoading] = useState(true)
    const [powerData, setPowerData] = useState()
    const [energyImport, setEnergyImport] = useState()
    const [energyVert, setEnergyVert] = useState()
    const [energyGen, setEnergyGen] = useState()
    const [energyCons, setEnergyCons] = useState()

    useEffect(() => {
        setLoading(!loading)
    }, [props])

    useEffect(() => {
        setPowerData([])
        setEnergyImport([])
        setEnergyVert([])
        setEnergyGen([])
        setEnergyCons([])
        if (props !== undefined) {
            props?.general?.forEach(device =>
                setPowerData(prev => [...prev, device?.datetime]))
            props?.general?.forEach(device =>
                setEnergyImport(prev => [...prev, device?.consumida]))
            props?.general?.forEach(device =>
                setEnergyVert(prev => [...prev, device?.vertida]))
            props?.general?.forEach(device =>
                setEnergyGen(prev => [...prev, device?.eagendia]))
            props?.general?.forEach(device =>
                setEnergyCons(prev => [...prev, device?.eaconsdia]))
        }
    }, [loading])

    const [data, setData] = useState()

    useEffect(() => {
        if (props !== undefined) {
            setData([{
                data: {
                    categories: powerData,
                    data: [{
                        vertida: energyVert,
                        consumida: energyCons,
                        importada: energyImport,
                        generada: energyGen
                    }]
                }
            }])
        }
    }, [powerData, energyImport])

    const [constructor, setConstructor] = useState(<GraphConstructor />)

    useEffect(() => {
        if (props !== undefined) {
            setConstructor(<GraphConstructor className={'graphs'} daily={props?.daily} activeTabDevice={props?.activeTabDevice} props={data} graphStyle={'bar'} historicalData={props?.historicalData} nombre={['Energy', 'General']} />)
        }
    }, [data])

    return (
        <>
            {constructor}
        </>
    )
}