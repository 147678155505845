import { Grid } from '@mui/material'
import React from 'react'
import { ProfileLogos } from 'views/User/Profile/Logo/ProfileLogos'

export const PersonalizationConfig = ({ idProject }) => {

    return (
        <Grid container className='pt-3'>
            <Grid item xs={4} md={4} lg={4} xl={4}/>
            <Grid item xs={3} md={3} lg={3} xl={3}>
                <Grid container>
                    <Grid item xs={9} md={9} lg={9} xl={9}>
                        <div className="avatar-container">
                            <h3>Logos</h3>
                        </div>
                    </Grid>
                    <Grid item xs={3} md={3} lg={3} xl={3} />
                </Grid>
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5}/>
            <Grid item xs={4} md={4} lg={4} xl={4}/>
            <Grid item xs={3} md={3} lg={3} xl={3}>
                <ProfileLogos idProject={idProject}/>
            </Grid>
            <Grid item xs={5} md={5} lg={5} xl={5}/>
        </Grid>
    )
}