import React, { useState, useEffect } from "react"
import { DragDropContext } from "@hello-pangea/dnd"
import { Grid } from "@mui/material"
import { ScadaconsumptionResume } from "views/Scada/Consumption/ScadaConsumptionComponent/ScadaconsumptionResume.jsx"
import { GaugesCharts } from "views/Scada/Consumption/GraphConsumption/GaugesCharts.jsx"
import { ConfiguracionList } from "views/Configuracion/ConfiguracionList"
import DropContainer from "views/LayOut/DropContainer.jsx"
import { Devices } from "views/Scada/Cards/Devices.jsx"
import { Alarms } from "views/Alarms/Alarms.jsx"
import { ButtonStyled } from "components/Buttons/ButtonStyled.jsx"
import { devices, deviceStatus, getUniqueID, getIconComponent } from "utils/Utils.jsx"
import { urls } from "utils/constant.jsx"
import { OnOff } from "./tabs/OnOff.jsx"
import { headersScada } from "services/headers.jsx"
import "../../LayOut/LayOut.scss"

export const CcMaster = (props) => {
  const [deviceElection, setDeviceElection] = useState()
  const [columns, setColumns] = useState(deviceStatus)
  const [loadingData, setLoadingData] = useState(false)
  const [data, setData] = useState()
  const token = sessionStorage?.token
  const relayIds = Object.keys(columns).filter(key => key.startsWith('Relay'))

  const getLayout = async ({ numserie }) => {
    let url = `distributionPanel/${numserie}`
    try {
      const res = await fetch(urls.scada + url, {
        method: "GET",
        headers: headersScada(token),
      })
      if (res.ok) {
        const data = await res.json()
        let configuration
        if(data.length === 0 ) {
          configuration = []
        } else {
          configuration = JSON.parse(data.confPanel)
        }
        const initialColumns = {
          Devices: {
            name: "Devices",
            items: devices,
          },
          Relay1: {
            name: "Relay1",
            items: []
          },
        }
        configuration.forEach((relay, index) => {
          initialColumns[`Relay${index + 1}`] = {
            name: `Relay${index + 1}`,
            items: relay.map((iconType) => {
              const { device_type, icon_type, name, content } = getIconComponent(iconType)
              return {
                id: getUniqueID(),
                device_type,
                icon_type,
                name,
                content,
              }
            })
          }
        })
        setColumns(initialColumns)
      } else {
        console.error("Error en la solicitud")
      }
    } catch (error) {
      console.error("Error en la solicitud", error)
    }
  } 

  const handleClick = () => {
    props?.seleccionarTab(2)
    props?.setResumeView(false)
    props?.setActiveTabCuadro(true)
  }

  useEffect(() => {
    const token = sessionStorage?.token
    let url
    if (props?.loose === 0) {
      url = `getCurrentData/${props?.deviceSelected?.numserie ? props?.deviceSelected?.numserie : props?.devices?.numserie}?idCCMaster=${props?.devices?.numserie}`
    } else {
      url = `getCurrentData/${props?.deviceSelected?.numserie ? props?.deviceSelected?.numserie : props?.devices?.numserie}`
    }
    if (!props?.resumeView && (props?.deviceSelected !== undefined || props?.device)) {
      const fetchData = () => {
        setLoadingData(true)
        fetch(urls.scada + url, { method: "GET", headers: headersScada(token) })
          .then((res) => {
            if (res.status === 204) {
              setLoadingData(false)
            } else {
              return res.json()
            }
          })
          .then((data) => {
            setData(data)
            setLoadingData(false)
          })
          .catch((err) => {
            console.error("ERROR. API Scada", err)
            setLoadingData(true)
          })
      }
      fetchData()
    }
  }, [props?.deviceSelected])

  useEffect(() => {
    setDeviceElection(
      props?.childrenDevices?.filter(
        (dev) => dev.numserie === props?.deviceSelected?.numserie
      )
    )
  }, [data])

  useEffect(() => {
    if (props?.devices?.numserie) {
      getLayout({ numserie: props?.devices?.numserie })
    }
  }, [props?.devices, props?.resumeView])

  useEffect(() => {
    if (props?.activeTabDevice === 0) {
      getLayout({ numserie: props?.devices?.numserie })
    }
  }, [props?.activeTabDevice])

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={12} lg={12} xl={12}>
        <Grid className="p-3" >
          <Grid container style={{ display: "flex", justifyContent: "space-between" }}>
            {!props?.resumeView && props?.activeTabDevice !== 5 && (
              <Grid item xs={2} md={2} lg={2} xl={2} className="device">
                <Devices
                  confirmSelfConsumption={props?.confirmSelfConsumption}
                  devices={props?.devices}
                  device={props?.device}
                  setDevices={props?.setDevices}
                  setDevice={props?.setDevice}
                  loose={props?.loose}
                  setLoose={props?.setLoose}
                  refresh={props?.refresh}
                  setRefresh={props?.setRefresh}
                  handleSelectedDevice={props?.handleSelectedDevice}
                  deviceSelected={props?.deviceSelected}
                  setDeviceSelected={props?.setDeviceSelected}
                  masterPermission={props?.masterPermission}
                  seeWeb={props?.seeWeb}
                  selfConsumption={props?.selfConsumption}
                  visible={props?.visible}
                  setVisible={props?.setVisible}
                  importSelf={props?.importSelf}
                  exportSelf={props?.exportSelf}
                  resumeView={props?.resumeView}
                  setResumeView={props?.setResumeView}
                  toggleDevice={props?.toggleDevice}
                  activeTab={props?.activeTabDevice}
                />
              </Grid>
            )}
            <Grid item xs={props?.resumeView ? 12 : 10} md={props?.resumeView ? 12 : 10} lg={props?.resumeView ? 12 : 10} xl={props?.resumeView ? 12 : 10} className="graficas pl-5">
              <Grid item xs={12} md={12} lg={12} xl={12} index={0}>
                {props?.resumeView && props?.activeTabDevice === 0 && (
                  <ScadaconsumptionResume
                    confirmSelfConsumption={props?.confirmSelfConsumption}
                    devices={props?.devices}
                    setDevices={props?.setDevices}
                    loose={props?.loose}
                    setLoose={props?.setLoose} GaugesCharts
                    refresh={props?.refresh}
                    setRefresh={props?.setRefresh}
                    handleSelectedDevice={props?.handleSelectedDevice}
                    deviceSelected={props?.deviceSelected}
                    setDeviceSelected={props?.setDeviceSelected}
                    masterPermission={props?.masterPermission}
                    seeWeb={props?.seeWeb}
                    selfConsumption={props?.selfConsumption}
                    visible={props?.visible}
                    setVisible={props?.setVisible}
                    importSelf={props?.importSelf}
                    exportSelf={props?.exportSelf}
                    resumeView={props?.resumeView}
                    setResumeView={props?.setResumeView}
                    setDevice={props?.setDevice}
                    toggleDevice={props.toggleDevice}
                  />
                )}
              </Grid>
              <div className={`tab-item-wrapper ${props?.activeTabDevice === 3 ? 'active' : ''}`} index={0}>
                <div className="my-2">
                  {props?.activeTabDevice === 3 && (
                    <GaugesCharts
                      masterPermission={props?.masterPermission}
                      devices={props.deviceSelected}
                      loadingData={loadingData}
                      data={data}
                      activeTabDevice={3}
                      device={props?.deviceSelected}
                      idMaster={props?.devices?.numserie}
                      loose={props?.loose}
                      style={{ border: "0px 0px 0px" }}
                      resumeView={props?.resumeView}
                      setResumeView={props?.setResumeView}
                      id_tipo_dispositivo={
                        deviceElection?.[0]?.id_tipo_dispositivo
                      }
                      id_padre={props?.device?.id_padre}
                    />
                  )}
                </div>
              </div>
              <div className={`tab-item-wrapper ${props?.activeTabDevice === 1 ? 'active' : ''}`} index={4}>
                <div className="my-2">
                  {props?.activeTabDevice === 1 && (
                    <Alarms
                      instalacioneSeleccionadas={[props?.numserieMaster]}
                      dispositivos={[props?.dispositivos]}
                    />
                  )}
                </div>
              </div>
              <div className={`tab-item-wrapper ${props?.activeTabDevice === 2 ? 'active' : ''}`} index={4}>
                <div className="my-2">
                  {props?.activeTabDevice === 2 && (
                    <ConfiguracionList
                      scadaSelf={true}
                      instalacioneSeleccionadas={[props?.numserieMaster]}
                      dispositivo={[props?.dispositivos]}
                      scada={true}
                      masterError={props?.masterError}
                      selfConsumption={props?.selfConsumption}
                      activeTabCuadro={props?.activeTabCuadro}
                    />
                  )}
                </div>
              </div>
              <div className={`tab-item-wrapper ${props?.activeTabDevice === 4 ? 'active' : ''}`} index={4}>
                <div className="my-2">
                  {props?.activeTabDevice === 4 && (
                    <OnOff activeTab={props?.activeTab} isMobile={props?.isMobile} />
                  )}
                </div>
              </div>
            </Grid>
            {(props?.activeTabDevice === 3 || !props?.activeTabDevice) && !props?.deviceSelected ?
              <Grid item xs={12} md={12} lg={12} xl={12} className="mx-6 mt-8">
                   <div className="flex flex-end px-2">
                    <ButtonStyled className="boton-delete-alarm mb-3" onClick={handleClick}>
                      <span className="p-1">Configurar cuadro</span>
                    </ButtonStyled>
                  </div>
                  <DragDropContext>
                     <div className="flex flex-col relay-main-container">
                      <div className="relay-div">
                        {relayIds.map((relayId) => {
                          const column = columns[relayId]
                          return (
                            <div key={relayId}>
                              <DropContainer columnId={relayId} column={column} direction="horizontal" disabled={true} />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </DragDropContext>
              </Grid> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}