import React, { useState } from "react";

import moment from "moment";
import 'moment/locale/es';
import { Button, Grid, CardContent, Menu, TextField } from "@mui/material";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";


export const MenuByTab = (props) => {

    moment.locale("es");

    const [anchorElMenu1, setAnchorElMenu1] = useState(null);

    const handleClickMenu1 = event => setAnchorElMenu1(event.currentTarget)
    const handleCloseMenu1 = () => setAnchorElMenu1(null)

    const [range, setRange] = useState(false)



    const handleClickToday = () => { props.setstart(moment()); props.setend(moment()); setRange(false); props.setTitleButton("Hoy"); props.fetchData(); handleCloseMenu1() }
    const handleClickYesterday = () => { props.setstart(moment().subtract(1, "days")); props.setend(moment().subtract(1, "days")); setRange(false); props.setTitleButton("Ayer"); props.fetchData(); handleCloseMenu1() }
    // const handleClickWeek = () => { props.setstart(moment().startOf('week')); props.setend(moment()); setRange(true); props.setTitleButton("Semana"); props.fetchData(); }
    const handleClickMonth = () => { props.setstart(moment().startOf('month')); props.setend(moment()); setRange(false); props.setTitleButton("Mes Actual"); handleCloseMenu1() }
    const handleClickYear = () => { props.setstart(moment().startOf('year')); props.setend(moment()); setRange(false); props.setTitleButton("Año"); handleCloseMenu1() }
    let ranges = [
        { title: "Hoy", click: handleClickToday },
        { title: "Ayer", click: handleClickYesterday },
        // { title: "Semana", click: handleClickWeek },
        { title: "Mes Actual", click: handleClickMonth },
        { title: "Año", click: handleClickYear },
    ];

    const [numberMonth, setNumberMonth] = useState(1)
    const [numberYear, setNumberYear] = useState(1)

    const handleClickInMonth = () => {
        props.setstart(moment(props.start).startOf('month'))
        props.setend(moment(props.start).endOf('month'))
        props.setTitleButton("Mes Actual")
        props.fetchData()
        setAnchorElMenu1(null)
    }
    const handleClickInYear = () => {
        props.setstart(moment(props.start).startOf('year'))
        props.setend(moment(props.start).endOf('year'))
        props.setTitleButton("Año")
        props.fetchData()
        setAnchorElMenu1(null)
    }

    const handleClickMoveMonthLeft = () => {
        props.setstart(moment(props.start).subtract(1, "months"))
        setAnchorElMenu1(null)
    }
    const handleClickMoveMonthRight = () => {
        props.setstart(moment(props.start).add(1, "months"))
        setAnchorElMenu1(null)
    }

    const handleClickMoveYearLeft = () => {
        props.setstart(moment(props.start).subtract(1, "years"))
        setAnchorElMenu1(null)
    }
    const handleClickMoveYearRight = () => {
        props.setstart(moment(props.start).add(1, "years"))
        setAnchorElMenu1(null)
    }
    return (
        <>
            <Grid item lg={2} className='d-flex justify-content-center mt-2' >
                <Button onClick={handleClickMenu1} style={{ border: "2px solid #bfbfc4", borderRadius: "3px", height: "35px", top: "-5px", width: '200px' }}>
                    {props.titleButton === "Día" && <span>{moment(props.start).format("DD-MM-YYYY")}</span>}
                    {props.titleButton === "Hoy" && <span>{moment(props.start).format("DD-MM-YYYY")}</span>}
                    {props.titleButton === "Ayer" && <span>{moment(props.start).subtract(1, "days").format("DD-MM-YYYY")}</span>}
                    {/* {props.titleButton === "Semana" && <span>{props.titleButton}{' '} {moment().startOf('week').format("DD")}</span>} */}
                    {props.titleButton === "Mes Actual" && <span>{moment(props.start).startOf('month').format("MMMM")}</span>}
                    {props.titleButton === "Año" && <span>{moment(props.start).startOf('year').format("YYYY")}</span>}
                    {props.titleButton === "Rango" && <span>{moment(props.start).format("DD-MM-YYYY")}{' - '}{moment(props.end).format("DD-MM-YYYY")}</span>}
                    {props.titleButton === "Start" && <span>{moment(props.start).format("DD-MM-YYYY")}</span>}
                </Button>
                <Menu
                    anchorEl={anchorElMenu1}
                    keepMounted
                    open={Boolean(anchorElMenu1)}
                    onClose={handleCloseMenu1} classes={{ list: 'p-0' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center', }} >
                    <div>
                        <CardContent className="p-0">
                            <Grid container spacing={0} className="mt-2">
                                <div className="d-flex">
                                    <Grid item >
                                        {/* Selector de años */}
                                        <Grid item sm={12} md={12} xl={12} className="ml-4 text-center" >
                                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#9cccd15e", borderRadius: "10px" }}>
                                                <Button variant="contained" className="btn-secondary m-2" size="small" onClick={handleClickMoveYearLeft}>
                                                    <span className="btn-wrapper--icon">
                                                        <FontAwesomeIcon className="mr-2" icon={faAngleLeft} />
                                                    </span>
                                                </Button>
                                                <Button className="btn-transparent btn-link btn-link-dark mr-4 ml-4" size="small">
                                                    <span className="mt-2" onClick={handleClickInYear}>{props?.start?.format("YYYY")}</span> {' '}
                                                </Button>
                                                <Button variant="contained"
                                                    className="btn-secondary m-2" size="small"
                                                    onClick={handleClickMoveYearRight} >
                                                    <span className="btn-wrapper--icon">
                                                        <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
                                                    </span>
                                                </Button>
                                            </div>
                                        </Grid>

                                        {/* Selector de meses */}
                                        <Grid item sm={12} md={12} xl={12} className="ml-4 mt-1 text-center" >
                                            <div className="d-flex justify-content-center" style={{ backgroundColor: "#adc3d870", borderRadius: "10px" }}>
                                                <Button variant="contained"
                                                    className="btn-secondary m-2" size="small"
                                                    onClick={handleClickMoveMonthLeft}>
                                                    <span className="btn-wrapper--icon">
                                                        <FontAwesomeIcon className="mr-2" icon={faAngleLeft} />
                                                    </span>
                                                </Button>


                                                <Button className="btn-transparent btn-link btn-link-dark mr-4 ml-4" size="small">
                                                    <span className="mt-2" onClick={handleClickInMonth}>{props.start.format("MMMM")}</span> {' '}
                                                </Button>

                                                <Button variant="contained"
                                                    className="btn-secondary m-2" size="small"
                                                    onClick={handleClickMoveMonthRight} >
                                                    <span className="btn-wrapper--icon">
                                                        <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
                                                    </span>
                                                </Button>
                                            </div>
                                        </Grid>

                                        {/* Calendario props.start */}
                                        <Grid item sm={5} md={5} className="ml-4">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <StaticDatePicker
                                                    displayStaticWrapperAs="desktop"
                                                    openTo="day"
                                                    maxDate={props.now}
                                                    value={props.start}
                                                    onChange={(newValue) => {
                                                        if (props.titleButton !== "Rango") {
                                                            handleCloseMenu1();
                                                            props.setTitleButton("Start")
                                                            props.setstart(moment(newValue));
                                                            props.setend(moment(newValue));
                                                        } else {
                                                            props.setstart(moment(newValue));
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}

                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>

                                    {range &&
                                        <>
                                            <Grid item>
                                                {/* Selector de años */}
                                                <Grid item sm={12} md={12} xl={12} className="ml-4 text-center" >

                                                    <div className="d-flex justify-content-center" style={{ backgroundColor: "#9cccd15e", borderRadius: "10px" }}>
                                                        <Button variant="contained"
                                                            className="btn-secondary m-2" size="small"
                                                            onClick={() => {
                                                                setNumberYear(numberYear + 1)
                                                                props.setstart(moment().subtract(numberYear, "year"))
                                                            }}>
                                                            <span className="btn-wrapper--icon">
                                                                <FontAwesomeIcon className="mr-2" icon={faAngleLeft} />
                                                            </span>
                                                        </Button>


                                                        <Button className="btn-transparent btn-link btn-link-dark mr-4 ml-4" size="small">
                                                            <span className="mt-2">{props.end.format("YYYY")}</span> {' '}
                                                        </Button>

                                                        <Button variant="contained"
                                                            className="btn-secondary m-2" size="small"
                                                            onClick={() => {
                                                                setNumberYear(numberYear - 1)
                                                                props.setstart(moment().subtract(numberYear, "year"))
                                                            }}>
                                                            <span className="btn-wrapper--icon">
                                                                <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Grid>
                                                {/* Selector de meses */}
                                                <Grid item sm={12} md={12} xl={12} className="ml-4 text-center" >

                                                    <div className="d-flex justify-content-center" style={{ backgroundColor: "#adc3d870", borderRadius: "10px" }}>
                                                        <Button variant="contained"
                                                            className="btn-secondary m-2" size="small"
                                                            onClick={() => {
                                                                setNumberMonth(numberMonth + 1)
                                                                props.setend(moment().subtract(numberMonth, "months"))
                                                            }}>
                                                            <span className="btn-wrapper--icon">
                                                                <FontAwesomeIcon className="mr-2" icon={faAngleLeft} />
                                                            </span>
                                                        </Button>


                                                        <Button className="btn-transparent btn-link btn-link-dark mr-4 ml-4"
                                                            size="small" style={{ backgroundColor: "transparent" }}>
                                                            <span className="mt-2" >{props.end.format("MMMM")}</span> {' '}
                                                        </Button>

                                                        <Button variant="contained"
                                                            className="btn-secondary m-2" size="small"
                                                            onClick={() => {
                                                                setNumberMonth(numberMonth - 1)
                                                                props.setend(moment().subtract(numberMonth, "months"))
                                                            }} >
                                                            <span className="btn-wrapper--icon">
                                                                <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </Grid>

                                                {/* Calendario fin */}
                                                <Grid item sm={5} md={5} className="ml-4">
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <StaticDatePicker
                                                            displayStaticWrapperAs="desktop"
                                                            openTo="day"
                                                            maxDate={props.now}
                                                            value={props.end}
                                                            onChange={(newValue) => {
                                                                props.fetchData();
                                                                if (props.titleButton !== "Rango") {
                                                                    props.setTitleButton("Start")
                                                                    props.setend(moment(props.start));
                                                                } else {
                                                                    props.setend(moment(newValue));
                                                                    handleCloseMenu1();
                                                                }
                                                            }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </LocalizationProvider>
                                                </Grid>
                                            </Grid>
                                        </>}
                                </div>
                            </Grid>

                            <Grid item className="d-flex" sm={12} md={12} xl={12} >
                                {ranges.map((range, i) => {
                                    return (
                                        <Button key={i} className={props.titleButton === range.title ? `btn-success m-1` : `m-1`} onClick={() => range.click()}>
                                            <span>{range.title}</span>
                                        </Button>
                                    )
                                })}
                                <Button className={props.titleButton === "Rango" ? `btn-success m-1` : `m-1`}
                                    onClick={() => { setRange(!range); props.setTitleButton("Rango") }}>
                                    <span>Rango</span>
                                </Button>
                            </Grid>
                            <div className="divider mt-0" />
                            <div className="text-center py-1">
                                <Button size="small" className="btn-secondary" onClick={handleCloseMenu1}>
                                    <span className="btn-wrapper--icon">Cerrar</span>
                                </Button>
                            </div>
                        </CardContent>
                    </div >
                </Menu >
            </Grid>
        </>
    )
}
