import React, { useState, useEffect } from 'react'
import { Grid } from "@mui/material"
import Chart from 'react-apexcharts'
import moment from 'moment'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const IntensityGraph = (props) => {
    const [lineChart, setLineChart] = useState(true)
    const [graphData, setGraphData] = useState()
    const token = sessionStorage?.token
    const [initDate, setInitDate] = useState(`${moment().format("YYYY-MM-DD")}`)
    const [endDate, setEndDate] = useState(`${moment().format("YYYY-MM-DD")}`)
    const [endTime, setEndTime] = useState(`${moment().format("HH:mm")}`)

    useEffect(() => {
        if (moment(props.startt.format('YYYY-MM-DD')).isAfter(props.start.format('YYYY-MM-DD'))) {
            setInitDate(`${props.start.format("YYYY-MM-DD")}`)
            setEndDate(`${props.end.format("YYYY-MM-DD")}`)
            setEndTime('23:59')
        } else {
            setInitDate(`${moment().format("YYYY-MM-DD")}`)
            setEndDate(`${moment().format("YYYY-MM-DD")}`)
            setEndTime(`${moment().format("HH:mm")}`)
        }
    }, [props.start, props.end])

    useEffect(() => {
        if (props?.device && props.activeTabDevice === '3' && initDate && endTime && endDate) {
            const fetchDataGraph = async () => {
                if (props?.device?.mabt_serial && props?.device?.id_tipo_dispositivo && props?.device?.numserie) {
                    fetch(urls.scada + `chart/getDataChartsCurves/${props?.device.numserie}?idCCMaster=${props?.device?.mabt_serial}`, {
                        method: 'POST', redirect: 'follow', headers: headersScada(token),
                        body: JSON.stringify(
                            {
                                "iniDate": initDate,
                                "endDate": endDate,
                                "iniTime": `00:00`,
                                "endTime": endTime,
                                "interval": "5",
                                "axis": [{
                                    "idDeviceType": `${props?.device?.id_tipo_dispositivo}`,
                                    "fields": ['i1', 'i2', 'i3'],
                                    "numseries": [`${props?.device?.numserie}`],
                                    "unit": "A"
                                }]
                            }
                        )
                    })
                        .then(res => res.json())
                        .then(data => {
                            setLineChart(true)
                            setGraphData(data)
                        })
                        .catch(err => {
                            console.error("ERROR. API Scada", err)
                            setLineChart(false)
                        })
                }
            }
            fetchDataGraph()
        }
    }, [props?.device, props.activeTabDevice, initDate, endTime, endDate])

    const consuptionRealTime_instantPower = {
        series: [
            { name: "Intensidad L1", data: graphData?.data?.[0]?.data },
            { name: "Intensidad L2", data: graphData?.data?.[1]?.data },
            { name: "Intensidad L3", data: graphData?.data?.[2]?.data }
        ],
        options: {
            chart: {
                events: { click: (event) => event.preventDefault() },
                id: "intensity1",
                height: 350,
                type: "line",
                zoom: { enabled: true },
                animations: { enabled: false },
                toolbar: { autoSelected: 'pan', show: false }
            },
            xaxis: {
                categories: graphData?.categories,
                tickAmount: 40,
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return value.split(' ')[1]
                        }
                    }
                }
            },
            yaxis: {
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return value.toFixed(2) + ' A'
                        }
                    }
                }
            },
            dataLabels: { enabled: false },
            stroke: { width: 2, curve: "straight" },
        },
        seriesLine: [
            { name: "Intensity L1", data: graphData?.data?.[0]?.data },
            { name: "Intensity L2", data: graphData?.data?.[1]?.data },
            { name: "Intensity L3", data: graphData?.data?.[2]?.data }
        ],
        optionsLine: {
            legend: { show: false, },
            chart: {
                id: 'intensity2', height: 170, type: 'line',
                brush: { target: 'intensity1', enabled: true },
                selection: {
                    enabled: true,
                    xaxis: { min: graphData?.categories?.length - 24, max: graphData?.categories?.length },
                },
            },

            stroke: { width: 2, curve: "straight" },
            xaxis: {
                categories: graphData?.categories,
                tickAmount: 40,
                tooltip: { enabled: false },
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null && value !== 0) {
                            return value.split(' ')[1]
                        }
                    }
                }
            },
            yaxis: {
                tickAmount: 2,
                labels: {
                    formatter: (value) => {
                        if (value !== undefined && value && value !== null) {
                            return value.toFixed(2) + ' A'
                        }
                    }
                }
            },
        },
    }


    return (
        <>
            <Grid container>
                <Grid item lg={12} className='mt-2'>
                    {lineChart && props.activeTabDevice === '3' ?
                        <>
                            <Chart id='intensity1' options={consuptionRealTime_instantPower.options} series={consuptionRealTime_instantPower.series} type="line" width={"100%"} height={320} />
                            <Chart id='intensity2' options={consuptionRealTime_instantPower.optionsLine} series={consuptionRealTime_instantPower.seriesLine} type="line" width={"100%"} height={170} />
                        </>
                        :
                        <h2 className='text-center' style={{ height: '650px' }}>CARGANDO</h2>
                    }
                </Grid>

            </Grid>
        </>
    )
}
