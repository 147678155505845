import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from 'components/Loader/Loader.jsx'
import { standarLabel, categoriesJoin } from 'views/Scada/SelfConsumption/GraphsUtils/PhasesGraphsUtils'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import { consumidos } from 'views/Scada/SelfConsumption/GraphsUtils/GraphUtils'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'

export const PhaseGraphsRed = (props) => {
    const location = useLocation()
    const idMaster = location.pathname.split("/")[4]
    const [loading, setLoading] = useState(true)
    const token = sessionStorage?.token
    const [data, setData] = useState()
    const [constructor, setConstructor] = useState()
    const [dataFilter, setDataFilter] = useState({ labels: null, datasets: null })

    const fetchData = async (numserie) => {
        try {
            const res = await fetch(urls.scada + `getPhases/${numserie}?idCCMaster=${idMaster}&date=${props.start.format('YYYY-MM-DD')}`, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                const json = await res.json()
                return json;
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    useEffect(() => {
        if ((props.titleButton === "Rango" && props.start <= props.end) || (props.titleButton !== "Rango")) {
            setData(undefined)
            setDataFilter({ labels: null, datasets: null })
            setConstructor(undefined)
            setLoading(true)
        }
    }, [props?.start, props?.daily, props?.end])

    useEffect(() => {
        const noRepeat = (obj) => {
            let aux = categoriesJoin(obj);
            setData(aux)
        }
        if (props?.updatedList !== undefined) {
            setData(null)
            props?.updatedList?.forEach(item => {
                fetchData(item).then(item2 =>
                    noRepeat(item2))
            })
        }
    }, [props?.start, props?.daily, props?.end, props?.updatedList]);

    const dataSets = (obj) => {
        let res = [];
        if (obj !== null) {
            let aux = 0;
            for (let i = 0; i < obj.data.length; i++) {
                for (let k = 0; k < obj.data[i].data.length; k++) {
                    res.push(
                        {
                            Type: 'line',
                            data: obj.data[i].data[k].data,
                            label: `${obj.data[i].nick} ${obj.data[i].data[k].name}`,
                            backgroundColor: consumidos[aux][1],
                            borderColor: consumidos[aux][0],
                            pointBackgroundColor: consumidos[aux][0],
                            fill: true,
                            id: aux,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: props?.updatedList[i],
                        }
                    )
                    aux = aux + 1
                }
            }
        } else {
            res.push(
                {
                    Type: 'line',
                    data: [],
                    label: [],
                    backgroundColor: [],
                    borderColor: [],
                    pointBackgroundColor: [],
                    fill: true,
                    id: 0,
                    pointRadius: 0,
                    tension: 0.1,
                    numserie: [],
                }
            )
        }
        return res
    }

    useEffect(() => {
        if (data !== undefined) {
            let aux = standarLabel()
            let aux1 = dataSets(data)
            if (aux1.length !== 0) {
                setDataFilter({
                    labels: aux.slice(0, aux1[0].data.length + 1),
                    datasets: aux1
                })
            }
        }
    }, [data])



    useEffect(() => {
        setConstructor(
            <GraphConstructor dataFilter={dataFilter}
                props={''}
                nombre={'Potencias Fases (kW)'}
                graphStyle={'line'}
                daily={'days'}
                activeTabDevice={4}
                updatedList={props?.updatedList}
            />
        )

    }, [dataFilter])

    useEffect(() => {
        if (constructor !== undefined) {
            setLoading(false)
        }
    }, [constructor])

    return (
        <>
            {(loading) ?
                <Loader />
                :
                constructor
            }
        </>
    )
}