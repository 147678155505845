import React, { useEffect, useState } from 'react'
import { GraphConstructor } from 'views/Scada/SelfConsumption/GraphsUtils/GraphConstructor'
import '../../General/ScadaGeneral.scss'

export const GeneralPotencialGraphs = (props) => {
    const [data, setData] = useState()

    useEffect(() => {
        if (props !== undefined) {
            setData([{
                data: {
                    categories: props?.graphDataProduction?.categories,
                    data: [{
                        vertida: props?.graphDataProduction?.data?.[0]?.data,
                        consumida: props?.graphDataProduction?.data?.[1]?.data,
                        cargaBatería: props?.graphDataProduction?.data?.[2]?.data ? props?.graphDataProduction?.data?.[2]?.data : undefined,
                        potenciaBatería: props?.graphDataProduction?.data?.[3]?.data ? props?.graphDataProduction?.data?.[3]?.data : undefined
                    }]
                }
            }])
        }

    }, [props])

    const [constructor, setConstructor] = useState(<GraphConstructor />)

    useEffect(() => {
        if (props !== undefined) {
            setConstructor(
                <>
                    <GraphConstructor
                        style={{ width: '100%', height: '20em' }}
                        className={'graphs'}
                        daily={props?.daily}
                        activeTabDevice={props?.activeTabDevice}
                        props={data}
                        graphStyle={'line'}
                        historicalData={props?.historicalData}
                        nombre={['Potencia', 'General']}
                    />
                </>
            )
        }
    }, [data, props])

    return (
        <>
            {constructor}
        </>
    )
}