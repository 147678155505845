import { Grid } from '@mui/material';
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import React, { useEffect } from 'react'
import { DropDownDevices } from 'views/Devices/MenuDevices/DropDownDevices'
import "./StyleFilterScada.scss"

export const FilterScada = ({ device, setDevicesSelect, devicesSelect, master }) => {

    let checkingAll = devicesSelect.length === device.length


    const CheckedDevFilter = (numserie) => {
        setDevicesSelect([])
        if (!devicesSelect.includes(numserie)) {
            setDevicesSelect([...devicesSelect, numserie]);
        } else {
            const newArray = devicesSelect.filter(item => item !== numserie);
            setDevicesSelect(newArray)
        }
    };

    const checkAll = () => {
        setDevicesSelect([])
        if (device && !checkingAll) {
            device?.forEach(dev => {
                setDevicesSelect(prev => [...prev, dev.numserie])
            })
        } else {
            setDevicesSelect([])
        }
    }


    useEffect(() => {
        checkAll()
    }, [])



    return (
        <>
            <Grid item xs={12} className="container-filter-component">
                <div className="checkAll" >
                    <input type="button" onClick={checkAll} value='Todos' className={checkingAll ? 'button-filter-active pd-filter' : 'button-filter pd-filter'} id="filterButtonAll" />
                </div>
                {device && device.map((dev, key) => {
                    return (
                        <button key={key} className="button-filter" onClick={() => CheckedDevFilter(dev.numserie)}>
                            <SwitchStyled value={dev.numserie} name='dv' checked={devicesSelect.includes(dev.numserie)} onChange={(e) => CheckedDevFilter(dev.numserie, e)} className="checkboxFilter switch-small" />
                            <div id={dev.numserie} onClick={setDevicesSelect} >
                                <p className="filter-name">{dev.nick}</p>
                            </div>
                            <span className={dev} id="button-menu-device"  >
                                <DropDownDevices
                                    selectedDevice={dev}
                                    icon="text"
                                    device={dev}
                                    instalacion={master}
                                    masterPermission={master}
                                    masterPermissionNew={master?.permissions}
                                />
                            </span>
                        </button>
                    )
                })}
            </Grid>
        </>
    )
}
