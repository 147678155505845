import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { dispositivos } from 'utils/constant'
import { CardSelfConsumption } from 'views/Scada/SelfConsumption/Cards/CardSelfConsumption'
import { CardInverter } from 'views/Scada/Inverter/Cards/CardInverter'
import { childrenMap } from './childrenUtils'

export const Devices = (props) => {
    const [propsPack, setPropsPack] = useState()
    const [mapa, setMapa] = useState()

    const filtrarObjetos = (objeto) => {
        let resultado = []
        if (objeto?.childrenDevices && objeto?.childrenDevices.length > 0) {
            objeto.childrenDevices.forEach(element => {
                let hijo = []
                if (element?.childrenDevices && element?.childrenDevices.length > 0) {
                    hijo = filtrarObjetos(element)
                }
                resultado.push(element, ...hijo)
            })
        }
        return resultado
    }

    useEffect(() => {
        let mapaDispositivos = [dispositivos.ccmabt, dispositivos.ccmaster].includes(props?.devices?.id_tipo_dispositivo) ? [] : [props?.devices]
        setMapa(mapaDispositivos.concat(filtrarObjetos(props?.devices)))
        setPropsPack({
            loose: props?.loose,
            setLoose: props?.setLoose,
            devices: props?.devices,
            handleSelectedDevice: props?.handleSelectedDevice,
            deviceSelected: props?.deviceSelected,
            setDeviceSelected: props?.setDeviceSelected,
            masterPermission: props?.masterPermission,
            setDevices: props?.setDevices,
            setRefresh: props?.setRefresh,
            refresh: props?.refresh,
            resumeView: props?.resumeView,
            setResumeView: props?.setResumeView,
            device: props?.device,
            setDevice: props?.setDevice,
            toggleDevice: props?.toggleDevice
        })
    }, [props])


    return (
        <Grid container style={{ display: 'flex', justifyContent: props?.resumeView ? 'start' : 'flex-start', alignItems: 'start' }}>
            {props?.devices?.inst_type === 2 ?
                <div >
                    <CardInverter setActiveTabDevice={props?.setActiveTabDevice}
                        setActiveTab={props?.setActiveTab} loose={props?.loose} setLoose={props?.setLoose} refresh={props?.refresh} setRefresh={props?.setRefresh} devices={props?.devices} handleSelectedDevice={props.handleSelectedDevice}
                        deviceSelected={props.deviceSelected} selfConsumption={props.selfConsumption} masterPermission={props.masterPermission} setDevices={props?.setDevices} seeWeb={props.seeWeb} visible={props?.visible} setVisible={props?.setVisible}
                    />
                </div>
                :
                props?.devices && (props?.devices?.inst_type !== 1) ?
                    (props?.devices?.id_tipo_dispositivo === 0 ?
                        <>
                            {mapa && mapa.length > 0 &&
                                mapa.map((elm) => {
                                    return childrenMap(elm, propsPack);
                                })
                            }
                        </>
                        :
                        props?.devices?.id_tipo_dispositivo === 2 ?
                            <>
                                {mapa && mapa.length > 0 &&
                                    mapa.map((elm) => {
                                        return childrenMap(elm, propsPack);
                                    })
                                }
                            </>
                            :
                            (props?.devices?.id_tipo_dispositivo === dispositivos.ccm4 || props?.devices?.id_tipo_dispositivo === dispositivos.ccm3) ?
                                <>
                                    {mapa && mapa.length > 0 &&
                                        mapa.map((elm) => {
                                            return childrenMap(elm, propsPack)
                                        })
                                    }
                                </>
                                :
                                    null
                    )
                    :
                    (props?.devices?.inst_type === 1 && props?.confirmSelfConsumption.length > 0) ?
                        <CardSelfConsumption
                            loose={props?.loose}
                            setLoose={props?.setLoose}
                            refresh={props?.refresh}
                            setRefresh={props?.setRefresh}
                            devices={props?.devices}
                            device={props?.device}
                            handleSelectedDevice={props.handleSelectedDevice}
                            deviceSelected={props?.deviceSelected?.[0] ? props?.deviceSelected?.[0] : props?.deviceSelected}
                            masterPermission={props.masterPermission}
                            setDevices={props?.setDevices}
                            seeWeb={props.seeWeb}
                            selfConsumption={props.selfConsumption}
                            visible={props?.visible}
                            setVisible={props?.setVisible}
                            importSelf={props?.importSelf}
                            exportSelf={props?.exportSelf}
                            isMobile={props?.isMobile}
                            setActiveTabDevice={props?.setActiveTabDevice}
                            setActiveTab={props?.setActiveTab}
                        />
                        :
                        <>
                            {
                                mapa && mapa.length > 0 &&
                                mapa.map((elm) => {
                                    return childrenMap(elm, propsPack)
                                })
                            }
                        </>
            }
        </Grid>
    )
}