import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { urls } from "utils/constant.jsx"
import { GeneralPotencialGraphs } from './GeneralPotencialGraphs'
import { GeneralEnergyGraphs } from './GeneralEnergyGraphs'
import { TableGeneralMobile } from '../TableGeneralMobile'
import { TableResumeGeneral } from './TableResumeGeneral'
import { ProgressBarGeneral } from './GeneralProgressBar'
import { TableGeneral } from '../TableGeneral'
import { Loader } from "components/Loader/Loader"
import { headersScada } from 'services/headers'
import { OjoConsumo } from 'utils/icon-centrocontrol'
import '../ScadaGeneral.scss'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import { CsvDownloader } from '../../CsvDownloader'
import { isToday } from 'utils/Utils'
import { moreOneDay } from 'utils/Utils'

export const GeneralProductionGraphs = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split('/')[4]

    const [loading, setLoading] = useState()
    const [general, setGeneral] = useState()
    const [dataTable, setDataTable] = useState()
    const [graphVisibility, setGraphVisibility] = useState(true);
    const [selectDaily, setSelectDaily] = useState("day")

    const headTable = ["Fecha", "Consumo (kWh)", "Energía Consumida Red (kWh)", "Producción FV (kWh)", "Energía Vertida Red (kWh)", "Ecológico (%)"]

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await fetch(urls.scada + `getActualDataPlant/${idMaster}?idCCMaster=${idMaster}&iniDate=${props?.start?.format("YYYY-MM-DD")}&endDate=${props?.end?.format("YYYY-MM-DD")}&groupDate=${props?.daily}`,
                { method: "GET", headers: headersScada(token) })
            if (res.status !== 204) {
                const json = await res.json()
                setLoading(false)
                return setGeneral(json)
            } else {
                setLoading(false)
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const variableTableSelfConsumption = [
        { label: "Nombre", key: "nick" },
        { label: "Fecha", key: "datetime" },
        { label: "Consumo (kWh)", key: "eaconsdia" },
        { label: "Energía Consumida Red (kWh)", key: "consumida" },
        { label: "Producción FV (kWh)", key: "eagendia" },
        { label: "Energía Vertida (kWh)", key: "vertida" },
        { label: "Ecológico (%)", key: `eco` }
    ]
    const [csvHeaders, setCsvHeaders] = useState(variableTableSelfConsumption)

    useEffect(() => {
        setCsvHeaders(variableTableSelfConsumption)
    }, [])

    const fetchDataHistorical = async () => {
        try {
            setLoading(true)
            const res = await fetch(urls.scada + `getHistoricalMain/?idCCMaster=${idMaster}&iniDate=${props?.start?.format("YYYY-MM-DD")}&endDate=${props?.end?.format("YYYY-MM-DD")}&groupDate=${props?.daily}`,
                { method: "GET", headers: headersScada(token) })
            if (res.status !== 204) {
                const json = await res.json()
                setLoading(false);
                return setDataTable(json)
            } else {
                setLoading(false)
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    useEffect(() => {
        fetchData()
        fetchDataHistorical()
    }, [props?.start, props?.daily])

    const [activeTabDevice, setActiveTabDevice] = useState(0)

    useEffect(() => {
        if (props?.titleButton === "Mes Actual" || props?.titleButton === "Año" || props?.tittleButton === "Rango") {
            setActiveTabDevice(1)
        }
    }, [props?.titleButton])

    useEffect(() => {
        if (!moreOneDay(props?.start, props?.end)) {
            setActiveTabDevice(1)
        };
    }, [props?.start, props?.end])

    return (
        <>
            {props.loadingCurves || props?.graphDataProduction === undefined ?
                <Loader />
                :
                <>
                    <ProgressBarGeneral data={props?.data} general={general} />
                    <TableResumeGeneral general={general} isMobile={props?.isMobile} loading={loading} />
                    <div className="button-calendar-graph-general">
                        <div className="selected-graphs">
                            <button
                                className={activeTabDevice === 0 ? "button-general-active" : "button-general"}

                                disabled={!moreOneDay(props?.start, props?.end)}
                                selected={activeTabDevice === 0} onClick={() => setActiveTabDevice(0)}
                            >Potencia (kW)
                            </button>
                            <button
                                className={activeTabDevice === 1 ? "button-general-active" : "button-general"}

                                selected={activeTabDevice === 1}
                                onClick={() => setActiveTabDevice(1)}
                            >Energía (kWh)
                            </button>
                        </div>
                        <span className="filter-component-calendar">
                            <div>
                                <CalendarComponent
                                    maxDate={props?.end}
                                    setStart={props?.setStart}
                                    setEnd={props?.setEnd}
                                    setDaily={props?.setDaily}
                                    setSelectDaily={setSelectDaily}
                                />
                            </div>
                            <div className="ml-3 d-flex align-items-center">
                                <SelectorFecha
                                    daily={props?.daily}
                                    setDaily={props?.setDaily}
                                    tipo={props?.daily}
                                    selectDaily={selectDaily}

                                />
                                {dataTable && dataTable &&
                                    <CsvDownloader
                                        className="csvDonwloader"
                                        today={isToday(props?.start)}
                                        daily={props?.daily}
                                        props={props}
                                        historicalData={dataTable}
                                        finalData={dataTable}
                                        start={props?.start}
                                        end={props?.end}
                                        setHeadersCsv={setCsvHeaders}
                                        headersCsv={csvHeaders}
                                    />
                                }
                            </div>
                        </span>
                    </div>
                    <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "15px", }}>
                        <span style={{ height: 30, marginRight: "10px", cursor: "pointer", }} onClick={() => setGraphVisibility(!graphVisibility)}>
                            <OjoConsumo />
                        </span>
                    </span>
                    {graphVisibility &&
                        <span>
                            {loading === true ?
                                <Loader />
                                :
                                <>
                                    <div className={`tab-item-wrapper prueba ${activeTabDevice === 0 ? 'active' : ''}`} index={0}>
                                        <div className="graphs-general">
                                            <GeneralPotencialGraphs
                                                id={'graphs'}
                                                activeTabDevice={activeTabDevice}
                                                graphDataProduction={props?.graphDataProduction}
                                                daily={props.daily}
                                                start={props?.start} />
                                        </div>
                                    </div>
                                    <div className={`tab-item-wrapper prueba ${activeTabDevice === 1 ? 'active' : ''}`} index={0}>
                                        <div className="graphs-general">
                                            <GeneralEnergyGraphs
                                                id={'graphs'}
                                                activeTabDevice={activeTabDevice}
                                                general={dataTable}
                                                start={props?.start}
                                                end={props?.end}
                                                daily={props?.daily}
                                                data={props?.data} />
                                        </div>
                                    </div>
                                </>
                            }
                        </span>
                    }
                    <>
                        <div className="table-general">
                            {props?.isMobile === true ?
                                <TableGeneralMobile start={props.start} data={props.data} childrenDevices={props.childrenDevices} end={props.end} general={dataTable} daily={props.daily} headTable={headTable} /> :
                                <TableGeneral start={props.start} data={props.data} childrenDevices={props.childrenDevices} end={props.end} general={dataTable} daily={props.daily} headTable={headTable} />}
                        </div>
                    </>
                </>
            }
        </>
    )
}