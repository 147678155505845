import { Grid } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { dispositivos } from 'utils/constant'

export const ModalActionEMS = ({ device, meter, production, open, setOpen, setKeySwitch, mode, master, order }) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [body, setBody] = useState()

    const fetchEditMeter = (numserie) => {
        let url
        if ((device.id_tipo_dispositivo === dispositivos.ccm2 || device.id_tipo_dispositivo === dispositivos.ccm4)) {
            url = `emsMeters/${numserie}?idCcMaster=${numserie}`
        } else {
            url = `emsDevices/${numserie}?idCcMaster=${numserie}`
        }

        setLoading(true)
        let status
        fetch(urls.scada + url, {
            method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body)
        }).then(res => {
            if (res.status === 200) {
                status = res.status
                dispatch(createToast({ status: status, message: "Configuración actualizada correctamente", reload: false }))
            } else {
                status = res.status
                dispatch(createToast({ status: status, message: "Ha habido un error al actualizar la configuración", reload: false }))
            }
        }).catch((error) => {
            console.error("Ha habido un error en API Scada", error)
        }).finally(() => {
            setLoading(false)
            setKeySwitch(Date.now())
            setOpen(false)
        })
    }

    const handleEdit = () => {
        fetchEditMeter(device.id_tipo_dispositivo === dispositivos.ccmaster ? device.numserie : device.id_parent)
    }

    useEffect(() => {
        setBody([{
            "numserie": Number(device.numserie),
            "nick": device.nick,
            "id_tipo_dispositivo": device.id_tipo_dispositivo,
            "id_parent": device.id_parent,
            "ID_entry": device.id_entry,
            "mode": mode ? mode[`${device.numserie}-${device.id_entry}`] : device.mode,
            "general_meter": meter ? meter[device.numserie] : device.general_meter,
            "production": production ? production[device.numserie] : device.production,
            "device_power": device.device_power,
            "control_order": order ? order[`${device.numserie}-${device.id_entry}`] : device.control_order
        }])
    }, [device, meter, production, mode, order])

    return (
        <>
            <Modal onClick={(e) => e.stopPropagation()} maxWidth="sm" open={open} setOpen={setOpen} disabled={loading} title={`Guardar configuración de ${master?.[0]?.nick ? master?.[0]?.nick : master.nick ? master.nick : device?.master?.nick}`} loading={loading} functSave={handleEdit} buttonAction="Aceptar" >
                <Grid item xs={12} className="text-center mt-2">
                    <Grid item xs={12} className="text-center mt-2">
                        <span>
                            <h3 style={{ fontSize: '1.2rem' }} className="font-weight-lighter">
                                ¿Estás seguro que deseas actualizar la configuración?
                            </h3>
                        </span>
                    </Grid>
                </Grid>

            </Modal>

        </>)
}
