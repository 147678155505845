import React from "react"

/** 
 * @param {project {}} recibimos los datos del proyecto
 * @returns retornamos el permiso que tenga el usuario
 */
export const UserPermission = ({ className, project, style }) => {
    
    return (
        <>
            {project?.permissions && (project?.permissions === "admin" || project?.permissions === "Admin") ?
                <span className={`${className} type-permission admin`} style={style} title="Administrador">A</span>
                :
                project?.permissions && project?.permissions === "instal" ?
                    <span className={`${className} type-permission instal`} style={style} title="Instalador">I</span>
                    :
                    <span className={`${className} type-permission user`} style={style} title="Usuario">U</span>
            }
        </>
    )
}