import React, { useContext, useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from 'react-router-dom'
import { Grid } from "@mui/material"
import { GlobalContext } from "context/GlobalContext.jsx"
import { Loader } from 'components/Loader/Loader'
import { clearInfoInstal, addInfoInstal } from "reduxSlice/infoInstalSlice"
import { addElementTree, clearTree } from "reduxSlice/treeDevicesSlice"
import { addAllAlarms, clearAlarms, updateAllAlarms } from "reduxSlice/alarmaSlice"
import { headersCentroControl } from "services/headers"
import { ErrorDevices } from 'utils/Utils'
import { urls } from "utils/constant"
import { PrincipalResume } from "../Principal/PrincipalResume/PrincipalResume"
import { MenuCentroControl } from "./MenuCentroControl"
import { Principal } from "../Principal/Principal.jsx"

export const Index = () => {
  const location = useLocation()
  const token = sessionStorage?.token
  const { actualProject, loadingApp, updateDevice } = useContext(GlobalContext)
  const [activeTab, setActiveTab] = useState(location?.state?.alarms ? 3 : 1)
  const [data, setData] = useState([])
  const [numserieError, setNumserieError] = useState([])
  const [allDevicesProject, setAllDevicesProject] = useState(null)
  const [selectOneInstallation, setSelectOneInstallation] = useState()
  const [masterError, setMasterError] = useState([])
  const dispatch = useDispatch()
  const infoInstal = useSelector((state) => state.infoInstal)
  const intervalRef = useRef()

  const fetchAlarmProject = async (body) => {
    try {
      const response = await fetch(urls.ccontrol + 'activeAlarms', {
        method: "POST",
        headers: headersCentroControl(token),
        body: JSON.stringify({ numseries: body })
      })
      const data = await response.json();
      return data
    } catch (err) {
      console.error("ERROR. API Centro de Control", err)
      return {}
    }
  }

  const fetchAlarmHideProject = async (body) => {
    try {
      const response = await fetch(urls.ccontrol + 'showHideAlarms', {
        method: "POST",
        headers: headersCentroControl(token),
        body: JSON.stringify({ numseries: body })
      })
      const data = await response.json()
      return data
    } catch (err) {
      console.error("ERROR. API Centro de Control", err)
      return {}
    }
  }

  const chargeAlarms = async (body, repeat) => {
    const alarms = await fetchAlarmProject(repeat ? allDevicesProject : body)
    dispatch(addAllAlarms(alarms))
    const alarmsHide = await fetchAlarmHideProject(repeat ? allDevicesProject : body)
    dispatch(updateAllAlarms(alarmsHide))
  }

  function repeatFunction() {
    intervalRef.current = setInterval(actionAlarm, 15000)
  }

  function actionAlarm() {
    chargeAlarms([], true)
  }

  useEffect(() => {
    if (allDevicesProject?.length > 0) {
      repeatFunction()
    }
    return () => clearInterval(intervalRef.current)
  }, [allDevicesProject])

  useEffect(() => {
    dispatch(clearInfoInstal())
    dispatch(clearTree())
    dispatch(clearAlarms())
  }, [])

  useEffect(() => {
    if (actualProject?.id_project) {
      let devicesError = []
      let allDevices = []
      actualProject?.groups?.forEach(group => {
        dispatch(addElementTree({ element: `G-${group?.id_group}`, check: true }))
        group?.devices?.forEach(device => {
          dispatch(addInfoInstal({ numserie: device?.numserie, info: { error: device?.error } }))
          dispatch(addElementTree({ element: `G-${group?.id_group}_D-${device?.numserie}`, check: device?.error !== 1 }))
          allDevices.push(device?.numserie)
          if (device?.error === 1) {
            devicesError.push(device?.numserie)
          }
        })
        group?.subgroups?.forEach(subGroup => {
          dispatch(addElementTree({ element: `G-${group?.id_group}_S-${subGroup?.id_subgroup}`, check: true }))
          subGroup?.devices?.forEach(device => {
            dispatch(addInfoInstal({ numserie: device?.numserie, info: { error: device?.error } }))
            dispatch(addElementTree({ element: `G-${group?.id_group}_S-${subGroup?.id_subgroup}_D-${device?.numserie}`, check: device?.error !== 1 }))
            allDevices.push(device?.numserie)
            if (device?.error === 1) {
              devicesError.push(device?.numserie)
            }
          })
        })
      })
      setNumserieError(devicesError)
      setAllDevicesProject(allDevices)
      chargeAlarms(allDevices)
    }
  }, [actualProject, updateDevice])

  useEffect(() => {
    if (actualProject?.id_project) {
      let count = 0
      actualProject?.groups?.forEach(group => {
        count += group?.devices?.length
        group?.subgroups?.forEach(subGroup => {
          count += subGroup?.devices?.length
        })
      })
      const numAttributes = Object.keys(infoInstal).length
      if (numAttributes === count) {
        setMasterError(ErrorDevices(actualProject, infoInstal))
      }
    }
  }, [infoInstal, actualProject])

  useEffect(() => {
    window.scroll(0, 0)
  }, [activeTab])


  return (
    <Grid container className="index-main-cc" style={{ justifyContent: "center", background: "#fff" }}>
      {!loadingApp && actualProject?.groups?.length >= 1 ?
        <>
          <MenuCentroControl activeTab={activeTab} setActiveTab={setActiveTab} />
          <Grid className="principal-cc" item xs={12} sm={12} md={3} xl={3} style={{ height: 'auto', scrollbarWidth: window.innerWidth > 960 ? 'thin' : "none" }}>
            <Principal
              numserieError={numserieError}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              data={data}
              actualProject={actualProject}
              setSelectOneInstallation={setSelectOneInstallation}
              selectOneInstallation={selectOneInstallation}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9} xl={9}>
            <PrincipalResume
              masterError={masterError}
              data={data}
              setData={setData}
              idUser={token}
              actualProject={actualProject}
              selectOneInstallation={selectOneInstallation}
              activeTab={activeTab}
              allDevicesProject={allDevicesProject}
            />
          </Grid>
        </>
        :
        <div className="justify-content-center ">
          <Loader message={`Cargando el proyecto ...`} />
        </div>
      }
    </Grid>
  )
}