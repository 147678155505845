// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.devices {
  padding-right: 5% !important;
}

.MuiInputBase-formControl, .Mui-focused :hover {
  border-color: #FF8A1F !important;
}`, "",{"version":3,"sources":["webpack://./src/views/Scada/General/ScadaScreen.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;;AAGA;EACI,gCAAA;AAAJ","sourcesContent":[".devices {\n    padding-right: 5% !important;\n}\n\n\n.MuiInputBase-formControl, .Mui-focused :hover {\n    border-color: #FF8A1F !important\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
