import { headersOnOff } from "services/headers";
import { urls } from "utils/constant.jsx"

export const BulbAsyncRequest = async (sn, name, color, command) => {

    let aux;

    try {

        const response = await fetch(
            urls.onoff + `command/${sn}`, {
            method: 'POST', redirect: 'follow', headers: headersOnOff,
            body: JSON.stringify({ "SN": name, "command": command, "value": color })
        });

        aux = await response;

    } catch (err) {
        console.error("ERROR. API OnOff", err);

    }
    return aux;
}