import React, { useState, useRef, useEffect } from 'react'
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { CsvDownloader } from 'views/Scada/SelfConsumption/CsvDownloader'
import { StyledTableCell, StyledTableRow, StyledTableCellHead } from 'components/Table/TableStyle'
import { Loader } from "components/Loader/Loader"
import { Modal } from 'components/Modal/Modal'
import { headersCentroControl } from 'services/headers'
import { stateUpdateInstal } from 'utils/Utils'
import { urls } from "utils/constant.jsx"

/**
 * @param {{ token: string, configuración: {}, modalHistory: boolean, setModalHistory: function}} 
 * @param token Token necesario para las llamadas.
 * @param configuración dispositivo sobre el que se va a trabajar.
 * @param modalHistory estado quee stablece si el modal permanece abierto o no.
 * @param setModalHistory función que setea modalHistory.
 * @returns Devuelve tabla de histórico de actualizaciones.
 */
export const FirmwareHistory = ({
    token,
    configuracion,
    modalHistory,
    setModalHistory,
    actualProject
}) => {

    const [data, setData] = useState();
    const [csvData, setCsvData] = useState();
    const [loading, setLoading] = useState();
    const tableRef = useRef(null);

    /**
     * @description cabecera del excel resultante.
     */
    const [headersCsv, setHeadersCsv] = useState([
        { label: 'Nº serie', key: 'numserie' },
        { label: 'Fecha', key: 'fecha' },
        { label: 'FW anterior', key: 'oldFW' },
        { label: 'FW actual', key: 'newFW' },
        { label: 'Estado', key: 'status' },
    ]);

    /**
     * 
     * @param {*} obj datos de la consulta con el historial de actualizaciones.
     * @returns los valores de 0 y 1 de la columna estado, traducidos como
     * Error o Actualizado
     */
    const csvFormat = (obj) => {
        let aux = [];
        obj.forEach((device) => {
            let object = Object.assign({}, device);
            aux.push(object)
        })
        aux.forEach((device) => {
            if (device.status === 0) {
                device.status = 'Error'
            } else if (device.status === 1) {
                device.status = 'Actualizado'
            }
        })
        return aux
    }

    /**
     * @param {*} obj resultado de la consulta.
     * @description setea el resultado de la consulta dentro de los estados
     * necesarios.
     */
    const dataFormat = (obj) => {
        if (obj !== undefined) {
            setData(obj)
            setCsvData(csvFormat(obj))
        }
    }

    /**
     * @description trae los datos históricos de las actualizaciones de firmware.
     */
    const fetchDataFirmware = async () => {
        setLoading(true)
        try {
            const res = await fetch(urls.ccontrol + `getHistoricalVersions/${configuracion.numserie}`,
                {
                    method: 'GET',
                    headers: headersCentroControl(token)
                })
            if (res.status === 200) {
                let json = await res.json()

                dataFormat(json)
            } else {
                setData([])
            };
            setLoading(false)
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    /**
 
    /**
    * @description lanza la consulta al abrir el modal. 
    */
    useEffect(() => {
        fetchDataFirmware(configuracion)
    }, [configuracion])

    return (
        <>
            <Modal maxWidth="lg" open={modalHistory} setOpen={setModalHistory} title={`Histórico de actualizaciones de ${actualProject?.category === "mabt" ? 'MABT de ' : ''} "${configuracion.nick}"`} footer=" ">
                <hr className="m-0" />
                <Grid item lg={12} style={{ padding: '2%' }}>
                    <div style={{ width: "16px", display: 'flex', alignItems: 'center', height: "32px" }}>
                        {csvData !== undefined &&
                            <CsvDownloader
                                className="csvDonwloader"
                                today={true}
                                daily={'days'}
                                finalData={csvData}
                                setHeadersCsv={setHeadersCsv}
                                headersCsv={headersCsv}
                            />
                        }
                    </div>
                    {!loading ?
                        <>
                            <TableContainer style={{ maxHeight: '30em', minHeight: '30em', marginTop: '0px' }}>
                                <Table ref={tableRef} sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow style={{ borderBottom: '2px solid black', borderRadius: '50px' }}>
                                            <StyledTableCellHead>Nº serie</StyledTableCellHead>
                                            <StyledTableCellHead>Fecha</StyledTableCellHead>
                                            <StyledTableCellHead>FW anterior</StyledTableCellHead>
                                            <StyledTableCellHead>FW actual</StyledTableCellHead>
                                            <StyledTableCellHead>Estado</StyledTableCellHead>
                                        </TableRow>
                                    </TableHead>
                                    {data !== undefined && data.length > 0 &&
                                        <TableBody>
                                            {data.map((obj) => {
                                                return (
                                                    <StyledTableRow>
                                                        <StyledTableCell>{obj.numserie}</StyledTableCell>
                                                        <StyledTableCell>{obj.fecha}</StyledTableCell>
                                                        <StyledTableCell>{obj.oldFW}</StyledTableCell>
                                                        <StyledTableCell>{obj.newFW}</StyledTableCell>
                                                        <StyledTableCell>{stateUpdateInstal(obj.status)}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    }
                                </Table>
                                {data !== undefined && data.length === 0 &&
                                    <h1 style={{ textAlign: 'center', marginTop: '30px' }}>Sin datos para este dispositivo</h1>
                                }
                            </TableContainer>
                        </>
                        :
                        <div className="justify-content-center">
                            <Loader />
                        </div>
                    }
                </Grid>
            </Modal>
        </>
    )
}