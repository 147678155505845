import React, { useEffect, useState } from "react"
import { headersScada } from "services/headers"
import { urls } from "utils/constant"
import { Modal } from "components/Modal/Modal"
import { useDispatch } from "react-redux"
import { createToast } from "reduxSlice/toastSlice"

export const ModalChangeAliasCalendar = ({ data, dataRelay, alias, loadingAlias, setLoadingAlias, idCcMaster, setOpen, open }) => {
    const token = sessionStorage?.token
    const dispatch = useDispatch()
    const [body, setBody] = useState()


    useEffect(() => {
        setBody({
            "ID_entry": data?.ID_entry,
            "alias": data?.name,
        })
    }, [data])


    const asyncCalendar = () => {
        if (alias) {
            setLoadingAlias(true)
        } let status
        fetch(urls.scada + `calendarRelay/${idCcMaster}?idCCMaster=${idCcMaster}`, { method: 'PATCH', headers: headersScada(token), body: JSON.stringify(body) })
            .then(res => {
                status = res.status
                if (res.status === 200) {
                    if (dataRelay && data?.ID_entry === 1) {
                        dataRelay.out1_alias = data.name
                    } else if (dataRelay && data?.ID_entry === 2) {
                        dataRelay.out2_alias = data.name
                    }
                }
                return res.json()
            }).then(data => {
                dispatch(createToast({ status: status, message: data.message, reload: false }))
            }).catch(err => console.error("ERROR. API Scada", err))
            .finally(() => {
                if (alias) {
                    data.prevName = data.name
                }
                setOpen(false);
                setLoadingAlias(false)
            }
            )
    }

    const functCancel = () => {
        setOpen(false)
    }

    return (
        <Modal onClick={(e) => e.stopPropagation()} open={open} setOpen={setOpen} disabled={loadingAlias} functSave={asyncCalendar} title="Cambios en Punto de control" buttonAction="Aplicar" functCancel={functCancel}>
            <span className="content-modal-center">
                <h3>¿Deseas aplicar estos cambios a {data?.prevName ? data?.prevName : data?.name}?</h3>
            </span>
        </Modal>
    )
}