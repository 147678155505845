import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import moment from "moment"
import { ConfiguracionList } from 'views/Configuracion/ConfiguracionList'
import { Alarms } from 'views/Alarms/Alarms'
import { headersScada } from 'services/headers'
import { compararFecha, isMobile, esHoy } from 'utils/Utils'
import { urls, dispositivos } from 'utils/constant'
import { cargarDatosGrafica, cargarDatosTabla, convertirDatosMabt, convertirHistoricoEnergiaGrafica, datoNull, diccionarioConversionMabt, diccionarioConversionMabtHistorico } from './utils'
import { OnOff } from '../CcMaster/tabs/OnOff'
import { InformeMabt } from './InformeMabt'
import { GeneralMabt } from './GeneralMabt'
import { ScadaMabt } from './ScadaMabt'
import { MenuScada } from './MenuScada'

export const Mabt = ({ devices, token, masterPermission, masterError }) => {
    const [firstCharge, setFirstCharge] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [activeTabDeviceMenuGrafica, setActiveTabDeviceMenuGrafica] = useState(0)
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const [activeTabDeviceGrafica, setActiveTabDeviceGrafica] = useState(0)
    const [medidor, setMedidor] = useState(0)
    const [visible, setVisible] = useState(true)
    const [data, setData] = useState()
    const [tipoGrafica, setTipoGrafica] = useState('pact')
    const [tipo, setTipo] = useState('pact')
    const [menuGrafica, setMenuGrafica] = useState('total')
    const [loadingLineas, setLoadingLineas] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [error, setError] = useState(false)
    const [loadingGrafica, setLoadingGrafica] = useState(false)
    const [loadingTabla, setLoadingTabla] = useState(false)
    const [errorGrafica, setErrorGrafica] = useState(false)
    const [general, setGeneral] = useState()
    const [datosLineas, setDatosLineas] = useState()
    const [datosGrafica, setDatosGrafica] = useState()
    const [datosTabla, setDatosTabla] = useState()
    const [total, setTotal] = useState(0)
    const [vtn, setVtn] = useState()
    const [tipoEscala, setTipoEscala] = useState('kW')
    const [alarmas, setAlarmas] = useState()
    const nombreInstalacion = useSelector((state) => state.instalacion)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [fechaVtn, setFechaVtn] = useState()
    const [confAlarms, setConfAlarms] = useState()
    const [daily, setDaily] = useState('days')
    const vtnDevice = devices?.childrenDevices?.find(ele => ele?.id_tipo_dispositivo === dispositivos.ccmabtv)
    const url = devices?.numserie + "?idCCMaster=" + devices?.numserie
    let mobile = isMobile(1300)

    const seleccionarTab = (tab) => {
        setMedidor()
        setActiveTab(tab)
        if (tab === 0) {
            setStart(moment())
            setEnd(moment())
            setDaily('days')
            if (tipoGrafica !== 'energiaGenerada' && tipoGrafica !== 'energiaConsumida') {
                seleccionarTabMenuGrafica(0)
            } else {
                seleccionarTabMenuGrafica(1)
            }
            seleccionarTabLinea(0)
            seleccionarTabGrafica(0)
        }
    }

    const seleccionarTabLinea = (tab) => {
        setActiveTabDevice(tab)
        switch (tab) {
            case 0:
                setTipo('pact')
                break
            case 1:
                setTipo('corriente')
                break
            case 2:
                setTipo('tension')
                break
            case 3:
                setTipo('preact')
                break
            default:
                break
        }
    }

    const seleccionarTabGrafica = (tab) => {
        setActiveTabDeviceGrafica(tab)
        switch (tab) {
            case 0:
                setTipoEscala('kW')
                setTipoGrafica('pact')
                break
            case 1:
                setTipoEscala('A')
                setTipoGrafica('corriente')
                break
            case 2:
                setTipoEscala('V')
                setTipoGrafica('tension')
                break
            case 3:
                setTipoEscala('kW')
                setTipoGrafica('preact')
                break
            case 4:
                setTipoEscala('kWh')
                setTipoGrafica('energia')
                break
            case 5:
                setTipoEscala('kWh')
                setTipoGrafica('energiaGenerada')
                break
            case 6:
                setTipoEscala('kWh')
                setTipoGrafica('energiaConsumida')
                break
            default:
                break
        }
    }

    const seleccionarTabMenuGrafica = (tab) => {
        setActiveTabDeviceMenuGrafica(tab)
        switch (tab) {
            case 0:
                setMenuGrafica('total')
                if (activeTabDeviceGrafica > 4) {
                    seleccionarTabGrafica(4)
                }
                break
            case 1:
                setMenuGrafica('medidores')
                if (activeTabDeviceGrafica === 4) {
                    seleccionarTabGrafica(5)
                }
                break
            default:
                break
        }
    }

    const seleccionarMedidor = (device) => {
        setActiveTab()
        setLoadingData(true)
        if (medidor && device && device.numserie === medidor.numserie) {
            fetchData()
        }
        device['mabt_serial'] = devices.numserie
        setMedidor(device)
    }

    const fecthResumenTabla = () => {
        fetch(urls.scada + 'getCurrentData/resumen/' + url, { method: 'GET', headers: headersScada(token) })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return false
            }
        })
        .then(data => {
            if (data !== false) {
                setGeneral(data)
            } else {
                setError(true)
            }
        })
        .catch(err => 
            console.error("ERROR. API Scada", err)
        )
    }

    const fecthLineas = () => {
        fetch(urls.scada + 'getCurrentData/' + tipo + '/' + url, { method: 'GET', headers: headersScada(token) })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return false
            }
        })
        .then(data => {
            if (data !== false) {
                setVtn(datoNull(data?.vtn))
                setFechaVtn(data?.datetime ? data?.datetime : '')
                setDatosLineas(convertirDatosMabt(data.data, tipo, setTotal))
            } else {
                setError(true)
            }
            setLoadingLineas(false)
        })
        .catch(err => 
            console.error("ERROR. API Scada", err)
        )
    }

    const fecthGrafica = (grafica, escala) => {
        let obj = {
            iniDate: moment(start).format('YYYY-MM-DD'),
            endDate: moment(end).format('YYYY-MM-DD'),
            iniTime: "00:00",
            endTime: esHoy(end) ? moment().format('HH:mm') : moment(end).format('HH:mm'),
            interval: !esHoy(start) ? "60" : "5",
            axis: [
                {
                    idDeviceType: 20,
                    fields: [grafica ? grafica : tipoGrafica],
                    numseries: [devices.numserie],
                    plantType: "mabt",
                    unit: escala ? escala : tipoEscala
                }
            ]
        }
        fetch(urls.scada + 'chart/getDataChartsCurves/' + menuGrafica + '/' + url, { method: 'POST', headers: headersScada(token), body: JSON.stringify(obj) })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return false
            }
        })
        .then(data => {
            if (data !== false && !data?.message) {
                setDatosGrafica(cargarDatosGrafica(data))
            } else {
                setErrorGrafica(true)
            }
            setLoadingGrafica(false)
        })
        .catch(err => 
            console.error("ERROR. API Scada", err)
        )
    }

    const fecthTabla = () => {
        let extension = ''
        if (menuGrafica === 'total') {
            extension += '&typeMabt=total'
        }
        fetch(urls.scada + `getHistoricalDataScada/` + url + `&iniDate=${start.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${daily}&scadaType=mabt${extension}`, { method: 'GET', headers: headersScada(token) })
        .then(res => {
            if (res.status === 200) {
                return res.json()
            } else {
                return false
            }
        })
        .then(data => {
            let mapa = data.flat().concat()
            if (tipoGrafica === 'energia' || tipoGrafica === 'energiaConsumida' || tipoGrafica === 'energiaGenerada') {
                setDatosGrafica(convertirHistoricoEnergiaGrafica(data, tipoGrafica))
                setLoadingGrafica(false)
            }
            setDatosTabla(cargarDatosTabla(mapa, esHoy(start) ? diccionarioConversionMabt : diccionarioConversionMabtHistorico, esHoy(start), daily, menuGrafica === 'total'))
            setLoadingTabla(false)
        })
        .catch(err => 
            console.error("ERROR. API Scada", err)
        )
    }

    /** 
    * @param {boolean} histo 
    * @returns Devuelve las alarmas activas o historicas de las instalaciones seleccionadas
    */
    const fetchDataAlarms = async () => {
        let url = 'alarms/getActiveAlarms'
        let body = { 'devices': [devices.numserie] }
        try {
            const res = await fetch(urls.scada + url, { method: 'POST', headers: headersScada(token), body: JSON.stringify(body) })
            if (res.status === 200) {
                let json = await res.json()
                setAlarmas(json)
            } else {
                setAlarmas([])
            }
            return true
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const fetchData = () => {
        let url = `getCurrentData/${medidor.numserie}?idCCMaster=${devices.numserie}`
        fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
        .then(res => {
            if (res.status === 204) {
            } else {
                return res.json()
            }
        })
        .then(data => {
            setData(data)
            setLoadingData(false)
        })
        .catch(err => {
            console.error("ERROR. API Scada ", err)
        })
    }

    const getAlarms = () => {
        let body = { 'devices': [devices.numserie] }
        fetch(urls.scada + 'alarms/getConfiguredAlarms', { method: 'POST', headers: headersScada(token), body: JSON.stringify(body) })
        .then((res) => {
            return res.json()
        }).then(data => {
            setConfAlarms(data)
        }).catch(err => console.error("ERROR. API Scada", err))
    }

    useEffect(() => {
        if (activeTab === 0) {
            setError(false)
            setLoadingLineas(true)
            fecthLineas()
            fecthResumenTabla()
            fetchDataAlarms()
        }
    }, [tipo, activeTab])

    useEffect(() => {
        if (start !== undefined && end !== undefined ) {
            let visibleGrafica = compararFecha(start, end)
            if (!visibleGrafica && activeTabDeviceGrafica < 4) {
                setActiveTabDeviceGrafica(menuGrafica === 'total' ? 4 : 5)
            } else if (!esHoy(start) && activeTabDeviceGrafica === 3) {
                setActiveTabDeviceGrafica(0)
            }
            setVisible(visibleGrafica)
            if (tipoGrafica !== 'energia' && tipoGrafica !== 'energiaGenerada' && tipoGrafica !== 'energiaConsumida' && (daily === 'days' || daily === 'hours')) {
                if (activeTab === 0) {
                    setDatosGrafica()
                    setErrorGrafica(false)
                    setLoadingGrafica(true)
                    setDatosTabla()
                    setLoadingTabla(true)
                    if (!visibleGrafica) {
                        fecthGrafica('pact', 'kWh')
                    } else if (!esHoy(start) && activeTabDeviceGrafica === 3) {
                        fecthGrafica('pact', 'kWh')
                    } else {
                        fecthGrafica()
                    }
                    fecthTabla()
                }
            } else if (tipoGrafica === 'energia' || tipoGrafica === 'energiaGenerada' || tipoGrafica === 'energiaConsumida') {
                setDatosTabla()
                setLoadingTabla(true)
                setDatosGrafica()
                setErrorGrafica(false)
                setLoadingGrafica(true)
                fecthTabla()
            }
        }
    }, [tipoGrafica, menuGrafica, start, end, activeTab])

    useEffect(() => {
        if (daily !== 'days' && daily !== 'hours' && tipoGrafica !== 'energia' && tipoGrafica !== 'energiaGenerada' && tipoGrafica !== 'energiaConsumida') {
            if (menuGrafica === 'total') {
                setTipoGrafica('energia')
            } else {
                setTipoGrafica('energiaGenerada')
            }
        } else if (!firstCharge) {
            setDatosTabla()
            setLoadingTabla(true)
            fecthTabla()
        } else {
            setFirstCharge(false)
        }
    }, [daily])

    useEffect(() => {
        getAlarms()
    }, [])

    useEffect(() => {
        if (medidor) {
            fetchData()
        }
    }, [medidor])
    
    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className='scada-navbar-centro-control card-centro-control'>
                <MenuScada token={token} activeTab={activeTab} seleccionarTab={seleccionarTab} devices={devices} master={masterPermission} nombreInst={nombreInstalacion} masterError={masterError} />
            </Grid>
            {activeTab === 0 &&
                <Grid container>
                    <GeneralMabt
                        token={token}
                        activeTabDevice={activeTabDevice}
                        seleccionarTabLinea={seleccionarTabLinea}
                        start={start}
                        datosLineas={datosLineas}
                        vtn={vtn}
                        general={general}
                        error={error}
                        loadingLineas={loadingLineas}
                        total={total}
                        seleccionarMedidor={seleccionarMedidor}
                        vtnDevice={vtnDevice}
                        alarmas={alarmas}
                        devices={devices}
                        masterPermission={masterPermission}
                        seleccionarTab={seleccionarTab}
                        confAlarms={confAlarms}
                    />
                    <InformeMabt
                        activeTabDeviceMenuGrafica={activeTabDeviceMenuGrafica}
                        seleccionarTabMenuGrafica={seleccionarTabMenuGrafica}
                        activeTabDeviceGrafica={activeTabDeviceGrafica}
                        seleccionarTabGrafica={seleccionarTabGrafica}
                        start={start}
                        setStart={setStart}
                        end={end}
                        setEnd={setEnd}
                        visible={visible}
                        datosTabla={datosTabla}
                        datosGrafica={datosGrafica}
                        loadingGrafica={loadingGrafica}
                        loadingTabla={loadingTabla}
                        mobile={mobile}
                        errorGrafica={errorGrafica}
                        daily={daily}
                        setDaily={setDaily}
                        tipoGrafica={tipoGrafica}
                        master={devices}
                        tipoEscala={tipoEscala}
                    />
                </Grid>
            }
            {activeTab === 1 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <Alarms instalacioneSeleccionadas={[devices.numserie]} dispositivos={[devices]} />
                </Grid>
            }
            {activeTab === 2 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <ConfiguracionList instalacioneSeleccionadas={[devices.numserie]} dispositivo={[devices]} scada={true} masterError={masterError} />
                </Grid>
            }
            {medidor !== undefined && data &&
                <ScadaMabt
                    token={token}
                    alarmas={alarmas}
                    seleccionarMedidor={seleccionarMedidor}
                    data={data}
                    loadingData={loadingData}
                    medidor={medidor}
                    devices={devices}
                    masterPermission={masterPermission}
                    fechaVtn={fechaVtn}
                    datosLineas={datosLineas}
                    mobile={mobile}
                />
            }
            {activeTab === 4 &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='pl-3 pr-3'>
                    <OnOff activeTab={activeTab} isMobile={isMobile} />
                </Grid>
            }
        </>
    )
}