import React, { useState } from "react"
import arrowExpanseScada from 'assets/img/icons/arrow-expanse-scada.png'
import './styleSelfConsumption.scss'

export const MenuMobile = ({ children }) => {
    const [expande, setExpande] = useState(false)

    const handleClick = () => {
        setExpande(current => !current)
    }

    return (
        <div >
            <span onClick={handleClick}>
                <div onClick={handleClick} >
                    <span className="menu-mobile-item">
                        <span className={`${expande === true ? "mostrar-menu" : "ocultar-menu"} expanse-menu-scada`}>
                            {children}
                        </span>
                        <span onClick={handleClick} className="button-expanse-menu">
                            <img src={arrowExpanseScada} alt="arrowExpan" style={{ width: "18px", transform: !expande ? "rotate(270deg)" : "rotate(90deg)" }} />
                        </span>
                    </span>
                </div>
            </span>
        </div>
    )
}