import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, FormControlLabel, Grid, TextField, FormControl } from '@mui/material'
import { SwitchStyled } from 'components/Switch/SwitchStyled'
import { Modal } from 'components/Modal/Modal'
import { headersCentroControlSharedCede, headersUsersLogin } from "services/headers"
import { validatorEmail, validatorInput, validatorMac, validatorNumber, validatorPass } from 'utils/ValidatorForm'
import { CustomValidation } from 'utils/Utils'
import { urls } from 'utils/constant'
import "../styles.scss"

export const Register = (props) => {
    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [checked, setChecked] = useState(false)
    const [emailMessagge, setEmailMessagge] = useState(false)
    const [resendMail, setResendMail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [validName, setValidName] = useState(false)
    const [correctEmail, setCorrectEmail] = useState(true);
    const [validLastName, setValidLastName] = useState(false)
    const [validPassword, setValidPassword] = useState(false)
    const [validConfirmPassword, setValidConfirmPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [validMac, setValidMac] = useState(false)
    const [validNumserie, setValidNumserie] = useState(false)
    const [visible, setVisible] = useState(false)
    //Alert settings
    const [messageReenvio, setMessageReenvio] = useState('')
    const [visibleMac, setVisibleMac] = useState(false)
    const [existUser, setExistUser] = useState(false)
    const [visibleError, setVisibleError] = useState(false)
    const [errorMsg, setErrorMsg] = useState(" ")
    const [visiblemsgMac, setVisiblemsgMac] = useState("")
    const [correctValidator, setCorrectValidator] = useState(false)
    const [customPass, setCustomPass] = useState([])
    const [numserie, setNumserie] = useState('')
    const [mac, setMac] = useState()
    const [visiblenum, setVisiblenum] = useState(false)
    const navigate = useNavigate()

    const goLogin = () => {
        navigate("/auth/login")
    }

    const toggle = () => { 
        navigate("/auth/login")
    }

    const reToggle = () => setResendMail(!resendMail)

    const validateForm = () => {
        if (password === confirmPassword && name.length > 0 && email.length > 0 && password.length > 7 && confirmPassword.length > 7 && correctEmail && customPass.length < 1 && !validName && !validLastName && !validPassword && !validConfirmPassword && !visiblenum && checked) {
            return true
        } else if (password !== confirmPassword || name.length < 1 || email < 1 || password.length < 8 || confirmPassword.length < 8 || !correctEmail || customPass.length > 3 || validName || validLastName || validPassword || validConfirmPassword || (!correctValidator && checked)) {
            return false
        } else if (checked && correctValidator && visiblenum) {
            return true
        } else {
            return false
        }
    }

    const asyncRegister = () => {
        setLoading(true)
        fetch(urls.users + 'user/create', {
            method: 'POST',
            redirect: 'follow',
            headers: headersUsersLogin,
            body: JSON.stringify({ 'name': name, 'lastname': lastname, 'email': email, 'pass': password, 'serial': visiblenum && Number(numserie) ? numserie : null, 'mac': visiblenum && mac ? mac : '', "device": visiblenum })
        })
            .then(res => {
                if ([200, 400, 434, 435].indexOf(res.status) > -1) {
                    return res.json()
                } else {
                    throw res
                }
            })
            .then(data => {
                if (data.status === 434) {
                    reToggle()
                    setMessageReenvio(data.message)
                } else if (data.status === 435) {
                    setExistUser(true)
                    setErrorMsg(data.message)
                } else if (data?.status === 400) {
                    setVisibleError(true)
                    setErrorMsg(data.message)
                } else {
                    setEmailMessagge(!emailMessagge)
                }
                setLoading(false)
            })
            .catch(err => { console.error("ERROR API...USER", err) })
    }

    const buttonResendMail = () => {
        fetch(urls.users + 'user/resend', {
            method: 'POST', redirect: 'follow', headers: headersUsersLogin,
            body: JSON.stringify({ 'name': name, 'lastname': lastname, 'email': email, 'pass': password })
        })
            .then(res => {
                if (res.status === 422) {
                } else if (res.ok) {
                    setResendMail(!resendMail)
                    setLoading(false)
                    navigate("/auth/login")
                }
            })
            .catch(err => console.error("ERROR. API Users", err))
    }

    const fetchValidatorMacSerial = () => {
        if (numserie && !validNumserie)
            fetch(urls.ccontrol + 'getMacSerial', {
                method: 'POST', redirect: 'follow', headers: headersCentroControlSharedCede,
                body: JSON.stringify([{ "mac": mac, "serial": Number(numserie) }])
            })
                .then(res => {
                    if (res.status === 401 || res.status === 400) {
                        setCorrectValidator(false)
                        setVisibleMac(true)
                    } else {
                        setCorrectValidator(true)
                        setVisibleMac(false)
                    }
                    return res.json()
                }).then(data => {
                    setVisiblemsgMac(data?.message)
                })
    }

    const handleChange = (event) => {
        setChecked(event.target.checked)
    }

    const validateEmail = () => {
        if (email.length > 0) {
            setCorrectEmail(validatorEmail(email))
        }
    }

    const validatePass = () => {
        setCustomPass(CustomValidation(email, password))
    }

    const handleBlur = () => {
        validatePass()
    }

    const handleName = (e) => {
        setName(e)
        setValidName(validatorInput(e))
    }
    const handleLastName = (e) => {
        setLastname(e)
        setValidLastName(validatorInput(e))
    }

    const handlePassword = (e) => {
        setPassword(e)
        setValidPassword(validatorPass(e))
    }

    const hanldeConfirmPassword = (e) => {
        setConfirmPassword(e)
        setValidConfirmPassword(validatorPass(e))
    }

    const handleBlurMail = () => {
        setEmail(email.toLowerCase())
        validateEmail()
    }

    const handleBlurMac = () => {
        if (mac) {
            setValidMac(validatorMac(mac))
        } else {
            setValidMac(false)
        }
        fetchValidatorMacSerial()
    }

    const validNum = (e) => {
        setNumserie(e)
        setValidNumserie(validatorNumber(e))
    }

    const handleBlurNum = () => {
        if (mac) {
            setValidMac(validatorMac(mac))
        } else {
            setValidMac(false)
        }
        fetchValidatorMacSerial()
    }

    return (
        <>
            <Grid item xs={11} md={12} lg={12} xl={12} className="grid-login">
                <h2 className="title-login"> ACCESO CLIENTES</h2>
                <p className="subtitle-login">¡Bienvenido! Accede a tu <b>Portal Enerclic</b></p>
                <Card className="card-reset-password">
                    <Grid item xs={12} md={12} lg={12} xl={12} className="grid-return-login">
                        <a onClick={goLogin} className="button-return-login">x</a>
                    </Grid>
                    <h2 className="text-register-user">Registro de usuario</h2>
                    <FormControl users={props?.users} style={{ width: "100%" }} autoSave="off" onSubmit={asyncRegister}>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <Grid item xs={11} sm={11} lg={5} xl={5} style={{ marginTop: window.innerWidth < 378 ? '6px' : null }}>
                                <TextField className="textfied-login" size='small' variant='outlined' style={{ width: '100%' }}
                                    placeholder="Nombre*" type="text" value={name} onChange={(e) => handleName(e.target.value)}
                                    error={validName}
                                    helperText={validName ? 'Caracteres no permitidos' : ''}
                                    inputProps={{ maxLength: 20 }} />
                            </Grid>
                            <Grid item xs={11} sm={11} lg={5} xl={5} style={{ marginTop: window.innerWidth < 378 ? '6px' : null }}>
                                <TextField className="textfied-login" size='small' variant='outlined' style={{ width: '100%' }}
                                    placeholder="Apellidos" type="text" value={lastname}
                                    onChange={(e) => handleLastName(e.target.value)}
                                    error={validLastName}
                                    helperText={validLastName ? 'Caracteres no permitidos' : ''}
                                    inputProps={{ maxLength: 20 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <Grid item xs={11} sm={11} lg={10} xl={10}>
                                <TextField className="textfied-login" size='small' variant='outlined' style={{ width: '100%' }}
                                    placeholder="Email*" type="text" value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!correctEmail}
                                    helperText={!correctEmail ? 'Email inválido' : ''}
                                    onBlur={handleBlurMail}
                                    inputProps={{ maxLength: 50 }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <Grid item xs={11} sm={11} lg={10} xl={10}>
                                <form>
                                    <TextField className="textfied-login"
                                        size='small'
                                        variant='outlined'
                                        style={{ width: '100%' }}
                                        placeholder="Contraseña*"
                                        type="password"
                                        autoComplete="off"
                                        onClick={() => setVisible(true)}
                                        value={password}
                                        name="password*"
                                        onChange={(e) => handlePassword(e.target.value)}
                                        error={validPassword}
                                        helperText={validPassword ? 'Caracteres no permitidos' : ''}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 65 }}
                                    />
                                    <TextField
                                        className="textfied-login"
                                        size='small'
                                        variant='outlined'
                                        style={{ width: '100%' }}
                                        placeholder=" Confirmar Contraseña*"
                                        type="password"
                                        autoComplete="off"
                                        onClick={() => setVisible(true)}
                                        value={confirmPassword}
                                        name="password"
                                        onChange={(e) => hanldeConfirmPassword(e.target.value)}
                                        error={validConfirmPassword}
                                        helperText={validConfirmPassword ? 'Caracteres no permitidos' : ''}
                                        onBlur={handleBlur}
                                        inputProps={{ maxLength: 65 }}
                                    />
                                </form>
                            </Grid>
                        </Grid>
                        {visible &&
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield pt-4">
                                <Grid item xs={11} sm={11} lg={10} xl={10}>
                                    <ul className="font-size-10px">
                                        <li className={`${password?.length > 0 && customPass?.includes(1) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(1)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Mínimo 8 caracteres</li>
                                        <li className={`${password?.length > 0 && customPass?.includes(3) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(3)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Las contraseñas no pueden coincidir con el email</li>
                                        <li className={`${password?.length > 0 && customPass?.includes(2) ? "error-checklist-password checklist-password" : (password?.length > 7 && !customPass?.includes(2)) ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Debe contener al menos una mayúscula, minúscula, número</li>
                                        {confirmPassword?.length > 0 && <li className={`${confirmPassword?.length > 0 && (password !== confirmPassword) ? "error-checklist-password checklist-password" : password?.length > 0 && confirmPassword?.length > 0 && password === confirmPassword ? "correct-checklist-password checklist-password" : "checklist-password"}`}>Las contraseñas coinciden</li>}
                                    </ul>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <p className="mb-0 mr-2">¿Tienes un dispositivo y quieres registrarlo?</p>
                            <SwitchStyled checked={visiblenum} className="switch-small" onClick={() => setVisiblenum(!visiblenum)}></SwitchStyled>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='d-flex justify-content-center align-items-center'>
                            {/* MAC Y NUMSERIE */}
                            {visiblenum &&
                                <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                                    <Grid item xs={11} sm={11} lg={5} xl={5}>
                                        <TextField className="textfied-login" size='small'
                                            variant='outlined' style={{ width: '100%' }} placeholder="Número de Serie*"
                                            type="text" autoComplete="off" name="numserie"
                                            title="Aparece en la parte posterior del dispositivo cómo SN"
                                            value={numserie}
                                            error={validNumserie}
                                            helperText={validNumserie ? 'Número de serie incorrecto' : ''}
                                            onChange={(e) => { validNum(e.target.value) }}
                                            onBlur={handleBlurNum}
                                            inputProps={{ maxLength: 12 }} />
                                    </Grid>
                                    <Grid item xs={11} sm={11} lg={5} xl={5}>
                                        <TextField className="textfied-login" size='small' variant='outlined' style={{ width: '100%' }} placeholder="MAC"
                                            type="text" autoComplete="off" name="installation"
                                            error={validMac}
                                            title="Aparece en la parte posterior del dispositivo como MAC"
                                            helperText={validMac ? 'Dirección MAC incorrecta' : ''}
                                            onChange={(e) => setMac(e.target.value)}
                                            onBlur={handleBlurMac}
                                            inputProps={{ maxLength: 20 }} />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className='d-flex justify-content-center align-items-center'>
                            <Grid item xs={11} sm={11} lg={10} xl={10} className='d-flex justify-content-center align-items-center'>
                                {visibleMac &&
                                    <p className="information-text">
                                        {visiblemsgMac}
                                    </p>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <Grid item xs={11} sm={11} lg={10} xl={10} className="grid-checkbox-register">
                                <FormControlLabel className="label-checkbox-register"
                                    control={<label className="checkbox path  checkInstallations-check checkbox-display-group" style={{ stroke: checked ? "#FF8A1F" : "#fff" }}>
                                        <input type="checkbox" className="checkbox-login" checked={checked} onChange={handleChange} />
                                    </label>} />
                                <span className="text-muted text-confirm-information mb-1 ml-2">
                                    Acepto los {" "}
                                    <a href="https://enerclic.es/aviso-legal-y-politica-de-privacidad/" target='_blank' rel="noreferrer" className="text-polity">
                                        Terminos de privacidad y aviso legal
                                    </a>
                                </span>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="counter-separator">
                            <span className="line-separator-login"></span>
                            <span className="rounded-separator-login"></span>
                            <span className="line-separator-login"></span>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield container-button-register-user">
                            <Grid item xs={12} sm={12} lg={6} xl={6}>
                                <button variant='contained' className="button-create-user-login" disabled={!validateForm() || loading} onClick={asyncRegister}>
                                    Registrar usuario
                                </button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <Grid item xs={11} sm={11} lg={10} xl={10} className='p-4'>
                                <hr />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12} xl={12} className="register-login-textfield">
                            <small style={{ fontSize: "14px" }}>¿Ya tienes cuenta? </small>
                            <span className="hoverAction" onClick={goLogin} style={{ cursor: "pointer", marginLeft: "5px" }}>
                                <small className="hoverAction" style={{ fontSize: "14px" }}> Entra aquí</small>
                            </span>
                        </Grid>
                    </FormControl>
                </Card>
            </Grid >
            <Modal open={emailMessagge} setOpen={setEmailMessagge} title={`Confirmación usuario`} functSave={toggle} loading={loading} buttonAction="Entrar">
                <Grid container className="text-center mt-2 pr-5 pl-5">
                    <h3> Te hemos enviado un email de confirmación</h3>
                    <p>
                        Se ha enviado un email para confirmar su usuario. Este correo caduca en 48 horas. Si no ve el email en la bandeja de entrada, revise la bandeja de spam.
                    </p>
                </Grid>
                <hr className='m-0' />
            </Modal>
            <Modal open={resendMail} setOpen={setResendMail} title={`Confirmación usuario`} functSave={buttonResendMail} loading={loading} buttonAction="Reenviar Correo">
                <Grid container className="text-center mt-2 pr-2 pl-2">
                    <h3>{messageReenvio}</h3>
                    <p>
                        El siguiente enlace reenviará un email de confirmación para activar su cuenta.
                    </p>
                </Grid>
                <hr className='m-0' />
            </Modal>
            <Modal open={visibleError} setOpen={setVisibleError} title={`Confirmación usuario`} functSave={() => setVisibleError(current => !current)} loading={loading} buttonAction="Reenviar Correo" footer=' '>
                <Grid container className="text-center mt-2 pb-5 pr-5 pl-5">
                    <h2>{errorMsg}</h2>
                </Grid>
                <hr className='m-0' />
            </Modal>
            <Modal open={existUser} setOpen={setExistUser} footer=' ' loading={loading} buttonAction="Ir a login" >
                <Grid container className="text-center mt-2 pb-5 pr-5 pl-5">
                    <h2>{errorMsg}</h2>
                </Grid>
                <hr className='m-0' />
            </Modal>


        </>
    )
}