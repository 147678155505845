import React, { useContext, useState } from "react"
import { Grid } from "@mui/material"
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { GlobalContext } from "context/GlobalContext"
import LayOutBoard from "views/LayOut/LayOutBoard"
import { IconWarning } from "utils/icon-centrocontrol"
import { dispositivos } from "utils/constant"
import { PersonalizationConfig } from "./Personalization/PersonalizationConfig"
import { ConfiguracionPlantaMabt } from "./Mabt/ConfiguracionPlantaMabt"
import { InstallationConfig } from "./Installation/InstallationConfig"
import { ConfiguracionAlarms } from "./Alarms/ConfiguracionAlarms"
import "./Configuracion.scss"

export const ConfiguracionList = ({ consumption, instalacioneSeleccionadas, dispositivo, scada, masterError, scadaSelf, activeTabCuadro }) => {
  const [activeTab, setActiveTab] = useState(activeTabCuadro ? 4 : 0)
  const errors = masterError.filter(master => master.error !== 0)
  const { actualProject } = useContext(GlobalContext)
  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    } 
  }

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12} xl={12} className="menu-configuracion">
        <div selected={activeTab === 0} onClick={() => { toggle(0) }}>
          <span className={activeTab === 0 ? "list-item-icon active-button" : "list-item-icon"} >
            Alarmas
          </span>
        </div>
        {!scada &&
          <div selected={activeTab === 1} onClick={() => { toggle(1) }}>
            <span className={activeTab === 1 ? "list-item-icon active-button" : "list-item-icon"} >
              Personalización
            </span>
          </div>
        }
        {(scada && dispositivo[0].id_tipo_dispositivo === dispositivos.ccmabt && actualProject?.permissions !== "user") &&
          <div selected={activeTab === 2} onClick={() => { toggle(2) }}>
            <span className={activeTab === 2 ? "list-item-icon active-button" : "list-item-icon"} >
              Instalación
              {masterError && masterError?.length > 0 &&
                <div className='separator-left'>
                  <HtmlTooltip title={<span>Revise su configuración.</span>}>
                    <IconWarning width={20} height={20} />
                  </HtmlTooltip>
                </div>
              }
            </span>
          </div>
        }
        {((!scada && actualProject?.permissions !== "user") || (dispositivo[0].inst_type !== 3 && actualProject?.permissions !== "user")) &&
          <div selected={activeTab === 3} onClick={() => { toggle(3) }}>
            <span className={activeTab === 3 ? "list-item-icon active-button" : "list-item-icon"}>
              Instalación
              {errors && errors?.length > 0 &&
                <div className='separator-left'>
                  <HtmlTooltip title={<span>Revise su configuración.</span>}>
                    <IconWarning width={20} height={20} />
                  </HtmlTooltip>
                </div>
              }
            </span>

          </div>
        }
        {consumption &&
          <div selected={activeTab === 4} onClick={() => { toggle(4) }}>
            <span className={activeTab === 4 ? "list-item-icon active-button" : "list-item-icon"}>
              Configurar cuadro
            </span>
          </div>
        }
      </Grid>

      <Grid item xs={12} md={12} lg={12} xl={12}>
        <div className={`tab-item-wrapper ${activeTab === 0 ? 'active' : ''}`} index={0}>
          {activeTab === 0 && <ConfiguracionAlarms instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivos={dispositivo} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 1 ? 'active' : ''}`} index={1}>
          {activeTab === 1 && <PersonalizationConfig idProject={actualProject?.id_project} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 2 ? 'active' : ''}`} index={2}>
          {activeTab === 2 && <ConfiguracionPlantaMabt instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivo={dispositivo} />}
        </div>
        <div className={`tab-item-wrapper ${activeTab === 3 ? 'active' : ''}`} index={3}>
          {activeTab === 3 && <InstallationConfig scadaSelf={scadaSelf} activeTab={activeTab} instalacioneSeleccionadas={instalacioneSeleccionadas} dispositivos={dispositivo} masterError={masterError} />}
        </div>
        {consumption && 
          <div className={`tab-item-wrapper ${activeTab === 4 ? 'active' : ''}`} index={4}>
            {activeTab === 4 && <LayOutBoard dispositivo={dispositivo} />}
          </div>
        }
      </Grid>
    </Grid>
  )
}