import React, { useState, useEffect } from 'react'
import { Chart } from '../../../SelfConsumption/GraphsUtils/LineChart'
import { Loader } from "components/Loader/Loader"
import { dispositivos } from 'utils/constant'

export const TensionLineGraph = (props) => {
    const [graph1, setGraph1] = useState()

    useEffect(() => {
        if (props?.graphData?.data) {
            setGraph1(props?.graphData?.data?.[0]?.consumida ? props?.graphData?.data?.[0]?.consumida : props?.graphData?.data?.[0]?.data)
        }
    }, [props?.graphData?.data])

    useEffect(() => {
        if (graph1) {
            const checkValues = (value) => {
                if (value === 0 || value === null) {
                    return true
                } else {
                    return false
                }
            }
            if (graph1.every(checkValues)) {
                graph1?.forEach((elm, i) => {
                    if (elm === 0) {
                        graph1[i] = 0.001
                    }
                })
            }
        }
    }, [graph1])

    const colors = ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 206, 86)"]
    const [dataPack, setDatapack] = useState({ labels: [], datasets: [], })
    const LineOptions =
    {
        responsive: true,
        maintainAspectRatio: false,
        animations: {
            radius: {
                duration: 400,
                easing: 'linear',
                loop: (context) => context.active
            }
        },
        hoverRadius: 6,
        hoverOpacity: 0.6,
        interaction: {
            mode: 'nearest',
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                ticks: {
                    // Include kw in Y
                    callback: function (value, index, ticks) {
                        return value.toFixed(2) + ' V';
                    },
                }
            }
        },
        plugins: {
            legend: {
                labels: {
                    padding: 40, //default is 10
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
                display: true,
                position: 'bottom',
                align: 'center',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || ' V';
                        return label + '  ' + new Intl.NumberFormat().format(context.parsed.y) + ' V';
                    }
                }
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "x",
                    speed: 0.5,
                },
                zoom: {
                    wheel: {
                        enabled: true,
                        speed: 0.1,
                    },
                    mode: 'x',
                }
            },
        },
    }
        ;

    const [graph, setGraph] = useState(
        <Chart
            id='tensionGraph'
            dataFilter={dataPack}
            LineOptions={LineOptions}
            graphStyle={'line'}
        />
    )

    useEffect(() => {
        let aux1Label = props?.graphData?.categories;
        let aux2Label = [];
        if (props?.graphData?.categories) {
            aux1Label.forEach((element) => {
                let aux3Label = element.slice(11);
                aux2Label.push(aux3Label)
            })
        }
        if (props?.graphData?.data) {
            let aux1Data = [];
            let aux2Data = props?.graphData.data;
            let auxName = props?.data?.currentData?.id_tipo_dispositivo === Number(dispositivos.ccmabtm) ? ['R', 'S', 'T'] : props?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtv && props?.hoy ? ['VTN'] : props?.data?.currentData?.id_tipo_dispositivo === dispositivos.ccmabtv && !props?.hoy ? ['VTN_max', 'VTN_min'] : undefined
            if (props?.graphData?.data[0].consumida) {
                aux2Data.forEach((element, index) => {
                    aux1Data.push(
                        {
                            Type: 'line',
                            data: element.consumida,
                            label: auxName ? auxName[index] : `Tensión - L${index + 1}`,
                            borderColor: colors[index],
                            pointBackgroundColor: colors[index],
                            fill: false,
                            id: index + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${element.numserie}`,
                        },
                    );
                });

            } else if (props?.graphData?.data[0].data) {
                aux2Data.forEach((element, index) => {
                    aux1Data.push(
                        {
                            Type: 'line',
                            data: element.data,
                            label: auxName ? auxName[index] : `Tensión - L${index + 1}`,
                            borderColor: colors[index],
                            pointBackgroundColor: colors[index],
                            fill: false,
                            id: index + 5,
                            pointRadius: 0,
                            tension: 0.1,
                            numserie: `${element.numserie}`,
                        },
                    );
                });
            };
            setDatapack(
                {
                    labels: aux2Label,
                    datasets: aux1Data,
                }
            );
        };
    }, [props]);

    useEffect(() => {
        setGraph(
            <Chart
                id='tensionGraph'
                dataFilter={dataPack}
                LineOptions={LineOptions}
                graphStyle={'line'}
            />
        )
    }, [dataPack])

    return (
        <>
            {!props.loading && props.graphData !== undefined ?
                graph
                :
                <Loader />
            }
        </>
    )
}
