import React, { useState } from 'react'
import { Grid, Button } from '@mui/material'
import { HtmlTooltip } from 'components/Tooltip/HtmltoolTip'
import { IconDownloadFile } from 'utils/icon-centrocontrol'
import { ModalDownloadMoreData } from './ModalDownloadMoreData'
import { MabtLineasDatosMovil } from './MabtLineasDatosMovil'
import { MenuDescripcionMabt } from './MenuDescripcionMabt'
import { MabtLineasDatos } from './MabtLineasDatos'
import { TablaPotencia } from './TablaPotencia'

export const GeneralMabt = ({ confAlarms, activeTabDevice, seleccionarTabLinea, start, datosLineas, vtn, general, error, loadingLineas, total, seleccionarMedidor, vtnDevice, alarmas, devices, masterPermission, seleccionarTab, token }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Grid item xs={12} md={12} lg={12} xl={12} className={`ml-3 mt-4 ${window.innerWidth >= 1060 ? 'mb-5' : 'mb-8'}`}>
                <MenuDescripcionMabt activeTab={activeTabDevice} seleccionarTab={seleccionarTabLinea} start={start} />
                <Button className="pt-0" onClick={() => setOpen(true)}>
                    <HtmlTooltip title={<span>Descargar datos</span>}>
                        <IconDownloadFile />
                    </HtmlTooltip>
                </Button>
            </Grid>
            {datosLineas && vtn !== undefined && general && !error && alarmas &&
                <>
                    <Grid item xs={12} md={12} lg={12} xl={window.innerWidth < 1600 ? 9 : 8} className='mt-2'>
                        {window.innerWidth >= 1060 ?
                            <MabtLineasDatos confAlarms={confAlarms} loading={loadingLineas} datosLineas={datosLineas} vtn={vtn} total={total} seleccionarMedidor={seleccionarMedidor} vtnDevice={vtnDevice} masterPermission={masterPermission} devices={devices} alarms={alarmas} activeTabDevice={activeTabDevice} importada={general.importada} exportada={general.exportada} seleccionarTab={seleccionarTab} />
                            :
                            <MabtLineasDatosMovil confAlarms={confAlarms} loading={loadingLineas} datosLineas={datosLineas} vtn={vtn} total={total} seleccionarMedidor={seleccionarMedidor} vtnDevice={vtnDevice} masterPermission={masterPermission} devices={devices} alarms={alarmas} activeTabDevice={activeTabDevice} importada={general.importada} exportada={general.exportada} seleccionarTab={seleccionarTab} />
                        }
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={window.innerWidth < 1600 ? 3 : 4} className='mb-6'>
                        <TablaPotencia importada={general.importada} exportada={general.exportada} potenciaActiva={general.pap} devices={devices} />
                    </Grid>
                </>
            }
            {datosLineas === undefined && vtn !== undefined && general && !error &&
                <>
                    <Grid item xs={12} md={12} lg={12} xl={window.innerWidth < 1600 ? 9 : 8} className='mt-2'>
                        {window.innerWidth >= 1060 ?
                            <MabtLineasDatos confAlarms={confAlarms} loading={loadingLineas} datosLineas={[]} vtn={vtn} total={total} seleccionarMedidor={seleccionarMedidor} vtnDevice={vtnDevice} masterPermission={masterPermission} devices={devices} alarms={alarmas} activeTabDevice={activeTabDevice} seleccionarTab={seleccionarTab} />
                            :
                            <MabtLineasDatosMovil confAlarms={confAlarms} loading={loadingLineas} datosLineas={[]} vtn={vtn} total={total} seleccionarMedidor={seleccionarMedidor} vtnDevice={vtnDevice} masterPermission={masterPermission} devices={devices} alarms={alarmas} activeTabDevice={activeTabDevice} seleccionarTab={seleccionarTab} />
                        }
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={window.innerWidth < 1600 ? 3 : 4} className='mb-6'>
                        <TablaPotencia importada={general.importada} exportada={general.exportada} devices={devices} />
                    </Grid>
                </>
            }
            {error &&
                <Grid item xs={12} md={12} lg={12} xl={12} className='mt-2 mb-6'>
                    <h3 className='texto-error-carga'>
                        Error al cargar los datos, contacte con el soporte técnico.
                    </h3>
                </Grid>
            }
            {open && <ModalDownloadMoreData token={token} master={devices} devices={devices?.childrenDevices} open={open} setOpen={setOpen} />}
        </>
    )
}