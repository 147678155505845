import React, { useState } from 'react'
import { GeneralProductionGraphs } from './GeneralProductionGraphs'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'

export const GraphsGeneral = (props) => {
    const [activeTabDevice, setActiveTabDevice] = useState(0)
    const toggleDevice = tab => setActiveTabDevice(tab)
    const [loading, setLoading] = useState(true)

    return (
        <>
            <ul className="nav nav-tabs list-general" >
                <li
                    className="nav-item"
                    selected={activeTabDevice === 0}
                    onClick={() => { toggleDevice(0); }}
                >
                    <span
                        className="nav-link active list-p"
                    >Informe de producción
                    </span>
                </li>
            </ul>
            <div className={`tab-item-wrapper ${activeTabDevice === 0 ? 'active' : ''}`} index={0}>
                <div>
                    <GeneralProductionGraphs
                        isMobile={props?.isMobile}
                        data={props?.data}
                        device={props?.device}
                        start={props.start}
                        end={props.end}
                        setStart={props.setStart}
                        now={props.now}
                        setEnd={props.setEnd}
                        titleButton={props.titleButton}
                        setTitleButton={props.setTitleButton}
                        handleClickRight={props.handleClickRight}
                        handleClickLeft={props.handleClickLeft}
                        daily={props.daily}
                        setDaily={props.setDaily}
                        historicalData={props.historicalData}
                        loadingCurves={props.loadingCurves}
                        graphDataProduction={props.graphDataProduction}
                        setHistoricalData={props.setHistoricalData}
                        loading={loading}
                        setLoading={setLoading}
                        noData={props.noData}
                        setNoData={props.setNoData}
                        childrenDevices={props.childrenDevices}
                        setRefresh={props?.setRefresh}
                        importSelf={props?.importSelf}
                        refresh={props?.refresh}
                        deviceType={props?.deviceType}
                    />
                </div>
            </div>
        </>
    )
}
