import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BeatLoader } from 'react-spinners'
import { convertDatetime } from 'utils/Utils'

export const DeviceDate = ({ device, data, loading, masterPermission, fontSize }) => {
    const [color, setColor] = useState("black")
    const [date, setDate] = useState("") 
    const alarma = useSelector((state) => state.alarma)
    const objetoEncontrado = alarma.history.find((objeto) => Number(objeto?.numserie) === Number(device?.numserie) || Number(objeto?.numserie) === Number(device?.id_padre))
    
    const alarmColor = () => {
        if (masterPermission && masterPermission?.alarms?.length > 0) {
            const alarms = masterPermission?.alarms?.filter(alarm => Number(alarm.numserie) === Number(device?.numserie))
            setColor(alarms?.length === 0 ? 'black' : 'red')
        } else if (objetoEncontrado?.alarmCount > 0) {
            const alarms = objetoEncontrado?.alarms?.filter(alarm => Number(alarm.numserie) === Number(device?.numserie))
            setColor(alarms?.length === 0 ? 'black' : 'red')
        } else {
            setColor('black')
        }
        if (data?.datetime) {
            setDate(convertDatetime(data?.datetime))
        } else {
            setDate(" - ")
        }
    }

    useEffect(() => {
        alarmColor()
    }, [data])

    return (
        <div>
            {loading ?
                <BeatLoader color="#ea5e0b" size={5} />
                :
                <small style={{ fontSize: fontSize ? fontSize : "9px", color: color }}>
                    {date}
                </small>
            }
        </div>
    )
}