import React, { useState } from 'react'
import { FormControl, Grid, MenuItem, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { StyledTableCell, StyledTableRow } from 'components/Table/TableStyle'
import { StyledTableCellHead } from 'components/Table/TableStyle'
import { deviceName } from 'utils/Utils'
import { ButtonStyledIcon } from 'components/Buttons/ButtonStyledIcon'
import { IconSave } from 'utils/icon-centrocontrol'
import { modeEMS } from 'utils/constant'
import { SelectStyled } from 'components/Select/SelectStyled'
import { ModalActionEMS } from './Modal/ModalActionEMS'
import { dispositivos } from 'utils/constant'
import { Loader } from 'components/Loader/Loader'

export const GeneralControlEms = ({ master, setKeySwitch, dataDevices, loading }) => {
  const [config, setConfig] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [mode, setMode] = useState()
  const [change, setChange] = useState([])

  const devicesEnable = [dispositivos.enchufe, dispositivos.ccmaster, dispositivos.inversor]

  const data = dataDevices && dataDevices?.filter(dev => devicesEnable?.includes(dev?.id_tipo_dispositivo))

  const relay = (rele) => {
    switch (rele) {
      case 1:
        return "Relay 1";
      case 2:
        return "Relay 2";
      default:
        return "Relay 1"
    }
  }

  const handleModeChange = (dev, modeValue) => {
    setMode(prevState => ({
      ...prevState,
      [`${dev.numserie}-${dev.id_entry}`]: modeValue
    }));
    let find = change.filter(ele => ele.numserie === dev.numserie && ele.id === dev.id_entry)
    if (find.length === 0) {
      setChange(prev => [...prev, { numserie: dev.numserie, id: dev.id_entry, mode: modeValue }])
    } else if (modeValue === dev?.mode) {
      let modeDefault = change.filter(ele => ele.numserie !== dev.numserie || ele.id !== dev.id_entry)
      setChange(modeDefault)
    } else {
      let aux = [...change]
      aux.forEach(ele => {
        if (ele.numserie === dev.numserie && ele.id === dev.id_entry) {
          ele.mode = modeValue
        }
      })
      setChange(aux)
    };
  }

  const disableButton = (dev) => {
    let find = change.filter(ele => ele.numserie === dev.numserie && ele.id === dev.id_entry)
    const dis = find.length > 0 ? false : true
    return dis
  }

  let dataOperative = data?.some(item => item?.id_tipo_dispositivo !== null); ////CAMBIAR EN UN FUTURO

  return (
    <> {loading ? <Loader /> : <>
      {data && !dataOperative ?
        <Grid items xs={12} className="container-no-data">
          <h4 align='center' className='mt-4'>No existen dispositivos</h4>
        </Grid> :
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead style={{ borderBottom: '2px solid #FF8A1F', borderRadius: '50px' }}>
              <TableRow>
                <StyledTableCellHead align="center">Tipo dispositivo</StyledTableCellHead>
                <StyledTableCellHead align="center">Nombre del dispositivo</StyledTableCellHead>
                <StyledTableCellHead align="center">Instalación</StyledTableCellHead>
                <StyledTableCellHead align="center">Salida</StyledTableCellHead>
                <StyledTableCellHead align="center">Modo</StyledTableCellHead>
                <StyledTableCellHead align="center"></StyledTableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data?.map((dev, i) => {
                const matchingElement = master?.find(elm => elm?.numserie === dev.id_parent);
                const nick = matchingElement ? matchingElement?.nick : ' '; return (
                  <StyledTableRow key={i} >
                    {dev?.id_tipo_dispositivo !== null &&
                      <>
                        <StyledTableCell>{deviceName(dev?.id_tipo_dispositivo)} </StyledTableCell>
                        <StyledTableCell>{dev?.nick}</StyledTableCell>
                        <StyledTableCell>{nick}</StyledTableCell>
                        <StyledTableCell>
                          {relay(dev?.id_entry)}
                        </StyledTableCell>
                        <StyledTableCell>
                          <FormControl className='width-100' size="small">
                            <SelectStyled
                              value={mode} select defaultValue={dev.mode !== null ? dev?.mode : 0} onChange={(e) => { handleModeChange(dev, e.target.value) }}>
                              {modeEMS
                                .filter(prm => {
                                  if (prm?.value === 5 && dev.id_tipo_dispositivo !== 5) {
                                    return false;
                                  }
                                  return true;
                                }).map((prm, i) => {
                                  return (
                                    <MenuItem value={prm?.value} key={i}>
                                      {prm?.mode}
                                    </MenuItem>
                                  )
                                })}
                            </SelectStyled>
                          </FormControl>
                        </StyledTableCell>
                        <StyledTableCell>
                          <ButtonStyledIcon className='boton-icon mr-1' title={'Guardar'} disabled={disableButton(dev)} onClick={() => { setOpenModal(!openModal); setConfig(dev) }} >
                            <IconSave className='color-icon' />
                          </ButtonStyledIcon>
                        </StyledTableCell>                    </>}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>}
    </>}
      {openModal && <ModalActionEMS device={config} mode={mode} open={openModal} setOpen={setOpenModal} master={master} setKeySwitch={setKeySwitch} />}
    </>
  )
}
