import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { useDebounce } from 'use-debounce'
import { Grid } from "@mui/material"
import moment from 'moment'
import solarPanel from 'assets/img/icons/consumption/solarPanel.png'
import { GlobalContext } from 'context/GlobalContext'
import { Loader } from 'components/Loader/Loader'
import { ScadaDeviceHeader } from 'views/Scada/SelfConsumption/ScadaDeviceHeader'
import { Filter } from 'views/Scada/FilterScada/FilterScada.jsx'
import { headersScada } from 'services/headers'
import { result } from 'utils/functionsforGraphs'
import { urls } from 'utils/constant'
import { HistoricalTableProduction } from './Tables/HistoricalTableProduction'
import { CurrentTableProduction } from './Tables/CurrentTableProduction'
import 'views/Scada/SelfConsumption/styleSelfConsumption.scss'
import { convertDatetime } from 'utils/Utils'
import CalendarComponent from 'components/Calendar/CalendarComponent'
import { SelectorFecha } from 'views/SelectorCalendar/SelectorFecha'
import { isToday } from 'utils/Utils'
import { GraphsPhotovoltaic } from './GraphsProduction/GraphPhotovoltaicSelf'
import { CsvDownloader } from '../CsvDownloader'

export const ScadaProduction = (props) => {
    const location = useLocation()
    const token = sessionStorage?.token
    const idMaster = location.pathname.split('/')[4]
    const [deviceType, setDeviceType] = useState()
    const [historicalData, setHistoricalData] = useState()
    const [selectedDevice, setSelectedDevice] = useState()
    const [prod, setProd] = useState([])
    const [activeTabDevice, setActiveTabDevice] = useState(Number(props?.device?.id_tipo_dispositivo) === 5 ? 0 : Number(props?.device?.id_tipo_dispositivo) === 2 ? 1 : 2)
    const toggleDevice = tab => setActiveTabDevice(tab)
    const [finalData, setFinalData] = useState([])
    const [loading, setLoading] = useState(true)
    const [headersCsv, setHeadersCsv] = useState()
    const variableTableSelfConsumption = [{ label: "Nombre", key: "nick" }, (props?.daily === "hours" && isToday(props?.start) === true) ? { label: "Fecha", key: "fecha" } : { label: "Fecha", key: "datetime" }, { label: "Energía Vertida (kWh)", key: "eagendia" }]
    const fechaNow = moment(props?.now).format("DD-MM-YYYY")
    const [checked, setChecked] = useState([])
    const [activo, setActivo] = useState(true)
    const [updatedList, setUpdatedList] = useState([])
    const [children, setChildren] = useState([])
    const [keySwitch, setKeySwitch] = useState(Date.now())
    const [selectDaily, setSelectDaily] = useState("day")

    let historicalCSV = historicalData?.map(device => device.data)
    let FinalDataCSV = finalData?.map(device => device?.data)
    let finalDataCSV2 = FinalDataCSV?.map(device => {
        let obj = Object.assign({}, device?.currentData)
        if (isToday(props?.start)) {
            obj.datetime = convertDatetime(obj?.datetime)
        }
        return obj
    })
    const { updateDevice } = useContext(GlobalContext)
    const [debouncedProduction] = useDebounce(prod, 200)


    useEffect(() => {
        setDeviceType(props?.data?.[0]?.deviceType ? props?.data?.[0]?.deviceType : props?.data?.[0]?.id_tipo_dispositivo)
        props?.setDaily("days")
    }, [props?.data])


    const fetchData = async (numserie, start, end) => {
        let url
        if (isToday(props?.start) && props?.daily === "days") {
            url = `getCurrentData/${numserie}?idCCMaster=${idMaster}`
        }
        else {
            url = `getHistoricalDataScada/${numserie}?idCCMaster=${idMaster}&iniDate=${start?.format('YYYY-MM-DD')}&endDate=${end?.format('YYYY-MM-DD')}&groupDate=${props?.daily}`
        }

        try {
            const res = await fetch(urls.scada + url, { method: 'GET', headers: headersScada(token) })
            if (res.status === 200) {
                setLoading(false)
                const json = await res.json()
                return json
            } else {
                return []
            }
        } catch (err) {
            console.error("ERROR. API Scada", err)
        }
    }

    const arrayData = () => {
        let filterDeviceByType = props?.data?.filter(ele => ele.deviceType === deviceType)
        if (filterDeviceByType?.length > 0) {
            setHistoricalData([])
            setProd([])
            if (isToday(props?.start) && props?.daily === "days") {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(dev => {
                        fetchData(dev).then(item => {
                            setFinalData(prev => [...prev, { nick: item?.currentData?.nick, id_tipo_dispositivo: item?.currentData?.id_tipo_dispositivo, data: item, numserie: item?.currentData?.numserie, datetime: item?.currentData?.datetime }])
                        })
                    })
                })
            } else {
                filterDeviceByType?.forEach(elm => {
                    elm?.devices?.forEach(device => {
                        fetchData(device, props?.start, props?.end).then(item => {
                            item?.forEach(item2 => {
                                setHistoricalData(prev => [...prev, { nick: item2?.nick, data: item2, numserie: item2?.numserie }])
                            })
                        })
                    })
                })
            }
        }
    }

    const finalCSV = finalDataCSV2?.filter(dev => updatedList?.includes(dev.numserie))
    const historCSV = historicalCSV?.filter(dev => updatedList?.includes(dev.numserie))

    useEffect(() => {
        arrayData()
    }, [activeTabDevice, props?.end, props?.start, keySwitch])

    useEffect(() => {
        setFinalData([])
    }, [updateDevice])

    useEffect(() => {
        setProd([])
        if (isToday(props?.start) && props?.daily === "days") {
            const setObj = new Set();
            const uniqs = finalData?.map(device => result(finalData, device.numserie))
            const noRepeat = uniqs.reduce((acc, device) => {
                if (!setObj.has(device.numserie)) {
                    setObj.add(device.numserie, device)
                    acc.push(device)
                }
                return acc
            }, [])
            setProd(noRepeat)
        } else {
            setProd(historicalData)
        }
    }, [finalData, historicalData])


    useEffect(() => {
        const serialNumbers = props?.data?.flatMap(item => item.devices);
        const filteredArray = props?.childrenDevices?.filter(item => serialNumbers.includes(item.numserie));
        setChildren(filteredArray)
    }, [props?.childrenDevices])


    return (
        <>
            <Grid className="d-flex flex-column scada-component mt-3" item sm={12} lg={12} xl={12}>
                <ScadaDeviceHeader props={props} icon={solarPanel} setDeviceType={setDeviceType} type="Generación"></ScadaDeviceHeader>
                <span className="filter-component-calendar">
                    <div>
                        <CalendarComponent
                            maxDate={props?.end}
                            setStart={props?.setStart}
                            setEnd={props?.setEnd}
                            setDaily={props?.setDaily}
                            setSelectDaily={setSelectDaily}
                        />
                    </div>
                    <div className="ml-3 d-flex align-items-center">
                        <SelectorFecha
                            daily={props?.daily}
                            setDaily={props?.setDaily}
                            tipo={props?.daily}
                            setKeySwitch={setKeySwitch}
                            selectDaily={selectDaily}
                        />
                        {prod && prod?.length > 0 &&
                            <CsvDownloader
                                className="csvDonwloader"
                                today={isToday(props?.start)}
                                daily={props?.daily}
                                props={props}
                                historicalData={historCSV !== undefined && historicalData !== undefined ? historCSV : historicalData}
                                finalData={finalCSV !== undefined && finalData !== undefined ? finalCSV : finalData}
                                start={props?.start}
                                end={props?.end}
                                setHeadersCsv={setHeadersCsv}
                                headersCsv={headersCsv}
                            />
                        }
                    </div>
                </span>
                <span className='listItem-componente'>
                    <ul className="nav nav-tabs" >
                        {props?.data?.filter(device => device.deviceType === 5).length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 0 ? "active-button" : null} `} selected={activeTabDevice === 0} onClick={() => { toggleDevice(0); setDeviceType(7) }}>
                                Inversores
                            </li>
                        }
                        {props?.data?.filter(device => device.deviceType === 2).length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 1 ? "active-button" : null} `} selected={activeTabDevice === 1} onClick={() => { toggleDevice(1); setDeviceType(2) }}>
                                CCM2
                            </li>
                        }
                        {props?.data?.filter(device => device.deviceType === 4).length > 0 &&
                            <li className={`list-item-icon  active-button-list-device pointer-button tab-style-mabt  ${activeTabDevice === 2 ? "active-button" : null} `} selected={activeTabDevice === 2} onClick={() => { toggleDevice(2); setDeviceType(4) }}>
                                CCM4
                            </li>
                        }
                    </ul>
                </span>
                <span className="filter-scada-component">
                    <Filter selectedDevice={selectedDevice} setSelectedDevice={setSelectedDevice}
                        end={props?.end}
                        start={props?.start}
                        dataSliced={props?.device}
                        devices={props?.device}
                        HeadersCsv={setHeadersCsv}
                        headersCsv={headersCsv}
                        variableTableSelfConsumption={variableTableSelfConsumption}
                        historicalData={true}
                        icon="text"
                        loading={loading}
                        setLoading={setLoading}
                        setStart={props?.setStart}
                        setEnd={props?.setEnd}
                        device={props.device}
                        setRefresh={props?.setRefresh}
                        activo={activo}
                        setActivo={setActivo}
                        data1={debouncedProduction}
                        checked={checked}
                        setChecked={setChecked}
                        updatedList={updatedList}
                        setUpdatedList={setUpdatedList}
                        props={props}
                        daily={props?.daily}
                        isMobile={props?.isMobile}
                        master={props?.master ? props?.master : props?.device}
                        masterPermission={props?.masterPermission} />
                </span>
                <div className="table-card " >
                    {(prod?.length === 0) ?
                        <Loader />
                        :
                        <>
                            {moment(props?.startt?.format('YYYY-MM-DD')).isAfter(props?.start?.format('YYYY-MM-DD')) || props?.daily !== "days" ?
                                <HistoricalTableProduction
                                    inverter={props?.inverter}
                                    isMobile={props?.isMobile}
                                    activo={activo}
                                    checked={updatedList}
                                    variableTableSelfConsumption={variableTableSelfConsumption}
                                    setHeadersCsv={setHeadersCsv}
                                    headersCsv={headersCsv}
                                    historicalData={historicalData}
                                    loading={loading}
                                    start={props?.start}
                                    daily={props?.daily}
                                    setDaily={props?.setDaily}
                                    data={props?.data}
                                    device={props.device}
                                    end={props?.end}
                                    childrenDevices={props.childrenDevices}
                                    deviceType={deviceType}
                                    setRefresh={props?.setRefresh}
                                    refresh={props?.refresh}
                                    now={fechaNow}
                                    selectedDevice={selectedDevice}
                                    setSelectedDevice={setSelectedDevice}
                                    dataSliced={props?.device}
                                    devices={props?.device}
                                    HeadersCsv={setHeadersCsv}
                                    icon="text"
                                    setLoading={setLoading}
                                    setStart={props?.setStart}
                                    setEnd={props?.setEnd}
                                    setActivo={setActivo}
                                    data1={debouncedProduction}
                                    setChecked={setChecked}
                                    updatedList={updatedList}
                                    etUpdatedList={setUpdatedList}
                                    props={props}
                                />
                                :
                                <CurrentTableProduction
                                    inverter={props?.inverter}
                                    isMobile={props?.isMobile}
                                    activo={activo}
                                    setHeadersCsv={setHeadersCsv}
                                    checked={updatedList}
                                    headersCsv={headersCsv}
                                    daily={props?.daily}
                                    setFinalData={setFinalData}
                                    finalData={finalData}
                                    deviceType={deviceType}
                                    data={props?.data}
                                    loading={loading}
                                    device={props.device}
                                    start={props?.start}
                                    end={props?.end}
                                    setRefresh={props?.setRefresh}
                                    refresh={props?.refresh} now={fechaNow}
                                    selectedDevice={selectedDevice}
                                    setSelectedDevice={setSelectedDevice}
                                    dataSliced={props?.device}
                                    devices={props?.device}
                                    HeadersCsv={setHeadersCsv}
                                    variableTableSelfConsumption={variableTableSelfConsumption}
                                    historicalData={true}
                                    icon="text"
                                    setLoading={setLoading}
                                    setStart={props?.setStart}
                                    setEnd={props?.setEnd}
                                    setActivo={setActivo}
                                    data1={debouncedProduction}
                                    setChecked={setChecked}
                                    updatedList={updatedList}
                                    setUpdatedList={setUpdatedList}
                                    props={props}
                                    setProd={setProd}
                                />
                            }
                        </>
                    }
                </div>
                <div className="graph-card ">
                    <GraphsPhotovoltaic loadingData={props?.loading} devicesSelect={updatedList} devices={props?.childrenDevices.filter(dev => dev.id_tipo_dispositivo === deviceType)} childrenDevices={children.filter(dev => dev.id_tipo_dispositivo !== 9)} currentData={prod}
                        data={prod} daily={props?.daily} start={props?.start} end={props?.end} idCcMaster={idMaster} />
                </div>
            </Grid>
        </>
    )
}