import React, { useEffect, useState } from 'react'
import { Grid, Button, Card } from "@mui/material"
import { Graphs } from './Graphs/Graphs.jsx'
import { headersScada } from 'services/headers'
import { urls } from 'utils/constant'
import { MenuByTab } from '../FilterDateScada/MenuByTab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Tables } from './Tables/Tables'

export const Meter = (props) => {
    const token = sessionStorage?.token
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [childrens, setChildrens] = useState()

    useEffect(() => {
        if (props?.devices?.numserie && token && props?.devices?.numserie) {
            const fetchData = () => {
                setLoading(true)
                if (props?.devices?.numserie && token && props?.devices?.numserie) {
                    fetch(urls.scada + `getCurrentData/${props?.devices?.numserie}/?idCCMaster=${props?.devices?.mabt_serial}`,
                        { method: 'GET', headers: headersScada(token) })
                        .then(res => res.json())
                        .then(data => {
                            setData(data)
                            setLoading(false)
                        })
                        .catch(err => {
                            console.error("ERROR. API Scada", err);
                            setLoading(true)
                        })
                }
            }
            const fecthDevices = () => {
                fetch(urls.scada + `getccm/${props?.devices?.numserie}?idCCMaster=${props?.devices?.mabt_serial}`, { method: 'GET', headers: headersScada(token) })
                    .then(res => res.json())
                    .then(data => setChildrens(data.childrenDevices))
                    .catch(err => console.error("ERROR. API Scada", err))
            }
            fetchData()
            fecthDevices()
        }
    }, [props?.devices?.numserie, token])

    useEffect(() => {
        if (childrens) {
            const getTenChildrens = () => {
                let getLength = 10 - childrens?.length
                for (let i = 0; i < getLength; i++) {
                    childrens.push({ numserie: '', data: '' })
                }
            }
            getTenChildrens()
        }
    }, [childrens])

    return (
        <>
            <Grid item lg={12}>
                <Grid container className='d-flex justify-content-center'>
                    <Grid item lg={12} className='mt-2'>
                        <Grid container className='d-flex justify-content-end'>
                            <Grid item lg={1} className='mt-1 d-flex justify-content-end'>
                                <Button className="btn-neutral-second btn-icon btn-animated-icon btn-transition-none d-20 p-0 m-2"
                                    onClick={props.handleClickLeft}>
                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon icon={faArrowLeft} className="font-size-xs" />
                                    </span>
                                </Button>
                            </Grid>

                            <MenuByTab start={props.start} end={props.end} setstart={props.setStart} setend={props.setEnd} titleButton={props.titleButton} setTitleButton={props.setTitleButton} />

                            <Grid item lg={1} className='mt-1'>
                                <Button className="btn-neutral-second btn-icon btn-animated-icon btn-transition-none d-20 p-0 m-2"
                                    onClick={props.handleClickRight}>

                                    <span className="btn-wrapper--icon">
                                        <FontAwesomeIcon icon={faArrowRight} className="font-size-xs" />
                                    </span>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} className='mt-2 '>
                        <Graphs device={props?.devices} start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart} now={props.now} startt={props.startt}
                            setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                            handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                    </Grid>
                    <Grid item lg={12} className='mt-2 mb-6'>
                        <Card className="shadow-m p-4 m-2"  >
                            <Tables closeDevices={props.closeDevices} device={props?.devices} data={data?.currentData} loading={loading} startt={props.startt}
                                start={props.start} end={props.end} groupData={props.groupData} setStart={props.setStart} now={props.now}
                                setEnd={props.setEnd} setgroupData={props.setgroupData} titleButton={props.titleButton} setTitleButton={props.setTitleButton}
                                handleClickRight={props.handleClickRight} handleClickLeft={props.handleClickLeft} daily={props.daily} setDaily={props.setDaily} />
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}