export const cerrarSesion = (token) => {
    //busco los usuarios
    const usuarios = JSON.parse(localStorage.getItem('usuarios'))
    //busco el email del usuario del token
    const user = usuarios.filter(datos => datos.token === token)
    //eliminar solo la sesion de ese token
    let resultado = usuarios.filter(usuario => ((usuario.email === user[0].email) && (usuario.token !== user[0].token)) || usuario.email !== user[0].email)
    //eliminar el token
    localStorage.removeItem(token)
    //actualizar los usuarios
    localStorage.setItem('usuarios', JSON.stringify(resultado))
}

export const cerrarOtrasSesiones = (token) => {
    //busco los usuarios
    let usuarios = JSON.parse(localStorage.getItem('usuarios'))
    //busco el email del usuario del token
    let user = usuarios.filter(datos => datos.token === token)
    //eliminar todos los usuarios con ese email y token distinto
    let resultado = usuarios.filter(usuario => ((usuario.email === user[0].email) && (usuario.token === user[0].token)) || usuario.email !== user[0].email)
    //eliminar los tokens de las otras sesiones
    usuarios.forEach(usuario => {
        if (usuario.email === user[0].email && usuario.token !== user[0].token) {
            localStorage.removeItem(usuario.token)
        }
    })
    //actualizar los usuarios
    localStorage.setItem('usuarios', JSON.stringify(resultado))
}

export const iniciarSesion = (email, token, data) => {
    if (localStorage.getItem('usuarios')) {
        let usuarios = JSON.parse(localStorage.getItem('usuarios'))
        usuarios.push({ 'email': email, 'token': token })
        localStorage.setItem('usuarios', JSON.stringify(usuarios))
    } else {
        localStorage.setItem('usuarios', JSON.stringify([{ 'email': email, 'token': token }]))
    }
    localStorage.setItem(token, JSON.stringify(data))
}

export const listUsers = (token) => {
    let listUsers = JSON.parse(localStorage.getItem('usuarios'))
    let mailUser = listUsers?.filter(user => user?.token === token)

    return mailUser?.[0]?.email
}