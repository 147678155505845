import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { urls } from 'utils/constant'
import InputStyled from 'components/Input/InputStyled'
import { Modal } from 'components/Modal/Modal'
import { validatorEmail } from 'utils/ValidatorForm'
import { headersUsersLogin } from 'services/headers'
import "../styles.scss"
import { useDispatch } from 'react-redux'
import { createToast } from 'reduxSlice/toastSlice'

export const RememberPassword = (props) => {
    const [modal, setModal] = useState(false)
    const [email, setEmail] = useState("")
    const dispatch = useDispatch()
    const [validMail, setValidMail] = useState(true)
    const [isDisabled, setIsDisabled] = useState(true)

    const toggle = () => setModal(!modal)

    const postRecoverPass = () => {
        let status
        fetch(urls.users + 'user/sendrecoverypass', { method: 'POST', redirect: 'follow', headers: headersUsersLogin, body: JSON.stringify({ 'email': email }) })
            .then(res => {
                status = res.status
                return res.json()
            }).then(data => {
                dispatch(createToast({ status: status, message: data.message }))

            }).catch(err => console.error("ERROR. API Users", err))
        setModal(!modal)
    }

    const validateEmail = () => {
        if (email.length > 0) {
            setValidMail(validatorEmail(email))
        } if (email.length === 0) {
            setValidMail(true)
        }
    }

    const handleBlurMail = () => {
        validateEmail()
    }

    useEffect(() => {
        setEmail(props.email)
        setValidMail(true)
    }, [modal])

    useEffect(() => {
        if (!validMail || email.length === 0) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [email, validMail])

    return (
        <>
            <a className="text-reset-password" onClick={toggle}>¿Olvidaste la contraseña?</a>
            <Modal open={modal} setOpen={setModal} buttonAction="Enviar" title="Recordar contraseña" functSave={postRecoverPass} disabled={isDisabled}>
                <Grid container className="pr-5 pl-5">
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                        <div className='pb-1'>
                            <small className="color-black">
                                Introduce el email para restablecer la contraseña:
                            </small>
                        </div>
                        <InputStyled fullWidth type="email"
                            value={email}
                            onChange={(e) => setEmail(e)}
                            error={!validMail}
                            errorMessage={'Email inválido'}
                            onBlur={handleBlurMail}
                            maxLength={50}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}