import React from 'react'
import { Box, Modal } from '@mui/material'
import { Button } from '@mui/material'

const styled = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border:"1px solid white",
    borderRadius:'10px',
    width: 600,
    backgroundColor: "#f75676",
    fontSize: 24,
    color: "#fff",
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
}

export const ModalErrorConfig = ({ children, open, setOpenConfigError}) => {

    return (
        <Modal
            keepMounted
            open={open}
            setOpenConfigError={setOpenConfigError}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={styled}>
                {children}
                <Button 
                    onClick={()=> setOpenConfigError(false)}
                    style={{
                        backgroundColor:'white',
                        color:'black'
                    }}
                    variant="contained"
                    size="medium"
                >Aceptar</Button>
            </Box>
        </Modal>
    )
}