import React from 'react'
import { toKiloW, getPercent, toKiloWFecha } from 'utils/functionsforGraphs'
import { Table } from "@mui/material"
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { StyledTableCell } from "components/Table/TableStyle"
import { StyledTableRow } from "components/Table/TableStyle"

export const TableGeneralMobile = (props) => {

    return (
        <div className='boder-table'>
            <TableContainer component={Paper} >
                <Table aria-label="customized table" >
                    <TableBody >
                        {props?.general?.map((data, i) => {
                            return (
                                props?.inverter === true ?

                                    <><>
                                        <StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[0]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{toKiloWFecha(data?.datetime, props?.daily)}</StyledTableCell>
                                        </StyledTableRow>
                                    </><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[1]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{toKiloW(data?.eagendia)}</StyledTableCell>
                                        </StyledTableRow></>
                                    :
                                    <><StyledTableRow key={i} className="data-row-mobile">
                                        <StyledTableCell className="data-table-mobile title">{props?.headTable?.[0]}</StyledTableCell>
                                        <StyledTableCell className="data-table-mobile">{toKiloWFecha(data?.datetime, props?.daily)}</StyledTableCell>
                                    </StyledTableRow><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[1]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{toKiloW(data?.vertida)}</StyledTableCell>
                                        </StyledTableRow><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[2]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile data">{toKiloW(data?.consumida)}</StyledTableCell>
                                        </StyledTableRow><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[3]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{toKiloW(data?.eagendia)}</StyledTableCell>
                                        </StyledTableRow><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[4]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{toKiloW(data?.eaconsdia)}</StyledTableCell>
                                        </StyledTableRow><StyledTableRow key={i} className="data-row-mobile">
                                            <StyledTableCell className="data-table-mobile title">{props?.headTable?.[5]}</StyledTableCell>
                                            <StyledTableCell className="data-table-mobile">{getPercent(data.eagendia, data.eaconsdia)} %</StyledTableCell>
                                        </StyledTableRow></>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    )
}